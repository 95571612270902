import React from 'react';

const BinocularsBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 986 986"
    width="986"
    height="986"
  >
    <defs>
      <linearGradient id="LiNGrV23VF" x1="-0.16" x2="0.89" y1="0.94" y2="-0.21">
        <stop offset="0" stopColor="#FFF" />
        <stop offset="0.45" stopColor="#FFF" stopOpacity="0.1" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#LiNGrV23VF)"
      fillRule="evenodd"
      d="M955.947 512.4h2.55l-95.39-180.582c-16.552-28.651-42.54-50.643-73.55-62.172-21.688-51.1-72.027-84.127-127.583-83.71-55.513.42-105.354 34.207-126.248 85.688a94.212 94.212 0 00-84.66 0c-21.002-51.823-71.341-85.687-127.231-85.687-55.933 0-106.27 33.864-127.271 85.687a141.695 141.695 0 00-69.364 60.879l-74.31 135.57C16.135 522.52 6.546 590.853 26.978 653.3c20.432 62.447 68.527 111.94 130.359 134.128 61.83 22.145 130.436 14.535 185.909-20.699 55.438-35.195 91.508-94.057 97.708-159.463.647-6.697.99-13.431.99-20.128 0-14.307-1.408-28.575-4.224-42.577a203.606 203.606 0 00-7.306-25.835c11.681-23.895 35.957-39.115 62.554-39.115 26.596 0 50.872 15.22 62.553 39.115a206.532 206.532 0 00-7.306 25.912 216.292 216.292 0 00-4.223 42.577c.038 6.506.342 13.012.99 19.481A212.843 212.843 0 00632 759.614c49.73 35.994 112.592 48.779 172.4 35.082a212.774 212.774 0 00139.865-106.728c29.031-54.106 33.293-118.105 11.68-175.555l.002-.014-199.111 217.41h.003a142.779 142.779 0 0097.327-37.896 142.691 142.691 0 0045.393-94.058 142.83 142.83 0 00-30.896-99.763l-7.42-9.056h-.57c-26.938-28.65-64.53-44.898-103.835-44.822a143.843 143.843 0 00-85.383 28.347 143.891 143.891 0 00-51.86 73.55 139.666 139.666 0 00-5.936 41.016c.114 37.894 15.22 74.193 42.082 100.942a143.014 143.014 0 00101.095 41.74H229.093c-35.424-.114-69.554-13.43-95.732-37.325-26.14-23.895-42.501-56.656-45.811-91.927a142.649 142.649 0 0127.776-98.926c21.232-28.347 52.242-47.828 87.019-54.601a142.668 142.668 0 01101.137 17.998 142.634 142.634 0 0162.857 81.273c4.033 13.242 6.05 26.977 6.05 40.827a143.132 143.132 0 01-42.12 100.981c-26.863 26.749-63.277 41.74-101.176 41.7L955.947 512.4z"
      opacity="1"
    />
  </svg>
);

export default BinocularsBgIcon;
