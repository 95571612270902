import React from 'react';

const HandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 73.5"
    width="45"
    height="73.5"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M36.816 11.19l.035-.048c.408-.565 1.203-1.665 2.697-.845 1.54.847-.495 4.223-.495 4.223s-3.772 6.604-4.122 7.248c-.344.647-.297 1.044-.297 1.044l-.247 5.066c-.991 6.854-4.27 8.741-4.27 8.741l1.009 35.746L18.12 73.5l.876-37.478s-.897-.594-2.682-1.69c-1.787-1.091-3.478-3.77-3.875-4.667-.4-.894-1.294-3.375-3.875-5.86-2.582-2.479-2.979-1.488-3.576-2.879-.6-1.387 1.385-1.984 1.385-1.984s5.364-.397 9.136 5.663c0 0 .138-.018.38-.027l.088-.203c-.056-.005-.089-.005-.089-.005h.089l.373-.865s.447-2.285.447-3.228c0-.941-.297-1.885-.297-1.885L13.272 7.269s-.644-1.885 1.044-2.532c1.688-.647 2.385.894 2.385.894s3.625 10.08 4.022 11.323c.4 1.238 1.294-.2 1.294-.2l-.197-12.91s-.25-2.135 2.035-2.335c2.287-.2 2.088 3.128 2.088 3.128s.197 11.124.246 12.417c.05 1.294.894.15.894.15s2.929-10.326 3.229-11.42c.297-1.094.794-2.285 2.432-2.038 1.637.247.993 3.773.993 3.773s-2.534 10.526-2.681 11.323c-.15.794.397.644.397.644s4.966-7.748 5.363-8.295z"
    />
  </svg>
);

export default HandIcon;
