import React, { Component } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import { ExploreConsumer } from '../../contexts/ExploreContext';
import { container, noTransiton } from './Layout.module.sass';
import Loader from '../Loader';
import MobileLoader from '../MobileLoader';
import MouseGlow from '../MouseGlow';
import Mouse from '../Mouse';
import ResponsiveRender from '../ResponsiveRender';
import Header from '../Header';
import MobileHeader from '../MobileHeader';
import Footer from '../Footer';
import Stats from '../Stats';
import MobileStats from '../MobileStats';
import Modal from '../Modal';
import MobileModal from '../MobileModal';
import Overlay from '../Overlay';

class Layout extends Component {
  state = { loadProgress: 10 };
  resetTransition = null;

  onLoad = () => {
    setTimeout(() => {
      this.setState({ loadProgress: 40 });
      this.props.getExploreData();
    }, 1000);
  };

  removeTransitions = () => {
    this.setState({ removeTransitions: true });
    clearTimeout(this.resetTransition);
    this.resetTransition = setTimeout(
      () => this.setState({ removeTransitions: false }),
      300
    );
  };

  onUserReady = () => {
    this.setState({ userReady: true });
  };

  componentDidUpdate(prevProps) {
    const {
      exploreData,
      statsDataReady,
      hasToken,
      getUserData,
      authError,
      hasInsufficientData
    } = this.props;

    if (exploreData && !prevProps.exploreData) {
      if (hasToken) {
        this.setState({ loadProgress: 70 });
        getUserData();
      } else {
        this.setState({ loadProgress: 100 });
      }
    }

    if (
      (statsDataReady && !prevProps.statsDataReady) ||
      (!prevProps.authError && authError) ||
      (hasInsufficientData && !prevProps.hasInsufficientData)
    ) {
      this.setState({ loadProgress: 100 });
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.onLoad);
    window.addEventListener('resize', this.removeTransitions);
  }

  render() {
    const { loadProgress, removeTransitions, userReady } = this.state;
    const { hasToken } = this.props;

    return (
      <div className={cx(container, { [noTransiton]: removeTransitions })}>
        <Overlay />
        <MouseGlow />
        <Mouse />
        <ResponsiveRender
          isLoading={!userReady}
          mobileComponent={MobileHeader}
          desktopComponent={Header}
        />
        <ResponsiveRender
          isLoading={!userReady}
          mobileComponent={MobileStats}
          desktopComponent={Stats}
        />
        <ResponsiveRender
          mobileComponent={MobileLoader}
          desktopComponent={Loader}
          progress={loadProgress}
          onUserReady={this.onUserReady}
        />
        {hasToken && userReady && <Footer />}
        {hasToken && (
          <ResponsiveRender
            mobileComponent={MobileModal}
            desktopComponent={Modal}
          />
        )}
      </div>
    );
  }
}

export default UserConsumer(ExploreConsumer(Layout));
