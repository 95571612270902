import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  teamMemberCardContainer,
  teamMemberCard,
  isPassiveMember,
  memberImage,
  memberName,
  social
} from './Team.module.sass';
import DrumPads from '../DrumPads';
import TwitterIcon from '../../vectors/TwitterIcon';
import DribbbleIcon from '../../vectors/DribbbleIcon';
import GithubIcon from '../../vectors/GithubIcon';
import InstagramIcon from '../../vectors/InstagramIcon';
import { scrollViewListener } from '../../utils';

const TeamMemberCard = ({
  firstName,
  lastName,
  coverImage,
  twitterUrl,
  dribbbleUrl,
  githubUrl,
  instagramUrl,
  className
}) => {
  const [containerInView, setContainerInview] = useState(false);

  useEffect(() => {
    scrollViewListener(containerRef.current, 0.2, () =>
      setContainerInview(true)
    );
  }, []);

  const containerRef = useRef();

  return (
    <div
      ref={containerRef}
      className={cx(className, teamMemberCardContainer, {
        [isPassiveMember]: !containerInView
      })}
    >
      <DrumPads total={20} />
      <div className={teamMemberCard}>
        <div
          className={memberImage}
          style={{ backgroundImage: `url(${coverImage})` }}
        />
        <h2 className={memberName}>
          <span>{firstName}</span> <br />
          <span>{lastName}</span>
        </h2>
        <div className={social}>
          {twitterUrl && (
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
              {' '}
              <TwitterIcon />{' '}
            </a>
          )}
          {dribbbleUrl && (
            <a href={dribbbleUrl} target="_blank" rel="noopener noreferrer">
              {' '}
              <DribbbleIcon />{' '}
            </a>
          )}
          {githubUrl && (
            <a href={githubUrl} target="_blank" rel="noopener noreferrer">
              {' '}
              <GithubIcon />{' '}
            </a>
          )}
          {instagramUrl && (
            <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
              {' '}
              <InstagramIcon />{' '}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

TeamMemberCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  coverImage: PropTypes.string,
  twitterUrl: PropTypes.string,
  dribbbleUrl: PropTypes.string,
  githubUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  className: PropTypes.string
};

export default TeamMemberCard;
