import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  userSummary,
  topArtistCaption,
  topArtistName,
  topArtistSubcaption,
  artistsGrid,
  listActions
} from './MobileTopArtists.module.sass';
import MobileArtistCard from '../MobileArtistCard';
import { MobileTopModalButton } from '../Button';
import MobilePlaylistCard from '../MobilePlaylistCard';
import { getImageUrl } from '../../utils';
import { LOAD_COMPLETE_ENTANCE_DELAY as entranceDelay } from '../../config';

const MobileTopArtists = ({ shortTermTopArtists, setActiveTopList }) => {
  const artists = shortTermTopArtists
    .slice(0, 5)
    .map(({ id, uri, name, images }) => ({
      id,
      uri,
      artistName: name,
      artistCover: getImageUrl(images)
    }));

  return (
    <section className={container}>
      <div className={userSummary}>
        <h1 className={topArtistCaption}>
          <span>Grooving to</span> <br />
          <span className={topArtistName}>{artists[0].artistName}</span>
        </h1>
        <p className={topArtistSubcaption}>
          <span>Nobody does it like this artist for you!</span>
        </p>
      </div>
      <div className={artistsGrid}>
        {artists.map((artist, index) => (
          <MobileArtistCard
            key={index}
            isFeatured={index === 0}
            maskOut={true}
            title={index === 0 ? 'Your Top Artist' : `#${index + 1}`}
            renderDelay={entranceDelay + 300 + index * 100}
            columnDelay={index === 0 ? 0 : ((index + 1) % 2) * 100}
            {...artist}
          />
        ))}
      </div>
      <div className={listActions}>
        <MobileTopModalButton onClick={() => setActiveTopList('artists')} />
        <MobilePlaylistCard />
      </div>
    </section>
  );
};

export default UserConsumer(MobileTopArtists);
