import React from 'react';

const TypographyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50"
    height="50"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M29.229 40.409h-6.613l-1.665-4.904H8.894L7.23 40.409H.616L11.863 8.917h6.119l11.247 31.492zm8.053.674c2.88 0 5.084-.9 6.253-2.564h.09v1.89h5.759V24.933c0-5.579-3.78-9.043-9.763-9.043-5.488 0-9.717 3.149-10.437 7.873l5.893 1.304c.45-2.429 2.07-3.824 4.544-3.824 2.07 0 3.284.81 3.284 2.205 0 1.26-1.17 1.934-4.948 2.834-6.119 1.485-8.503 3.644-8.503 7.648 0 4.32 3.104 7.153 7.828 7.153zM14.878 18.05l-3.96 11.607h8.009l-3.96-11.607h-.09zm20.74 15.476c0 1.44.989 2.16 2.923 2.16 2.7 0 4.59-1.35 4.59-3.33v-3.464h-.09c-.348.617-1.124 1.102-2.7 1.625l-.855.265c-2.879.765-3.869 1.485-3.869 2.744z"
    />
  </svg>
);

export default TypographyIcon;
