import React from 'react';

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M15.001 4h-.003c-2.985 0-3.36.013-4.533.066-1.17.054-1.97.24-2.67.511a5.386 5.386 0 00-1.948 1.269 5.4 5.4 0 00-1.27 1.948c-.272.7-.458 1.5-.51 2.67C4.013 11.637 4 12.013 4 15c0 2.987.013 3.361.067 4.535.053 1.17.239 1.97.51 2.67a5.39 5.39 0 001.27 1.948 5.386 5.386 0 001.947 1.27c.7.272 1.5.457 2.67.51 1.174.054 1.548.067 4.536.067 2.987 0 3.361-.013 4.534-.066 1.171-.054 1.972-.24 2.672-.511a5.382 5.382 0 001.947-1.27 5.4 5.4 0 001.27-1.948c.27-.7.455-1.5.51-2.67C25.986 18.36 26 17.987 26 15s-.014-3.362-.067-4.536c-.055-1.17-.24-1.97-.51-2.67a5.399 5.399 0 00-1.27-1.948 5.376 5.376 0 00-1.948-1.269c-.701-.272-1.501-.457-2.672-.51C18.36 4.012 17.986 4 15 4zm-.367 1.982h.367c2.937 0 3.285.01 4.445.064 1.072.049 1.655.228 2.042.378.514.2.88.438 1.264.823.385.385.624.752.824 1.265.15.387.33.97.378 2.042.053 1.16.065 1.508.065 4.443 0 2.936-.012 3.284-.065 4.444-.049 1.073-.228 1.655-.378 2.042-.2.513-.439.879-.824 1.264-.385.385-.75.623-1.264.822-.387.151-.97.33-2.042.38-1.16.052-1.508.063-4.445.063-2.937 0-3.285-.011-4.445-.064-1.072-.05-1.654-.228-2.042-.379-.514-.2-.88-.438-1.265-.823a3.41 3.41 0 01-.824-1.264c-.15-.387-.33-.97-.378-2.042-.053-1.16-.064-1.508-.064-4.445 0-2.938.01-3.284.064-4.444.049-1.072.228-1.654.378-2.042.2-.513.439-.88.824-1.265a3.414 3.414 0 011.265-.823c.387-.152.97-.33 2.042-.38 1.015-.045 1.408-.06 3.458-.061v.002h.62zm4.919 3.146a1.32 1.32 0 112.64 0 1.32 1.32 0 01-2.64 0zM15 9.351a5.65 5.65 0 100 11.298 5.65 5.65 0 000-11.298zM18.668 15a3.667 3.667 0 10-7.334 0 3.667 3.667 0 007.334 0z"
    />
  </svg>
);

export default InstagramIcon;
