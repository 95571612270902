import React from 'react';

const BoltGreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
    <g opacity="1">
      <g opacity="1">
        <path
          fill="rgb(51,255,122)"
          fillOpacity="1"
          opacity="1"
          fillRule="evenodd"
          d="M28.070844444444443,16.61863518005208 L24.159733333333335,16.61863518005208 C23.840288,16.61863518005208 23.590275555555557,16.394894624636517 23.6264,16.1061457295879 L24.230559999999997,7.146539952242103 C24.372227555555554,5.09914203775034 21.386115555555556,4.2666666666666675 20.212426666666666,6.026589230073878 L10.08051555555556,20.93857310278348 C9.191626666666671,22.251027354796392 10.222183111111116,23.914698153281257 11.929120000000005,23.914698153281257 L15.840231111111112,23.914698153281257 C16.159676444444447,23.914698153281257 16.40968888888889,24.138438708696818 16.373564444444447,24.427187603745438 L15.769404444444447,33.38679338109123 C15.62773688888889,35.43419129558299 18.61384888888889,36.266666666666666 19.78753777777778,34.50674410325946 L29.920871111111108,19.563721596249763 C30.80837333333333,18.282529968681377 29.777816888888886,16.61885917019651 28.070844444444443,16.61863518005208Z"
        />
      </g>
    </g>
  </svg>
);

export default BoltGreenIcon;
