import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as vibrant from 'node-vibrant';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { UserConsumer } from '../../contexts/UserContext';
import styles from './TopArtists.module.sass';
import MultiLineEllipsis from '../MultiLineEllipsis';
import DrumPads from '../DrumPads';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import { SpotifyLogo } from '../AnimatedVectors';
import { AudioControl } from '../AnimatedVectors';
import {
  scrollViewListener,
  getPopularityGroup,
  playAudio,
  pauseAudio,
  fadeOutAudio,
  getImageUrl,
  preloadImages
} from '../../utils';
import {
  BLACK as black,
  AUDIO_LOADING_DELAY as audioLoadingDelay,
  AUDIO_FADE_DURATION as audioFadeDuration
} from '../../config';

class ArtistCard extends Component {
  state = { audioState: 'paused' };
  containerRef = createRef();
  topTrackPreviewRef = createRef();
  topTrackProgressRef = createRef();
  mousePlayTimeout = null;

  onMouseEnter = () => {
    const { activateGlow, audioAutoPreview } = this.props;
    const {
      hasEnteredCard,
      canPlayAudio,
      mouseLeavePause,
      hasInitializedPlay
    } = this.state;

    activateGlow(false);
    this.setState({
      cardActive: true,
      animateCardIn: true
    });

    setTimeout(() => {
      this.setState({ animateCardIn: false });
    }, 1000);

    if (!hasEnteredCard) {
      this.setState({
        audioState: 'loading',
        hasEnteredCard: true
      });

      setTimeout(() => this.topTrackPreview.load(), audioLoadingDelay);
    }

    if (
      canPlayAudio &&
      audioAutoPreview &&
      (!hasInitializedPlay || mouseLeavePause)
    ) {
      this.mousePlayTimeout = setTimeout(() => {
        const { isPlaying, hasInitializedPlay, mouseLeavePause } = this.state;

        if (!isPlaying && (!hasInitializedPlay || mouseLeavePause)) {
          playAudio(this.topTrackPreview);
          this.setState({
            isPlaying: true,
            mouseLeavePause: false
          });
        }

        !hasInitializedPlay && this.setState({ hasInitializedPlay: true });
      }, audioFadeDuration);
    }
  };

  onMouseLeave = () => {
    clearTimeout(this.mousePlayTimeout);
    const { canPlayAudio, isPlaying } = this.state;

    this.props.activateGlow(true);

    if (canPlayAudio && isPlaying) {
      pauseAudio(this.topTrackPreview);

      this.setState({
        isPlaying: false,
        audioState: 'paused',
        mouseLeavePause: true
      });
    }

    this.setState({
      cardActive: false,
      showPlayer: false
    });
  };

  onCanPlayAudio = () => {
    const { audioAutoPreview } = this.props;
    const { cardActive, hasPlayedThrough } = this.state;

    if (hasPlayedThrough) {
      return;
    }

    const autoPreview = cardActive && audioAutoPreview;

    this.setState(
      {
        canPlayAudio: true,
        audioState: autoPreview ? 'playing' : 'paused'
      },
      () => {
        autoPreview && this.togglePlayState();
      }
    );
  };

  onAudioTimeUpdate = () => {
    const { currentTime } = this.topTrackPreview;
    const progress = currentTime / 30;
    const topTrackProgressContainer = this.topTrackProgressRef.current;

    if (topTrackProgressContainer) {
      topTrackProgressContainer.style.transform = `scaleX(${progress})`;
    }

    if (currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.topTrackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' }, () => {
      if (this.state.trackEnd) {
        setTimeout(() => this.setState({ trackEnd: false }), 500);
      }
    });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      hasPlayedThrough: true,
      trackEnd: true,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { canPlayAudio, isPlaying, hasInitializedPlay } = this.state;

    if (!canPlayAudio) {
      return;
    }

    isPlaying
      ? pauseAudio(this.topTrackPreview)
      : playAudio(this.topTrackPreview);
    !hasInitializedPlay && this.setState({ hasInitializedPlay: true });

    this.setState({
      isPlaying: !isPlaying,
      mouseLeavePause: false
    });
  };

  setPlayerView = showPlayer => {
    this.setState({ showPlayer });
  };

  setTrackProperties = async () => {
    const { artistsTopTracks, id } = this.props;
    const trackImages = artistsTopTracks[id][0].album.images;
    const topTrackImage = getImageUrl(trackImages);
    await preloadImages([topTrackImage]);
    this.setState({ topTrackImage });
    const colors = await vibrant.from(topTrackImage).getPalette();
    const [r, g, b] = colors.DarkVibrant._rgb;
    const vibrantColor = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(
      b
    )})`;
    setTimeout(() => {
      this.setState({ vibrantColor });
    }, 1000);
  };

  onEnterView = () => {
    const { inViewDelay } = this.props;

    setTimeout(() => {
      this.setState({ containerInView: true });
    }, inViewDelay);

    setTimeout(() => {
      this.setState({ addEllipsisText: true });
    }, 1100);
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  componentDidMount() {
    this.container = this.containerRef.current;
    this.topTrackPreview = this.topTrackPreviewRef.current;
    this.setTrackProperties();
    scrollViewListener(this.container, 0.2, this.onEnterView);
  }

  render() {
    const {
      id,
      artistsTopTracks,
      className,
      isFeatured,
      artistCover,
      title,
      artistName,
      popularity,
      followers,
      uri,
      setActiveArtist,
      setActiveTrack
    } = this.props;
    const {
      cardActive,
      containerInView,
      topTrackImage,
      audioState,
      trackEnd,
      vibrantColor,
      addEllipsisText,
      animateCardIn
    } = this.state;
    const topTrack = artistsTopTracks[id][0];
    const showPlayer = this.state.showPlayer && vibrantColor;
    const playerBgColor = showPlayer ? vibrantColor : black;

    return (
      <div
        ref={this.containerRef}
        className={cx(styles.artistCardContainer, className, {
          [styles.featured]: isFeatured,
          [styles.cardActive]: cardActive
        })}
      >
        <DrumPads total={isFeatured ? 20 : 9} />
        <div
          className={cx(styles.artistCard, {
            [styles.isCardPassive]: !containerInView
          })}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={() => setActiveArtist(id)}
        >
          <img
            alt={artistName}
            src={artistCover}
            className={cx(styles.artistCover, {
              [styles.trackPlayerActive]: showPlayer,
              [styles.cardIn]: animateCardIn
            })}
          />
          <div className={styles.titleColumn}>
            <h3>{title}</h3>
            <h2 className={styles.artistName}>
              <span>
                <MultiLineEllipsis text={artistName} lines={2} />
              </span>
            </h2>
          </div>
          {topTrack.preview_url && (
            <>
              <div
                className={styles.audioControl}
                onClick={this.stopPropagation}
              >
                <span
                  className={cx(styles.audioToggle, {
                    [styles.playerActive]: showPlayer
                  })}
                  onMouseEnter={() => this.setPlayerView(true)}
                  onClick={this.togglePlayState}
                >
                  <AudioControl audioState={audioState} />
                </span>
              </div>
              <div
                className={cx(styles.trackCoverBg, {
                  [styles.active]: showPlayer
                })}
                onClick={this.stopPropagation}
                onMouseLeave={() => this.setPlayerView(false)}
                style={{ background: playerBgColor }}
              />
            </>
          )}
          {addEllipsisText && (
            <div
              className={cx(styles.trackDetails, {
                [styles.active]: showPlayer
              })}
            >
              <div className={styles.playerData}>
                <div
                  className={styles.trackCover}
                  style={{ backgroundImage: `url(${topTrackImage})` }}
                />
                <div className={styles.trackProgress}>
                  <div
                    className={cx({ [styles.progressEnd]: trackEnd })}
                    ref={this.topTrackProgressRef}
                  />
                </div>
              </div>
              <h4 className={styles.topTrackName}>
                <span>
                  <MultiLineEllipsis text={topTrack.name} lines={2} />
                </span>
              </h4>
            </div>
          )}
          <div
            className={cx(styles.trackActions, { [styles.active]: showPlayer })}
            onMouseEnter={() => this.setPlayerView(true)}
            onClick={this.stopPropagation}
          >
            <div>
              <span onClick={() => setActiveTrack(topTrack.id)}>
                <ExpandAltIcon />
              </span>
              <a href={topTrack.uri}>
                <SpotifyLogo />
              </a>
            </div>
          </div>
          <div
            className={cx(styles.trackPlayerBackdrop, {
              [styles.active]: showPlayer
            })}
          />
          <div className={styles.artistActions}>
            <div>
              <span>
                <ExpandAltIcon />
              </span>
              <a href={uri} onClick={this.stopPropagation}>
                <SpotifyLogo />
              </a>
            </div>
          </div>
          <ul className={styles.artistInfo}>
            {isFeatured && (
              <>
                <li>
                  <h3>Popularity</h3>
                  <span className={styles.value}>
                    {getPopularityGroup(popularity).label}
                  </span>
                </li>
                <li>
                  <h3>Followers</h3>
                  <span className={styles.value}>
                    {followers ? followers.toLocaleString() : 'N/A'}
                  </span>
                </li>
              </>
            )}
            {addEllipsisText && (
              <li>
                <h3>Top Track</h3>
                <span className={styles.value}>
                  <MultiLineEllipsis text={topTrack.name} lines={2} />
                </span>
              </li>
            )}
          </ul>
          <audio
            ref={this.topTrackPreviewRef}
            preload="none"
            src={topTrack.preview_url}
            onCanPlay={this.onCanPlayAudio}
            onPlay={this.onAudioPlay}
            onPause={this.onAudioPause}
            onEnded={this.onAudioEnd}
            onTimeUpdate={this.onAudioTimeUpdate}
          />
        </div>
        <span
          className={cx(styles.cardGlow, {
            [styles.cardGlowActive]: cardActive
          })}
        />
      </div>
    );
  }
}

ArtistCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  artistCover: PropTypes.string,
  isFeatured: PropTypes.bool,
  title: PropTypes.string,
  artistName: PropTypes.string,
  popularity: PropTypes.number,
  followers: PropTypes.number,
  uri: PropTypes.string,
  inViewDelay: PropTypes.number
};

export default ExperienceConsumer(UserConsumer(ArtistCard));
