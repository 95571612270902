import React from 'react';

const SettingsGreenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M8.63 9.004H6.78a.849.849 0 01-.866-.866c0-.491.398-.867.866-.867h1.85C9.004 5.961 10.2 5 11.604 5c1.405 0 2.6.96 2.974 2.271h8.64a.85.85 0 01.867.867.865.865 0 01-.866.866h-8.641c-.374 1.311-1.57 2.272-2.974 2.272-1.406 0-2.6-.96-2.975-2.272zm4.38-.866a1.41 1.41 0 00-1.405-1.405c-.75 0-1.406.656-1.406 1.405 0 .75.61 1.405 1.406 1.405a1.38 1.38 0 001.405-1.405zm10.211 5.996h-1.78c-.375-1.312-1.57-2.272-2.974-2.272-1.405 0-2.6.984-2.998 2.272h-8.69a.865.865 0 00-.866.866c0 .468.376.866.867.866h8.689c.375 1.312 1.569 2.272 2.974 2.272 1.405 0 2.6-.96 2.974-2.272h1.78a.865.865 0 00.867-.866.83.83 0 00-.843-.866zm-6.16.866a1.41 1.41 0 001.406 1.405c.749 0 1.405-.61 1.405-1.405a1.38 1.38 0 00-1.405-1.405A1.38 1.38 0 0017.062 15zm6.16 5.996H14.58c-.375-1.312-1.569-2.272-2.974-2.272-1.405 0-2.6.96-2.974 2.272H6.78a.865.865 0 00-.866.866.85.85 0 00.866.867h1.85C9.006 24.04 10.2 25 11.605 25c1.405 0 2.6-.96 2.974-2.271h8.641a.865.865 0 00.866-.867.849.849 0 00-.865-.866zm-13.022.866a1.41 1.41 0 001.406 1.405c.749 0 1.405-.609 1.405-1.405a1.38 1.38 0 00-1.405-1.405 1.38 1.38 0 00-1.406 1.405z"
    />
  </svg>
);

export default SettingsGreenIcon;
