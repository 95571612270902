import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  filterForm,
  exitFilterForm,
  timeOptions,
  sortOptions,
  orderOptions,
  cardRadio,
  iconCardRadio,
  actionButton
} from './MobileTopList.module.sass';
import Fieldset from '../Fieldset';
import CardRadio from '../CardRadio';
import Button from '../Button';
import StarIcon from '../../vectors/StarIcon';
import PublishIcon from '../../vectors/PublishIcon';
import TypographyIcon from '../../vectors/TypographyIcon';

const ListFilterForm = ({
  title,
  timeRange,
  sort,
  order,
  onSubmit,
  willExit
}) => {
  const [period, setPeriod] = useState(timeRange);
  const [sortType, setSortType] = useState(sort);
  const [orderType, setOrderType] = useState(order);
  const onFormSubmit = e => {
    e.preventDefault();
    onSubmit(period, sortType, orderType);
  };

  return (
    <form
      className={cx(filterForm, { [exitFilterForm]: willExit })}
      onSubmit={onFormSubmit}
    >
      <Button className={actionButton}>Apply</Button>
      <h2>{title}</h2>
      <Fieldset legend="TIME RANGE">
        <div className={timeOptions}>
          <CardRadio
            className={cardRadio}
            value={period}
            name="shortTerm"
            onChange={setPeriod}
            title="Recent"
          />
          <CardRadio
            className={cardRadio}
            value={period}
            name="mediumTerm"
            onChange={setPeriod}
            title="6 Months"
          />
          <CardRadio
            className={cardRadio}
            value={period}
            name="longTerm"
            onChange={setPeriod}
            title="All Time"
          />
        </div>
      </Fieldset>
      <Fieldset legend="SORT BY">
        <div className={sortOptions}>
          <CardRadio
            className={cx(cardRadio, iconCardRadio)}
            value={sortType}
            name={0}
            onChange={setSortType}
            title="Rank"
            icon={PublishIcon}
          />
          <CardRadio
            className={cx(cardRadio, iconCardRadio)}
            value={sortType}
            name={1}
            onChange={setSortType}
            title="Popularity"
            icon={StarIcon}
          />
          <CardRadio
            className={cx(cardRadio, iconCardRadio)}
            value={sortType}
            name={2}
            onChange={setSortType}
            title="Name"
            icon={TypographyIcon}
          />
        </div>
      </Fieldset>
      <Fieldset legend="ORDER">
        <div className={orderOptions}>
          <CardRadio
            className={cardRadio}
            value={orderType}
            name={0}
            onChange={setOrderType}
            title="First to Last"
          />
          <CardRadio
            className={cardRadio}
            value={orderType}
            name={1}
            onChange={setOrderType}
            title="Last to First"
          />
        </div>
      </Fieldset>
    </form>
  );
};

ListFilterForm.propTypes = {
  title: PropTypes.string.isRequired,
  timeRange: PropTypes.string.isRequired,
  sort: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  willExit: PropTypes.bool
};

export default ListFilterForm;
