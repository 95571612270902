export const SPOTIFY_ACCESS_URL = 'https://accounts.spotify.com/authorize';
export const SPOTIFY_SCOPES =
  'user-read-private user-read-email user-top-read user-read-recently-played playlist-modify-public playlist-modify-private ugc-image-upload';
export const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;

export const INSPIRATION_PLAYLIST_URI =
  'spotify:playlist:05k0S6kQkjvvtvHjbTrvSl';

export const UPDATE_PLAYLIST_ID = 'updatePlaylist';
export const SAVE_PLAYLIST_ID = 'saveToSpotify';

export const TWITTER_ID = '1152195874048610304';

export const APP_URL = 'https://cruuunchify.com';
export const SHARE_URL = 'https://cruuunchify-share.web.app';
export const TASTE_SHARE_URL = 'https://cruuunchify-share.web.app/taste';

export const FACEBOOK_SHARE_URL =
  'http://www.facebook.com/sharer/sharer.php?u=';
export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?text=';

export const APP_REPO_URL = 'https://github.com/ekwonye-richard/cruuunchify';
export const DESIGN_ASSETS_URL = 'https://dribbble.com/JoAccord';
export const SPOTIFY_API_DOCUMENTATION_URL =
  'https://developer.spotify.com/documentation/web-api/';

export const TIME_RANGES = {
  short_term: 'shortTerm',
  medium_term: 'mediumTerm',
  long_term: 'longTerm'
};

export const V_SCROLL_OPTIONS = {
  mouseMultiplier: 0.1,
  preventTouch: true,
  passive: false,
  keyStep: 200
};

export const MOBILE_WIDTH_BREAKPOINT = 576;

export const BLACK = '#111112';

export const DEFAULT_SCROLL_EASE = 0.1;

export const MOBILE_COVERS_EASE = 0.01;

export const ARSTISTS_BUBBLE_CONTAINER_TRANSLATE_LEFT = 515;

export const AUDIO_LOADING_DELAY = 2000;

export const AUDIO_FADE_DURATION = 1000;

export const LOAD_COMPLETE_ENTANCE_DELAY = 1000;
export const MOBILE_MODAL_ENTANCE_DELAY = 1200;

export const SECTION_SUMMARY_ENTRANCE_FRACTION = 0.4;
export const MOBILE_SECTION_SUMMARY_ENTRANCE_FRACTION = 0.4;
export const STREAMING_INSIGHT_BLOCK_ENTRANCE_FRACTION = 0.7;

export const GENRE_LEVEL_COLORS = [
  'linear-gradient(127.09deg, #E43F3F 0%, #E46C3F 100%)',
  'linear-gradient(134.4deg, #20AC9A 0%, #1DB954 52%, #91C040 100%)',
  'linear-gradient(134.4deg, #20AC9A 0%, #1DB954 52%, #91C040 100%)',
  'linear-gradient(268.81deg, #3F86E4 0%, #1B52DF 100%)',
  'linear-gradient(268.81deg, #3F86E4 0%, #1B52DF 100%)'
];
