import React from 'react';

const PeopleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path
      fill="rgb(255,255,255)"
      fillRule="evenodd"
      d="M13.808 6.798a3.63 3.63 0 01-1.079 2.607 3.709 3.709 0 01-2.607 1.078 3.63 3.63 0 01-2.606-1.078 3.709 3.709 0 01-1.08-2.607 3.63 3.63 0 011.08-2.607 3.709 3.709 0 012.606-1.079 3.63 3.63 0 012.607 1.079 3.738 3.738 0 011.079 2.607zM24.372 9.7c0 .854-.315 1.573-.854 2.112-.54.54-1.258.854-2.112.854a2.991 2.991 0 01-2.113-.854c-.539-.539-.854-1.258-.854-2.112 0-.809.315-1.573.854-2.112.54-.54 1.259-.854 2.113-.854.809 0 1.573.314 2.112.854.54.539.854 1.258.854 2.112zm-8.61 7.453a6.305 6.305 0 011.848 4.47v4.264H2.5V21.67c0-1.7.684-3.307 1.848-4.471 1.2-1.2 2.803-1.894 4.471-1.894h2.472c1.7 0 3.307.684 4.47 1.849zM27.5 25.887h-6.796v-4.263c0-1.49-.441-2.946-1.248-4.21a6.872 6.872 0 011.247-.131h1.377c1.463 0 2.837.575 3.841 1.579S27.5 20.5 27.5 23.703v2.184z"
    />
  </svg>
);

export default PeopleIcon;
