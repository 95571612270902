import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  isBarPassive,
  genreBar,
  genreBarLevelText,
  genreBarLevel,
  genreName
} from './MobileTopGenre.module.sass';
import { scrollViewListener } from '../../utils';

const GenreBar = ({ className, name, level, levelColor }) => {
  const [inView, setInview] = useState(false);
  const barContainer = useRef(null);

  useEffect(() => {
    scrollViewListener(barContainer.current, 0.7, () => setInview(true));
  }, []);

  return (
    <div
      ref={barContainer}
      className={cx(genreBar, { [isBarPassive]: !inView })}
    >
      <div
        className={genreBarLevel}
        style={{ width: `${level}%`, background: levelColor }}
      />
      <h3 className={genreName}>{name}</h3>
      <span className={genreBarLevelText}>{level}%</span>
    </div>
  );
};

GenreBar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  level: PropTypes.number,
  levelColor: PropTypes.string
};

export default GenreBar;
