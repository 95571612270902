import React, { Component } from 'react';
import cx from 'classnames';

import {
  landing,
  isPassive,
  caption,
  captionContent,
  listen,
  description,
  dots,
  login,
  loginContent,
  loginButton,
  explore,
  exploreContent,
  exploreIcons,
  guest,
  exploreAction
} from './Landing.module.sass';
import { UserConsumer } from '../../contexts/UserContext';
import { ExploreConsumer } from '../../contexts/ExploreContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { Dots } from '../Patterns';
import ArtistsCarousel from './ArtistsCarousel';
import DrumPads from '../DrumPads';
import Button from '../Button';
import { SpotifyLogo } from '../AnimatedVectors';
import InstrumentIcon from '../../vectors/InstrumentIcon';
import BoltIcon from '../../vectors/BoltIcon';
import { LOAD_COMPLETE_ENTANCE_DELAY as entranceDelay } from '../../config';

class Landing extends Component {
  constructor() {
    super();

    this.state = {
      containerColumns: 0,
      totalColumns: 18
    };
  }

  setContainerColumns = () => {
    const rows = 10;
    const minColumns = 16;

    const viewportColumns = Math.ceil(
      window.innerWidth / (window.innerHeight / rows)
    );

    const totalColumns = Math.max(viewportColumns, minColumns);
    const containerArea = minColumns * (window.innerHeight / rows);
    const maxTranslate = Math.min(0, window.innerWidth - containerArea);
    const sectionStyle = {
      width: `calc((100vh / ${rows}) * ${totalColumns})`,
      gridTemplateColumns: `repeat(${totalColumns}, calc(100vh / ${rows}))`
    };

    this.setState({ totalColumns, sectionStyle });
    this.props.setMaxTranslate(maxTranslate);
  };

  componentDidMount() {
    const { disableVScrollEase } = this.props;
    disableVScrollEase(true);
    setTimeout(() => disableVScrollEase(false), 200);
    setTimeout(() => this.setState({ isReady: true }), entranceDelay);

    this.setContainerColumns();
    window.addEventListener('resize', this.setContainerColumns);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setContainerColumns);
  }

  render() {
    const { getAccess, exploreData } = this.props;
    const { isReady, totalColumns, sectionStyle } = this.state;
    const extraDrumPads = totalColumns * 10 - 92;
    const { artists, title, uri, images: playlistImages } = exploreData;

    return (
      <section
        className={cx(landing, { [isPassive]: !isReady })}
        style={sectionStyle}
      >
        <div className={caption}>
          <DrumPads total={28} />
          <Dots className={dots} isPassive={!isReady} />
          <div className={captionContent}>
            <h1>
              <span>Discover how</span>
            </h1>
            <h1>
              <span>you</span> <span className={listen}>listen.</span>
            </h1>

            <p className={description}>
              <span>
                Explore your music taste profile and create awesome playlists.
              </span>
            </p>
          </div>
        </div>

        <div className={login}>
          <DrumPads total={4} />
          <div className={loginContent}>
            <Button className={loginButton} onClick={getAccess}>
              <SpotifyLogo /> Continue with spotify
            </Button>
          </div>
        </div>

        {artists.length > 0 && (
          <ArtistsCarousel
            isPassive={!isReady}
            artists={artists}
            playlistImages={playlistImages}
            playlistTitle={title}
            playlistUri={uri}
          />
        )}

        <div className={explore}>
          <DrumPads total={6} />
          <div className={exploreContent}>
            <div className={exploreIcons}>
              <div>
                <span>
                  <InstrumentIcon />
                </span>
                <span>
                  <BoltIcon />
                </span>
              </div>
            </div>
            <div className={guest}>
              <span>Don't have a Spotify Account?</span> <br />
              <span className={exploreAction}>Explorer coming soon</span>
            </div>
          </div>
        </div>

        <DrumPads total={extraDrumPads} />
      </section>
    );
  }
}

export default UserConsumer(ExploreConsumer(ExperienceConsumer(Landing)));
