import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  trackCard,
  isPassive,
  pushUp,
  trackInfo,
  removeTrack,
  coverImage,
  trackDetails,
  trackActions,
  audioControl
} from './PlaylistCreator.module.sass';
import RemoveIcon from '../../vectors/RemoveIcon';
import { AudioControl } from '../AnimatedVectors';
import { SpotifyLogo } from '../AnimatedVectors';
import {
  modalScrollViewListener,
  playAudio,
  pauseAudio,
  fadeOutAudio
} from '../../utils';

class TrackCard extends Component {
  state = {};
  containerRef = createRef();
  trackPreviewRef = createRef();
  mousePlayTimeout = null;

  onMouseEnter = () => {
    const { canPlayAudio } = this.state;

    if (!canPlayAudio) {
      this.setState({
        audioState: 'loading',
        hasEnteredCard: true
      });
    }
  };

  onMouseLeave = () => {
    clearTimeout(this.mousePlayTimeout);
    const { canPlayAudio, isPlaying } = this.state;

    if (canPlayAudio && isPlaying) {
      pauseAudio(this.trackPreview);

      this.setState({
        isPlaying: false,
        audioState: 'paused'
      });
    }
  };

  onCanPlayAudio = () => {
    const { hasPlayedThrough } = this.state;

    if (hasPlayedThrough) {
      return;
    }

    this.setState({
      canPlayAudio: true,
      audioState: 'paused'
    });
  };

  onAudioTimeUpdate = () => {
    if (this.trackPreview.currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.trackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      hasPlayedThrough: true,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { canPlayAudio, isPlaying, hasInitializedPlay } = this.state;

    if (!canPlayAudio) {
      return;
    }

    isPlaying ? pauseAudio(this.trackPreview) : playAudio(this.trackPreview);
    !hasInitializedPlay && this.setState({ hasInitializedPlay: true });

    this.setState({ isPlaying: !isPlaying });
  };

  onEnterView = onStart => {
    setTimeout(
      () => this.setState({ isReady: true }),
      onStart ? this.props.renderDelay : 0
    );
  };

  componentDidMount() {
    this.container = this.containerRef.current;
    this.trackPreview = this.trackPreviewRef.current;
    modalScrollViewListener(this.container, -0.4, this.onEnterView);
  }

  render() {
    const {
      remove,
      id,
      name,
      artists,
      albumCover,
      previewUrl,
      uri,
      index,
      removedIndex
    } = this.props;
    const { audioState, isReady } = this.state;

    return (
      <div
        ref={this.containerRef}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={cx(trackCard, {
          [isPassive]: !isReady,
          [pushUp]: typeof removedIndex === 'number' && removedIndex <= index
        })}
      >
        <div className={trackInfo}>
          <div className={removeTrack}>
            <span onClick={() => remove(id)}>
              <RemoveIcon />
            </span>
          </div>
          <div className={coverImage}>
            <div style={{ backgroundImage: `url(${albumCover})` }} />
          </div>
          <div className={trackDetails}>
            <h3>{`${index + 1}. ${name}`}</h3>
            <p>{artists.map(({ name }) => name).join(', ')}</p>
          </div>
        </div>
        <div className={trackActions}>
          <div onClick={this.togglePlayState} className={audioControl}>
            <AudioControl audioState={audioState} />
          </div>
          <a href={uri}>
            <SpotifyLogo />
          </a>
        </div>
        <audio
          ref={this.trackPreviewRef}
          preload="auto"
          src={previewUrl}
          onCanPlay={this.onCanPlayAudio}
          onPlay={this.onAudioPlay}
          onPause={this.onAudioPause}
          onEnded={this.onAudioEnd}
          onTimeUpdate={this.onAudioTimeUpdate}
        />
      </div>
    );
  }
}

TrackCard.defaultProps = {
  renderDelay: 0
};

TrackCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  artists: PropTypes.array,
  albumCover: PropTypes.string,
  previewUrl: PropTypes.string,
  uri: PropTypes.string,
  index: PropTypes.number,
  removedIndex: PropTypes.number,
  remove: PropTypes.func.isRequired,
  renderDelay: PropTypes.number
};

export default TrackCard;
