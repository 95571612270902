import React from 'react';

import SectionIconBox from './SectionIconBox';
import HarpIcon from '../../vectors/HarpIcon';

const HarpIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(136.04deg, #D4D4D4 0%, #FFF 48%, #FFF 100%)"
    icon={<HarpIcon />}
    {...props}
  />
);

export default HarpIconBox;
