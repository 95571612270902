import React from 'react';

import TopArtists from '../TopArtists';
import TopTracks from '../TopTracks';
import TopGenre from '../TopGenre';

const Favourites = () => (
  <>
    <TopArtists />
    <TopTracks />
    <TopGenre />
  </>
);

export default Favourites;
