export const SECTION_TITLES = [
  'Favorite Artists',
  'Favorite Tracks',
  'Favorite Genres',
  'Streaming Analysis',
  'Streaming Insights',
  'Taste Profile',
  'Tracks Profile',
  'Artists Profile',
  'Say Hi'
];
