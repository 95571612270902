import React, { Component, createRef } from 'react';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase
} from '../../config';

import { UserConsumer } from '../../contexts/UserContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { main, isLoadingStats } from './Stats.module.sass';
import Landing from '../Landing';
import InsufficientData from '../InsufficientData';
import Favourites from '../Favourites';
import StreamingAnalysis from '../StreamingAnalysis';
import TasteProfile from '../TasteProfile';
import Team from '../Team';

class Stats extends Component {
  state = {};

  mainPositionX = 0;
  targetX = 0;
  resized = false;
  mainContainerRef = createRef();

  initializeVirtualScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaX, deltaY }) => {
      if (this.disableVScroll || this.props.disableVScroll) {
        return;
      }

      const delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;
      this.targetX += delta;
      this.setTarget();
    });
  };

  animatePosition = () => {
    const ease =
      this.props.disableGlobalScrollEase || this.resized ? 1 : scrollEase;
    this.animateScroll = requestAnimationFrame(this.animatePosition);

    this.mainPositionX += (this.targetX - this.mainPositionX) * ease;
    const translateX = parseFloat(this.mainPositionX.toFixed(2));
    const translate = `translate3d(${translateX}px, 0, 0)`;

    this.mainContainer.style.transform = translate;
    this.setMenuProgress(translateX);

    if (this.resized) {
      this.resized = false;
    }
  };

  setMenuProgress = translateX => {
    const { containerWidth } = this.state;
    const statsProgress = document.querySelector('[data-stats-progress]');

    if (!statsProgress || !containerWidth) {
      return;
    }

    const maxOverflow = containerWidth - window.innerWidth;
    const progress = parseFloat(
      (Math.abs(translateX) / maxOverflow).toFixed(5)
    );
    statsProgress.style.transform = `scale3d(${progress}, 1, 1)`;

    const sections = this.mainContainer.querySelectorAll('section');
    const sectionsDistance = [...sections].map(
      section => section.getBoundingClientRect().x - window.innerWidth * 0.4
    );
    const visibleDistance = sectionsDistance.filter(distance => distance < 0);
    const activeMenuIndex = visibleDistance.length - 1;
    const menuTitlesFraction = 1 / sectionsDistance.length;

    const menuTitles = document.querySelector('[data-menu-titles]');
    menuTitles.style.transform = `translate3d(0, -${activeMenuIndex *
      menuTitlesFraction *
      100}%, 0)`;
  };

  setTarget = () => {
    const { containerWidth, maxTranslate, minTranslate } = this.state;
    const maxOverflow = containerWidth - window.innerWidth;
    const targetXMax =
      typeof maxTranslate === 'number' ? maxTranslate : -maxOverflow;
    const targetXMin = typeof minTranslate === 'number' ? minTranslate : 0;

    this.targetX = Math.min(Math.max(targetXMax, this.targetX), targetXMin);
  };

  setContainerWidth = (initializeScroll, scrollProgress) => {
    const mainWidth = this.mainContainer.getBoundingClientRect().width;

    this.setState(
      {
        containerWidth: mainWidth
      },
      () => {
        initializeScroll && this.initializeVirtualScroll();
      }
    );

    const maxOverflow = mainWidth - window.innerWidth;

    if (scrollProgress) {
      this.targetX = -maxOverflow * scrollProgress;
    }

    if (this.targetX < 0 && this.targetX < -maxOverflow) {
      this.targetX = -maxOverflow;
    }
  };

  setMaxTranslate = maxTranslate => {
    this.setState({ maxTranslate }, this.setTarget);
  };

  setMinTranslate = minTranslate => {
    this.setState({ minTranslate });

    if (this.targetX > minTranslate) {
      this.targetX = minTranslate;
    }
  };

  onMouseDown = e => {
    if (this.disableVScroll || this.props.disableVScroll) {
      return;
    }

    e.preventDefault();
    this.setState({ willDrag: true });
    this.dragStart = e.pageX;
    this.dragPosition = this.targetX;
  };

  onMouseUp = e => {
    if (this.state.willDrag) {
      e.preventDefault();
      this.setState({ willDrag: false });
    }
  };

  onMouseMove = e => {
    if (this.state.willDrag) {
      e.preventDefault();
      const translateX = e.pageX - this.dragStart;
      this.targetX = this.dragPosition + translateX;
      this.setTarget();
    }
  };

  onResize = () => {
    const maxOverflow = this.state.containerWidth - window.innerWidth;
    const scrollProgress = Math.abs(this.targetX / maxOverflow);
    this.setContainerWidth(false, scrollProgress);
    this.resized = true;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setContainerWidth();
    }
  }

  componentDidMount() {
    this.mainContainer = this.mainContainerRef.current;

    document.readyState === 'complete'
      ? this.setContainerWidth(true)
      : window.addEventListener('load', () => this.setContainerWidth(true));
    window.addEventListener('resize', this.onResize);
    window.addEventListener('wheel', e => e.preventDefault(), {
      passive: false
    });
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);

    this.animateScroll = requestAnimationFrame(this.animatePosition);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animateScroll);
    this.vScroll.destroy();
    window.removeEventListener('load', () => this.setContainerWidth(true));
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('wheel', e => e.preventDefault(), {
      passive: false
    });
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
  }

  render() {
    const { isLoading, hasToken, hasInsufficientData } = this.props;

    return (
      <main
        ref={this.mainContainerRef}
        className={cx(main, { [isLoadingStats]: isLoading })}
      >
        {!isLoading && (
          <>
            {hasToken ? (
              <>
                {hasInsufficientData ? (
                  <InsufficientData setMaxTranslate={this.setMaxTranslate} />
                ) : (
                  <>
                    <Favourites />
                    <StreamingAnalysis />
                    <TasteProfile />
                    <Team />
                  </>
                )}
              </>
            ) : (
              <Landing setMaxTranslate={this.setMaxTranslate} />
            )}
          </>
        )}
      </main>
    );
  }
}

export default UserConsumer(ExperienceConsumer(Stats));
