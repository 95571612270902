import React from 'react';

const CircularArrowTopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    width="36"
    height="36"
  >
    <path
      fill="#252527"
      fillRule="evenodd"
      d="M12-6c9.934 0 18 8.066 18 18s-8.066 18-18 18-18-8.066-18-18S2.066-6 12-6z"
      transform="matrix(-1 0 0 1 30 6)"
    />
    <g>
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M18.375 12.542c-.491.384-1.22.342-1.696-.135l-3.396-3.344.001 9.018c0 .756-.556 1.312-1.313 1.312l-.133-.006a1.314 1.314 0 01-1.18-1.306V9.078l-3.328 3.33a1.29 1.29 0 01-1.847 0c-.531-.389-.531-1.316 0-1.847l5.565-5.565a1.29 1.29 0 011.847 0l5.63 5.564c.5.5.522 1.332.052 1.804l-.202.178z"
        transform="matrix(-1 0 0 1 30 6)"
      />
    </g>
  </svg>
);

export default CircularArrowTopIcon;
