import React, { Component } from 'react';
import cx from 'classnames';

import {
  insufficientData,
  isPassive,
  ghostIcon,
  ghostIconContent,
  caption,
  captionContent,
  greenText,
  description,
  descriptionContent,
  projectPlaylist
} from './InsufficientData.module.sass';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import DrumPads from '../DrumPads';
import ProjectPlaylist from '../ProjectPlaylist';
import { Underground } from '../AnimatedVectors';
import { LOAD_COMPLETE_ENTANCE_DELAY as entranceDelay } from '../../config';

class InsufficientData extends Component {
  constructor() {
    super();

    this.state = {
      containerColumns: 0,
      totalColumns: 18
    };
  }

  setContainerColumns = () => {
    const rows = 10;
    const minColumns = 16;

    const viewportColumns = Math.ceil(
      window.innerWidth / (window.innerHeight / rows)
    );

    const totalColumns = Math.max(viewportColumns, minColumns);
    const containerArea = minColumns * (window.innerHeight / rows);
    const maxTranslate = Math.min(0, window.innerWidth - containerArea);
    const sectionStyle = {
      width: `calc((100vh / ${rows}) * ${totalColumns})`,
      gridTemplateColumns: `repeat(${totalColumns}, calc(100vh / ${rows}))`
    };

    this.setState({ totalColumns, sectionStyle });
    this.props.setMaxTranslate(maxTranslate);
  };

  componentDidMount() {
    const { disableVScrollEase } = this.props;
    disableVScrollEase(true);
    setTimeout(() => disableVScrollEase(false), 200);
    setTimeout(() => this.setState({ isReady: true }), entranceDelay);

    this.setContainerColumns();
    window.addEventListener('resize', this.setContainerColumns);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setContainerColumns);
  }

  render() {
    const { isReady, totalColumns, sectionStyle } = this.state;
    const extraDrumPads = totalColumns * 10 - 52;

    return (
      <section
        className={cx(insufficientData, { [isPassive]: !isReady })}
        style={sectionStyle}
        id="insufficientData"
      >
        <div className={ghostIcon}>
          <DrumPads total={12} />
          <div className={ghostIconContent}>
            <Underground />
          </div>
        </div>
        <div className={caption}>
          <DrumPads total={21} />
          <div className={captionContent}>
            <h1>
              <span>
                Oooooops! <br />
                <span className={greenText}>We hit a snag</span>
              </span>
            </h1>
          </div>
        </div>
        <div className={description}>
          <DrumPads total={14} />
          <div className={descriptionContent}>
            <p>
              <span>
                You don't have enough data for us to build your listening
                profile. Try again after some time
              </span>
            </p>
          </div>
        </div>

        <ProjectPlaylist className={projectPlaylist} />

        <DrumPads total={extraDrumPads} />
      </section>
    );
  }
}

export default ExperienceConsumer(InsufficientData);
