import {
  barOne,
  barTwo,
  barThree,
  barFour,
  barFive
} from './TopGenre.module.sass';

export const GENRE_BARS_CLASS_NAMES = [
  barOne,
  barTwo,
  barThree,
  barFour,
  barFive
];

export const GENRE_CAPTIONS = {
  afrobeat: {
    captionOne: 'Gbe',
    captionTwo: 'body e!'
  },
  afropop: {
    captionOne: 'Gbe',
    captionTwo: 'body e!'
  },
  'afro dancehall': {
    captionOne: 'Gbe',
    captionTwo: 'body e!'
  },
  pop: {
    captionOne: 'Once you pop, the',
    captionTwo: `the fun don't stop`
  },
  rock: {
    captionOne: 'Keep on',
    captionTwo: 'Rockin’'
  },
  reggae: {
    captionOne: 'Welcome to',
    captionTwo: 'JamRock!'
  }
};

export const DEFAULT_GENTRE_CAPTION_TWO = 'slaps hard.';
