import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  suggestionPlaylistCreator,
  playlistCover,
  playlistDetails,
  greenText,
  playlistSummary,
  playlistButton,
  createNew,
  playlistActionProgress,
  hasSubmitted,
  fadeOutActions
} from './SuggestionPlaylistCreator.module.sass';
import RadialLoader from '../RadialLoader';
import { PlaylistButton } from '../Button';
import PlaylistActionProgress from '../PlaylistActionProgress';
import { createPlaylistCover } from '../../utils';
import {
  UPDATE_PLAYLIST_ID as updateId,
  SAVE_PLAYLIST_ID as saveId
} from '../../config';

const SuggestionPlaylistCreator = ({
  activeSuggestionPlaylist,
  getPlaylists,
  createPlaylist,
  updatePlaylist,
  resetModalScroll
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [coverUrl, setCoverUrl] = useState('');
  const [previouslyCreated, setPreviouslyCreated] = useState(null);
  const playlistUri = previouslyCreated && previouslyCreated.id;
  const [isSavingPlaylist, setIsSavingPlaylist] = useState(false);
  const [canUpdate, setCanUpdate] = useState(Boolean(playlistUri));
  const [hasClickedPlaylistButton, setHasClickedPlaylistButton] = useState(
    false
  );
  const [showProgress, setShowProgress] = useState(false);
  const [willExitSaving, setWillExitSaving] = useState(false);
  const [willExitProgress, setWillExitProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showActions, setShowActions] = useState(true);

  const {
    summary,
    createNewAction,
    playlistTracks = [],
    ...coverOptions
  } = activeSuggestionPlaylist;
  const { titleOne, titleTwo } = coverOptions;
  const playlistName = `Uu - ${titleOne} ${titleTwo}`;
  const canCreatePlaylist = playlistTracks.length > 0;

  const setPlaylistData = async () => {
    try {
      const coverUrl = await createPlaylistCover(coverOptions);
      setCoverUrl(coverUrl);
      const playlists = await getPlaylists();
      const previouslyCreated = playlists.find(
        ({ name }) => name === playlistName
      );

      setPreviouslyCreated(previouslyCreated);
      setIsLoading(false);
      resetModalScroll(false, true);
    } catch (error) {
      setIsLoading(false);
      resetModalScroll(false, true);
    }
  };

  const onPlaylistPrimaryActionClick = () => {
    setCanUpdate(Boolean(playlistUri));
    setHasClickedPlaylistButton(true);
    previouslyCreated ? updatePreviousPlaylist() : createNewPlaylist();
  };

  const createNewPlaylist = () => {
    setLoading();
    const data = {
      name: playlistName,
      description: 'Created with Cruuunchify'
    };
    const tracksUris = playlistTracks.map(({ uri }) => uri);
    const cover = coverUrl.split('base64,')[1];

    createPlaylist(data, tracksUris, cover, onSave, onSaveError);
  };

  const updatePreviousPlaylist = () => {
    setLoading();
    const tracksUris = playlistTracks.map(({ uri }) => uri);
    const cover = coverUrl.split('base64,')[1];

    updatePlaylist(
      previouslyCreated.id,
      tracksUris,
      cover,
      onSave,
      onSaveError
    );
  };

  const setLoading = () => {
    setIsSavingPlaylist(true);
    setTimeout(() => setShowProgress(true), 300);
    setTimeout(() => setShowActions(false), 500);
  };

  const clearLoading = hasError => {
    setIsSavingPlaylist(false);
    setWillExitSaving(true);

    if (hasError) {
      setTimeout(() => setIsError(true), 500);
      return;
    }

    setTimeout(() => setIsSuccess(true), 200);
    setTimeout(() => setWillExitProgress(true), 1200);
    setTimeout(() => setShowActions(true), 1500);
    setTimeout(() => {
      setShowProgress(false);
      setIsSuccess(false);
      setWillExitSaving(false);
      setWillExitProgress(false);
    }, 1700);
  };

  const onSave = previouslyCreated => {
    previouslyCreated && setPreviouslyCreated(previouslyCreated);
    setTimeout(() => clearLoading(), 1300);
  };

  const onSaveError = () => {
    setTimeout(() => {
      clearLoading(true);
    }, 1300);
  };

  const dismissError = () => {
    setWillExitProgress(true);
    setTimeout(() => setShowActions(true), 300);
    setTimeout(() => {
      setShowProgress(false);
      setIsError(false);
      setWillExitSaving(false);
      setWillExitProgress(false);
    }, 500);
  };

  useEffect(() => {
    setPlaylistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={suggestionPlaylistCreator}>
      <RadialLoader isLoading={isLoading} renderChildrenOnLoad={true}>
        <div className={playlistCover}>
          <div style={{ backgroundImage: `url(${coverUrl})` }} />
        </div>
        <div className={playlistDetails}>
          <h1>
            <span>Suggestion</span>
          </h1>
          <h1 className={greenText}>
            <span>Playlist</span>
          </h1>
          <p className={playlistSummary}>
            <span>
              {canCreatePlaylist
                ? summary
                : 'Unique playlist based on your favorites coming very soon.'}
            </span>
          </p>
          {showProgress && (
            <PlaylistActionProgress
              className={playlistActionProgress}
              isSuccess={isSuccess}
              isError={isError}
              canUpdate={canUpdate}
              willExitProgress={willExitProgress}
              willExitSaving={willExitSaving}
              dismissError={dismissError}
            />
          )}

          {showActions && (
            <div
              className={cx({
                [hasSubmitted]: hasClickedPlaylistButton,
                [fadeOutActions]: isSavingPlaylist
              })}
            >
              {canCreatePlaylist && (
                <>
                  <PlaylistButton
                    id={playlistUri ? updateId : saveId}
                    text={playlistUri ? 'Update Playlist' : 'Save To Spotify'}
                    className={playlistButton}
                    onClick={onPlaylistPrimaryActionClick}
                    updateButton={Boolean(playlistUri)}
                  />
                  <span onClick={createNewAction} className={createNew}>
                    {' '}
                    or Create your own{' '}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </RadialLoader>
    </div>
  );
};

export default UserConsumer(SuggestionPlaylistCreator);
