import React, { Component, createRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import {
  artistsCarouselContainer,
  isPassiveCarousel,
  carouselEntrance,
  artistCard,
  loadingNextArtist,
  artistCover,
  animateArtistIn,
  artistLink,
  artistTitile,
  artistName,
  artistGenres,
  nextArtistDetails,
  audioToggleBg,
  audioToggle,
  cardGlow,
  cardGlowActive,
  ovalDash,
  explorePlaylist,
  expandable,
  danceIcon,
  playlistCover,
  playlistPlay,
  ArtistFeaturedTrack,
  artistFeaturedAlbumCover,
  nextArtistFeaturedAlbumCover,
  featuredTrackData,
  trackDetails,
  nextArtistTrackDetails,
  featuredTrackNameLabel,
  featuredTrackName,
  artistPopularityContainer,
  artistPopularity,
  loadNextPopularity,
  currentArtistPopularity,
  nextArtistPopularity,
  carouselControl,
  loadNextArtist,
  loadPreviousArtist,
  loadNextTrack,
  carouselChevron,
  passiveChevron,
  controlArtist,
  passiveControl,
  passiveRing,
  carouselArtistImage
} from './Landing.module.sass';
import MultiLineEllipsis from '../MultiLineEllipsis';
import DrumPads from '../DrumPads';
import { SpotifyLogo } from '../AnimatedVectors';
import { AudioControl } from '../AnimatedVectors';
import PlayIcon from '../../vectors/PlayIcon';
import OvalDash from '../../vectors/OvalDash';
import DanceIcon from '../../vectors/DanceIcon';
import ChevronLeftIcon from '../../vectors/ChevronLeftIcon';
import ChevronRightIcon from '../../vectors/ChevronRightIcon';
import RadialProgress from '../../vectors/RadialProgress';
import {
  getPopularityGroup,
  playAudio,
  pauseAudio,
  fadeOutAudio,
  getImageUrl
} from '../../utils';
import {
  AUDIO_LOADING_DELAY as audioLoadingDelay,
  AUDIO_FADE_DURATION as audioFadeDuration
} from '../../config';

class ArtistsCarousel extends Component {
  constructor(props) {
    super(props);

    const { artists } = props;

    this.state = {
      artists,
      popularityIcon: null,
      swipeArtists: [...props.artists],
      nextArtists: [],
      newArtistKey: 0,
      cardKeyNumber: 0,
      popularityKeyNumber: 0
    };

    this.featuredTrackPreviewRef = createRef();
    this.mousePlayTimeout = null;
    this.setPopularityIconTimeout = null;
    this.setActiveArtistTimeout = null;
  }

  onMouseEnter = () => {
    const { activateGlow, audioAutoPreview } = this.props;
    const {
      hasEnteredCard,
      canPlayAudio,
      mouseLeavePause,
      hasInitializedPlay
    } = this.state;

    activateGlow(false);
    this.setState({ cardActive: true });

    if (!hasEnteredCard) {
      this.setState({
        audioState: 'loading',
        hasEnteredCard: true
      });

      setTimeout(() => this.featuredTrackPreview.load(), audioLoadingDelay);
    }

    if (
      canPlayAudio &&
      audioAutoPreview &&
      (!hasInitializedPlay || mouseLeavePause)
    ) {
      this.mousePlayTimeout = setTimeout(() => {
        const { isPlaying, hasInitializedPlay, mouseLeavePause } = this.state;

        if (!isPlaying && (!hasInitializedPlay || mouseLeavePause)) {
          playAudio(this.featuredTrackPreview);
          this.setState({
            isPlaying: true,
            mouseLeavePause: false
          });
        }

        !hasInitializedPlay && this.setState({ hasInitializedPlay: true });
      }, audioFadeDuration);
    }
  };

  onMouseLeave = () => {
    clearTimeout(this.mousePlayTimeout);
    const { canPlayAudio, isPlaying } = this.state;

    this.props.activateGlow(true);

    if (canPlayAudio && isPlaying) {
      pauseAudio(this.featuredTrackPreview);

      this.setState({
        isPlaying: false,
        audioState: 'paused',
        mouseLeavePause: true
      });
    }

    this.setState({ cardActive: false });
  };

  onCanPlayAudio = () => {
    const { audioAutoPreview } = this.props;
    const { cardActive, hasPlayedThrough } = this.state;

    if (hasPlayedThrough) {
      return;
    }

    const autoPreview = cardActive && audioAutoPreview;

    this.setState(
      {
        canPlayAudio: true,
        audioState: autoPreview ? 'playing' : 'paused'
      },
      () => {
        autoPreview && this.togglePlayState();
      }
    );
  };

  onAudioTimeUpdate = () => {
    if (this.featuredTrackPreview.currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.featuredTrackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      hasPlayedThrough: true,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { canPlayAudio, isPlaying, hasInitializedPlay } = this.state;

    if (!canPlayAudio) {
      return;
    }

    isPlaying
      ? pauseAudio(this.featuredTrackPreview)
      : playAudio(this.featuredTrackPreview);
    !hasInitializedPlay && this.setState({ hasInitializedPlay: true });

    this.setState({
      isPlaying: !isPlaying,
      mouseLeavePause: false
    });
  };

  onCardIterationComplete = (evt, reverseCarousel) => {
    const {
      swipeArtists,
      cardKeyNumber,
      popularityKeyNumber,
      nextArtists,
      newArtistKey
    } = this.state;

    clearTimeout(this.setPopularityIconTimeout);
    clearTimeout(this.setActiveArtistTimeout);
    const newNextArtists = [...nextArtists];
    const selectedArtist = reverseCarousel
      ? swipeArtists[swipeArtists.length - 1]
      : swipeArtists[1];
    const newArtist = JSON.parse(JSON.stringify(selectedArtist));
    newArtist.key = newArtistKey + 1;
    newNextArtists.push(newArtist);
    if (reverseCarousel) {
      const lastArtist = swipeArtists.pop();
      swipeArtists.unshift(lastArtist);
    } else {
      const firstArtist = swipeArtists.shift();
      swipeArtists.push(firstArtist);
    }

    this.setState({
      reverseCarousel,
      swipeArtists,
      nextArtists: newNextArtists.slice(
        Math.max(newNextArtists.length - 10, 0)
      ),
      newArtistKey: newArtistKey + 1,
      swipeTriggered: !evt
    });

    this.setPopularityIconTimeout = setTimeout(() => {
      const { icon: popularityIcon } = getPopularityGroup(
        swipeArtists[0].popularity
      );

      this.setState({
        popularityIcon,
        popularityKeyNumber: popularityKeyNumber + 1,
        swipeTriggered: false
      });
    }, 700);

    this.setActiveArtistTimeout = setTimeout(() => {
      this.setState({
        artists: [...swipeArtists],
        cardKeyNumber: cardKeyNumber + 1,
        hasEnteredCard: false,
        canPlayAudio: false,
        mouseLeavePause: false,
        hasInitializedPlay: false,
        hasPlayedThrough: false,
        nextArtists: [],
        reverseCarousel: false
      });
    }, 1500);
  };

  setPlaylistOvalActive = playlistOvalActive => {
    this.setState({ playlistOvalActive });
  };

  componentDidUpdate(prevProps) {
    const { isPassive, artists } = this.props;

    if (!isPassive && prevProps.isPassive) {
      const { icon: popularityIcon } = getPopularityGroup(
        artists[0].popularity
      );
      this.setState({ animateArtist: true });
      setTimeout(() => this.setState({ canAnimateRing: true }), 1000);
      setTimeout(() => this.setState({ animateArtist: false }), 2000);
      setTimeout(() => this.setState({ popularityIcon }), 1000);
      setTimeout(() => this.setState({ disableToggleAudioBg: true }), 3000);
      setTimeout(() => this.setState({ canExpandPlaylist: true }), 2500);
    }
  }

  componentDidMount() {
    this.featuredTrackPreview = this.featuredTrackPreviewRef.current;
  }

  render() {
    const {
      isPassive,
      playlistImages,
      playlistTitle,
      playlistUri
    } = this.props;
    const {
      canAnimateRing,
      animateArtist,
      disableToggleAudioBg,
      canExpandPlaylist,
      playlistOvalActive,
      cardActive,
      audioState,
      artists,
      swipeArtists,
      cardKeyNumber,
      popularityKeyNumber,
      nextArtists,
      reverseCarousel,
      popularityIcon,
      swipeTriggered
    } = this.state;
    const { name, images, genres, popularity, uri, featuredTrack } = artists[0];
    const { label: popularityLabel } = getPopularityGroup(popularity);
    const lastNextArtist = nextArtists[nextArtists.length - 1];
    const secondToLastNextArtist = nextArtists[nextArtists.length - 2];
    const { label: nextPopularityLabel } = getPopularityGroup(
      lastNextArtist && lastNextArtist.popularity
    );
    const { label: secondNextPopularityLabel } = getPopularityGroup(
      secondToLastNextArtist && secondToLastNextArtist.popularity
    );

    return (
      <div
        className={cx(artistsCarouselContainer, {
          [isPassiveCarousel]: isPassive,
          [carouselEntrance]: animateArtist
        })}
      >
        <DrumPads total={54} />
        <div
          key={`artist-card-${cardKeyNumber}`}
          className={cx(artistCard, {
            [loadingNextArtist]: nextArtists.length
          })}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <a href={uri} className={artistLink}>
            <SpotifyLogo animateIn={false} />
          </a>
          <img
            alt={name}
            src={getImageUrl(images)}
            className={cx(artistCover, { [animateArtistIn]: animateArtist })}
          />
          <div className={artistTitile}>
            <span className={artistName}>
              <MultiLineEllipsis text={name} lines={2} />
            </span>
            <span className={artistGenres}>
              {genres.length > 0 && (
                <>
                  <span>{genres[0]}</span>
                  {genres.length > 1 && <span>+{genres.length - 1}</span>}
                </>
              )}
            </span>
          </div>
          {nextArtists.map(({ name, images, genres, key }) => (
            <div className={nextArtistDetails} key={key}>
              <img
                alt={name}
                src={getImageUrl(images)}
                className={artistCover}
              />
              <div className={artistTitile}>
                <span className={artistName}>
                  <MultiLineEllipsis text={name} lines={2} />
                </span>
                <span className={artistGenres}>
                  {genres.length > 0 && (
                    <>
                      <span>{genres[0]}</span>
                      {genres.length > 1 && <span>+{genres.length - 1}</span>}
                    </>
                  )}
                </span>
              </div>
            </div>
          ))}
          {!disableToggleAudioBg && <span className={audioToggleBg} />}
          <span className={audioToggle} onClick={this.togglePlayState}>
            <AudioControl audioState={audioState} />
          </span>
        </div>
        <audio
          ref={this.featuredTrackPreviewRef}
          preload="none"
          src={featuredTrack.preview_url}
          onCanPlay={this.onCanPlayAudio}
          onPlay={this.onAudioPlay}
          onPause={this.onAudioPause}
          onEnded={this.onAudioEnd}
          onTimeUpdate={this.onAudioTimeUpdate}
        />
        <span
          className={cx(cardGlow, {
            [cardGlowActive]: cardActive
          })}
        />
        <div
          onMouseEnter={() => this.setPlaylistOvalActive(true)}
          onMouseLeave={() => this.setPlaylistOvalActive(false)}
          className={cx(explorePlaylist, { [expandable]: canExpandPlaylist })}
        >
          <span className={danceIcon}>
            <DanceIcon />
          </span>
          <div
            className={playlistCover}
            style={{ backgroundImage: `url(${getImageUrl(playlistImages)})` }}
          />
          <p>Picks of the week</p>
          <h3 dangerouslySetInnerHTML={{ __html: playlistTitle }} />
          <a href={playlistUri} className={playlistPlay}>
            <PlayIcon />
          </a>
        </div>
        <div className={ovalDash}>
          <OvalDash isActive={playlistOvalActive} />
        </div>
        <div
          className={cx(ArtistFeaturedTrack, {
            [loadNextTrack]: lastNextArtist
          })}
          key={`artistTrack-${cardKeyNumber}`}
        >
          <div
            key={secondToLastNextArtist ? secondToLastNextArtist.key : 0}
            className={artistFeaturedAlbumCover}
          >
            <div>
              <div
                style={{
                  backgroundImage: `url(${getImageUrl(
                    secondToLastNextArtist
                      ? secondToLastNextArtist.featuredTrack.album.images
                      : featuredTrack.album.images
                  )})`
                }}
              />
            </div>
          </div>
          {lastNextArtist && (
            <div
              key={lastNextArtist.key}
              className={nextArtistFeaturedAlbumCover}
            >
              <div
                style={{
                  backgroundImage: `url(${getImageUrl(
                    lastNextArtist.featuredTrack.album.images
                  )})`
                }}
              />
            </div>
          )}
          <div className={featuredTrackData}>
            <div
              key={secondToLastNextArtist ? secondToLastNextArtist.key : 0}
              className={trackDetails}
            >
              <span className={featuredTrackNameLabel}>Featured Track</span>
              <span className={featuredTrackName}>
                {secondToLastNextArtist
                  ? secondToLastNextArtist.featuredTrack.name
                  : featuredTrack.name}
              </span>
            </div>
            {lastNextArtist && (
              <div key={lastNextArtist.key} className={nextArtistTrackDetails}>
                <span className={featuredTrackNameLabel}>Featured Track</span>
                <span className={featuredTrackName}>
                  {lastNextArtist.featuredTrack.name}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={artistPopularityContainer}>
          <div
            className={cx(artistPopularity, {
              [loadNextPopularity]: lastNextArtist
            })}
          >
            <div className={currentArtistPopularity}>
              <span key={`artist-popularity-icon-${popularityKeyNumber}`}>
                {popularityIcon}
              </span>
              <div
                key={
                  secondToLastNextArtist
                    ? secondToLastNextArtist.key
                    : `artist-popularity-${cardKeyNumber}`
                }
              >
                <h4>Popularity</h4>
                <p>
                  {secondToLastNextArtist
                    ? secondNextPopularityLabel
                    : popularityLabel}
                </p>
              </div>
            </div>
            {lastNextArtist && (
              <div key={lastNextArtist.key} className={nextArtistPopularity}>
                <span />
                <div>
                  <h4>Popularity</h4>
                  <p>{nextPopularityLabel}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          key={
            secondToLastNextArtist
              ? secondToLastNextArtist.key
              : `carousel-control-${cardKeyNumber}`
          }
          className={cx(carouselControl, {
            [loadNextArtist]: lastNextArtist && !reverseCarousel,
            [loadPreviousArtist]: lastNextArtist && reverseCarousel
          })}
        >
          <span
            className={cx(carouselChevron, {
              [passiveChevron]: isPassive
            })}
            onClick={() => this.onCardIterationComplete(null, true)}
          >
            <ChevronLeftIcon />
          </span>
          <div className={controlArtist}>
            <div
              className={carouselArtistImage}
              style={{
                backgroundImage: `url(${getImageUrl(
                  secondToLastNextArtist
                    ? swipeArtists[
                        reverseCarousel
                          ? swipeArtists.length - 1
                          : swipeArtists.length - 3
                      ].images
                    : artists[artists.length - 2].images
                )})`
              }}
            />
          </div>
          <div
            className={cx(controlArtist, {
              [passiveControl]: isPassive
            })}
            onClick={() => this.onCardIterationComplete(null, true)}
          >
            <div
              className={carouselArtistImage}
              style={{
                backgroundImage: `url(${getImageUrl(
                  secondToLastNextArtist
                    ? swipeArtists[
                        reverseCarousel ? 0 : swipeArtists.length - 2
                      ].images
                    : artists[artists.length - 1].images
                )})`
              }}
            />
          </div>
          <div
            className={cx(controlArtist, {
              [passiveControl]: isPassive,
              [passiveRing]: !canAnimateRing
            })}
          >
            <RadialProgress
              preventEndEvent={swipeTriggered}
              onAnimationEnd={this.onCardIterationComplete}
            />
            <div
              className={carouselArtistImage}
              style={{
                backgroundImage: `url(${getImageUrl(
                  secondToLastNextArtist
                    ? swipeArtists[
                        reverseCarousel ? 1 : swipeArtists.length - 1
                      ].images
                    : images
                )})`
              }}
            />
          </div>
          <div
            className={cx(controlArtist, {
              [passiveControl]: isPassive
            })}
            onClick={() => this.onCardIterationComplete(null)}
          >
            <div
              className={carouselArtistImage}
              style={{
                backgroundImage: `url(${getImageUrl(
                  secondToLastNextArtist
                    ? swipeArtists[reverseCarousel ? 2 : 0].images
                    : artists[1].images
                )})`
              }}
            />
          </div>
          <div className={controlArtist}>
            <div
              className={carouselArtistImage}
              style={{
                backgroundImage: `url(${getImageUrl(
                  secondToLastNextArtist
                    ? swipeArtists[reverseCarousel ? 3 : 1].images
                    : artists[2].images
                )})`
              }}
            />
          </div>
          <span
            className={cx(carouselChevron, {
              [passiveChevron]: isPassive
            })}
            onClick={() => this.onCardIterationComplete(null)}
          >
            <ChevronRightIcon />
          </span>
        </div>
      </div>
    );
  }
}

ArtistsCarousel.propTypes = {
  artists: PropTypes.array.isRequired,
  playlistImages: PropTypes.array,
  playlistTitle: PropTypes.string,
  playlistUri: PropTypes.string,
  isPassive: PropTypes.bool
};

export default ExperienceConsumer(ArtistsCarousel);
