import React from 'react';

const SoundWavesLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient
        id="LJHdb43X4bSDV"
        x1="0.09"
        x2="0.55"
        y1="0.11"
        y2="0.32"
      >
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#LJHdb43X4bSDV)"
      fillRule="evenodd"
      d="M370.192 230.258c131.404-11.013 235.168 41.715 314.102 145.253 8.796 11.109 7.85 27.078-2.252 37.05-10.005 10.626-19.196 20.077-30.374 31.581-11.179 11.537-21.543 10.69-30.537-2.737a271.125 271.125 0 00-102.788-92.425c-117.712-60.321-259.548-22.452-336.135 88.779a69.656 69.656 0 01-5.834 8.343 15.735 15.735 0 01-11.406 6.029 15.707 15.707 0 01-12.059-4.628c-12.417-12.188-24.605-24.605-35.947-37.706a63.954 63.954 0 01-7.267-17.6 77.514 77.514 0 017.267-16.686c63.424-84.863 146.755-136.91 253.23-145.253zm164.675 335.318c11.765-11.602 23.27-23.433 34.383-35.719 9.418-9.875 9.712-25.323.651-35.556-40.966-51.037-102.754-80.857-168.197-81.15a216.95 216.95 0 00-168.948 79.65 26.938 26.938 0 00-7.659 19.522 27.047 27.047 0 008.67 19.13 1198.648 1198.648 0 0031.547 32.688 16.579 16.579 0 0014.503 6.877 16.626 16.626 0 0013.59-8.538c2.411-2.999 4.66-6.16 7.104-9.093a129.457 129.457 0 0175.414-45.17 129.168 129.168 0 0186.852 13.558c17.86 10.037 33.308 23.79 45.3 40.379 8.344 10.591 18.121 12.025 26.79 3.422z"
      opacity="0.1"
      transform="rotate(90 400 400)"
    />
  </svg>
);

export default SoundWavesLgIcon;
