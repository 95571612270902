import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { spotifyButton } from './Button.module.sass';
import { SpotifyLogo } from '../AnimatedVectors';

const SpotifyButton = ({ uri, className }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <a
      href={uri}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={cx(className, spotifyButton)}
    >
      <span>
        <SpotifyLogo playHoverLoop={hovered} /> Open In Spotify
      </span>
    </a>
  );
};

SpotifyButton.propTypes = {
  uri: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SpotifyButton;
