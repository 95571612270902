import React from 'react';

const ArrowRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
    <g opacity="1">
      <path
        fill="#ACB4B9"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        d="M15.211511885518048,8.931923505239508 C14.690098202008398,8.411661163012623 14.683986842125632,7.6078038848240634 15.179158933472008,7.100014030592417 C15.623245520682458,6.534363625824978 16.544381047387944,6.531675244877915 17.079090956073443,7.066392800955368 L24.034387676307613,14.104654736998652 C24.560895536468973,14.631162597160012 24.560895536468973,15.445070800880883 24.034387676307613,15.971578661042242 L17.07804481916473,22.92792151818524 C16.542544298186655,23.46342203916326 15.606722488145051,23.46342203916326 15.211120895120912,22.927921518185126 C14.68461303495978,22.401413658023767 14.68461303495978,21.5875054543029 15.211120895120912,21.06099759414154 L19.905681790243463,16.366436699019047 L6.899302857142786,16.366436699020184 C6.159708972871087,16.366436699020184 5.570731428571364,15.764720527750951 5.570731428571364,15.037865270449045 C5.570731428571364,14.264397181247928 6.1258347679417255,13.709293841877622 6.899302857142786,13.709293841877622 L19.91827618603001,13.709293841877622 L15.211511885518048,8.931923505239508Z"
      />
    </g>
  </svg>
);

export default ArrowRightIcon;
