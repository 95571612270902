import React from 'react';
import './App.sass';

import { UserProvider } from './contexts/UserContext';
import { ExploreProvider } from './contexts/ExploreContext';
import { ExperienceProvider } from './contexts/ExperienceContext';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => (
  <UserProvider>
    <ExploreProvider>
      <ExperienceProvider>
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      </ExperienceProvider>
    </ExploreProvider>
  </UserProvider>
);

export default App;
