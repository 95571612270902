import React from 'react';

const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height="30"
    width="30"
  >
    <g opacity="1">
      <path
        fill="rgb(255,255,255)"
        opacity="1"
        fillRule="evenodd"
        d="M19.878980690820455,19.125171874140626 C19.149378260424342,19.125171874140626 18.468783971272167,19.348608256958713 17.907978068311806,19.727803860980693 L13.381276148408066,16.2160714196429 C13.52610663544142,15.83687581562092 13.60777850768395,15.430812845935767 13.60777850768395,14.998982505087472 C13.60777850768395,14.55854470727646 13.520662215654763,14.136394318028408 13.368210098464361,13.750736246318766 L17.827449161820702,10.217553912230438 C18.400242266564277,10.63005184974075 19.110247015905504,10.874965625171873 19.879044808411386,10.874965625171873 C21.803213712325146,10.874965625171873 23.36369648957882,9.335605821970889 23.36369648957882,7.4374828125859365 C23.36369648957882,5.539359803200984 21.803213712325146,4 19.879044808411386,4 C17.951586393310613,4 16.39439312724396,5.539359803200984 16.39439312724396,7.4374828125859365 C16.39439312724396,7.829575852120739 16.45973174012652,8.205553972230138 16.5827817602919,8.555737221313894 L12.056079840388165,12.142544287278563 C11.506146050673044,11.777318613406932 10.839713385953136,11.56246218768906 10.123185368664764,11.56246218768906 C8.19463974223946,11.56246218768906 6.636303510421385,13.101821990890043 6.636303510421385,14.999945000274998 C6.636303510421385,16.89806800965995 8.194583987812562,18.437427812860932 10.122070280126792,18.437427812860932 C10.84841107654933,18.437427812860932 11.530092577026029,18.217214413927927 12.09089847998639,17.83801330993345 L16.61760039989013,21.348645756771216 C16.472769912856776,21.727841360793196 16.39436525003051,22.137121814390923 16.39436525003051,22.562517187414063 C16.39436525003051,24.460640196799016 17.951558516097162,26 19.87901693119794,26 C21.803185835111695,26 23.36366861236537,24.460640196799016 23.36366861236537,22.562517187414063 C23.36366861236537,20.664394178029113 21.803185835111695,19.125034374828125 19.878980690820455,19.125171874140626Z"
      />
    </g>
  </svg>
);

export default ShareIcon;
