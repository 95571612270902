import React from 'react';

import TopAudioFeatures from '../TopAudioFeatures';
import StreamingInsights from '../StreamingInsights';

const StreamingAnalysis = () => (
  <>
    <TopAudioFeatures />
    <StreamingInsights />
  </>
);

export default StreamingAnalysis;
