import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import VirtualScroll from 'virtual-scroll';

import { container } from './SwipeCarousel.module.sass';
import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase
} from '../../config';

class SwipeCarousel extends Component {
  containerPositionX = 0;
  targetX = window.innerWidth * 0.08;
  containerRef = createRef();

  initializeVScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaX, deltaY }) => {
      if (!this.canSwipe) {
        return;
      }

      if (!this.swiping && Math.abs(deltaY) > Math.abs(deltaX)) {
        this.canSwipe = false;
        this.props.disableParentScroll(false);
        return;
      }

      this.swiping = true;
      const { containerWidth } = this.state;
      const containerPadding = window.innerWidth * 0.08;
      const maxOverflow = containerWidth - window.innerWidth + containerPadding;

      this.targetX += deltaX;
      this.targetX = Math.min(
        Math.max(-maxOverflow, this.targetX),
        containerPadding
      );
    });
  };

  setContainerWidth = initializeScroll => {
    const containerWidth = this.container
      .querySelector('div')
      .getBoundingClientRect().width;

    this.setState(
      {
        containerWidth
      },
      () => {
        initializeScroll && this.initializeVScroll();
      }
    );

    const containerPadding = window.innerWidth * 0.08;
    const maxOverflow = containerWidth - window.innerWidth + containerPadding;

    if (this.targetX < containerPadding && this.targetX < -maxOverflow) {
      this.targetX = -maxOverflow;
    }
  };

  animatePosition = () => {
    this.animateSwipe = requestAnimationFrame(this.animatePosition);

    this.containerPositionX +=
      (this.targetX - this.containerPositionX) * scrollEase;
    const translateX = parseFloat(this.containerPositionX.toFixed(2));
    const translate = `translate3d(${translateX}px, 0, 0)`;

    this.container.style.transform = translate;
  };

  onTouchStart = () => {
    this.canSwipe = true;
    this.props.disableParentScroll(true);
  };

  onTouchEnd = () => {
    this.canSwipe = false;
    this.swiping = false;
    this.props.disableParentScroll(false);
  };

  componentDidMount() {
    this.container = this.containerRef.current;

    this.setContainerWidth(true);
    window.addEventListener('resize', () => this.setContainerWidth(false));

    this.animateSwipe = requestAnimationFrame(this.animatePosition);
  }

  componentWillUnmount() {
    this.vScroll.destroy();
    cancelAnimationFrame(this.animateSwipe);
    window.removeEventListener('resize', () => this.setContainerWidth(false));
  }

  render() {
    const { children } = this.props;
    return (
      <div
        ref={this.containerRef}
        className={container}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
      >
        {children}
      </div>
    );
  }
}

SwipeCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  disableParentScroll: PropTypes.func.isRequired
};

export default SwipeCarousel;
