import React from 'react';

const SpotifyLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M3 15C3 8.372 8.373 3 15 3c6.628 0 12 5.372 12 12s-5.372 12-12 12C8.373 27 3 21.628 3 15zm5.157-2.75c3.693-1.12 10.073-.909 13.941 1.388a1.122 1.122 0 101.146-1.93c-4.453-2.644-11.5-2.892-15.738-1.605a1.123 1.123 0 00.651 2.148zm12.528 5.099a.936.936 0 00.979-1.595c-3.704-2.276-9.122-2.916-13.48-1.594a.937.937 0 00-.624 1.167.937.937 0 001.167.623c3.815-1.158 8.733-.584 11.958 1.399zm-.182 2.96a.747.747 0 01-1.028.248c-2.818-1.722-6.365-2.111-10.542-1.157a.748.748 0 11-.333-1.458c4.571-1.045 8.492-.595 11.655 1.338a.748.748 0 01.248 1.028z"
    />
  </svg>
);

export default SpotifyLogo;
