import React from 'react';

import SectionIconBox from './SectionIconBox';
import PhonographIcon from '../../vectors/PhonographIcon';

const PhonographIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(136.04deg, #424446 0%, #2D343B 48%, #000 100%)"
    icon={<PhonographIcon />}
    {...props}
  />
);

export default PhonographIconBox;
