import React, { Component, createRef } from 'react';
import cx from 'classnames';

import {
  RANGE_OPTIONS as rangeOptions,
  SORT_KEYS as sortKeys
} from './MobileTopList.constants';

import { UserConsumer } from '../../contexts/UserContext';
import {
  listNav,
  hideNav,
  collapsedNav,
  openFilter,
  openShare,
  isActiveNavControl,
  isPassiveNavControl,
  coversContainer,
  listCovers,
  scaleCoversUp,
  listCover,
  listSummary,
  fadedSummary,
  listIcon,
  title,
  descriptionPill,
  playlistButton,
  listGrid
} from './MobileTopList.module.sass';
import {
  getImageUrl,
  preloadImages,
  shuffleArray,
  compareObjectValues
} from '../../utils';
import ListFilterForm from './ListFilterForm';
import RadialLoader from '../RadialLoader';
import { PlaylistButton } from '../Button';
import MobileArtistCard from '../MobileArtistCard';
import MobileTrackCard from '../MobileTrackCard';
import FilterIcon from '../../vectors/FilterIcon';
import ShareIcon from '../../vectors/ShareIcon';
import MicAltIcon from '../../vectors/MicAltIcon';
import MusicIcon from '../../vectors/MusicIcon';
import {
  MOBILE_COVERS_EASE as coversEase,
  MOBILE_MODAL_ENTANCE_DELAY as entranceDelay
} from '../../config';

class MobileTopList extends Component {
  state = {
    isLoading: true,
    timeRange: 'shortTerm',
    sortType: 0,
    orderType: 0,
    typeOptions: {}
  };

  listCoversRef = createRef();
  targetRotateX = 0;
  targetRotateY = 0;
  coverRotateX = 0;
  coverRotateY = 0;

  setListData = async () => {
    try {
      await this.setItems();
      await this.loadImages();

      this.setState(
        {
          isLoading: false,
          navReady: true
        },
        () => {
          this.props.resetModalScroll(false, true);
        }
      );
    } catch (error) {}
  };

  setItems = () => {
    const { activeTopList } = this.props;
    const { typeOptions } = this.state;
    const { listDataSuffix, setActiveList } = typeOptions[activeTopList];
    const listItems = {};

    Object.keys(rangeOptions).forEach(range => {
      const listItemKey = `${range}${listDataSuffix}`;
      listItems[range] = this.props[listItemKey];
    });

    setActiveList(listItems);
  };

  setActiveArtists = data => {
    const activeItems = {};
    Object.entries(data).forEach(([range, items]) => {
      const rangeItems = items.map(
        ({ id, uri, name, images, genres, popularity }, index) => ({
          id,
          genres,
          uri,
          popularity,
          rank: index,
          title: name,
          artistName: name,
          artistCover: getImageUrl(images)
        })
      );
      activeItems[range] = rangeItems;
    });

    this.setState({ activeItems });
  };

  setActiveTracks = data => {
    const activeItems = {};
    Object.entries(data).forEach(([range, items]) => {
      const rangeItems = items.map(
        ({ id, name, artists, uri, album, popularity, explicit }, index) => ({
          id,
          name,
          uri,
          popularity,
          explicit,
          rank: index,
          title: name,
          artists: artists.map(({ name }) => name),
          albumCover: getImageUrl(album.images)
        })
      );
      activeItems[range] = rangeItems;
    });

    this.setState({ activeItems });
  };

  loadImages = () => {
    const [listA, listB, listC] = Object.values(this.state.activeItems);
    const shortTermCovers = listA
      .filter(({ artistCover, albumCover }) => artistCover || albumCover)
      .map(({ artistCover, albumCover }) => artistCover || albumCover);
    const mediumTermCovers = listB
      .filter(({ artistCover, albumCover }) => artistCover || albumCover)
      .map(({ artistCover, albumCover }) => artistCover || albumCover);
    const longTermCovers = listC
      .filter(({ artistCover, albumCover }) => artistCover || albumCover)
      .map(({ artistCover, albumCover }) => artistCover || albumCover);
    const itemsCovers = [
      ...shortTermCovers,
      ...mediumTermCovers,
      ...longTermCovers
    ];

    const listCovers = [...new Set(shortTermCovers)];

    this.setState({
      shortTermCovers,
      mediumTermCovers,
      longTermCovers,
      listBgCovers: shuffleArray(listCovers).slice(0, 4)
    });

    return preloadImages(itemsCovers);
  };

  openFilter = () => {
    this.props.expandNav(430);
    this.setState({ filtersActive: true });
  };

  openShare = () => {
    this.props.expandNav(235);
    this.setState({ shareActive: true });
  };

  filterList = (period, sort, order) => {
    const { timeRange, sortType, orderType, activeItems } = this.state;
    const { resetModalScroll, scrollTo, collapseNav } = this.props;

    if (period === timeRange && sortType === sort && orderType === order) {
      return collapseNav();
    }

    const newItems = JSON.parse(JSON.stringify(activeItems));
    const desc = order === 1;
    const sortDesc = sort === 1 ? !desc : desc;
    const rangeCovers = this.state[`${period}Covers`];
    const listCovers = [...new Set(rangeCovers)];

    Object.values(newItems).forEach(item => {
      item.sort(compareObjectValues(sortKeys[sort], sortDesc));
    });

    resetModalScroll();
    scrollTo(0, false);

    this.setState(
      {
        sortType: sort,
        orderType: order,
        timeRange: period,
        listBgCovers: shuffleArray(listCovers).slice(0, 4),
        activeItems: newItems,
        isLoading: true
      },
      () => {
        collapseNav();
      }
    );

    setTimeout(() => {
      this.setState({ isLoading: false });
      resetModalScroll();
    }, 1000);
  };

  onDeviceOrientation = e => {
    const { beta, gamma: y } = e;
    const maxRotate = 5;
    const x = Math.min(Math.max(beta, -90), 90);
    const xAngle = y + 90;
    const yAngle = x + 90;

    this.targetRotateX = (maxRotate * 2 * xAngle) / 180 - maxRotate;
    this.targetRotateY = (maxRotate * 2 * (yAngle - 90)) / 180;
  };

  animateListCovers = () => {
    this.animateCovers = requestAnimationFrame(this.animateListCovers);

    const listCovers = this.listCoversRef.current;
    if (!listCovers) {
      return;
    }

    const perspectiveOriginMultiplier = 8;

    this.coverRotateX += (this.targetRotateX - this.coverRotateX) * coversEase;
    this.coverRotateY += (this.targetRotateY - this.coverRotateY) * coversEase;

    const rotateX = parseFloat(this.coverRotateX.toFixed(2));
    const rotateY = parseFloat(this.coverRotateY.toFixed(2));

    listCovers.style.perspectiveOrigin = `${50 +
      rotateX * perspectiveOriginMultiplier}% ${50 +
      rotateY * perspectiveOriginMultiplier}%`;
    const coverItems = [...listCovers.children].slice(1, 5);
    coverItems.forEach((cover, index) => {
      cover.style.transform = `translate3d(0, 0, ${index *
        33}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    });
  };

  componentDidUpdate(prevProps) {
    const { isNavExpanded } = this.props;
    if (!isNavExpanded && prevProps.isNavExpanded) {
      this.setState({ collapseNavForm: true });

      setTimeout(() => {
        this.setState({
          filtersActive: false,
          shareActive: false,
          collapseNavForm: false
        });
      }, 400);
    }
  }

  componentDidMount() {
    const typeOptions = {
      artists: {
        name: 'Artists',
        icon: MicAltIcon,
        listDataSuffix: 'TopArtists',
        setActiveList: this.setActiveArtists,
        cardComponent: MobileArtistCard,
        filterFormTitle: 'Filter top artists'
      },
      tracks: {
        name: 'Tracks',
        icon: MusicIcon,
        listDataSuffix: 'TopTracks',
        setActiveList: this.setActiveTracks,
        cardComponent: MobileTrackCard,
        filterFormTitle: 'Filter top tracks'
      }
    };

    window.addEventListener('deviceorientation', this.onDeviceOrientation);
    this.animateCovers = requestAnimationFrame(this.animateListCovers);
    setTimeout(() => {
      this.setState({ typeOptions }, this.setListData);
    }, entranceDelay);
  }

  componentWillUnmount() {
    window.removeEventListener('deviceorientation', this.onDeviceOrientation);
    cancelAnimationFrame(this.animateCovers);
  }

  render() {
    const {
      activeTopList,
      isNavExpanded,
      hasCollapsedNav,
      willCloseModal
    } = this.props;
    const {
      isLoading,
      navReady,
      filtersActive,
      shareActive,
      collapseNavForm,
      timeRange,
      typeOptions,
      sortType,
      orderType,
      listBgCovers,
      activeItems
    } = this.state;
    const { name, filterFormTitle, icon: Icon, cardComponent: CardComponent } =
      typeOptions[activeTopList] || {};

    return (
      <>
        <nav
          className={cx(listNav, {
            [hideNav]: !navReady || willCloseModal,
            [collapsedNav]: hasCollapsedNav
          })}
        >
          <span
            className={cx(openFilter, {
              [isActiveNavControl]: !collapseNavForm && filtersActive,
              [isPassiveNavControl]: !collapseNavForm && shareActive
            })}
            onClick={this.openFilter}
          >
            <FilterIcon /> Filter
          </span>
          <span
            className={cx(openShare, {
              [isActiveNavControl]: !collapseNavForm && shareActive,
              [isPassiveNavControl]: !collapseNavForm && filtersActive
            })}
            onClick={this.openShare}
          >
            <ShareIcon /> Share
          </span>
        </nav>
        {filtersActive && (
          <ListFilterForm
            title={filterFormTitle}
            timeRange={timeRange}
            sort={sortType}
            order={orderType}
            onSubmit={this.filterList}
            willExit={collapseNavForm}
          />
        )}
        <div>
          <div>
            <RadialLoader isLoading={isLoading}>
              {!isLoading && (
                <>
                  <div data-cover className={coversContainer}>
                    <div data-cover-image>
                      <div
                        className={cx(listCovers, {
                          [scaleCoversUp]: isNavExpanded
                        })}
                        ref={this.listCoversRef}
                      >
                        <div />
                        {listBgCovers.map((cover, index) => (
                          <div className={listCover} key={index}>
                            <div style={{ backgroundImage: `url(${cover})` }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    className={cx(listSummary, {
                      [fadedSummary]: isNavExpanded
                    })}
                  >
                    <div>
                      <span className={listIcon}>
                        <Icon />
                      </span>
                      <h1 className={title}>
                        <span>Your Top</span> <br />
                        <span>{name}</span>
                      </h1>
                      <span className={descriptionPill}>
                        {rangeOptions[timeRange].description}
                      </span>
                    </div>
                    <PlaylistButton
                      text="Generate Playlist"
                      className={playlistButton}
                    />
                  </div>

                  <div>
                    <div className={listGrid}>
                      {activeItems[timeRange].map((item, index) => (
                        <CardComponent
                          {...item}
                          key={`${timeRange}-${item.id}`}
                          renderDelay={600 + index * 50}
                          columnDelay={(index % 2) * 50}
                          forceSmooth={index < 4}
                          title={`#${index + 1}`}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </RadialLoader>
          </div>
        </div>
      </>
    );
  }
}

export default UserConsumer(MobileTopList);
