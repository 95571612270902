import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  modalOpen,
  modalOpenContent,
  isPassive,
  top,
  fifty,
  expandCorner,
  expandIn
} from './Button.module.sass';
import DrumPads from '../DrumPads';
import ExpandIcon from '../../vectors/ExpandIcon';
import { scrollViewListener } from '../../utils';

const TopModalButton = ({ className, onClick }) => {
  const [inView, setInview] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  const container = useRef(null);

  const onEnterView = () => {
    setInview(true);
    setAnimateIn(true);
    setTimeout(() => setAnimateIn(false), 700);
  };

  useEffect(() => {
    scrollViewListener(container.current, 0.8, onEnterView);
  }, []);

  return (
    <div
      role="button"
      ref={container}
      className={cx(modalOpen, className)}
      onClick={onClick}
    >
      <DrumPads total={1} />
      <div className={cx(modalOpenContent, { [isPassive]: !inView })}>
        <ExpandIcon
          cornerClassName={cx(expandCorner, { [expandIn]: animateIn })}
        />
        <span className={top}>Top</span>
        <span className={fifty}>50</span>
      </div>
    </div>
  );
};

TopModalButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default TopModalButton;
