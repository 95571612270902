import React from 'react';

const BoltIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height="30"
    width="30"
  >
    <g opacity="1">
      <g opacity="1">
        <path
          fill="rgb(255,255,255)"
          fillOpacity="1"
          opacity="1"
          fillRule="evenodd"
          d="M21.053133333333335,12.463976385039059 L18.119799999999998,12.463976385039059 C17.880215999999997,12.463976385039059 17.692706666666666,12.296170968477389 17.7198,12.079609297190926 L18.172919999999998,5.359904964181577 C18.279170666666666,3.824356528312755 16.039586666666665,3.2000000000000006 15.15932,4.519941922555408 L7.56038666666667,15.703929827087608 C6.893720000000004,16.68827051609729 7.666637333333337,17.936023614960945 8.946840000000003,17.936023614960945 L11.880173333333335,17.936023614960945 C12.119757333333336,17.936023614960945 12.307266666666667,18.103829031522615 12.280173333333334,18.320390702809075 L11.827053333333335,25.04009503581842 C11.720802666666668,26.575643471687243 13.960386666666668,27.2 14.840653333333336,25.88005807744459 L22.44065333333333,14.672791197187324 C23.106279999999998,13.711897476511034 22.333362666666666,12.464144377647385 21.053133333333335,12.463976385039059Z"
        />
      </g>
    </g>
  </svg>
);

export default BoltIcon;
