import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  sectionIcon,
  userSummary,
  isPassive,
  userSummaryContent,
  topGenreCaption,
  greenCaption,
  topGenreSubcaption,
  whiteText
} from './TopGenre.module.sass';
import {
  GENRE_BARS_CLASS_NAMES as barsClassNames,
  GENRE_CAPTIONS as genreCaptions,
  DEFAULT_GENTRE_CAPTION_TWO as defaultCaptionTwo
} from './TopGenres.constants';
import GenreBar from './GenreBar';
import DrumPads from '../DrumPads';
import { DiscIconBox } from '../SectionIconBox';
import { scrollViewListener } from '../../utils';
import {
  SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction,
  GENRE_LEVEL_COLORS as levelColors
} from '../../config';

const TopGenre = ({ topGenres }) => {
  const [summaryInView, setSummaryInview] = useState(false);
  const summaryRef = useRef();
  const topGenreName = topGenres[0].name;
  const topGenrePercentage = topGenres[0].percentage;

  const genreCaption = () => {
    const captionsKeys = Object.keys(genreCaptions);
    const topKey = captionsKeys.find(key => topGenreName.includes(key));
    return topKey
      ? genreCaptions[topKey]
      : {
          captionOne: topGenreName,
          captionTwo: defaultCaptionTwo
        };
  };

  const { captionOne, captionTwo } = genreCaption();

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  return (
    <section className={container}>
      <DiscIconBox className={sectionIcon} isPassive={!summaryInView} />
      <div
        ref={summaryRef}
        className={cx(userSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={42} />
        <div className={userSummaryContent}>
          <h1 className={topGenreCaption}>
            <span>
              <span>{captionOne}</span> <br />
              <span className={greenCaption}>{captionTwo}</span>
            </span>
          </h1>
          <p className={topGenreSubcaption}>
            <span>
              Looks like you're a huge{' '}
              <span className={whiteText}>{topGenreName}</span> fan! This
              appears in {topGenrePercentage}% of your top tracks. Nice one!
            </span>
          </p>
        </div>
      </div>
      {topGenres.slice(0, 5).map(({ name, percentage }, index) => (
        <GenreBar
          key={index}
          className={barsClassNames[index]}
          name={name}
          level={percentage}
          levelColor={levelColors[index]}
        />
      ))}
      <DrumPads total={112} />
    </section>
  );
};

export default UserConsumer(TopGenre);
