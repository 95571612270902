import React from 'react';
import cx from 'classnames';

import { micIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import MicIcon from '../../vectors/MicIcon';
import MicBgicon from '../../vectors/MicBgicon';

const MicIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(134.4deg, #20AC9A 0%, #1DB954 52%, #91C040 100%)"
    icon={<MicIcon />}
    bgIcon={<MicBgicon />}
    className={cx(className, micIconBox)}
    {...props}
  />
);

export default MicIconBox;
