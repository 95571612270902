import React from 'react';
import cx from 'classnames';

import { musicIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import MusicIcon from '../../vectors/MusicIcon';
import MusicBgIcon from '../../vectors/MusicBgIcon';

const MusicIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(135.57deg, #FA9917 0%, #A727EE 47%, #4360DE 100%)"
    icon={<MusicIcon />}
    bgIcon={<MusicBgIcon />}
    className={cx(className, musicIconBox)}
    {...props}
  />
);

export default MusicIconBox;
