import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  userSummary,
  topTrackCaption,
  topTrackArtistName,
  topTrackSubcaption,
  tracksGrid,
  listActions
} from './MobileTopTracks.module.sass';
import MobileTrackCard from '../MobileTrackCard';
import { MobileTopModalButton } from '../Button';
import MobilePlaylistCard from '../MobilePlaylistCard';
import { getImageUrl } from '../../utils';

const MobileTopArtists = ({ shortTermTopTracks, setActiveTopList }) => {
  const tracks = shortTermTopTracks
    .slice(0, 5)
    .map(({ id, uri, name, album, artists, explicit }) => ({
      id,
      uri,
      artists,
      explicit,
      name,
      albumCover: getImageUrl(album.images)
    }));

  const topTrackArtist = tracks[0].artists[0].name;

  return (
    <section className={container}>
      <div className={userSummary}>
        <h1 className={topTrackCaption}>
          <span>
            <span className={topTrackArtistName}>{topTrackArtist}</span>{' '}
            <span>has been on repeat!</span>
          </span>
        </h1>
        <p className={topTrackSubcaption}>
          <span>Can't get enough of this.</span>
        </p>
      </div>
      <div className={tracksGrid}>
        {tracks.map((artist, index) => (
          <MobileTrackCard
            key={index}
            isFeatured={index === 0}
            title={index === 0 ? 'Your Top Track' : `#${index + 1}`}
            {...artist}
          />
        ))}
      </div>
      <div className={listActions}>
        <MobileTopModalButton onClick={() => setActiveTopList('tracks')} />
        <MobilePlaylistCard />
      </div>
    </section>
  );
};

export default UserConsumer(MobileTopArtists);
