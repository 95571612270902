import React from 'react';
import cx from 'classnames';

import { handIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import HandIcon from '../../vectors/HandIcon';

const HandIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(134.4deg, #20AC9A 0%, #1DB954 52%, #91C040 100%)"
    icon={<HandIcon />}
    className={cx(className, handIconBox)}
    {...props}
  />
);

export default HandIconBox;
