import React, { useEffect } from 'react';

import {
  landing,
  caption,
  listen,
  description,
  loginButton
} from './MobileLanding.module.sass';
import { UserConsumer } from '../../contexts/UserContext';
import { ExploreConsumer } from '../../contexts/ExploreContext';
import ArtistsBubbles from './ArtistsBubbles';
import Button from '../Button';
import SpotifyLogo from '../../vectors/SpotifyLogo';

const MobileLanding = ({ setScrollPosition, exploreData }) => {
  const artists = exploreData.artists.slice(0, 6);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setScrollPosition(0), []);

  return (
    <section className={landing}>
      <h1 className={caption}>
        <span>
          Coming soon <br />
          <span className={listen}>to mobile.</span>
        </span>
      </h1>

      <p className={description}>
        <span>Cruuunchify is live on desktop devices, mobile coming soon.</span>
      </p>

      <Button className={loginButton}>
        <span>
          <SpotifyLogo /> Continue with spotify
        </span>
      </Button>
      <ArtistsBubbles artists={artists} />
    </section>
  );
};

export default UserConsumer(ExploreConsumer(MobileLanding));
