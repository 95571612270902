import React from 'react';

const AnalyticsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 45"
    width="45"
    height="45"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M18.913 10.696a3.948 3.948 0 013.946-3.946 3.948 3.948 0 013.945 3.946V36.25a2 2 0 01-2 2h-3.891a2 2 0 01-2-2V10.696zM6 23.529a3.948 3.948 0 013.946-3.946 3.948 3.948 0 013.945 3.946V36.25a2 2 0 01-2 2H8a2 2 0 01-2-2V23.529zm29.054 1.054a3.948 3.948 0 00-3.945 3.946v7.721a2 2 0 002 2H37a2 2 0 002-2v-7.721a3.948 3.948 0 00-3.946-3.946z"
    />
  </svg>
);

export default AnalyticsIcon;
