import React from 'react';

const DribbbleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M15 3C8.372 3 3 8.373 3 15s5.372 12 12 12 12-5.373 12-12S21.628 3 15 3zm1.3 8.073c2.007-.803 3.78-1.984 5.193-3.699a9.969 9.969 0 00-8.856-2.113 51.4 51.4 0 013.663 5.812zM10.69 5.951a49.166 49.166 0 013.708 5.757c-2.816.773-5.965.982-9.153.991a10.06 10.06 0 015.445-6.748zm12.183 2.857a9.976 9.976 0 012.142 5.91c-2.692-.471-5.014-.485-7.02-.167a42.612 42.612 0 00-.785-1.7c2.152-.885 4.081-2.176 5.663-4.043zM4.985 14.675L4.977 15c0 2.522.937 4.828 2.48 6.59 1.787-2.62 4.394-5.372 8.577-6.598a45.834 45.834 0 00-.698-1.487c-3.18.937-6.725 1.171-10.297 1.171h-.054zm13.786 1.764c.81 2.127 1.481 4.422 1.891 6.827a10.044 10.044 0 004.215-6.566c-2.337-.43-4.352-.483-6.106-.26zm-9.854 6.52c1.692-2.55 4.05-5.07 7.887-6.13.897 2.297 1.628 4.803 2.015 7.436a9.93 9.93 0 01-3.818.759 9.958 9.958 0 01-6.084-2.066z"
    />
  </svg>
);

export default DribbbleIcon;
