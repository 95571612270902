import React from 'react';

const Spinner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fillOpacity="0"
      stroke="rgb(51,255,122)"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity="1"
      fillRule="evenodd"
      d="M30 10C18.96169951012 10.000000000000004 10.000000000000002 18.961699510120003 10.000000000000002 30.000000000000004C10.000000000000005 41.03830048988 18.961699510120006 50 30 50C39.86474578159237 50 48.070972662175635 42.842559467338866 49.704385726615065 33.441973317151316"
    />
  </svg>
);

export default Spinner;
