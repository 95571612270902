import React from 'react';

const FireIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
    <g transform="translate(4.547473508864641e-13, 0)" opacity="1">
      <path
        fill="rgb(255,255,255)"
        opacity="1"
        fillRule="evenodd"
        d="M10.197120666503906,25.611600875854492 C6.5512800216674805,25.286279678344727 3.2616000175476074,21.585119247436523 4.507440090179443,16.6135196685791 C6.098376274108887,11.518320083618164 11.260320663452148,12.260640144348145 13.239360809326172,3 C17.31839942932129,6.696479797363281 18.957120895385742,10.667759895324707 18.15648078918457,14.911919593811035 C20.401801109313965,15.465047836303711 21.948721885681152,14.580984115600586 22.79616069793701,12.260640144348145 C26.738402366638184,15.741600036621094 25.477438926696777,25.330320358276367 19.062000274658203,27 C14.742000579833984,25.887191772460938 12.39072036743164,22.87775993347168 12.008159637451172,17.973838806152344 C9.996288299560547,19.69228744506836 9.392640113830566,22.23863983154297 10.197120666503906,25.611600875854492Z"
      />
    </g>
  </svg>
);

export default FireIcon;
