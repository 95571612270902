import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  artistsBubblesContainer,
  artistsBubbles,
  exploreArtist,
  artistCover,
  artistCoverImage,
  animatedIn,
  popularityIcon
} from './MobileLanding.module.sass';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { getImageUrl, getPopularityGroup } from '../../utils';
import {
  ARTIST_DIMENSION as artisDimension,
  ARTIST_MARGIN as artistMargin
} from './MobileLanding.constants';
import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase,
  ARSTISTS_BUBBLE_CONTAINER_TRANSLATE_LEFT as bubblesContainerLeft
} from '../../config';

class ArtistsBubbles extends Component {
  constructor(props) {
    super(props);
    const { artists } = props;
    this.state = {
      artists: [...artists, ...artists, ...artists]
    };

    this.steps = 1;
    this.column =
      Math.ceil(artists.length / 2) * (artisDimension + artistMargin) +
      artisDimension * 0.3;
    this.targetX = 0;
    this.artistsPositionX = 0;
    this.autoSwipe = true;
    this.artistsRef = createRef();
  }

  initializeVScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaX, deltaY }) => {
      if (!this.canSwipe) {
        return;
      }

      if (!this.swiping && Math.abs(deltaY) > Math.abs(deltaX)) {
        this.canSwipe = false;
        this.props.setVScrollable(true);
        this.autoSwipe = true;
        return;
      }

      this.swiping = true;
      this.targetX += deltaX;
    });
  };

  animatePosition = () => {
    this.animateSwipe = requestAnimationFrame(this.animatePosition);

    if (this.autoSwipe) {
      this.targetX--;
    }

    this.artistsPositionX +=
      (this.targetX - this.artistsPositionX) * scrollEase;
    const translateX = parseFloat(this.artistsPositionX.toFixed(2));
    const translate = `translate3d(${translateX}px, 0, 0)`;

    this.artists.style.transform = translate;
    setTimeout(() => this.updateList(), 1000);
  };

  onTouchStart = () => {
    this.canSwipe = true;
    this.props.setVScrollable(false);
    this.autoSwipe = false;
  };

  onTouchEnd = () => {
    this.canSwipe = false;
    this.swiping = false;
    this.props.setVScrollable(true);
    this.autoSwipe = true;
  };

  updateList = () => {
    const { artists } = this.props;

    if (
      this.artistsPositionX >
      -this.column * this.steps + bubblesContainerLeft
    ) {
      const trimmedArtists = this.state.artists.slice(0, artists.length * 2);
      const newArtists = [...artists, ...trimmedArtists];
      this.setState({ artists: newArtists }, () => {
        this.steps--;
        const marginLeft = (this.steps - 1) * this.column;
        this.artists.style.marginLeft = `${marginLeft}px`;
      });
    } else if (
      this.artistsPositionX <
      -this.column * (this.steps + 1) + bubblesContainerLeft
    ) {
      const trimmedArtists = this.state.artists.slice(
        artists.length,
        artists.length * 3
      );
      const newArtists = [...trimmedArtists, ...artists];
      this.setState({ artists: newArtists }, () => {});
      this.steps++;
      const marginLeft = (this.steps - 1) * this.column;
      this.artists.style.marginLeft = `${marginLeft}px`;
    }
  };

  componentDidMount() {
    this.artists = this.artistsRef.current;
    this.artists.style.transform = 'translate3d(0, 0, 0)';

    this.initializeVScroll();
    setTimeout(() => {
      this.animateSwipe = requestAnimationFrame(this.animatePosition);
      this.setState({ hasAnimatedIn: true });
    }, 3300);
  }

  componentWillUnmount() {
    this.vScroll.destroy();
    cancelAnimationFrame(this.animateSwipe);
    window.removeEventListener('resize', () => this.setContainerWidth(false));
  }

  render() {
    const { artists, hasAnimatedIn } = this.state;

    return (
      <div
        className={artistsBubblesContainer}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
      >
        <div className={artistsBubbles} ref={this.artistsRef}>
          {artists.map(({ images, popularity }, index) => {
            const left = index * (artisDimension / 2) + index * artistMargin;
            const top = (index % 2) * artisDimension;
            const { icon } = getPopularityGroup(popularity);

            return (
              <div
                className={exploreArtist}
                key={index}
                style={{ left: `${left}px`, top: `${top}px` }}
              >
                <div
                  className={cx(artistCover, { [animatedIn]: hasAnimatedIn })}
                >
                  <div className={artistCoverImage}>
                    <div
                      style={{ backgroundImage: `url(${getImageUrl(images)})` }}
                    />
                  </div>
                </div>
                <div
                  className={cx(popularityIcon, {
                    [animatedIn]: hasAnimatedIn
                  })}
                >
                  {icon}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ArtistsBubbles.propTypes = {
  artists: PropTypes.array.isRequired
};

export default ExperienceConsumer(ArtistsBubbles);
