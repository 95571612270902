import React from 'react';

const AnalyticsBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    width="130"
    height="130"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M54.638 30.899c0-6.291 5.107-11.399 11.398-11.399 6.291 0 11.399 5.108 11.399 11.399v73.823a5.78 5.78 0 01-5.778 5.778H60.415a5.78 5.78 0 01-5.777-5.778V30.9zM17.333 67.973c0-6.291 5.108-11.399 11.399-11.399 6.29 0 11.398 5.108 11.398 11.399v36.75a5.78 5.78 0 01-5.777 5.777H23.11a5.78 5.78 0 01-5.778-5.778v-36.75zm83.935 3.046c-6.29 0-11.398 5.107-11.398 11.398v22.305a5.78 5.78 0 005.777 5.778h11.242a5.78 5.78 0 005.778-5.778V82.417c0-6.291-5.108-11.398-11.399-11.398z"
    />
  </svg>
);

export default AnalyticsBgIcon;
