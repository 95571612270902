import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  audioFeatureCard,
  isPassive,
  featureSummary,
  featureLabel,
  featureValue,
  featureProgress
} from './AudioFeatureCard.module.sass';
import { modalScrollViewListener } from '../../utils';

const AudioFeatureCard = ({
  label,
  value,
  icon,
  progress,
  renderDelay,
  columnDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const cardRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : columnDelay);
  };

  useEffect(() => {
    modalScrollViewListener(cardRef.current, 0.15, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx(audioFeatureCard, { [isPassive]: !isReady })}
      ref={cardRef}
    >
      <span>{icon}</span>
      <div className={featureSummary}>
        <span className={featureLabel}>{label}</span>
        <h3 className={featureValue}>{value}</h3>
      </div>
      <div className={featureProgress}>
        <div style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

AudioFeatureCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

AudioFeatureCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  icon: PropTypes.node,
  progress: PropTypes.string,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default AudioFeatureCard;
