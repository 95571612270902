import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { dots, isPassiveDotPattern, animatedIn } from './Patterns.module.sass';

const Dots = ({ isPassive, className }) => {
  const [hasAnimatedIn, setHasAnimatedIn] = useState(false);

  const rows = Array(5).fill();
  const columns = Array(4).fill();

  useEffect(() => {
    !isPassive && setTimeout(() => setHasAnimatedIn(true), 1500);
  }, [isPassive]);

  return (
    <div
      className={cx(dots, className, {
        [isPassiveDotPattern]: isPassive,
        [animatedIn]: hasAnimatedIn
      })}
    >
      {rows.map((item, index) => (
        <div key={index}>
          {columns.map((item, index) => (
            <span key={index} />
          ))}
        </div>
      ))}
    </div>
  );
};

Dots.propTypes = {
  className: PropTypes.string,
  isPassive: PropTypes.bool
};

export default Dots;
