import React from 'react';

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FF3660"
      fillRule="evenodd"
      d="M20.711 7.168a1.5 1.5 0 012.12 2.12L17.121 15l5.71 5.711a1.5 1.5 0 01-2.12 2.12L15 17.122l-5.711 5.71a1.5 1.5 0 01-2.12-2.12l5.71-5.71-5.71-5.712a1.499 1.499 0 112.12-2.12L15 12.879l5.711-5.71z"
      transform="rotate(-180 15 15)"
    />
  </svg>
);

export default CloseIcon;
