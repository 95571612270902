import FacebookColoredIcon from '../../vectors/FacebookColoredIcon';
import TwitterColoredIcon from '../../vectors/TwitterColoredIcon';
import InstagramColoredIcon from '../../vectors/InstagramColoredIcon';
import CopyIcon from '../../vectors/CopyIcon';

export const TYPES = {
  facebook: {
    label: 'Facebook',
    icon: FacebookColoredIcon
  },
  twitter: {
    label: 'Twitter',
    icon: TwitterColoredIcon
  },
  instagram: {
    label: 'Instagram',
    icon: InstagramColoredIcon
  },
  copyLink: {
    label: 'Copy link',
    icon: CopyIcon
  }
};
