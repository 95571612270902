import React from 'react';

const MusicNoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 24"
    height="24"
    width="22.056331026797807"
  >
    <g>
      <path
        fill="rgb(51,255,122)"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        d="M5.437683582305908,1.3044605255126953 C5.437683582305908,0.5837483406066895 6.02114725112915,0 6.741577625274658,0 L20.752344131469727,0 C21.47283363342285,0.00018930435180664062 22.05632972717285,0.5839071273803711 22.05632972717285,1.3046493530273438 L22.055139541625977,14.915420532226562 C21.760854721069336,17.02882194519043 19.96174430847168,18.665672302246094 17.779027938842773,18.665672302246094 C15.280738830566406,18.665672302246094 13.281421661376953,16.521194458007812 13.523151397705078,13.97061538696289 C13.711236953735352,11.990577697753906 15.283086776733398,10.382322311401367 17.256227493286133,10.141681671142578 C17.840909957885742,10.070205688476562 18.402944564819336,10.121429443359375 18.926904678344727,10.266769409179688 L18.926904678344727,4.916491508483887 C18.926904678344727,4.195779800415039 18.34343910217285,3.6120309829711914 17.623010635375977,3.6120309829711914 L9.857810974121094,3.6120309829711914 C9.137411117553711,3.6120309829711914 8.553916931152344,4.195749282836914 8.553916931152344,4.916491508483887 L8.552726745605469,19.72214698791504 C8.551536560058594,22.08440399169922 6.636864185333252,24 4.275539875030518,24 C1.7772517204284668,24 -0.22087383270263672,21.855552673339844 0.019663333892822266,19.306163787841797 C0.19951915740966797,17.401241302490234 1.6640944480895996,15.825172424316406 3.549105167388916,15.505897521972656 C4.212378025054932,15.392723083496094 4.849432468414307,15.439184188842773 5.437683582305908,15.604774475097656 L5.437683582305908,1.3044605255126953Z"
      />
    </g>
  </svg>
);

export default MusicNoteIcon;
