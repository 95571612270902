import React from 'react';

const SmileyLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient
        id="Lhfv458hdWDS"
        x1="0.39"
        x2="0.54"
        y1="-0.03"
        y2="0.51"
      >
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lhfv458hdWDS)"
      fillRule="evenodd"
      d="M129.824 400c0-149.399 120.776-270.175 270.176-270.175 149.4 0 270.176 120.776 270.176 270.175S549.4 670.175 400 670.175C250.6 670.175 129.824 549.4 129.824 400zm100.14-55.624c0 6.363 5.556 11.92 11.92 11.92h98.521c7.14 0 11.92-4.781 11.92-11.92 0-30.978-27.812-57.207-61.18-57.207-33.369 0-61.181 25.421-61.181 57.207zm229.63 11.92h98.523c6.363 0 11.92-4.781 11.92-11.92 0-31.786-27.813-57.207-61.181-57.207-33.369 0-61.181 25.421-61.181 57.207 0 7.139 5.556 11.92 11.92 11.92zM400 575.63c-77.073 0-139.061-61.988-139.061-139.061H539.06c0 77.073-61.988 139.06-139.061 139.06z"
      opacity="0.1"
    />
  </svg>
);

export default SmileyLgIcon;
