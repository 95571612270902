import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { carouselGroup, carouselItems } from './SwipeCarousel.module.sass';
import SwipeCarousel from './SwipeCarousel';
import MobileModalSectionTitle from '../MobileModalSectionTitle';
import MobileTrackCard from '../MobileTrackCard';

const TracksCarousel = ({ title, data, disableParentScroll }) => {
  const [resized, setResized] = useState(true);
  const getCarouselItemsWidth = () => {
    const total = resized && data.length;
    const itemWidth = (window.innerWidth * 0.84 - 15) / 2;
    return itemWidth * total + (total - 1) * 15;
  };

  useEffect(() => {
    window.addEventListener('resize', () => setResized(true));
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', () => setResized(true));
    };
  }, []);

  return (
    <div className={carouselGroup}>
      <MobileModalSectionTitle title={title} />
      <SwipeCarousel disableParentScroll={disableParentScroll}>
        <div
          className={carouselItems}
          style={{
            width: getCarouselItemsWidth()
          }}
        >
          {data.map((artist, index) => (
            <MobileTrackCard
              key={index}
              title={`#${index + 1}`}
              columnDelay={50 + index * 50}
              {...artist}
            />
          ))}
        </div>
      </SwipeCarousel>
    </div>
  );
};

TracksCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  disableParentScroll: PropTypes.func.isRequired,
  data: PropTypes.array
};

export default TracksCarousel;
