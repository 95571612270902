import React from 'react';

const SortIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M12.146 11.28a1.315 1.315 0 01-1.867 0l-.692-.696v11.908c0 .731-.592 1.326-1.32 1.326-.73 0-1.319-.595-1.319-1.326V10.581l-.695.699a1.314 1.314 0 01-1.865 0 1.329 1.329 0 010-1.877l2.944-2.96h.003c.062-.062.125-.117.193-.161.035-.024.072-.047.11-.069.027-.012.053-.026.082-.04.02-.01.04-.017.062-.026a1.284 1.284 0 01.972 0c.162.064.314.163.447.296l2.946 2.96c.513.52.513 1.36-.001 1.877zm7.815-2.416h-3.146c-.728 0-1.319-.594-1.319-1.326 0-.732.59-1.326 1.32-1.326h3.146c.728 0 1.319.594 1.319 1.326 0 .733-.591 1.326-1.32 1.326zm1.573 4.985h-4.719c-.728 0-1.319-.594-1.319-1.326 0-.732.59-1.326 1.32-1.326h4.72c.728 0 1.319.594 1.319 1.326-.001.732-.592 1.326-1.32 1.326zm2.893 3.658c0 .732-.59 1.326-1.319 1.326h-6.292c-.729 0-1.32-.594-1.32-1.326 0-.732.591-1.326 1.32-1.326h6.292c.728 0 1.32.594 1.32 1.326zM26 22.491c0 .732-.591 1.326-1.32 1.326h-7.865c-.728 0-1.319-.594-1.319-1.326 0-.732.59-1.326 1.32-1.326h7.865c.728 0 1.319.594 1.319 1.326z"
      transform="matrix(1 0 0 -1 0 29.87)"
    />
  </svg>
);

export default SortIcon;
