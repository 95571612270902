import React from 'react';

const MicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.5 84.056"
    width="44.5"
    height="84.056"
  >
    <defs>
      <linearGradient id="lBGmmnUIb24" x1="0.18" x2="1.04" y1="0.05" y2="0.99">
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#lBGmmnUIb24)"
      fillRule="evenodd"
      d="M12.263 18.663l6.934-10.537a7.87 7.87 0 00-3.085-3.932A7.896 7.896 0 005.176 6.452a7.894 7.894 0 002.256 10.936 7.877 7.877 0 004.831 1.275L22.38 84.056l3.763-.089v-51.94a1.887 1.887 0 001.881-1.882v-3.58l8.012 3.913a3.2 3.2 0 004.061-1.094 3.127 3.127 0 00-.573-4.124L23.08 11.29l-6.404 9.734 3.821 1.868v7.256c0 1.034.847 1.88 1.883 1.88v52.028L12.263 18.663z"
      opacity="1"
    />
  </svg>
);

export default MicIcon;
