export const ON_GRID_CLASS_STRINGS = [
  'Loader',
  'MouseGlow',
  'Header_header',
  'Header_userProfile',
  'Header_username',
  'Footer_footer',
  'Footer_footerNav',
  'Footer_progressBar',
  'Footer_appSections',
  'Landing_guest',
  'Landing_carouselControl',
  'Landing_controlArtist',
  'DrumPads',
  'Modal_modal_',
  'startContent',
  'TopTracks_trackInfo',
  'TopGenre_genreName',
  'TopGenre_genreBarContent',
  'statsSummary',
  'statsSubcaptionText',
  'spiderChartContent',
  'SpiderChart_chartTipsEllipse',
  'chartTips_',
  'insightBlockContent',
  'tasteSummary',
  'tasteSubcaptionText',
  'tasteArtistLink'
];

export const ON_GRID_CLASS_BASE_STRINGS = ['chartVector'];
