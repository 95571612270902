import React, { Component } from 'react';

const ExperienceContext = React.createContext();

class ExperienceProvider extends Component {
  state = {
    glowActive: true,
    activeSection: 0,
    audioAutoPreview: !Boolean(localStorage.getItem('d_a_p'))
  };

  activateGlow = glowActive => {
    this.setState({ glowActive });
  };

  toggleAudioAutoPreview = () => {
    const { audioAutoPreview } = this.state;

    this.setState({
      audioAutoPreview: !audioAutoPreview
    });

    if (audioAutoPreview) {
      localStorage.setItem('d_a_p', 'tt');
    } else {
      localStorage.removeItem('d_a_p');
    }
  };

  setActiveSection = activeSection => {
    this.setState({ activeSection });
  };

  setVScrollable = enableVScroll => {
    this.setState({
      disableVScroll: !enableVScroll
    });
  };

  setMobileModalOpen = mobileModalOpen => {
    this.setState({ mobileModalOpen });
  };

  disableVScrollEase = disableGlobalScrollEase => {
    this.setState({ disableGlobalScrollEase });
  };

  render() {
    return (
      <ExperienceContext.Provider
        value={{
          ...this.state,
          activateGlow: this.activateGlow,
          toggleAudioAutoPreview: this.toggleAudioAutoPreview,
          setActiveSection: this.setActiveSection,
          setVScrollable: this.setVScrollable,
          disableVScrollEase: this.disableVScrollEase,
          setMobileModalOpen: this.setMobileModalOpen
        }}
      >
        {this.props.children}
      </ExperienceContext.Provider>
    );
  }
}

const ExperienceConsumer = Component => {
  return class Consumer extends React.Component {
    render() {
      return (
        <ExperienceContext.Consumer>
          {data => <Component {...this.props} {...data} />}
        </ExperienceContext.Consumer>
      );
    }
  };
};

export default ExperienceContext;
export { ExperienceProvider, ExperienceConsumer };
