import React, { Component } from 'react';

import {
  SPOTIFY_ACCESS_URL as accessUrl,
  SPOTIFY_SCOPES as scopes,
  SPOTIFY_CLIENT_ID as clientId,
  TIME_RANGES as timeRanges
} from '../../config';

import {
  userProfile,
  userTopTracks,
  userTopArtists,
  userPlaylists,
  audioFeatures,
  artists,
  tracks,
  recommendations,
  playlists,
  users
} from './resources';
import { DEFAULT_MODAL_DATA as defaultModalData } from './UserContext.constant';
import richardCoverImage from '../../shared/images/richard-ekwonye.png';
import joshuaCoverImage from '../../shared/images/joshua-oluwagbemiga.png';
import nemiCoverImage from '../../shared/images/nemi-banigo.png';
import {
  getHashParams,
  getImageUrl,
  preloadImages,
  filterByUniqueKey,
  getTrackTaste,
  getArtistsTaste,
  shuffleArray
} from '../../utils';

const UserContext = React.createContext();
class UserProvider extends Component {
  constructor() {
    super();
    const { access_token } = getHashParams();
    const hasAccessToken = Boolean(access_token);
    const hasLocalToken = Boolean(localStorage.getItem('token'));

    this.state = {
      hasToken: hasLocalToken || hasAccessToken,
      artistsTopTracks: {},
      audioFeatures: [],
      featureTrackIds: []
    };

    hasAccessToken && localStorage.setItem('token', access_token);
  }

  getAccess = () => {
    const { origin, pathname } = window.location;
    window.location.href = `${accessUrl}?response_type=token&client_id=${clientId}&scope=${encodeURIComponent(
      scopes
    )}&redirect_uri=${origin}${pathname}`;
  };

  getUserData = async () => {
    try {
      await Promise.all([
        this.getUserProfile(),
        this.getUserTopTracks('short_term'),
        this.getUserTopTracks('medium_term'),
        this.getUserTopTracks('long_term'),
        this.getUserTopArtists('short_term'),
        this.getUserTopArtists('medium_term'),
        this.getUserTopArtists('long_term')
      ]);

      const { shortTermTopTracks, shortTermTopArtists } = this.state;

      if (shortTermTopTracks.length < 5 || shortTermTopArtists.length < 5) {
        return this.setState({ hasInsufficientData: true });
      }

      try {
        this.getTopGenres();

        await Promise.all([
          this.getTopTracksAudioFeatures(),
          this.state.shortTermTopArtists
            .slice(0, 5)
            .forEach(({ id }) => this.getArtistTopTracks(id))
        ]);

        this.getFeatureGroups();
        this.onStatsDataReady();
      } catch (error) {
        this.onRequestError(error);
      }
    } catch (error) {
      this.onRequestError(error);
    }
  };

  onStatsDataReady = async () => {
    const {
      user,
      shortTermTopArtists,
      mediumTermTopArtists,
      longTermTopArtists,
      shortTermTopTracks,
      mediumTermTopTracks
    } = this.state;

    const tracks = filterByUniqueKey(
      [...shortTermTopTracks, ...mediumTermTopTracks],
      'id'
    );
    const trackTaste = await getTrackTaste(
      tracks,
      this.getArtists,
      this.getRecommendations
    );

    trackTaste.createNewAction = () => {
      this.setActivePlaylist('shortTermTopTracks');
    };

    const artistTaste = getArtistsTaste(
      shortTermTopArtists,
      mediumTermTopArtists,
      longTermTopArtists
    );

    const userImage = getImageUrl(user.images);
    const artistsWithCovers = shortTermTopArtists.filter(
      ({ images }) => images.length
    );
    const albumsWithCovers = shortTermTopTracks.filter(
      ({ album }) => album.images.length
    );
    const artistsCovers = artistsWithCovers.map(({ images }) =>
      getImageUrl(images)
    );
    const albumCovers = albumsWithCovers.map(({ album }) =>
      getImageUrl(album.images)
    );

    const otherArtistsWithCovers = [
      ...mediumTermTopArtists,
      ...longTermTopArtists
    ].filter(({ images }) => images.length);
    const tasteArtistsCovers = [
      ...artistsWithCovers,
      ...otherArtistsWithCovers
    ].map(({ images }) => getImageUrl(images));
    const uniqueTasteArtistCovers = [...new Set(tasteArtistsCovers)];
    const tasteCovers = shuffleArray(uniqueTasteArtistCovers);
    const covers = [
      ...artistsCovers,
      ...albumCovers,
      ...uniqueTasteArtistCovers,
      ...trackTaste.artistsCovers,
      richardCoverImage,
      joshuaCoverImage,
      nemiCoverImage
    ];
    userImage && covers.push(userImage);
    const images = [...new Set(covers)];
    await preloadImages(images);

    this.setState({
      tasteCovers,
      trackTaste,
      artistTaste,
      statsDataReady: true
    });
  };

  getUserProfile = () => {
    return userProfile
      .get()
      .then(({ data }) => {
        this.setState({ user: data });
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getUserTopTracks = time_range => {
    const params = {
      time_range,
      limit: 50
    };

    return userTopTracks
      .get(params)
      .then(async ({ data }) => {
        const ids = data.items.map(({ id }) => id);

        if (!ids.length) {
          return this.setState({ [`${timeRanges[time_range]}TopTracks`]: [] });
        }

        await this.getTracks(ids.join(), tracks => {
          this.setState({
            [`${timeRanges[time_range]}TopTracks`]: tracks
          });
        });
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getUserTopArtists = time_range => {
    return userTopArtists
      .get({
        time_range,
        limit: 50
      })
      .then(({ data }) => {
        this.setState({
          [`${timeRanges[time_range]}TopArtists`]: data.items
        });
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getArtist = (id, callback) => {
    return artists
      .getById(id, {})
      .then(({ data }) => {
        callback && callback(data);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getArtists = (ids, callback) => {
    return artists
      .get({ ids })
      .then(({ data }) => {
        callback && callback(data.artists);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getTrack = (id, callback) => {
    return tracks
      .getById(id, { market: this.state.user.country })
      .then(({ data }) => {
        callback && callback(data);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getTracks = (ids, callback) => {
    return tracks
      .get({ ids, market: this.state.user.country })
      .then(({ data }) => {
        callback && callback(data.tracks);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getAlbums = (id, params, callback) => {
    return artists
      .getById(id, { ...params, country: this.state.user.country }, '/albums')
      .then(({ data }) => {
        callback && callback(data);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getRelatedArtist = (id, callback) => {
    return artists
      .getById(id, {}, '/related-artists')
      .then(({ data }) => {
        callback && callback(data.artists);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getRecommendations = (params, callback) => {
    return recommendations
      .get(params)
      .then(async ({ data }) => {
        const recommendedTracks = data.tracks;
        if (!recommendedTracks.length) {
          return callback && callback(recommendedTracks);
        }

        const trackIds = recommendedTracks.map(({ id }) => id);

        const getTracks = (ids, onSuccess) => {
          const tracksParams = {
            ids: ids.join(),
            market: this.state.user.country
          };

          return tracks
            .get(tracksParams)
            .then(({ data }) => {
              onSuccess && onSuccess(data.tracks);
            })
            .catch(error => {
              this.onRequestError(error);
            });
        };

        if (trackIds.length < 50) {
          return getTracks(trackIds, callback);
        } else {
          data = [];
          await getTracks(trackIds.slice(0, 50), tracks =>
            data.push(...tracks)
          );
          await getTracks(trackIds.slice(50, 100), tracks =>
            data.push(...tracks)
          );
          return callback(data);
        }
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getTracksAudioFeatures = (params, callback) => {
    return audioFeatures
      .get(params)
      .then(({ data }) => {
        callback && callback(data.audio_features);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getFeature = key => {
    const { audioFeatures } = this.state;
    const activeFeatures = audioFeatures.filter(
      feature => feature && feature[key] >= 0.5
    );
    const totalActive = activeFeatures.length;

    if (!totalActive) {
      return {
        featureTrack: null,
        percentage: 0
      };
    }

    const activeFeatureOptions =
      totalActive < 4
        ? activeFeatures
        : activeFeatures.filter(feature => {
            const { featureTrackIds } = this.state;
            return !featureTrackIds.includes(feature.id);
          });

    const featureIndex = Math.floor(
      Math.random() * activeFeatureOptions.length
    );
    const featureTrackId = activeFeatureOptions[featureIndex].id;

    const { featureTrackIds } = this.state;
    featureTrackIds.push(featureTrackId);
    this.setState({ featureTrackIds });

    const track = this.state.shortTermTopTracks.find(
      ({ id }) => id === featureTrackId
    );

    return {
      featureTrack: track,
      percentage: Math.round((totalActive / audioFeatures.length) * 100)
    };
  };

  getFeatureGroups = () => {
    const danceability = this.getFeature('danceability');
    const energy = this.getFeature('energy');
    const valence = this.getFeature('valence');

    this.setState({
      dancePercentage: danceability.percentage,
      danceableTrack: danceability.featureTrack,
      energyPercentage: energy.percentage,
      energeticTrack: energy.featureTrack,
      happyPercentage: valence.percentage,
      happyTrack: valence.featureTrack
    });
  };

  getTopTracksAudioFeatures = () => {
    const trackIds = this.state.shortTermTopTracks.map(({ id }) => id);
    const params = {
      ids: trackIds.join()
    };

    return audioFeatures
      .get(params)
      .then(({ data }) => {
        this.setState({
          audioFeatures: data.audio_features
        });
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getArtistTopTracks = (id, callback) => {
    const { user } = this.state;

    return artists
      .getById(id, { country: user.country }, '/top-tracks')
      .then(({ data }) => {
        const topTracks = { ...this.state.artistsTopTracks };
        topTracks[id] = data.tracks;

        this.setState({
          artistsTopTracks: topTracks
        });
        callback && callback(data.tracks);
      })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  getTopGenres = () => {
    const { shortTermTopArtists } = this.state;
    const genres = {};

    shortTermTopArtists.forEach(artist => {
      artist.genres.forEach(genre => {
        if (genres[genre]) {
          genres[genre]++;
        } else {
          genres[genre] = 1;
        }
      });
    });

    const topGenresList = Object.entries(genres);
    topGenresList.sort((a, b) => b[1] - a[1]);

    const topGenres = topGenresList.slice(0, 6).map(([name, total]) => ({
      name,
      percentage: Math.round((total / shortTermTopArtists.length) * 100)
    }));

    this.setState({ topGenres });
  };

  getPlaylists = () => {
    return new Promise((resolve, reject) => {
      const playlists = [];
      const fetchData = (offset = 0) => {
        userPlaylists
          .get({ offset, limit: 50 })
          .then(({ data }) => {
            const { items, total } = data;
            playlists.push(...items);
            if (offset + 50 < total && offset < 150) {
              fetchData(offset + 50);
            } else {
              resolve(playlists);
            }
          })
          .catch(error => {
            reject(error);
          });
      };
      fetchData();
    });
  };

  createPlaylist = (data, tracksUris, cover, callback, onError) => {
    const { user } = this.state;
    users
      .post(data, `${user.id}/playlists`)
      .then(({ data }) => {
        const { id } = data;
        this.updatePlaylist(id, tracksUris, cover);
        callback && callback(data);
      })
      .catch(error => {
        this.onRequestError(error);
        onError && onError(error);
      });
  };

  updatePlaylist = (id, tracksUris, cover, callback, onError) => {
    playlists
      .putById(id, { uris: tracksUris }, `/tracks`)
      .then(() => {
        callback && callback();
      })
      .catch(error => {
        this.onRequestError(error);
        onError && onError(error);
      });
    this.setPlaylistCover(id, cover);
  };

  setPlaylistCover = (id, imageData) => {
    playlists
      .putById(id, imageData, '/images', { 'Content-Type': 'image/jpeg' })
      .catch(error => {
        this.onRequestError(error);
      });
  };

  setActiveArtist = (activeArtist, setModalKey = true) => {
    const modalData = {
      ...defaultModalData,
      activeArtist
    };
    if (setModalKey) {
      modalData.modalKey = Date.now();
    }

    this.setState(modalData);
  };

  setActiveTrack = (activeTrack, setModalKey = true) => {
    const modalData = {
      ...defaultModalData,
      activeTrack
    };
    if (setModalKey) {
      modalData.modalKey = Date.now();
    }

    this.setState(modalData);
  };

  setActiveTopList = (activeTopList, setModalKey = true) => {
    const modalData = {
      ...defaultModalData,
      activeTopList
    };
    if (setModalKey) {
      modalData.modalKey = Date.now();
    }

    this.setState(modalData);
  };

  setActivePlaylist = (
    activePlaylistType,
    activePlaylistData,
    setModalKey = true
  ) => {
    const modalData = {
      ...defaultModalData,
      activePlaylistType,
      activePlaylistData
    };
    if (setModalKey) {
      modalData.modalKey = Date.now();
    }

    this.setState(modalData);
  };

  setActiveSuggestionPlaylist = (
    activeSuggestionPlaylist,
    setModalKey = true
  ) => {
    const modalData = {
      ...defaultModalData,
      activeSuggestionPlaylist
    };
    if (setModalKey) {
      modalData.modalKey = Date.now();
    }

    this.setState(modalData);
  };

  logout = () => {
    this.setState({
      user: {},
      hasToken: false,
      authError: true,
      activeArtist: null
    });

    localStorage.removeItem('token');
  };

  onRequestError = error => {
    if (error.response && error.response.status === 401) {
      this.logout();
    }
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          getAccess: this.getAccess,
          getUserData: this.getUserData,
          getArtistTopTracks: this.getArtistTopTracks,
          logout: this.logout,
          setActiveArtist: this.setActiveArtist,
          setActiveTrack: this.setActiveTrack,
          setActiveTopList: this.setActiveTopList,
          setActivePlaylist: this.setActivePlaylist,
          setActiveSuggestionPlaylist: this.setActiveSuggestionPlaylist,
          getArtist: this.getArtist,
          getArtists: this.getArtists,
          getTrack: this.getTrack,
          getRecommendations: this.getRecommendations,
          getTracksAudioFeatures: this.getTracksAudioFeatures,
          getAlbums: this.getAlbums,
          getRelatedArtist: this.getRelatedArtist,
          getPlaylists: this.getPlaylists,
          createPlaylist: this.createPlaylist,
          updatePlaylist: this.updatePlaylist,
          setPlaylistCover: this.setPlaylistCover
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const UserConsumer = Component => {
  return class Consumer extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {data => <Component {...this.props} {...data} />}
        </UserContext.Consumer>
      );
    }
  };
};

export default UserContext;
export { UserProvider, UserConsumer };
