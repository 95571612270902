import React, { Component, createRef } from 'react';
import cx from 'classnames';

import {
  chartTip,
  chartTipContent,
  chartTipTitle,
  columnIcon,
  columnIconWillChange,
  columnLabel,
  tipValueLabel,
  tipTotal,
  columnInfoHotspot,
  columnInfo,
  infoIcon,
  columnBgIcon
} from './SpiderChart.module.sass';
import InfoIcon from '../../vectors/InfoIcon';

class ChartTip extends Component {
  state = {};
  tipRef = createRef();
  titleRef = createRef();
  removeIconWillChange = null;

  setIconPos = () => {
    const tipRect = this.tip.getBoundingClientRect();
    const titleRect = this.title.getBoundingClientRect();
    const tipCenterTop = tipRect.height / 2;
    const tipCenterLeft = tipRect.width / 2;
    const top = tipCenterTop - (titleRect.top - tipRect.top - 10);
    const left = tipCenterLeft - (titleRect.left - tipRect.left - 50);
    const iconPos = {
      transform: `translate3d(calc(-${left}px + 50%), calc(-${top}px + 50%), 0)`
    };

    this.setState({ iconPos });
  };

  setTipActive = () => {
    clearTimeout(this.removeIconWillChange);
    this.setState({
      isActive: true,
      iconWillChange: true
    });
  };

  setTipPassive = () => {
    this.setState({ isActive: false });
    this.removeIconWillChange = setTimeout(() => {
      this.setState({ iconWillChange: false });
    }, 700);
  };

  componentDidMount() {
    this.tip = this.tipRef.current;
    this.title = this.titleRef.current;
    this.setIconPos();
    window.addEventListener('resize', () => setTimeout(this.setIconPos, 300));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () =>
      setTimeout(this.setIconPos, 300)
    );
  }

  render() {
    const { x, y, data, percentageFraction } = this.props;
    const { icon, label, valueLabel, total, description } = data;
    const { iconPos, isActive, iconWillChange } = this.state;
    const iconStyle = isActive ? iconPos : {};

    return (
      <div
        onMouseEnter={this.setTipActive}
        onMouseLeave={this.setTipPassive}
        ref={this.tipRef}
        className={chartTip}
        style={{
          left: `${(x * percentageFraction).toFixed(4)}%`,
          top: `${(y * percentageFraction).toFixed(4)}%`
        }}
      >
        <div className={chartTipContent}>
          <div className={chartTipTitle}>
            <span
              className={cx(columnIcon, {
                [columnIconWillChange]: iconWillChange
              })}
              style={iconStyle}
            >
              {icon}
            </span>
            <h4 ref={this.titleRef} lang="en" className={columnLabel}>
              <span>{label}</span>
            </h4>
          </div>
          <h3 className={tipValueLabel}>
            <span>{valueLabel}</span>
          </h3>
          <div className={tipTotal}>
            <span>/{total}</span>
          </div>
          <div className={columnInfoHotspot} />
          <div className={columnInfo}>
            <p>{description}</p>
            <span className={infoIcon}>
              <InfoIcon />
            </span>
          </div>
          <div className={columnBgIcon}>
            <span>{icon}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartTip;
