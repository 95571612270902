import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  selectDropdown,
  selected,
  selectedValue,
  selectedInfo
} from './SelectDropdown.module.sass';
import ChevronDownIcon from '../../vectors/ChevronDownIcon';

const SelectDropdown = ({ value, options, className }) => {
  const selectedOption = options.find(option => option.value === value);
  const { label, info } = selectedOption;
  // const closeDropdown = () => {
  //window event
  // if isControlled return set isControlled to false else close
  // clicking the selected div will toggle dropdown and setIscontrolled
  // onClick of the dropdown div will do not but set isControlled to true.
  // }

  return (
    <>
      <div className={cx(selectDropdown, className)}>
        <div className={selected}>
          <span className={selectedValue}>{label}</span>
          <ChevronDownIcon />
        </div>
      </div>
      {info && <p className={selectedInfo}> {info} </p>}
    </>
  );
};

SelectDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default SelectDropdown;
