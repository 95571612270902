import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { card, isPassive, musicIcon } from './MobilePlaylistCard.module.sass';
import PlaylistIcon from '../../vectors/PlaylistIcon';
import { scrollViewListener } from '../../utils';

const MobilePlaylistCard = ({ onClick }) => {
  const [inView, setInview] = useState(false);
  const cardContainer = useRef(null);

  useEffect(() => {
    scrollViewListener(cardContainer.current, 0.6, () => setInview(true));
  }, []);

  return (
    <div
      onClick={onClick}
      ref={cardContainer}
      className={cx(card, { [isPassive]: !inView })}
    >
      <span className={musicIcon}>
        <PlaylistIcon />
      </span>
      <span>
        Generate <br />
        Playlist
      </span>
    </div>
  );
};

MobilePlaylistCard.propTypes = {
  onClick: PropTypes.func
};

export default MobilePlaylistCard;
