import React, { Component, createRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  firstLine,
  singleLine,
  lastLine
} from './MultiLineEllipsis.module.sass';

class MultilineEllipsis extends Component {
  firstLineRef = createRef();
  lastLineRef = createRef();

  fillTexts = () => {
    const { lines } = this.props;
    const words = this.props.text.split(' ');

    this.lastLine.innerText = '';
    this.firstLine.style.maxHeight = `unset`;
    this.firstLine.style.overflow = 'unset';

    this.firstLine.innerText = words[0];
    const maxHeight = this.firstLine.offsetHeight * Math.max(lines - 1, 1);

    words.shift();
    words.forEach((word, index) => {
      this.firstLine.innerHTML = `${this.firstLine.innerHTML} ${word}`;

      if (this.firstLine.offsetHeight > maxHeight) {
        this.firstLine.style.maxHeight = `${maxHeight}px`;
        this.firstLine.style.overflow = 'hidden';
        this.lastLine.innerText = words.slice(index, words.length).join(' ');
      }
    });

    if (lines < 2) {
      this.firstLine.style.maxHeight = `unset`;
      this.lastLine.innerText = '';
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lines !== this.props.lines) {
      this.fillTexts();
    }
  }

  componentDidMount() {
    this.firstLine = this.firstLineRef.current;
    this.lastLine = this.lastLineRef.current;
    this.fillTexts();

    window.addEventListener('resize', this.fillTexts);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fillTexts);
  }

  render() {
    const { lines } = this.props;

    return (
      <>
        <span
          className={cx(firstLine, { [singleLine]: lines === 1 })}
          ref={this.firstLineRef}
        />
        <span className={lastLine} ref={this.lastLineRef} />
      </>
    );
  }
}

MultilineEllipsis.defaultProps = {
  lines: 1
};

MultilineEllipsis.propTypes = {
  lines: PropTypes.number,
  text: PropTypes.string
};

export default MultilineEllipsis;
