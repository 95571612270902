import React from 'react';

const InstagramColoredIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50"
    height="50"
  >
    <defs>
      <radialGradient id="Grvsa872bTC" cx="0.81" cy="0.09" r="1.215">
        <stop offset="0" stopColor="#C837AB" />
        <stop offset="0.64" stopColor="#FF543E" />
        <stop offset="0.85" stopColor="#FD5" />
        <stop offset="1" stopColor="#FD5" />
      </radialGradient>
    </defs>
    <path
      fill="url(#Grvsa872bTC)"
      fillRule="evenodd"
      d="M25.002 6.667h-.006c-4.974 0-5.599.021-7.554.11-1.951.09-3.284.399-4.45.852a8.977 8.977 0 00-3.247 2.114 9 9 0 00-2.116 3.248c-.455 1.166-.764 2.498-.852 4.45-.087 1.955-.11 2.58-.11 7.559s.022 5.602.11 7.558c.09 1.951.4 3.283.852 4.45a8.983 8.983 0 002.115 3.247 8.977 8.977 0 003.246 2.116c1.167.453 2.5.762 4.45.852 1.956.089 2.58.11 7.56.11 4.979 0 5.602-.021 7.557-.11 1.952-.09 3.286-.399 4.452-.852a8.97 8.97 0 003.246-2.116 9 9 0 002.116-3.247c.45-1.167.76-2.5.851-4.45.088-1.956.111-2.579.111-7.558 0-4.979-.023-5.604-.11-7.56-.092-1.951-.402-3.283-.852-4.45a8.998 8.998 0 00-2.116-3.247 8.96 8.96 0 00-3.247-2.114c-1.169-.453-2.502-.762-4.453-.852-1.955-.089-2.578-.11-7.553-.11zm-.613 3.303h.612c4.896 0 5.476.018 7.409.106 1.787.082 2.758.38 3.404.631a5.67 5.67 0 012.107 1.371 5.69 5.69 0 011.372 2.109c.25.645.55 1.615.63 3.403.089 1.933.108 2.513.108 7.406s-.02 5.473-.107 7.406c-.082 1.787-.38 2.757-.631 3.403a5.669 5.669 0 01-1.372 2.106 5.67 5.67 0 01-2.107 1.371c-.646.252-1.617.55-3.404.632-1.933.088-2.513.107-7.408.107-4.896 0-5.476-.02-7.408-.107-1.788-.083-2.758-.381-3.405-.632a5.678 5.678 0 01-2.108-1.372 5.682 5.682 0 01-1.372-2.107c-.251-.645-.55-1.615-.631-3.403-.088-1.932-.106-2.513-.106-7.409s.018-5.473.106-7.406c.081-1.787.38-2.757.631-3.404a5.68 5.68 0 011.372-2.108 5.69 5.69 0 012.108-1.372c.647-.252 1.617-.55 3.405-.632 1.69-.076 2.346-.1 5.763-.103v.004H24.39zm8.199 5.244a2.2 2.2 0 114.399.001 2.2 2.2 0 01-4.4-.001zm-7.586.371A9.415 9.415 0 0015.586 25c0 5.2 4.215 9.413 9.415 9.413 5.2 0 9.413-4.214 9.413-9.413 0-5.2-4.214-9.415-9.413-9.415zM31.112 25a6.111 6.111 0 10-12.221 0 6.111 6.111 0 0012.222 0z"
    />
  </svg>
);

export default InstagramColoredIcon;
