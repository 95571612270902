import React from 'react';
import PropTypes from 'prop-types';

const DiscIcon = ({ blaze }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height={blaze ? 44 : 34}
    width={blaze ? 44 : 34}
  >
    <path
      fill={blaze ? '#FFDF36' : '#FFFFFF'}
      fillRule="evenodd"
      d="M15 1C7.3 1 1 7.3 1 15s6.3 14 14 14 14-6.3 14-14S22.7 1 15 1zm0 20.6c-3.63 0-6.6-2.97-6.6-6.6 0-3.63 2.97-6.6 6.6-6.6 3.63 0 6.6 2.97 6.6 6.6 0 3.63-2.97 6.6-6.6 6.6zM12.2 15c0 1.54 1.26 2.8 2.8 2.8 1.54 0 2.8-1.26 2.8-2.8 0-1.54-1.26-2.8-2.8-2.8-1.54 0-2.8 1.26-2.8 2.8z"
    />
  </svg>
);

DiscIcon.propTypes = {
  blaze: PropTypes.bool
};

export default DiscIcon;
