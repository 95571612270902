import React from 'react';

import MobileTopArtists from '../MobileTopArtists';
import MobileTopTracks from '../MobileTopTracks';
import MobileTopGenre from '../MobileTopGenre';

const MobileFavourites = () => (
  <>
    <MobileTopArtists />
    <MobileTopTracks />
    <MobileTopGenre />
  </>
);

export default MobileFavourites;
