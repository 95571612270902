import React, { createRef, Component } from 'react';
import cx from 'classnames';
import VirtualScroll from 'virtual-scroll';

import {
  RANGE_OPTIONS as rangeOptions,
  PLAYLIST_TYPES as playlistTypes,
  SHARE_TIME_RANGES as shareTimeRanges,
  ORDER_KEYS as orderKeys
} from './TopList.constants';

import { UserConsumer } from '../../contexts/UserContext';
import {
  listDetails,
  listActions,
  listNav,
  navBar,
  firstActive,
  lastActive,
  rangeOption,
  activeRangeOption,
  listItems,
  topListFade,
  isActive
} from './TopList.module.sass';
import ListSummary from './ListSummary';
import ListSortForm from './ListSortForm';
import ListShare from './ListShare';
import RadialLoader from '../RadialLoader';
import { getImageUrl, preloadImages, compareObjectValues } from '../../utils';
import { ArtistCard, TrackCard } from '../ListCards';
import SortIcon from '../../vectors/SortIcon';
import ShareIcon from '../../vectors/ShareIcon';

class TopList extends Component {
  state = {
    isLoading: true,
    timeRange: 'shortTerm',
    typeOptions: {},
    activeRangeIndex: 0,
    stickyColumnTab: 0,
    orderType: 0,
    descOrder: false
  };

  stickyColumnRef = createRef();
  stickyNavRef = createRef();
  stickyTopFadeRef = createRef();

  setListData = async () => {
    try {
      await this.setItems();
      await this.loadImages();

      this.setState({ isLoading: false }, () =>
        this.props.resetModalScroll(false, true)
      );
    } catch (error) {}
  };

  setItems = () => {
    const { activeTopList } = this.props;
    const { typeOptions } = this.state;
    const { listDataSuffix, setActiveList } = typeOptions[activeTopList];
    const listItems = {};

    Object.keys(rangeOptions).forEach(range => {
      const listItemKey = `${range}${listDataSuffix}`;
      listItems[range] = this.props[listItemKey];
    });

    setActiveList(listItems);
  };

  setActiveArtists = data => {
    const activeItems = {};
    Object.entries(data).forEach(([range, items]) => {
      const rangeItems = items.map(
        ({ id, uri, name, images, genres, popularity }, index) => ({
          id,
          genres,
          uri,
          popularity,
          rank: index,
          artistName: name,
          artistCover: getImageUrl(images),
          title: name
        })
      );
      activeItems[range] = rangeItems;
    });

    this.setState({ activeItems });
  };

  setActiveTracks = data => {
    const activeItems = {};
    Object.entries(data).forEach(([range, items]) => {
      const rangeItems = items.map(
        ({ id, name, artists, uri, album, popularity, explicit }, index) => ({
          id,
          name,
          uri,
          popularity,
          explicit,
          rank: index,
          artists: artists.map(({ name }) => name),
          albumCover: getImageUrl(album.images),
          title: name
        })
      );
      activeItems[range] = rangeItems;
    });

    this.setState({ activeItems });
  };

  onRangeSelect = (timeRange, activeRangeIndex) => {
    if (timeRange === this.state.timeRange) {
      return;
    }

    const { resetModalScroll, scrollTo } = this.props;
    resetModalScroll();
    scrollTo(0, false);

    this.setState(
      {
        timeRange,
        activeRangeIndex,
        topFadeActive: false
      },
      resetModalScroll
    );
  };

  sortList = (type, desc) => {
    const { orderType, descOrder, activeItems } = this.state;
    const { resetModalScroll, scrollTo } = this.props;

    if (orderType === type && descOrder === desc) {
      return;
    }

    const newItems = JSON.parse(JSON.stringify(activeItems));
    const sortDesc = type === 1 ? !desc : desc;

    Object.values(newItems).forEach(item => {
      item.sort(compareObjectValues(orderKeys[type], sortDesc));
    });

    resetModalScroll();
    scrollTo(0, false);

    this.setState(
      {
        orderType: type,
        descOrder: desc,
        activeItems: newItems,
        topFadeActive: false
      },
      resetModalScroll
    );
  };

  loadImages = () => {
    const [listA, listB, listC] = Object.values(this.state.activeItems);
    const activeItems = [...listA, ...listB, ...listC];

    const itemsWithCovers = activeItems.filter(
      ({ artistCover, albumCover }) => artistCover || albumCover
    );

    const itemsCovers = itemsWithCovers.map(
      ({ artistCover, albumCover }) => artistCover || albumCover
    );

    return preloadImages(itemsCovers);
  };

  getArtistsShareData = data => {
    return data.slice(0, 5).map(({ name, images }) => ({
      artistName: name,
      artistCover: getImageUrl(images)
    }));
  };

  getTracksShareData = data => {
    return data.slice(0, 5).map(({ name, artists, album }) => ({
      name,
      artists: artists.map(({ name }) => name),
      albumCover: getImageUrl(album.images)
    }));
  };

  shareData = () => {
    const { activeTopList } = this.props;
    const { typeOptions, timeRange } = this.state;
    const { listDataSuffix, getShareData } = typeOptions[activeTopList] || {};
    const listItemKey = `${timeRange}${listDataSuffix}`;
    const items = this.props[listItemKey];
    return getShareData(items);
  };

  setStickyColumnTab = tabIndex => {
    const { stickyColumnTab } = this.state;
    this.setState({ willSwitchTab: true });

    setTimeout(() => {
      this.setState({
        stickyColumnTab: tabIndex === stickyColumnTab ? 0 : tabIndex,
        willSwitchTab: false
      });
    }, 100);
  };

  initializePositionListener = () => {
    this.vScroll = new VirtualScroll();

    this.vScroll.on(() => {
      const { topFadeActive } = this.state;
      const topStart = this.stickyTopFade.getBoundingClientRect().y + 90;
      const topStartSibling = this.stickyTopFade.nextSibling;

      if (!topStartSibling) {
        return;
      }

      const topSiblingY = topStartSibling.getBoundingClientRect().y;
      const showTopFade = topStart - topSiblingY > 5;

      if (showTopFade && !topFadeActive) {
        this.setState({ topFadeActive: true });
      } else if (!showTopFade && topFadeActive) {
        this.setState({ topFadeActive: false });
      }
    });
  };

  componentDidMount() {
    const { addToSticky } = this.props;
    const typeOptions = {
      artists: {
        name: 'Artists',
        listDataSuffix: 'TopArtists',
        setActiveList: this.setActiveArtists,
        getShareData: this.getArtistsShareData,
        CardComponent: ArtistCard
      },
      tracks: {
        name: 'Tracks',
        listDataSuffix: 'TopTracks',
        setActiveList: this.setActiveTracks,
        getShareData: this.getTracksShareData,
        CardComponent: TrackCard
      }
    };

    this.setState({ typeOptions }, this.setListData);
    this.stickyColumn = this.stickyColumnRef.current;
    this.stickyNav = this.stickyNavRef.current;
    this.stickyTopFade = this.stickyTopFadeRef.current;

    addToSticky(this.stickyColumn);
    addToSticky(this.stickyNav);
    addToSticky(this.stickyTopFade);
    this.initializePositionListener();
  }

  componentWillUnmount() {
    const { removeFromSticky } = this.props;

    removeFromSticky(this.stickyColumn);
    removeFromSticky(this.stickyNav);
    removeFromSticky(this.stickyTopFade);
    this.vScroll.destroy();
  }

  render() {
    const { activeTopList, setActivePlaylist } = this.props;
    const {
      isLoading,
      timeRange,
      typeOptions,
      activeRangeIndex,
      stickyColumnTab,
      willSwitchTab,
      activeItems,
      topFadeActive,
      orderType,
      descOrder
    } = this.state;
    const { name, CardComponent } = typeOptions[activeTopList] || {};
    const { description } = rangeOptions[timeRange];
    const playlistType = playlistTypes[activeTopList][timeRange];
    const sortOrderKey = `${orderType}-${descOrder ? 'd' : 'a'}`;

    return (
      <div className={listDetails}>
        <RadialLoader isLoading={isLoading}>
          <div ref={this.stickyColumnRef}>
            {!isLoading && (
              <>
                <div className={listActions}>
                  <span
                    className={cx({ [isActive]: stickyColumnTab === 1 })}
                    onClick={() => this.setStickyColumnTab(1)}
                  >
                    <SortIcon /> Sort
                  </span>
                  <span
                    className={cx({ [isActive]: stickyColumnTab === 2 })}
                    onClick={() => this.setStickyColumnTab(2)}
                  >
                    <ShareIcon /> Share this
                  </span>
                </div>
                {stickyColumnTab === 0 && (
                  <ListSummary
                    name={name}
                    listDescription={description}
                    onPlaylistButtonClick={() =>
                      playlistType && setActivePlaylist(playlistType)
                    }
                    withoutPlaylistButton={name === 'Artists'}
                    willExit={willSwitchTab}
                  />
                )}
                {stickyColumnTab === 1 && (
                  <ListSortForm
                    type={orderType}
                    desc={descOrder}
                    onSubmit={this.sortList}
                    close={() => this.setStickyColumnTab(0)}
                    willExit={willSwitchTab}
                  />
                )}
                {stickyColumnTab === 2 && (
                  <ListShare
                    sourceType={name}
                    timeRange={shareTimeRanges[timeRange]}
                    data={this.shareData()}
                    close={() => this.setStickyColumnTab(0)}
                    willExit={willSwitchTab}
                  />
                )}
              </>
            )}
          </div>
          <div>
            <nav ref={this.stickyNavRef} className={listNav}>
              {!isLoading && (
                <>
                  <span
                    className={cx(navBar, {
                      [firstActive]: activeRangeIndex === 0,
                      [lastActive]: activeRangeIndex === 2
                    })}
                  />
                  {Object.entries(rangeOptions).map(
                    ([key, { label }], index) => (
                      <span
                        key={key}
                        onClick={() => this.onRangeSelect(key, index)}
                        className={cx(rangeOption, {
                          [activeRangeOption]: key === timeRange
                        })}
                      >
                        {label}
                      </span>
                    )
                  )}
                </>
              )}
            </nav>
            <div className={listItems}>
              <div
                ref={this.stickyTopFadeRef}
                className={cx(topListFade, {
                  [isActive]: topFadeActive
                })}
              />
              {!isLoading &&
                activeItems[timeRange].map((item, index) => (
                  <CardComponent
                    key={`${index}-${timeRange}-${item.id}-${sortOrderKey}`}
                    renderDelay={
                      200 + Math.ceil((index + 1) / 3) * 100 + (index % 3) * 100
                    }
                    columnDelay={(index % 3) * 100}
                    number={index + 1}
                    {...item}
                  />
                ))}
            </div>
          </div>
        </RadialLoader>
      </div>
    );
  }
}

export default UserConsumer(TopList);
