import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  header,
  isPassive,
  landingHeader,
  nav,
  menuPill,
  statsProgress,
  menuTitles,
  userProfile,
  username,
  userAvatar,
  menuIcon,
  isActive
} from './Header.module.sass';
import { SECTION_TITLES as sectionTitles } from './Header.constants';
import AboutMenu from './AboutMenu';
import ShareMenu from './ShareMenu';
import UserMenu from './UserMenu';
import Logo from '../../vectors/Logo';
import LinkIcon from '../../vectors/LinkIcon';
import ShareIcon from '../../vectors/ShareIcon';
import MenuIcon from '../../vectors/MenuIcon';
import { getImageUrl } from '../../utils';
import { LOAD_COMPLETE_ENTANCE_DELAY as entranceDelay } from '../../config';

const Header = ({ isLoading, hasToken, hasInsufficientData, user = {} }) => {
  const [isReady, setIsReady] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false);
  const { images, display_name } = user;
  const avatarUrl = getImageUrl(images);
  const avatarBg = avatarUrl && `url(${avatarUrl})`;

  useEffect(() => {
    !isLoading && setTimeout(() => setIsReady(true), entranceDelay);
  }, [isLoading]);

  return (
    <header
      className={cx(header, {
        [isPassive]: !isReady,
        [landingHeader]: !hasToken
      })}
    >
      <nav className={nav}>
        <Logo />
        <ul>
          <li>
            <span
              onClick={() => setAboutMenuOpen(true)}
              className={cx({ [isActive]: aboutMenuOpen })}
            >
              <LinkIcon /> About
            </span>
            {aboutMenuOpen && (
              <AboutMenu closeModal={() => setAboutMenuOpen(false)} />
            )}
          </li>
          <li>
            <span
              onClick={() => setShareMenuOpen(true)}
              className={cx({ [isActive]: shareMenuOpen })}
            >
              <ShareIcon /> Share
            </span>
            {shareMenuOpen && (
              <ShareMenu closeModal={() => setShareMenuOpen(false)} />
            )}
          </li>
        </ul>
      </nav>
      {hasToken && (
        <>
          {!hasInsufficientData && (
            <div className={menuPill}>
              <div className={statsProgress} data-stats-progress />
              <div className={menuTitles} data-menu-titles>
                {sectionTitles.map((title, index) => (
                  <span key={index}>{title}</span>
                ))}
              </div>
            </div>
          )}
          <div className={userProfile}>
            <h2 className={username}>{display_name}</h2>
            <div className={userAvatar} style={{ backgroundImage: avatarBg }} />
            <span className={cx(menuIcon, { [isActive]: userMenuOpen })}>
              <span onClick={() => setUserMenuOpen(true)}>
                <MenuIcon />
              </span>
            </span>
          </div>
          {userMenuOpen && (
            <UserMenu closeModal={() => setUserMenuOpen(false)} />
          )}
        </>
      )}
    </header>
  );
};

export default UserConsumer(Header);
