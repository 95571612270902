import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  tabSwitch,
  isPassive,
  tabTwoActive,
  tab
} from './TabSwitch.module.sass';
import { mobileScrollViewListener } from '../../utils';

const TabSwitch = ({ labels, activeTab, onChange, className }) => {
  const [containerInView, setContainerInview] = useState(false);
  const [labelOne, labelTwo] = labels;
  const containerRef = useRef();

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, 0.4, () =>
      setContainerInview(true)
    );
  }, []);

  return (
    <div
      ref={containerRef}
      className={cx(tabSwitch, className, {
        [isPassive]: !containerInView,
        [tabTwoActive]: activeTab === 1
      })}
    >
      <span onClick={() => onChange(0)} className={tab}>
        {labelOne}
      </span>
      <span onClick={() => onChange(1)} className={tab}>
        {labelTwo}
      </span>
    </div>
  );
};

TabSwitch.defaultProps = {
  activeTab: 0
};

TabSwitch.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default TabSwitch;
