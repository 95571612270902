import React from 'react';

const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height="30"
    width="30"
  >
    <g opacity="1">
      <g opacity="1">
        <path
          fill="rgb(255,255,255)"
          opacity="1"
          fillRule="evenodd"
          d="M13.830218341796458,4.420600276759211 L11.246056384852254,9.74094767802514 C11.123387200733525,10.111828510713826 10.753555917191594,10.35939421440526 10.384645803993484,10.48364478909944 L4.4765941897645805,11.349657029170992 C3.3689333750754797,11.472981512625216 3,12.710800676625414 3.7378434882735907,13.453497787699712 L8.045943177049057,17.660197086775383 C8.292193410879387,18.03107791946407 8.41485329024717,18.402894197849683 8.41485329024717,18.77377503053837 L7.429852354925849,24.713387483169228 C7.18360212109552,25.82696542693222 8.29126293578462,26.56966253800652 9.27626387110594,26.074531130623654 L14.445750878862809,23.352150291145115 C14.814660992060919,23.10458458745368 15.1845015803538,23.10458458745368 15.553411693551908,23.352150291145115 L20.845488795044446,26.074531130623654 C21.70689937590322,26.56966253800652 22.814560190592317,25.82696542693222 22.691900311224536,24.713387483169228 L21.584239496535435,18.77377503053837 C21.584239496535435,18.402894197849687 21.706908680654163,18.03107791946407 21.953149609733547,17.660197086775383 L26.261249298509014,13.453497787699714 C27,12.710800676625414 26.63015941170712,11.473907603865172 25.52249859701802,11.349657029170992 L19.73796755161956,10.48364478909944 C19.2454670839589,10.360320305645217 18.876556970760788,10.112763956410754 18.75296661629824,9.74094767802514 L16.168804659354038,4.420600276759213 C15.67630419169338,3.4303374619934797 14.322393143173947,3.4303374619934797 13.830218341796458,4.420600276759211Z"
        />
      </g>
    </g>
  </svg>
);

export default StarIcon;
