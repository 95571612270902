import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  card,
  cardContent,
  isPassive,
  musicIconRipple,
  openScale,
  closeScale,
  musicIcon,
  playlistCover,
  coverImage,
  summaryBlock,
  expand,
  expandIcon,
  willAnimateIn
} from './PlaylistCard.module.sass';
import DrumPads from '../DrumPads';
import MusicNoteIcon from '../../vectors/MusicNoteIcon';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import { scrollViewListener } from '../../utils';

const PlaylistCard = ({ className, summary, covers, action }) => {
  const [inView, setInview] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [focused, setFocused] = useState(false);
  const [animatedExapndIn, setAnimatedExapndIn] = useState(false);
  const [animatedCoversIn, setAnimatedCoversIn] = useState(false);
  const cardContainer = useRef(null);

  const onEnter = () => {
    setTimeout(() => setFocused(true));
  };

  const onLeave = () => {
    setLeaving(true);
    setFocused(false);
    setTimeout(() => setLeaving(false), 600);
  };

  useEffect(() => {
    scrollViewListener(cardContainer.current, 0.6, () => setInview(true));
    setTimeout(() => setAnimatedExapndIn(true), 1500);
    setTimeout(() => setAnimatedCoversIn(true), 2200);
  }, []);

  return (
    <div
      ref={cardContainer}
      className={cx(card, className)}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <DrumPads total={8} />
      <span
        className={cx(musicIconRipple, {
          [isPassive]: !inView,
          [openScale]: focused,
          [closeScale]: leaving
        })}
      >
        <span />
        <span />
      </span>
      <span className={cx(musicIcon, { [isPassive]: !inView })}>
        <span>
          <MusicNoteIcon />
        </span>
      </span>
      <div className={cx(cardContent, { [isPassive]: !inView })}>
        <div
          className={cx(playlistCover, { [willAnimateIn]: animatedCoversIn })}
        >
          {covers.map((cover, index) => (
            <div
              key={index}
              className={coverImage}
              style={{ backgroundImage: `url(${cover})` }}
            />
          ))}
        </div>
        <div className={summaryBlock}>
          <h4>Suggestion Playlist</h4>
          <p>{summary}</p>
        </div>
        <div
          onClick={action}
          className={cx(expand, { [willAnimateIn]: !animatedExapndIn })}
        >
          <span>
            <span className={expandIcon}>
              <ExpandAltIcon />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

PlaylistCard.defaultProps = {
  covers: []
};

PlaylistCard.propTypes = {
  summary: PropTypes.string,
  action: PropTypes.func,
  covers: PropTypes.array,
  className: PropTypes.string
};

export default PlaylistCard;
