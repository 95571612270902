import React, { Component } from 'react';
import cx from 'classnames';

import {
  loader,
  loadComplete,
  loaderProgressBar,
  loadProgress
} from './MobileLoader.module.sass';

class MobileLoader extends Component {
  state = {
    isLoading: true
  };

  componentDidUpdate(prevProps) {
    const { progress, onUserReady } = this.props;
    if (prevProps.progress !== progress && progress === 100) {
      onUserReady();
      this.setState({ isComplete: true });

      setTimeout(() => {
        this.setState({ progressBarComplete: true });
      }, 600);

      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1500);
    }
  }

  render() {
    const { isLoading, progressBarComplete, isComplete } = this.state;
    const { progress } = this.props;

    return (
      <>
        {isLoading && (
          <div className={cx(loader, { [loadComplete]: isComplete })}>
            {!progressBarComplete && (
              <div className={loaderProgressBar}>
                <div
                  className={loadProgress}
                  style={{
                    transform: `scaleX(${progress / 100})`,
                    transformOrigin: 'left'
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default MobileLoader;
