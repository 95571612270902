import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import {
  container,
  userSummary,
  isPassive,
  streamingAnalysisCaption,
  greenText,
  streamingAnalysisSubcaption,
  tabSwitch
} from './MobileStreamingAnalysis.module.sass';
import Analysis from './Analysis';
import Insights from './Insights';
import TabSwitch from '../TabSwitch';
import { mobileScrollViewListener } from '../../utils';
import { MOBILE_SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction } from '../../config';

const MobileStreamingAnalysis = () => {
  const [summaryInView, setSummaryInview] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const isAnalysis = activeTab === 0;
  const summaryRef = useRef();

  useEffect(() => {
    mobileScrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  return (
    <section className={container}>
      <div
        ref={summaryRef}
        className={cx(userSummary, { [isPassive]: !summaryInView })}
      >
        <h1 className={streamingAnalysisCaption}>
          <span>Here is how</span>
        </h1>
        <h1 className={streamingAnalysisCaption}>
          <span className={greenText}>you listen</span>
        </h1>
        <p className={streamingAnalysisSubcaption}>
          <span>Audio analysis on your top tracks.</span>
        </p>
      </div>

      {isAnalysis ? <Analysis /> : <Insights />}

      <TabSwitch
        className={tabSwitch}
        labels={['Analysis', 'Highlights']}
        activeTab={activeTab}
        onChange={setActiveTab}
      />
    </section>
  );
};

export default MobileStreamingAnalysis;
