import React from 'react';

const DanceIconBg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width="150"
    height="150"
  >
    <defs>
      <linearGradient id="LbGHfge265FC" x1="0.19" x2="0.79" y1="0.52" y2="0.5">
        <stop offset="0" stopColor="#FFF" stopOpacity="0" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#LbGHfge265FC)"
      fillRule="evenodd"
      d="M122.73 42.461v15.914a16.69 16.69 0 00-3.96.013c-7.716.941-13.837 7.218-14.571 14.948-.934 9.85 6.763 18.316 16.609 18.316 8.395 0 15.506-6.256 16.669-14.605l.023-46.978a6.49 6.49 0 00-6.49-6.493H82.937a6.49 6.49 0 00-6.49 6.492v46.614a16.717 16.717 0 00-4.802.125c-7.367 1.248-13.076 7.394-13.778 14.837-.93 9.848 6.766 18.31 16.61 18.31 9.213 0 16.686-7.473 16.69-16.692l.005-50.8c0-1.36 1.1-2.46 2.458-2.46h26.643c1.358 0 2.457 1.1 2.457 2.46l-85.083-2.417a6.49 6.49 0 00-6.49 6.492v46.614a16.718 16.718 0 00-4.803.125c-7.366 1.248-13.075 7.395-13.778 14.837-.929 9.848 6.766 18.311 16.61 18.311 9.214 0 16.687-7.474 16.691-16.693l.004-50.8c0-1.36 1.1-2.46 2.458-2.46h14.738V40.045h-25.43l85.083 2.416z"
      opacity="1"
    />
  </svg>
);

export default DanceIconBg;
