import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { btn } from './Button.module.sass';
import { SIZE_CLASS_NAMES as sizeClassNames } from './Button.constants';

const Button = ({ children, className, size, ...rest }) => (
  <button className={cx(btn, sizeClassNames[size], className)} {...rest}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.oneOf(['md', 'sm', 'xs'])
};

export default Button;
