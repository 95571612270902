import React from 'react';

const BoltIconBg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170 170"
    width="170"
    height="170"
  >
    <defs>
      <linearGradient id="Lghs6gFDXaS4" x1="0.47" x2="0.17" y1="0.39" y2="0.71">
        <stop offset="0" stopColor="#FFF" stopOpacity="0" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lghs6gFDXaS4)"
      id="a"
      fillRule="evenodd"
      d="M119.301 70.63H102.68c-1.358 0-2.42-.952-2.267-2.179l2.568-38.078c.602-8.702-12.089-12.24-17.077-4.76l-43.06 63.376c-3.779 5.578.601 12.648 7.856 12.648H67.32c1.358 0 2.42.951 2.267 2.179l-2.568 38.078c-.602 8.701 12.089 12.24 17.077 4.76l43.067-63.508c3.772-5.445-.608-12.516-7.863-12.517z"
      opacity="1"
    />
  </svg>
);

export default BoltIconBg;
