import {
  artistOne,
  artistTwo,
  artistThree,
  artistFour,
  artistFive
} from './TopArtists.module.sass';

export const ARTIST_CARDS_CLASS_NAMES = [
  artistOne,
  artistTwo,
  artistThree,
  artistFour,
  artistFive
];
