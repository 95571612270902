import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  insightBlock,
  insightBlockContent,
  insightIcon,
  insightLabel,
  isPassive,
  insightDescription,
  percentageValue
} from './StreamingInsights.module.sass';
import DrumPads from '../DrumPads';
import TrackPill from '../TrackPill';
import { getImageUrl, scrollViewListener } from '../../utils';
import { STREAMING_INSIGHT_BLOCK_ENTRANCE_FRACTION as insightBlockEntranceFraction } from '../../config';

const InsightBlock = ({
  className,
  iconBoxClassName,
  labelOne,
  labelTwo,
  percentage,
  percentageSuffix,
  track,
  icon: Icon
}) => {
  const [blockInView, setBlockInView] = useState(false);
  const getTrackDetails = () => {
    const { id, name, artists, uri, album, preview_url } = track;
    return {
      id,
      name,
      uri,
      previewUrl: preview_url,
      artists: artists.map(({ name }) => name).join(', '),
      albumCover: getImageUrl(album.images)
    };
  };

  useEffect(() => {
    scrollViewListener(blockRef.current, insightBlockEntranceFraction, () =>
      setBlockInView(true)
    );
  }, []);

  const blockRef = useRef();

  return (
    <>
      <Icon
        className={cx(insightIcon, iconBoxClassName)}
        isPassive={!blockInView}
      />
      <div ref={blockRef} className={cx(className, insightBlock)}>
        <DrumPads total={20} />
        <div
          className={cx(insightBlockContent, {
            [isPassive]: !blockInView
          })}
        >
          <div className={insightLabel}>
            <h1>
              <span>{labelOne}</span>
            </h1>
            <h1>
              <span>{labelTwo}</span>
            </h1>
          </div>
          <p className={insightDescription}>
            <span>
              <span className={percentageValue}>{percentage}%</span>{' '}
              {percentageSuffix}
            </span>
          </p>
          {track && (
            <TrackPill isPassive={!blockInView} {...getTrackDetails()} />
          )}
        </div>
      </div>
    </>
  );
};

InsightBlock.propTypes = {
  labelOne: PropTypes.string.isRequired,
  labelTwo: PropTypes.string,
  icon: PropTypes.func.isRequired,
  percentageSuffix: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  track: PropTypes.object,
  className: PropTypes.string,
  iconBoxClassName: PropTypes.string
};

export default InsightBlock;
