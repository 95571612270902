import React from 'react';

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#33FF7A"
      fillRule="evenodd"
      d="M16.875 7.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm0 7.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM15 24a1.875 1.875 0 100-3.75A1.875 1.875 0 0015 24z"
    />
  </svg>
);

export default MenuIcon;
