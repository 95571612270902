import React from 'react';

const RemoveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FF3660"
      fillRule="evenodd"
      d="M15 3c6.623 0 12 5.377 12 12s-5.377 12-12 12S3 21.623 3 15 8.377 3 15 3zm0 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S5 20.52 5 15 9.48 5 15 5zm4.5 10a1.5 1.5 0 00-1.5-1.5h-6a1.5 1.5 0 000 3h6a1.5 1.5 0 001.5-1.5z"
    />
  </svg>
);

export default RemoveIcon;
