import React from 'react';
import PropTypes from 'prop-types';

const RadialProgress = ({ onAnimationEnd, preventEndEvent }) => (
  <svg viewBox="0 0 90 90" width="90" height="90">
    <circle
      stroke="#0E5828"
      strokeWidth="2"
      fill="none"
      cx="45"
      cy="45"
      r="40"
    />
    <circle
      stroke="#33FF7A"
      strokeWidth="3"
      strokeDasharray="244, 252.1"
      fill="none"
      cx="45"
      cy="45"
      r="40"
      transform="rotate(-90) translate(-90)"
      onAnimationEnd={e => !preventEndEvent && onAnimationEnd(e)}
    />
  </svg>
);

RadialProgress.propTypes = {
  onAnimationEnd: PropTypes.func.isRequired,
  preventEndEvent: PropTypes.bool
};

export default RadialProgress;
