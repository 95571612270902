import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  sectionIcon,
  statsSummary,
  isPassive,
  statsSummaryContent,
  statsCaption,
  greenText,
  statsSubcaption,
  statsSubcaptionText,
  featuresChart
} from './TopAudioFeatures.module.sass';
import DrumPads from '../DrumPads';
import { AnalyticsIconBox } from '../SectionIconBox';
import SpiderChart from '../SpiderChart';
import DanceIcon from '../../vectors/DanceIcon';
import SpeechIcon from '../../vectors/SpeechIcon';
import InstrumentIcon from '../../vectors/InstrumentIcon';
import SmileyIcon from '../../vectors/SmileyIcon';
import SoundWavesIcon from '../../vectors/SoundWavesIcon';
import BoltIcon from '../../vectors/BoltIcon';
import TempoIcon from '../../vectors/TempoIcon';
import { scrollViewListener } from '../../utils';
import { SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction } from '../../config';

const TopAudioFeatures = ({ audioFeatures }) => {
  const [summaryInView, setSummaryInview] = useState(false);

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  const summaryRef = useRef();
  const getSum = (key, valueFunc) => {
    return audioFeatures.reduce(
      (acc, curr) =>
        acc + (curr ? (valueFunc ? valueFunc(curr[key]) : curr[key]) : 0),
      0
    );
  };

  const total = audioFeatures.length;
  const loudnessValue = val => Math.min(Math.max(val, -20), 0) / 20 + 1;
  const tempoValue = val => (Math.min(Math.max(val, 60), 170) - 60) / 110;

  const danceabilityTotal = getSum('danceability') / total;
  const speechinessTotal = getSum('speechiness') / total;
  const instrumentalnessTotal = getSum('instrumentalness') / total;
  const valenceTotal = getSum('valence') / total;
  const loudnessTotal = getSum('loudness', loudnessValue) / total;
  const energyTotal = getSum('energy') / total;
  const tempoTotal = getSum('tempo', tempoValue) / total;

  const moreSpeechiness = speechinessTotal > instrumentalnessTotal;

  const chartData = {
    danceability: {
      value: danceabilityTotal,
      label: <>Danceab&shy;ility</>,
      icon: <DanceIcon />,
      valueLabel: (danceabilityTotal * 100).toFixed(1),
      total: '100',
      description:
        'Danceability describes how danceable a track is, based on its beat and rhythm.'
    },
    prominentSound: {
      value: Math.max(speechinessTotal, instrumentalnessTotal),
      label: moreSpeechiness ? (
        <>Speechi&shy;ness</>
      ) : (
        <>Instrume&shy;ntalness</>
      ),
      icon: moreSpeechiness ? <SpeechIcon /> : <InstrumentIcon />,
      valueLabel: (
        Math.max(speechinessTotal, instrumentalnessTotal) * 100
      ).toFixed(1),
      total: '100',
      description: moreSpeechiness
        ? 'Speechiness defines how many spoken words exist on a track.'
        : 'Instrumentalness defines whether a track contains no vocals.'
    },
    valence: {
      value: valenceTotal,
      label: 'Valence',
      icon: <SmileyIcon />,
      valueLabel: (valenceTotal * 100).toFixed(1),
      total: '100',
      description:
        'Valence defines the musical positiveness conveyed by a track.'
    },
    loudness: {
      value: loudnessTotal,
      label: 'Loudness',
      icon: <SoundWavesIcon />,
      valueLabel: ((loudnessTotal - 1) * 20).toFixed(2),
      total: '-20 to 0db',
      description:
        'Loudness describes the subjective perception of sound pressure in a track.'
    },
    energy: {
      value: energyTotal,
      label: 'Energy',
      icon: <BoltIcon />,
      valueLabel: (energyTotal * 100).toFixed(1),
      total: '100',
      description: 'Energy describes how intense, upbeat, or chill a track is.'
    },
    tempo: {
      value: tempoTotal,
      label: 'Tempo',
      icon: <TempoIcon />,
      valueLabel: Math.round(tempoTotal * 110) + 60,
      total: '60 to 170 BPM',
      description: 'Tempo is the speed, pace or beat of any track.'
    }
  };

  return (
    <section className={container}>
      <AnalyticsIconBox className={sectionIcon} isPassive={!summaryInView} />
      <div
        ref={summaryRef}
        className={cx(statsSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={42} />
        <div className={statsSummaryContent}>
          <h1 className={statsCaption}>
            <span>
              Analysis on <br />{' '}
              <span className={greenText}>how you listen</span>
            </span>
          </h1>
          <p className={statsSubcaption}>
            <span className={statsSubcaptionText}>
              Audio analysis on how you listen using{' '}
              <a
                href="https://developer.spotify.com/documentation/web-api/reference/tracks/get-several-audio-features/"
                target="blank"
                className={greenText}
              >
                Spotify's AI
              </a>
              , based on your top 50 tracks.
            </span>
          </p>
        </div>
      </div>
      <SpiderChart className={featuresChart} data={chartData} />
      <DrumPads total={71} />
    </section>
  );
};

export default UserConsumer(TopAudioFeatures);
