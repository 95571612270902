import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { radialIconButton, isActive, isPassive } from './Button.module.sass';
import { mobileScrollViewListener } from '../../utils';

const RadialIconButton = ({
  active,
  onClick,
  icon: Icon,
  renderDelay,
  columnDelay
}) => {
  const [containerInView, setContainerInview] = useState(false);
  const containerRef = useRef();

  const onEnterView = onStart => {
    setTimeout(
      () => setContainerInview(true),
      onStart ? renderDelay : columnDelay
    );
  };

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, -3, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      ref={containerRef}
      role="button"
      className={cx(radialIconButton, {
        [isPassive]: !containerInView,
        [isActive]: active
      })}
      onClick={onClick}
    >
      <Icon />
    </span>
  );
};

RadialIconButton.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

RadialIconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default RadialIconButton;
