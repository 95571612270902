import React, { Component, createRef } from 'react';

import { overlay } from './Overlay.module.sass';
import { onScroll } from '../../utils';

class Overlay extends Component {
  state = {};
  overlayRef = createRef();

  onScrollStart = () => {
    this.overlay.style.pointerEvents = 'all';
  };

  onScrollEnd = () => {
    this.overlay.style.pointerEvents = 'none';
  };

  componentDidMount() {
    this.overlay = this.overlayRef.current;
    onScroll(this.onScrollStart, this.onScrollEnd);
  }

  render() {
    return <div className={overlay} ref={this.overlayRef} />;
  }
}

export default Overlay;
