import React from 'react';
import cx from 'classnames';

import {
  aboutMenu,
  description,
  resources,
  resource,
  resourceBrand,
  studio,
  github,
  resourceInfo,
  aboutMenuFooter,
  version
} from './Header.module.sass';
import pkg from '../../../package.json';
import Dropdown from '../Dropdown';
import StudioIcon from '../../vectors/StudioIcon';
import GithubIcon from '../../vectors/GithubIcon';
import MadeWithStudioIcon from '../../vectors/MadeWithStudioIcon';
import { SPOTIFY_API_DOCUMENTATION_URL as spotifyApiUrl } from '../../config';

const AboutMenu = ({ closeModal }) => (
  <Dropdown className={aboutMenu} close={closeModal}>
    <div>
      <h3>About the app</h3>
      <p className={description}>
        Cruuunchify is a streaming analysis and music discovery tool for Spotify
        users built on{' '}
        <a target="_blank" rel="noopener noreferrer" href={spotifyApiUrl}>
          Spotify's Web API
        </a>
        .<strong> In no way are we affiliated with Spotify. </strong>
      </p>
      <h4>PRIVACY</h4>
      <p className={description}>
        Cruuunchify requires access to your Spotify account and we do not store
        your Spotify data on any server. Generated images for sharing purposes
        are stored but not with any of the user's Spotify profile data.
      </p>
      <h4>RESOURCES</h4>
      <div className={resources}>
        <div className={resource}>
          <span className={cx(resourceBrand, studio)}>
            <span>
              <StudioIcon />
            </span>
          </span>
          <div className={resourceInfo}>
            <h6>Download Designs</h6>
            <span>Coming soon</span>
          </div>
        </div>
        <div className={resource}>
          <span className={cx(resourceBrand, github)}>
            <span>
              <GithubIcon />
            </span>
          </span>
          <div className={resourceInfo}>
            <h6>Explore Codebase</h6>
            <span>Coming soon</span>
          </div>
        </div>
      </div>
      <footer className={aboutMenuFooter}>
        <MadeWithStudioIcon />
        <span className={version}>version {pkg.version}</span>
      </footer>
    </div>
  </Dropdown>
);

export default AboutMenu;
