import React from 'react';

const GhostIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M7.244 8.883c0-9.791 15.338-10.777 15.338 0 0 1.768-.02 3.02-.036 3.942-.025 1.573-.035 2.187.098 2.769.158.689.517 1.332 1.289 3.472 1.054 2.92 2.473 4.23 3.31 5.003.596.55.896.827.556 1.22-.44.506-1.913-.326-3.3-1.11-1.194-.674-2.323-1.312-2.672-1.028-.754.615-.754 4.526-1.697 4.778-.457.121-1.187-.806-1.966-1.793-.827-1.05-1.707-2.167-2.37-2.167-.605 0-1.639 1.162-2.696 2.35-1.195 1.345-2.42 2.723-3.088 2.49-1.257-.44-.786-4.84-1.697-5.658-.479-.429-2.051.027-3.5.447-1.307.379-2.514.728-2.723.37-.44-.754 3.834-4.902 3.834-4.902s1.32-1.195 1.32-10.183zm6.296-2.018c1.076 0 1.949 1.483 1.949 3.31s-.873 3.31-1.949 3.31c-1.075 0-1.949-1.483-1.949-3.31s.874-3.31 1.95-3.31zm7.103 3.31c0-1.827-.873-3.31-1.949-3.31-1.075 0-1.948 1.483-1.948 3.31s.873 3.31 1.948 3.31c1.076 0 1.949-1.483 1.949-3.31z"
    />
  </svg>
);

export default GhostIcon;
