import {
  trackOne,
  trackTwo,
  trackThree,
  trackFour,
  trackFive
} from './TopTracks.module.sass';

export const TRACK_CARDS_CLASS_NAMES = [
  trackOne,
  trackTwo,
  trackThree,
  trackFour,
  trackFive
];
