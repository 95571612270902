import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase
} from '../../config';

import {
  filterFormContainer,
  exitFilterFormContainer,
  filterFormTopOverlay,
  filterForm,
  scrollBar,
  hideScrollBar,
  scrollThumb,
  hideThumb,
  sourceFieldSet,
  playlistSource,
  tracksCountFieldSet,
  tracksCount,
  filterFormActions,
  filterFormActionsOverlay,
  filterFormActionsButtons,
  actionButton,
  applyButton,
  closeButton
} from './PlaylistCreator.module.sass';
import AudioFeatureFieldSet from './AudioFeatureFieldSet';
import Fieldset from '../Fieldset';
import CardRadio from '../CardRadio';
import SelectDropdown from '../SelectDropdown';
import Button from '../Button';
import { FILTERS_ICONS as filtersIcons } from './PlaylistCreator.constant';

class PlaylistFilterForm extends Component {
  constructor(props) {
    super(props);
    const { formValues, audioFeaturesFilters } = props;

    this.state = {
      audioFeaturesFilters,
      formFields: formValues
    };

    this.contentPositionY = 0;
    this.targetY = 0;
    this.contentAreaRef = createRef();
    this.scrollThumbRef = createRef();
    this.formTopOverlayRef = createRef();
    this.formActionOverlayRef = createRef();
    this.stickyContainers = [];
  }

  onInputChange = (value, name) => {
    const formFields = {
      ...this.state.formFields,
      [name]: value
    };

    this.setState({ formFields });
  };

  onFeatureChange = (value, name, featuresLabel) => {
    const audioFeaturesFilters = {
      ...this.state.audioFeaturesFilters,
      [name]: featuresLabel
    };

    this.setState({ audioFeaturesFilters });
    this.onInputChange(value, name);
  };

  onFormSubmit = e => {
    e.preventDefault();
    const { formFields, audioFeaturesFilters } = this.state;
    this.props.onSubmit(formFields, audioFeaturesFilters);
  };

  getFilterFormHeight = () => {
    return window.innerHeight * 0.8 - 150;
  };

  initializeVirtualScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaY }) => {
      const { containerHeight, inFocus } = this.state;

      if (!inFocus) {
        return;
      }

      const maxOverflow = containerHeight - this.getFilterFormHeight();

      this.targetY += deltaY;
      this.targetY = Math.min(Math.max(-maxOverflow, this.targetY), 0);
    });
  };

  setContainerHeight = initializeScroll => {
    const { yStart } = this.state;
    const { height, y } = this.contentArea.getBoundingClientRect();
    const areaHeight = height;
    const filterFormHeight = this.getFilterFormHeight();

    this.setState(
      {
        containerHeight: areaHeight,
        yStart: initializeScroll ? y : yStart
      },
      () => {
        initializeScroll && this.initializeVirtualScroll();
      }
    );

    const maxOverflow = areaHeight - filterFormHeight;

    if (areaHeight <= filterFormHeight) {
      this.setState({ scrollThumbHeight: 0 });
    } else {
      const viewableRatio = filterFormHeight / areaHeight;
      const scrollBarArea = filterFormHeight - 120;
      const scrollThumbHeight = scrollBarArea * viewableRatio;
      this.setState({ scrollThumbHeight });
    }

    if (this.targetY < 0 && this.targetY < -maxOverflow) {
      this.targetY = -maxOverflow;
    }
  };

  animatePosition = () => {
    const ease = scrollEase;
    this.animateScroll = requestAnimationFrame(this.animatePosition);

    this.contentPositionY += (this.targetY - this.contentPositionY) * ease;
    const translateY = parseFloat(this.contentPositionY.toFixed(2));
    const translate = `translate3d(0, ${translateY}px, 0)`;
    this.contentArea.style.transform = translate;

    const stickyTranslate = `translate3d(0, ${-translateY}px, 0)`;
    this.stickyContainers.forEach(
      container => (container.style.transform = stickyTranslate)
    );

    const areaHeight = this.contentArea.getBoundingClientRect().height;
    const filterFormHeight = this.getFilterFormHeight();
    const maxOverflow = areaHeight - filterFormHeight;
    const scrolledRatio = -translateY / maxOverflow;
    const scrollBarArea = filterFormHeight - 120;
    const viewableRatio = filterFormHeight / areaHeight;
    const scrollThumbHeight = scrollBarArea * viewableRatio;
    const scrollThumbtranslateY =
      scrolledRatio * (scrollBarArea - scrollThumbHeight);
    const scrollThumbtranslate = `translate3d(0, ${scrollThumbtranslateY}px, 0)`;

    this.scrollThumb.style.transform = scrollThumbtranslate;

    const scrolledToTop = translateY > -1;
    this.formTopOverlay.style.opacity = scrolledToTop ? 0 : 1;
    const scrolledToBottom = translateY + maxOverflow < 1;
    this.formActionOverlay.style.opacity = scrolledToBottom ? 0 : 1;
  };

  onMouseMove = ({ clientX, clientY }) => {
    const { inFocus, yStart } = this.state;
    const { freezeScroll } = this.props;
    const { x, width } = this.contentArea.getBoundingClientRect();
    const filterFormHeight = this.getFilterFormHeight();
    const xStart = x - 50;
    const xEnd = x + width + 50;
    const yEnd = yStart + filterFormHeight;

    const mouseInFocus =
      clientX >= xStart &&
      clientX <= xEnd &&
      clientY >= yStart &&
      clientY <= yEnd;
    if (mouseInFocus && !inFocus) {
      this.setState({ inFocus: true });
      freezeScroll(true);
    } else if (!mouseInFocus && inFocus) {
      this.setState({ inFocus: false });
      freezeScroll(false);
    }
  };

  componentDidMount() {
    this.contentArea = this.contentAreaRef.current;
    this.scrollThumb = this.scrollThumbRef.current;
    this.formTopOverlay = this.formTopOverlayRef.current;
    this.formActionOverlay = this.formActionOverlayRef.current;
    this.setContainerHeight(true);
    this.animateScroll = requestAnimationFrame(this.animatePosition);
    window.addEventListener('resize', () => this.setContainerHeight(false));
    window.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animateScroll);
    this.vScroll.destroy();
    this.state.inFocus && this.props.freezeScroll(false);
    window.removeEventListener('resize', () => this.setContainerHeight(false));
    window.removeEventListener('mousemove', this.onMouseMove);
  }

  render() {
    const { close, willExit } = this.props;
    const { scrollThumbHeight, formFields } = this.state;
    const { maxTracks, danceability, energy, mood, tempo } = formFields;
    const hideBar = !scrollThumbHeight;

    const sourceOptions = [
      {
        value: 'favourites',
        label: 'Your favourites',
        info: 'A playlist with your top tracks just as you like it.'
      }
    ];

    return (
      <div
        className={cx(filterFormContainer, {
          [exitFilterFormContainer]: willExit
        })}
      >
        <div ref={this.formTopOverlayRef} className={filterFormTopOverlay} />
        <div className={cx(scrollBar, { [hideScrollBar]: hideBar })}>
          <div
            ref={this.scrollThumbRef}
            className={cx(scrollThumb, {
              [hideThumb]: hideBar
            })}
            style={{ height: scrollThumbHeight }}
          />
        </div>
        <div className={filterForm} ref={this.contentAreaRef}>
          <div>
            <h3>
              <span>Playlist Settings</span>
            </h3>
            <form id="playlistFilterForm" onSubmit={this.onFormSubmit}>
              <Fieldset className={sourceFieldSet} legend="PLAYLIST SOURCE">
                <SelectDropdown
                  value="favourites"
                  options={sourceOptions}
                  className={playlistSource}
                />
              </Fieldset>
              <Fieldset
                className={tracksCountFieldSet}
                legend={
                  <span>{filtersIcons.maxTracks} MAX NUMBER OF TRACKS</span>
                }
              >
                <div className={tracksCount}>
                  <CardRadio
                    value={maxTracks}
                    name={15}
                    onChange={value => this.onInputChange(value, 'maxTracks')}
                    title="15"
                  />
                  <CardRadio
                    value={maxTracks}
                    name={25}
                    onChange={value => this.onInputChange(value, 'maxTracks')}
                    title="25"
                  />
                  <CardRadio
                    value={maxTracks}
                    name={50}
                    onChange={value => this.onInputChange(value, 'maxTracks')}
                    title="50"
                  />
                  <CardRadio
                    value={maxTracks}
                    name={100}
                    onChange={value => this.onInputChange(value, 'maxTracks')}
                    title="100"
                    disabled
                  />
                </div>
              </Fieldset>
              <AudioFeatureFieldSet
                icon={filtersIcons.danceability}
                title="DANCEABILITY"
                name="danceability"
                value={danceability}
                onChange={this.onFeatureChange}
                plusTitle="Disco"
                minusTitle="Mellow"
                renderDelay={300}
              />
              <AudioFeatureFieldSet
                icon={filtersIcons.energy}
                title="ENERGY"
                name="energy"
                value={energy}
                onChange={this.onFeatureChange}
                plusTitle="Upbeat"
                minusTitle="Chill"
                renderDelay={400}
              />
              <AudioFeatureFieldSet
                icon={filtersIcons.mood}
                title="MOOD"
                name="mood"
                value={mood}
                onChange={this.onFeatureChange}
                plusTitle="Happy"
                minusTitle="Somber"
                renderDelay={500}
              />
              <AudioFeatureFieldSet
                icon={filtersIcons.tempo}
                title="TEMPO"
                name="tempo"
                value={tempo}
                onChange={this.onFeatureChange}
                plusTitle="Fast"
                minusTitle="Slow"
                renderDelay={600}
              />
            </form>
          </div>
        </div>
        <div className={filterFormActions}>
          <div
            ref={this.formActionOverlayRef}
            className={filterFormActionsOverlay}
          />
          <div className={filterFormActionsButtons}>
            <Button
              form="playlistFilterForm"
              className={cx(actionButton, applyButton)}
            >
              Apply
            </Button>
            <Button onClick={close} className={cx(actionButton, closeButton)}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PlaylistFilterForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  audioFeaturesFilters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  freezeScroll: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  willExit: PropTypes.bool
};

export default PlaylistFilterForm;
