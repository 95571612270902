import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import {
  container,
  sectionIcon,
  teamSummary,
  isPassive,
  teamSummaryContent,
  teamCaption,
  greenText,
  teamSubcaption,
  projectPlaylist
} from './Team.module.sass';

import TeamMemberCard from './TeamMemberCard';
import { TEAM_MEMBERS as teamMembers } from './Team.constants';
import ProjectPlaylist from '../ProjectPlaylist';
import DrumPads from '../DrumPads';
import { HandIconBox } from '../SectionIconBox';
import { scrollViewListener } from '../../utils';
import { SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction } from '../../config';

const Team = () => {
  const [summaryInView, setSummaryInview] = useState(false);

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  const summaryRef = useRef();

  return (
    <section className={container}>
      <HandIconBox className={sectionIcon} isPassive={!summaryInView} />
      <div
        ref={summaryRef}
        className={cx(teamSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={24} />
        <div className={teamSummaryContent}>
          <h1 className={teamCaption}>
            <span>
              Say <span className={greenText}>Hi</span>
            </span>
          </h1>
          <p className={teamSubcaption}>
            <span>
              You've reached the end of this experience. Made with love from
              Lagos, Nigeria.
            </span>
          </p>
        </div>
      </div>
      {teamMembers.map((teamMember, index) => (
        <TeamMemberCard key={index} {...teamMember} />
      ))}
      <ProjectPlaylist className={projectPlaylist} />
      <DrumPads total={140} />
    </section>
  );
};

export default Team;
