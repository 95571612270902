import React from 'react';

const HeadPhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <defs>
      <path
        id="PH6RWf623RF"
        fillRule="evenodd"
        d="M4.56 30.664c0-6.695 2.68-13.116 7.45-17.853A25.547 25.547 0 0130 5.417c6.747 0 13.217 2.66 17.99 7.394a25.158 25.158 0 017.45 17.853v12.624a11.418 11.418 0 01-3.444 7.996 11.603 11.603 0 01-8.104 3.299h-1.507c-.711 0-1.39-.28-1.893-.779a2.645 2.645 0 01-.785-1.879V34.32a2.666 2.666 0 012.678-2.658h1.507c2.194 0 4.343.625 6.192 1.801v-2.798c0-7.12-3.828-13.7-10.042-17.26a20.216 20.216 0 00-20.084 0c-6.214 3.56-10.042 10.139-10.042 17.26v2.798a11.545 11.545 0 016.192-1.801h1.507c.711 0 1.39.28 1.893.779.502.498.785 1.173.785 1.879v17.606c0 .706-.283 1.38-.785 1.88a2.686 2.686 0 01-1.893.778h-1.507a11.603 11.603 0 01-8.104-3.299 11.418 11.418 0 01-3.444-7.996V30.664z"
      />
    </defs>
    <use fill="#FFF" xlinkHref="#PH6RWf623RF" />
    <use fillOpacity="0" stroke="#FFF" xlinkHref="#PH6RWf623RF" />
  </svg>
);

export default HeadPhoneIcon;
