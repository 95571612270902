import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  label,
  withCardIcon,
  checkedLabel,
  isDisabled
} from './CardRadio.module.sass';

const CardRadio = ({
  value,
  name,
  onChange,
  title,
  disabled,
  className,
  icon: Icon
}) => {
  const isChecked = value === name;
  const withIcon = Boolean(Icon);
  const formControlKeyCodes = [9, 37, 38, 39, 40];

  const onKeyDown = e => {
    if (formControlKeyCodes.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  return (
    <label
      className={cx(label, className, {
        [withCardIcon]: withIcon,
        [checkedLabel]: isChecked,
        [isDisabled]: disabled
      })}
    >
      {withIcon && <Icon />}
      <span>{title}</span>
      <input
        type="radio"
        checked={isChecked}
        onChange={() => onChange(name)}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </label>
  );
};

CardRadio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.func
};

export default CardRadio;
