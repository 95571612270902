import React from 'react';

const DiskBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    width="130"
    height="130"
  >
    <defs>
      <radialGradient id="LjBS7GDHVc23" cx="0.52" cy="0.49" r="0.425">
        <stop offset="0" stopColor="#FFF" stopOpacity="0.4" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </radialGradient>
    </defs>
    <path
      fill="url(#LjBS7GDHVc23)"
      fillRule="evenodd"
      d="M65 4.333C31.633 4.333 4.333 31.633 4.333 65c0 33.367 27.3 60.667 60.667 60.667 33.367 0 60.667-27.3 60.667-60.667 0-33.367-27.3-60.667-60.667-60.667zM65 93.6c-15.73 0-28.6-12.87-28.6-28.6 0-15.73 12.87-28.6 28.6-28.6 15.73 0 28.6 12.87 28.6 28.6 0 15.73-12.87 28.6-28.6 28.6zM52.867 65c0 6.673 5.46 12.133 12.133 12.133 6.673 0 12.133-5.46 12.133-12.133 0-6.673-5.46-12.133-12.133-12.133-6.673 0-12.133 5.46-12.133 12.133z"
    />
  </svg>
);

export default DiskBgIcon;
