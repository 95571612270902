import React, { createRef, Component } from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import styles from './TrackDetails.module.sass';
import {
  getImageUrl,
  preloadImages,
  getPopularityGroup,
  formatReleaseDate,
  playAudio,
  pauseAudio,
  fadeOutAudio
} from '../../utils';
import RadialLoader from '../RadialLoader';
import { PlaylistButton, SpotifyButton } from '../Button';
import TitleCard from '../TitleCard';
import ArtistPill from '../ArtistPill';
import { TracksCarousel } from '../Carousel';
import AudioFeatures from './AudioFeatures';
import { AudioControl } from '../AnimatedVectors';
import PublishIcon from '../../vectors/PublishIcon';

class TrackDetails extends Component {
  state = { isLoading: true };

  stickyColumnRef = createRef();
  trackPreviewRef = createRef();

  getTrackData = async () => {
    const {
      resetModalScroll,
      activeTrack,
      getTrack,
      getRecommendations,
      getTracksAudioFeatures,
      getArtists
    } = this.props;

    try {
      await Promise.all([
        getTrack(activeTrack, track => this.setState({ track })),
        getRecommendations(
          { seed_tracks: activeTrack, limit: 6 },
          this.setRelatedTracks
        ),
        getTracksAudioFeatures({ ids: activeTrack }, data =>
          this.setState({ audioFeatures: data[0] })
        )
      ]);

      const { track, relatedTracks } = this.state;

      await getArtists(
        track.artists.map(({ id }) => id).join(),
        this.setTrackArtists
      );

      const trackImage = getImageUrl(track.album.images);
      const artistsWithCovers = this.state.trackArtists.filter(
        ({ artistCover }) => artistCover
      );
      const artistsCovers = artistsWithCovers.map(
        ({ artistCover }) => artistCover
      );
      const albumsWithCovers = relatedTracks.filter(
        ({ albumCover }) => albumCover
      );
      const albumCovers = albumsWithCovers.map(({ albumCover }) => albumCover);
      trackImage && albumCovers.push(trackImage);

      await preloadImages([...artistsCovers, ...albumCovers]);

      this.setState(
        {
          isLoading: false,
          audioState: 'paused'
        },
        () => resetModalScroll(false, true)
      );
    } catch (error) {}
  };

  setTrackArtists = data => {
    const trackArtists = data.map(({ id, uri, name, images }) => ({
      id,
      uri,
      artistName: name,
      artistCover: getImageUrl(images)
    }));
    this.setState({ trackArtists });
  };

  setRelatedTracks = data => {
    const relatedTracks = data.map(
      ({ id, name, artists, uri, album, explicit }) => ({
        id,
        name,
        uri,
        explicit,
        artists: artists.map(({ name }) => name),
        albumCover: getImageUrl(album.images)
      })
    );
    this.setState({ relatedTracks });
  };

  onCanPlayAudio = () => {
    this.setState({ canPlayAudio: true });
  };

  onAudioTimeUpdate = () => {
    const { currentTime } = this.trackPreview;

    if (currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.trackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { isPlaying } = this.state;
    isPlaying ? pauseAudio(this.trackPreview) : playAudio(this.trackPreview);
    this.setState({ isPlaying: !isPlaying });
  };

  componentDidUpdate(prevProps) {
    const { activeTrack, resetModalScroll, scrollTo } = this.props;

    if (activeTrack !== prevProps.activeTrack) {
      this.setState(
        {
          isLoading: true,
          canPlayAudio: false,
          isPlaying: false,
          audioState: 'paused'
        },
        () => {
          resetModalScroll();
          scrollTo(0, false);
        }
      );
      this.getTrackData();
    }
  }

  componentDidMount() {
    this.getTrackData();
    this.stickyColumn = this.stickyColumnRef.current;
    this.trackPreview = this.trackPreviewRef.current;
    this.props.addToSticky(this.stickyColumn);
  }

  componentWillUnmount() {
    this.props.removeFromSticky(this.stickyColumn);
  }

  render() {
    const { setActivePlaylist } = this.props;
    const {
      isLoading,
      relatedTracks,
      trackArtists,
      audioFeatures,
      canPlayAudio,
      audioState,
      track = {}
    } = this.state;
    const { id, name, uri, popularity, preview_url, album = {} } = track;
    const { images = [], release_date } = album;
    const isReady = preview_url ? !isLoading && canPlayAudio : !isLoading;
    const { label: popularityLabel, icon: popularityIcon } = getPopularityGroup(
      popularity
    );
    const cover = getImageUrl(images);
    const playlistData = { name, id, cover };

    return (
      <div className={styles.trackDetails}>
        <RadialLoader isLoading={isLoading}>
          <div ref={this.stickyColumnRef}>
            {isReady && (
              <>
                {preview_url && (
                  <div className={styles.trackPlayer}>
                    <span onClick={this.togglePlayState}>
                      <span className={styles.audioControl}>
                        <AudioControl audioState={audioState} />
                      </span>
                      <span>Play Preview</span>
                    </span>
                  </div>
                )}
                <div className={styles.albumCover}>
                  <div
                    style={{
                      backgroundImage: `url(${cover})`
                    }}
                  />
                </div>
                <PlaylistButton
                  className={styles.playlistButton}
                  text="Generate Playlist"
                  onClick={() => setActivePlaylist('track', playlistData)}
                />
                <SpotifyButton className={styles.spotifyButton} uri={uri} />
              </>
            )}
          </div>
          {isReady && (
            <div>
              <h1 className={styles.title}>
                <span>{name}</span>
              </h1>
              <div className={styles.trackArtists}>
                {trackArtists.map((artist, index) => (
                  <ArtistPill
                    renderDelay={400 + index * 100}
                    key={index}
                    {...artist}
                  />
                ))}
              </div>
              <div className={styles.quickStats}>
                <TitleCard
                  renderDelay={500}
                  icon={popularityIcon}
                  title="Popularity"
                  content={popularityLabel}
                />
                <TitleCard
                  renderDelay={600}
                  columnDelay={100}
                  icon={<PublishIcon />}
                  title="Release Date"
                  content={formatReleaseDate(release_date)}
                />
              </div>

              <AudioFeatures audioFeatures={audioFeatures} />
              <TracksCarousel
                title="Related Tracks"
                data={relatedTracks}
                renderDelay={700}
              />
            </div>
          )}
          <audio
            ref={this.trackPreviewRef}
            preload="auto"
            src={preview_url}
            onCanPlay={this.onCanPlayAudio}
            onPlay={this.onAudioPlay}
            onPause={this.onAudioPause}
            onEnded={this.onAudioEnd}
            onTimeUpdate={this.onAudioTimeUpdate}
          />
        </RadialLoader>
      </div>
    );
  }
}

export default UserConsumer(TrackDetails);
