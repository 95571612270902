import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  sortForm,
  exitTab,
  orderTypes,
  orderDirection,
  sortFormActions,
  actionButton,
  applyButton,
  closeSortFormButton
} from './TopList.module.sass';
import Fieldset from '../Fieldset';
import CardRadio from '../CardRadio';
import SelectDropdown from '../SelectDropdown';
import Button from '../Button';
import StarIcon from '../../vectors/StarIcon';
import PublishIcon from '../../vectors/PublishIcon';
import TypographyIcon from '../../vectors/TypographyIcon';

const ListSortForm = ({ type, onSubmit, close, willExit }) => {
  const [orderType, setOrderType] = useState(type);
  const onFormSubmit = e => {
    e.preventDefault();
    onSubmit(orderType, false);
  };

  const directionOptions = [
    {
      value: 'asc',
      label: 'First to Last'
    }
  ];

  return (
    <div className={cx(sortForm, { [exitTab]: willExit })}>
      <h3>
        <span>Sort</span>
      </h3>
      <form onSubmit={onFormSubmit}>
        <Fieldset legend="ORDER">
          <div className={orderTypes}>
            <CardRadio
              value={orderType}
              name={0}
              onChange={setOrderType}
              title="Rank"
              icon={PublishIcon}
            />
            <CardRadio
              value={orderType}
              name={1}
              onChange={setOrderType}
              title="Popularity"
              icon={StarIcon}
            />
            <CardRadio
              value={orderType}
              name={2}
              onChange={setOrderType}
              title="Name"
              icon={TypographyIcon}
            />
          </div>
          <SelectDropdown
            value="asc"
            options={directionOptions}
            className={orderDirection}
          />
        </Fieldset>
        <div className={sortFormActions}>
          <Button className={cx(actionButton, applyButton)}>Apply</Button>
          <Button
            type="button"
            onClick={close}
            className={cx(actionButton, closeSortFormButton)}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};

ListSortForm.propTypes = {
  type: PropTypes.number.isRequired,
  desc: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  willExit: PropTypes.bool
};

export default ListSortForm;
