import React, { Component, createRef } from 'react';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase,
  MOBILE_WIDTH_BREAKPOINT as mobileWidthBreakpoint
} from '../../config';

import { UserConsumer } from '../../contexts/UserContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import styles from './Modal.module.sass';
import ArrowLeftIcon from '../../vectors/ArrowLeftIcon';
import ArrowRightIcon from '../../vectors/ArrowRightIcon';
import CloseIcon from '../../vectors/CloseIcon';
import ArtistDetails from '../ArtistDetails';
import TrackDetails from '../TrackDetails';
import TopList from '../TopList';
import PlaylistCreator from '../PlaylistCreator';
import SuggestionPlaylistCreator from '../SuggestionPlaylistCreator';

class Modal extends Component {
  state = {
    scrollThumbHeight: 0,
    navHistory: [],
    navIndex: 0
  };

  contentPositionY = 0;
  targetY = 0;
  contentAreaRef = createRef();
  scrollThumbRef = createRef();
  ease = true;
  stickyContainers = [];

  getModalHeight = () => {
    return window.innerHeight * 0.8;
  };

  initializeVirtualScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaY }) => {
      const { containerHeight, freezeScroll } = this.state;
      if (freezeScroll) {
        return;
      }

      const maxOverflow = containerHeight - this.getModalHeight();

      this.targetY += deltaY;
      this.targetY = Math.min(Math.max(-maxOverflow, this.targetY), 0);
    });
  };

  setContainerHeight = (
    initializeScroll,
    childContainerLoaded,
    scrollProgress
  ) => {
    const { navReady } = this.state;
    childContainerLoaded && !navReady && this.setState({ navReady: true });

    if (window.innerWidth < mobileWidthBreakpoint) {
      return this.closeModal();
    }

    const areaHeight = this.contentArea.getBoundingClientRect().height;
    const modalHeight = this.getModalHeight();

    this.setState(
      {
        containerHeight: areaHeight
      },
      () => {
        initializeScroll && this.initializeVirtualScroll();
      }
    );

    const maxOverflow = areaHeight - modalHeight;

    if (areaHeight <= modalHeight) {
      this.setState({ scrollThumbHeight: 0 });
    } else {
      const viewableRatio = modalHeight / areaHeight;
      const scrollBarArea = modalHeight - 180;
      const scrollThumbHeight = scrollBarArea * viewableRatio;
      this.setState({ scrollThumbHeight });
    }

    if (scrollProgress) {
      this.targetY = -maxOverflow * scrollProgress;
    }

    if (this.targetY < 0 && this.targetY < -maxOverflow) {
      this.targetY = -maxOverflow;
    }
  };

  scrollTo = (position, ease = true) => {
    this.targetY = position;
    this.ease = ease;
  };

  addToSticky = container => {
    this.stickyContainers.push(container);
  };

  removeFromSticky = container => {
    this.stickyContainers = this.stickyContainers.filter(
      item => item !== container
    );
    container.style.transform = 'translate3d(0, 0, 0)';
  };

  animatePosition = () => {
    const ease = this.ease ? scrollEase : 1;
    this.animateScroll = requestAnimationFrame(this.animatePosition);

    this.contentPositionY += (this.targetY - this.contentPositionY) * ease;
    const translateY = parseFloat(this.contentPositionY.toFixed(2));
    const translate = `translate3d(0, ${translateY}px, 0)`;
    this.contentArea.style.transform = translate;

    const stickyTranslate = `translate3d(0, ${-translateY}px, 0)`;
    this.stickyContainers.forEach(
      container => (container.style.transform = stickyTranslate)
    );

    const areaHeight = this.contentArea.getBoundingClientRect().height;
    const modalHeight = this.getModalHeight();
    const maxOverflow = areaHeight - modalHeight;
    const scrolledRatio = -translateY / maxOverflow;
    const scrollBarArea = modalHeight - 180;
    const viewableRatio = modalHeight / areaHeight;
    const scrollThumbHeight = scrollBarArea * viewableRatio;
    const scrollThumbtranslateY =
      scrolledRatio * (scrollBarArea - scrollThumbHeight);
    const scrollThumbtranslate = `translate3d(0, ${scrollThumbtranslateY}px, 0)`;

    this.scrollThumb.style.transform = scrollThumbtranslate;

    if (!this.ease) {
      this.ease = true;
    }
  };

  resetModal = () => {
    this.setContainerHeight();
    this.scrollTo(0, false);
  };

  freezeScroll = freezeScroll => {
    this.setState({ freezeScroll });
  };

  closeModal = () => {
    const { setActiveArtist, setActiveTrack, setActiveTopList } = this.props;

    setActiveArtist(null);
    setActiveTrack(null);
    setActiveTopList(null);
  };

  getActiveModal = () => {
    const {
      activeArtist,
      activeTrack,
      activeTopList,
      activePlaylistType,
      activePlaylistData,
      activeSuggestionPlaylist,
      setActiveArtist,
      setActiveTrack,
      setActiveTopList,
      setActivePlaylist,
      setActiveSuggestionPlaylist
    } = this.props;
    if (activeArtist) {
      return {
        typeAction: setActiveArtist,
        id: activeArtist
      };
    } else if (activeTrack) {
      return {
        typeAction: setActiveTrack,
        id: activeTrack
      };
    } else if (activeTopList) {
      return {
        typeAction: setActiveTopList,
        id: activeTopList
      };
    } else if (activePlaylistType) {
      return {
        typeAction: setActivePlaylist,
        playlistData: activePlaylistData,
        playlistType: activePlaylistType
      };
    } else if (activeSuggestionPlaylist) {
      return {
        typeAction: setActiveSuggestionPlaylist,
        suggestionPlaylistData: activeSuggestionPlaylist
      };
    }
  };

  addToNavHistory = () => {
    const { navHistory, navIndex } = this.state;
    const currentNavHistory = navHistory.slice(0, navIndex + 1);
    currentNavHistory.push(this.getActiveModal());

    this.setState({
      navHistory: currentNavHistory,
      navIndex: navIndex + 1
    });
  };

  gotoPrev = () => {
    const { navHistory, navIndex } = this.state;
    const {
      typeAction,
      id,
      playlistType,
      playlistData,
      suggestionPlaylistData
    } = navHistory[navIndex - 1];

    if (playlistType) {
      typeAction(playlistType, playlistData, false);
    } else if (suggestionPlaylistData) {
      typeAction(suggestionPlaylistData, false);
    } else {
      typeAction(id, false);
    }

    this.setState({
      navIndex: navIndex - 1
    });
  };

  gotoNext = () => {
    const { navHistory, navIndex } = this.state;
    const {
      typeAction,
      id,
      playlistType,
      playlistData,
      suggestionPlaylistData
    } = navHistory[navIndex + 1];

    if (playlistType) {
      typeAction(playlistType, playlistData, false);
    } else if (suggestionPlaylistData) {
      typeAction(suggestionPlaylistData, false);
    } else {
      typeAction(id, false);
    }

    this.setState({
      navIndex: navIndex + 1
    });
  };

  onResize = () => {
    const maxOverflow = this.state.containerHeight - this.getModalHeight();
    const scrollProgress = Math.abs(this.targetY / maxOverflow);
    this.setContainerHeight(false, false, scrollProgress);
    this.ease = false;
  };

  componentDidUpdate(prevProps) {
    const {
      activeArtist,
      activeTrack,
      activeTopList,
      activePlaylistType,
      activeSuggestionPlaylist,
      modalKey
    } = this.props;

    if (prevProps.modalKey !== modalKey) {
      this.addToNavHistory();
    }

    if (
      (prevProps.activeArtist && !activeArtist) ||
      (prevProps.activeTrack && !activeTrack) ||
      (prevProps.activeTopList && !activeTopList) ||
      (prevProps.activePlaylistType && !activePlaylistType) ||
      (prevProps.activeSuggestionPlaylist && !activeSuggestionPlaylist)
    ) {
      this.resetModal();
    }
  }

  componentDidMount() {
    this.setState({ navHistory: [this.getActiveModal()] });
    this.contentArea = this.contentAreaRef.current;
    this.scrollThumb = this.scrollThumbRef.current;
    this.setContainerHeight(true);
    this.props.setVScrollable(false);
    this.animateScroll = requestAnimationFrame(this.animatePosition);
    window.addEventListener('resize', this.onResize);

    setTimeout(() => {
      this.setState({ hasAnimatedIn: true });
      this.setContainerHeight();
    }, 600);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animateScroll);
    this.props.setVScrollable(true);
    this.vScroll.destroy();
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const {
      activeArtist,
      activeTrack,
      activeTopList,
      activePlaylistType,
      activeSuggestionPlaylist
    } = this.props;
    const {
      hasAnimatedIn,
      scrollThumbHeight,
      navHistory,
      navReady,
      navIndex
    } = this.state;

    const hideBar = !hasAnimatedIn || !scrollThumbHeight;

    return (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <nav className={cx(styles.modalNav, { [styles.hideNav]: !navReady })}>
            <div>
              <span
                onClick={this.gotoPrev}
                className={cx(styles.navControl, {
                  [styles.disabled]: navIndex < 1
                })}
              >
                <ArrowLeftIcon />
              </span>
              <span
                onClick={this.gotoNext}
                className={cx(styles.navControl, {
                  [styles.disabled]: navIndex === navHistory.length - 1
                })}
              >
                <ArrowRightIcon />
              </span>
            </div>
          </nav>
          <span className={styles.close} onClick={this.closeModal}>
            <CloseIcon />
          </span>
          <div
            className={cx(styles.scrollBar, {
              [styles.hideBar]: hideBar
            })}
          >
            <div
              ref={this.scrollThumbRef}
              className={cx(styles.scrollThumb, {
                [styles.hideThumb]: hideBar
              })}
              style={{ height: scrollThumbHeight }}
            />
          </div>
          <div ref={this.contentAreaRef} className={styles.contentArea}>
            {activeArtist && (
              <ArtistDetails
                resetModalScroll={this.setContainerHeight}
                scrollTo={this.scrollTo}
                addToSticky={this.addToSticky}
                removeFromSticky={this.removeFromSticky}
              />
            )}
            {activeTrack && (
              <TrackDetails
                resetModalScroll={this.setContainerHeight}
                scrollTo={this.scrollTo}
                addToSticky={this.addToSticky}
                removeFromSticky={this.removeFromSticky}
              />
            )}
            {activeTopList && (
              <TopList
                resetModalScroll={this.setContainerHeight}
                scrollTo={this.scrollTo}
                addToSticky={this.addToSticky}
                removeFromSticky={this.removeFromSticky}
              />
            )}
            {activePlaylistType && (
              <PlaylistCreator
                resetModalScroll={this.setContainerHeight}
                scrollTo={this.scrollTo}
                addToSticky={this.addToSticky}
                removeFromSticky={this.removeFromSticky}
                freezeScroll={this.freezeScroll}
              />
            )}
            {activeSuggestionPlaylist && (
              <SuggestionPlaylistCreator
                resetModalScroll={this.setContainerHeight}
                scrollTo={this.scrollTo}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ExperienceConsumer(UserConsumer(Modal));
