import React from 'react';
import PropTypes from 'prop-types';

import { mobileModalOpen } from './Button.module.sass';
import MobileExpandIcon from '../../vectors/MobileExpandIcon';

const MobileTopModalButton = ({ onClick }) => {
  const onButtonClick = () => {
    try {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission()
          .then(() => onClick())
          .catch(() => onClick());
      } else {
        onClick();
      }
    } catch (error) {
      onClick();
    }
  };

  return (
    <div role="button" className={mobileModalOpen} onClick={onButtonClick}>
      <MobileExpandIcon />
      <span>Top</span>
      <span>50</span>
    </div>
  );
};

MobileTopModalButton.propTypes = {
  onClick: PropTypes.func
};

export default MobileTopModalButton;
