import React from 'react';

const ButterflyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M53.937 10.215c-10.808-1.508-20.86 10.993-20.86 10.993a7.966 7.966 0 00-1.659 2.192c.023-.146.037-.297.037-.452 0-.854-.36-1.586-.872-1.9.606-2.096 1.842-5.08 4.009-5.412a.43.43 0 00.383.265c.24 0 .433-.219.433-.49 0-.27-.194-.49-.433-.49-.24 0-.433.22-.433.49l.002.027c-2.282.356-3.545 3.376-4.16 5.513a1.048 1.048 0 00-.177-.052c-.06-.066-.123-.102-.186-.102-.062 0-.125.036-.185.102a1.09 1.09 0 00-.39.158c-.602-2.133-1.861-5.22-4.158-5.603-.046-.213-.216-.372-.42-.372-.239 0-.432.22-.432.49s.194.49.433.49c.21 0 .387-.172.425-.399 2.165.404 3.382 3.464 3.965 5.537-.401.365-.67 1.01-.67 1.747 0 .028.003.056.003.083a7.997 7.997 0 00-1.67-2.212s-10.05-12.5-20.86-10.992c0 0-8.063.685-1.787 8.44 0 0 4.15 11.19 12.05 9.967 0 0-3.766.86-3.69 4.632h-.003s-.451 7.554.697 12.718c.968 4.352 3.071 7.005 7.539 2.007 1.386-1.552 3-3.84 4.88-7.044a.011.011 0 00.005.003l.106-.194v.001c.493-.895 2.53-4.659 3.126-6.77.207 2.512.594 4.2 1.035 4.2.418 0 .785-1.504 1-3.787.69 2.414 3.496 6.853 3.496 6.853l.015-.01c1.981 3.199 3.658 5.35 5.078 6.688 3.156 2.974 5.039 1.92 6.157-.614 1.944-4.405 1.58-13.285 1.58-13.285l-.001.001c.347-4.094-3.671-5.01-3.671-5.01 7.902 1.223 12.051-9.968 12.051-9.968 6.276-7.753-1.788-8.44-1.788-8.44z"
    />
  </svg>
);

export default ButterflyIcon;
