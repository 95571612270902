import React from 'react';

const SmileyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M4.868 15C4.868 9.398 9.398 4.868 15 4.868c5.602 0 10.132 4.53 10.132 10.132 0 5.602-4.53 10.132-10.132 10.132A10.123 10.123 0 014.868 15zm3.756-2.086c0 .239.208.447.447.447h3.694c.268 0 .447-.18.447-.447 0-1.162-1.043-2.145-2.294-2.145-1.251 0-2.294.953-2.294 2.145zm8.164 0c0 .268.208.447.447.447h3.694c.239 0 .447-.18.447-.447 0-1.192-1.043-2.145-2.294-2.145-1.251 0-2.294.953-2.294 2.145zM15 21.586a5.202 5.202 0 01-5.215-5.215h10.43A5.202 5.202 0 0115 21.586z"
    />
  </svg>
);

export default SmileyIcon;
