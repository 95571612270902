import React from 'react';

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M10.733 24.346c8.68 0 13.426-7.19 13.426-13.426 0-.204-.004-.408-.013-.61A9.595 9.595 0 0026.5 7.866a9.41 9.41 0 01-2.71.743A4.735 4.735 0 0025.865 6a9.463 9.463 0 01-2.996 1.146 4.72 4.72 0 00-8.041 4.303A13.398 13.398 0 015.1 6.518a4.706 4.706 0 00-.64 2.372c0 1.637.834 3.082 2.1 3.928a4.684 4.684 0 01-2.137-.59v.06a4.72 4.72 0 003.786 4.625 4.725 4.725 0 01-2.132.081 4.724 4.724 0 004.409 3.278 9.467 9.467 0 01-5.861 2.02c-.38 0-.757-.022-1.126-.066a13.358 13.358 0 007.234 2.12"
    />
  </svg>
);

export default TwitterIcon;
