import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { loader, active } from './RadialLoader.module.sass';
import Spinner from '../../vectors/Spinner';

const RadialLoader = ({
  isLoading,
  renderChildrenOnLoad,
  className,
  children
}) => (
  <>
    <div className={cx(loader, className, { [active]: isLoading })}>
      <Spinner />
    </div>
    {!renderChildrenOnLoad && children}
    {renderChildrenOnLoad && !isLoading && children}
  </>
);

RadialLoader.propTypes = {
  isLoading: PropTypes.bool,
  renderChildrenOnLoad: PropTypes.bool,
  className: PropTypes.string
};

export default RadialLoader;
