import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  artistPill,
  isPassive,
  artistCoverImage
} from './ArtistPill.module.sass';
import { modalScrollViewListener } from '../../utils';
import { SpotifyLogo } from '../AnimatedVectors';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';

const ArtistPill = ({
  id,
  artistCover,
  artistName,
  uri,
  setActiveArtist,
  renderDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const pillRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : 0);
  };

  useEffect(() => {
    modalScrollViewListener(pillRef.current, 0.4, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(artistPill, { [isPassive]: !isReady })} ref={pillRef}>
      <div
        className={artistCoverImage}
        style={{ backgroundImage: `url(${artistCover})` }}
      >
        <span onClick={() => setActiveArtist(id)}>
          <ExpandAltIcon />
        </span>
      </div>
      <h3>{artistName}</h3>
      <a href={uri}>
        <SpotifyLogo />
      </a>
    </div>
  );
};

ArtistPill.defaultProps = {
  renderDelay: 0
};

ArtistPill.propTypes = {
  id: PropTypes.string,
  artistName: PropTypes.string,
  artistCover: PropTypes.string,
  uri: PropTypes.string,
  renderDelay: PropTypes.number
};

export default UserConsumer(ArtistPill);
