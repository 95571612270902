import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  artistCard,
  cardMaskOut,
  featured,
  isPassive,
  artistCoverImage,
  spotifyLink,
  artistCardTitle
} from './MobileArtistCard.module.sass';
import { UserConsumer } from '../../contexts/UserContext';
import MultiLineEllipsis from '../MultiLineEllipsis';
import SpotifyLogo from '../../vectors/SpotifyLogo';
import { mobileScrollViewListener } from '../../utils';

const MobileArtistCard = ({
  id,
  artistCover,
  isFeatured,
  title,
  artistName,
  uri,
  setActiveArtist,
  maskOut,
  renderDelay,
  columnDelay
}) => {
  const [containerInView, setContainerInview] = useState(false);
  const containerRef = useRef();

  const onEnterView = onStart => {
    setTimeout(
      () => setContainerInview(true),
      onStart ? renderDelay : columnDelay
    );
  };

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, -0.75, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={cx(artistCard, {
        [featured]: isFeatured,
        [isPassive]: !containerInView,
        [cardMaskOut]: maskOut
      })}
      onClick={() => setActiveArtist(id)}
    >
      <div>
        <div
          className={artistCoverImage}
          style={{ backgroundImage: `url(${artistCover})` }}
        />
        <a
          onClick={e => e.stopPropagation()}
          className={spotifyLink}
          href={uri}
        >
          <SpotifyLogo />
        </a>
        <div className={artistCardTitle}>
          <h3>{title}</h3>
          <h2>
            <span>
              <MultiLineEllipsis text={artistName} lines={2} />
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

MobileArtistCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

MobileArtistCard.propTypes = {
  id: PropTypes.string,
  artistCover: PropTypes.string,
  isFeatured: PropTypes.bool,
  title: PropTypes.string,
  artistName: PropTypes.string,
  uri: PropTypes.string,
  maskOut: PropTypes.bool,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default UserConsumer(MobileArtistCard);
