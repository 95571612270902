import React from 'react';

const MicAltIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    width="40"
    height="40"
  >
    <defs>
      <linearGradient id="lnK24ubrSdX" x1="0.18" x2="1.04" y1="0.05" y2="0.99">
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#lnK24ubrSdX)"
      fillRule="evenodd"
      d="M11.023 16.775l6.232-9.47a7.074 7.074 0 00-2.773-3.535 7.097 7.097 0 00-9.83 2.03 7.096 7.096 0 002.028 9.83 7.08 7.08 0 004.343 1.145zm10.784 22.786a1.732 1.732 0 001.691-1.731v-9.043c.93 0 1.691-.76 1.691-1.69v-3.218l7.202 3.517c1.291.63 2.85.21 3.65-.983a2.81 2.81 0 00-.515-3.707L20.745 10.148l-5.757 8.75 3.436 1.68v6.52c0 .93.76 1.692 1.692 1.692v9.119c0 .928.764 1.674 1.691 1.652z"
    />
  </svg>
);

export default MicAltIcon;
