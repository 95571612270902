import React, { Component } from 'react';
import cx from 'classnames';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { mouseGlow, passive } from './MouseGlow.module.sass';

class MouseGlow extends Component {
  state = {};

  setGlowPosition = e => {
    this.setState({
      glowPosition: {
        transform: `translate3d(${e.clientX}px, ${e.clientY}px, 0)`
      }
    });
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.setGlowPosition);
  }

  render() {
    const { glowPosition } = this.state;
    const { glowActive } = this.props;

    return (
      <div
        className={cx(mouseGlow, {
          [passive]: !glowPosition || !glowActive
        })}
        style={glowPosition}
      />
    );
  }
}

export default ExperienceConsumer(MouseGlow);
