import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { carouselGroup, carouselItems } from './SwipeCarousel.module.sass';
import SwipeCarousel from './SwipeCarousel';
import MobileModalSectionTitle from '../MobileModalSectionTitle';
import MobileArtistCard from '../MobileArtistCard';

const ArtistsCarousel = ({ title, data, disableParentScroll }) => {
  const [resized, setResized] = useState(true);
  const getCarouselItemsWidth = () => {
    const total = resized && data.length;
    const itemWidth = (window.innerWidth * 0.84 - 15) / 2;
    return itemWidth * total + (total - 1) * 15;
  };

  useEffect(() => {
    window.addEventListener('resize', () => setResized(true));
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', () => setResized(true));
    };
  }, []);

  return (
    <div className={carouselGroup}>
      <MobileModalSectionTitle title={title} />
      <SwipeCarousel disableParentScroll={disableParentScroll}>
        <div
          className={carouselItems}
          style={{
            width: getCarouselItemsWidth()
          }}
        >
          {data.map((track, index) => (
            <MobileArtistCard
              key={index}
              columnDelay={50 + index * 50}
              {...track}
            />
          ))}
        </div>
      </SwipeCarousel>
    </div>
  );
};

ArtistsCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  disableParentScroll: PropTypes.func.isRequired,
  data: PropTypes.array
};

export default ArtistsCarousel;
