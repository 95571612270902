import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  title,
  listName,
  description,
  playlistButton,
  swipeDown
} from './TopList.module.sass';
import { PlaylistButton } from '../Button';

const ListSummary = ({
  name,
  listDescription,
  onPlaylistButtonClick,
  willExit,
  withoutPlaylistButton
}) => (
  <>
    <h1 className={cx(title, { [swipeDown]: willExit })}>
      <span>Your Top</span>
    </h1>
    <h1 className={cx(title, { [swipeDown]: willExit })}>
      <span className={listName}>{name}</span>
    </h1>

    <p className={cx(description, { [swipeDown]: willExit })}>
      <span>
        Showing your top {name.toLowerCase()} {listDescription}. You can also
        add a playlist on Spotify.
      </span>
    </p>
    {!withoutPlaylistButton && (
      <PlaylistButton
        text="Generate Playlist"
        className={cx(playlistButton, { [swipeDown]: willExit })}
        onClick={onPlaylistButtonClick}
      />
    )}
  </>
);

ListSummary.propTypes = {
  name: PropTypes.string.isRequired,
  listDescription: PropTypes.string.isRequired,
  onPlaylistButtonClick: PropTypes.func.isRequired,
  willExit: PropTypes.bool,
  withoutPlaylistButton: PropTypes.bool
};

export default ListSummary;
