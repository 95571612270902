import React from 'react';

const GithubIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFFFFE"
      fillRule="evenodd"
      d="M2.535 15.304c0 5.436 3.572 10.048 8.525 11.675.622.114.851-.267.851-.592 0-.293-.011-1.263-.017-2.29-3.467.744-4.2-1.452-4.2-1.452-.566-1.423-1.383-1.8-1.383-1.8-1.131-.764.085-.749.085-.749 1.252.087 1.91 1.268 1.91 1.268 1.113 1.881 2.917 1.337 3.628 1.023.112-.795.435-1.338.791-1.645-2.768-.311-5.679-1.366-5.679-6.08 0-1.344.487-2.442 1.285-3.303-.13-.31-.556-1.562.12-3.257 0 0 1.047-.33 3.43 1.262.993-.273 2.06-.41 3.12-.414 1.058.004 2.126.141 3.122.414 2.379-1.592 3.424-1.262 3.424-1.262.679 1.695.252 2.946.122 3.257.8.861 1.283 1.959 1.283 3.302 0 4.726-2.916 5.766-5.691 6.071.447.382.845 1.13.845 2.278 0 1.647-.014 2.972-.014 3.377 0 .327.224.711.856.59 4.95-1.629 8.517-6.239 8.517-11.673C27.465 8.508 21.884 3 15 3 8.117 3 2.535 8.508 2.535 15.304z"
    />
  </svg>
);

export default GithubIcon;
