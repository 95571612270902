import React, { createRef, Component } from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import styles from './ArtistDetails.module.sass';
import RadialLoader from '../RadialLoader';
import { PlaylistButton, SpotifyButton } from '../Button';
import PillText from '../PillText';
import TitleCard from '../TitleCard';
import { TracksCarousel, ArtistsCarousel } from '../Carousel';
import HeartIcon from '../../vectors/HeartIcon';
import PublishIcon from '../../vectors/PublishIcon';
import DiscIcon from '../../vectors/DiscIcon';
import {
  getImageUrl,
  preloadImages,
  getPopularityGroup,
  formatReleaseDate
} from '../../utils';

class ArtistDetails extends Component {
  state = {
    isLoading: true
  };

  stickyColumnRef = createRef();

  getArtistData = async () => {
    const {
      resetModalScroll,
      activeArtist,
      getArtist,
      getArtistTopTracks,
      getAlbums,
      getRelatedArtist
    } = this.props;

    try {
      await Promise.all([
        getArtist(activeArtist, artist => this.setState({ artist })),
        getArtistTopTracks(activeArtist, this.setTopTracks),
        getRelatedArtist(activeArtist, this.setRelatedArtists),
        getAlbums(
          activeArtist,
          { include_groups: 'album', limit: 1 },
          ({ items, total }) =>
            this.setState({
              totalAlbums: total,
              latestAlbum: items[0] ? items[0].release_date : ''
            })
        ),
        getAlbums(
          activeArtist,
          { include_groups: 'single', limit: 1 },
          ({ items, total }) =>
            this.setState({
              totalSingles: total,
              latestSingle: items[0] ? items[0].release_date : ''
            })
        )
      ]);

      const { latestAlbum, latestSingle } = this.state;

      const { artist, relatedArtists, topTracks } = this.state;
      const artistImage = getImageUrl(artist.images);
      const artistsWithCovers = relatedArtists.filter(
        ({ artistCover }) => artistCover
      );
      const albumsWithCovers = topTracks.filter(({ albumCover }) => albumCover);
      const artistsCovers = artistsWithCovers.map(
        ({ artistCover }) => artistCover
      );
      const albumCovers = albumsWithCovers.map(({ albumCover }) => albumCover);

      artistImage && artistsCovers.push(artistImage);

      await preloadImages([...artistsCovers, ...albumCovers]);

      this.setState(
        {
          latestRelease:
            latestAlbum > latestSingle ? latestAlbum : latestSingle,
          isLoading: false
        },
        () => resetModalScroll(false, true)
      );
    } catch (error) {}
  };

  setTopTracks = data => {
    const topTracks = data
      .slice(0, 6)
      .map(({ id, name, artists, uri, album, explicit }) => ({
        id,
        name,
        uri,
        explicit,
        artists: artists.map(({ name }) => name),
        albumCover: getImageUrl(album.images)
      }));
    this.setState({ topTracks });
  };

  setRelatedArtists = data => {
    const relatedArtists = data
      .slice(0, 6)
      .map(({ id, uri, name, images, genres }) => ({
        id,
        genres,
        uri,
        artistName: name,
        artistCover: getImageUrl(images)
      }));
    this.setState({ relatedArtists });
  };

  componentDidUpdate(prevProps) {
    const { activeArtist, resetModalScroll, scrollTo } = this.props;

    if (activeArtist !== prevProps.activeArtist) {
      this.setState({ isLoading: true }, () => {
        resetModalScroll();
        scrollTo(0, false);
      });
      this.getArtistData();
    }
  }

  componentDidMount() {
    this.getArtistData();
    this.stickyColumn = this.stickyColumnRef.current;
    this.props.addToSticky(this.stickyColumn);
  }

  componentWillUnmount() {
    this.props.removeFromSticky(this.stickyColumn);
  }

  render() {
    const { setActivePlaylist } = this.props;
    const {
      isLoading,
      totalAlbums,
      totalSingles,
      latestRelease,
      topTracks,
      relatedArtists,
      artist = {}
    } = this.state;
    const {
      id,
      name,
      images = [],
      genres,
      uri,
      popularity,
      followers
    } = artist;
    const { label: popularityLabel, icon: popularityIcon } = getPopularityGroup(
      popularity
    );
    const cover = getImageUrl(images);
    const playlistData = { name, id, cover };

    return (
      <div className={styles.artistDetails}>
        <RadialLoader isLoading={isLoading}>
          <div ref={this.stickyColumnRef}>
            {!isLoading && (
              <>
                <div className={styles.artistsCover}>
                  <div
                    style={{
                      backgroundImage: `url(${cover})`
                    }}
                  />
                </div>
                <PlaylistButton
                  className={styles.playlistButton}
                  text="Generate Playlist"
                  onClick={() => setActivePlaylist('artist', playlistData)}
                />
                <SpotifyButton className={styles.spotifyButton} uri={uri} />
              </>
            )}
          </div>
          {!isLoading && (
            <div>
              <h1 className={styles.title}>
                <span>{name}</span>
              </h1>
              <div className={styles.genres}>
                {genres.map((genre, index) => (
                  <PillText key={index} renderDelay={400 + index * 50}>
                    {genre}
                  </PillText>
                ))}
              </div>
              <div className={styles.quickStats}>
                <TitleCard
                  renderDelay={500}
                  icon={popularityIcon}
                  title="Popularity"
                  content={popularityLabel}
                />
                <TitleCard
                  renderDelay={600}
                  columnDelay={100}
                  icon={<HeartIcon />}
                  title="Followers"
                  content={followers.total.toLocaleString()}
                />
              </div>

              <TracksCarousel
                title="Top Tracks"
                data={topTracks}
                renderDelay={500}
              />

              <div className={styles.quickStats}>
                <TitleCard
                  renderDelay={600}
                  icon={<DiscIcon />}
                  title="Discography"
                  content={`${totalAlbums} Album${
                    totalAlbums > 1 ? 's' : ''
                  }, ${totalSingles} Single${totalSingles > 1 ? 's' : ''}`}
                />
                <TitleCard
                  renderDelay={700}
                  columnDelay={100}
                  icon={<PublishIcon />}
                  title="Latest Release"
                  content={formatReleaseDate(latestRelease)}
                />
              </div>

              <ArtistsCarousel
                renderDelay={700}
                title="Related Artists"
                data={relatedArtists}
              />
            </div>
          )}
        </RadialLoader>
      </div>
    );
  }
}

export default UserConsumer(ArtistDetails);
