import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  projectPlaylistContainer,
  playlistPassive,
  projectPlaylist,
  projectPlaylistCover,
  projectPlaylistSummary
} from './ProjectPlaylist.module.sass';
import DrumPads from '../DrumPads';
import playlistCover from '../../shared/images/cruuunchify-inpiration-playlist.png';
import { INSPIRATION_PLAYLIST_URI as playlistUri } from '../../config';
import { scrollViewListener } from '../../utils';

const ProjectPlaylist = ({ className }) => {
  const [containerInView, setContainerInview] = useState(false);

  useEffect(() => {
    scrollViewListener(containerRef.current, 0.3, () =>
      setContainerInview(true)
    );
  }, []);

  const containerRef = useRef();

  return (
    <div
      ref={containerRef}
      className={cx(projectPlaylistContainer, className, {
        [playlistPassive]: !containerInView
      })}
    >
      <DrumPads total={5} />
      <div className={projectPlaylist}>
        <div className={projectPlaylistCover}>
          <div style={{ backgroundImage: `url(${playlistCover})` }} />
        </div>
        <div className={projectPlaylistSummary}>
          <h4>Cruuunchify Inspiration Playlist</h4>
          <a href={playlistUri}>Open in Spotify</a>
        </div>
      </div>
    </div>
  );
};

ProjectPlaylist.propTypes = {
  className: PropTypes.string
};

export default ProjectPlaylist;
