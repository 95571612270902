import React from 'react';
import PropTypes from 'prop-types';

import {
  audioFeaturesContainer,
  featuresCards
} from './MobileTrackDetails.module.sass';
import MobileAudioFeatureCard from '../MobileAudioFeatureCard';
import DanceGreenIcon from '../../vectors/DanceGreenIcon';
import SpeechGreenIcon from '../../vectors/SpeechGreenIcon';
import InstrumentGreenIcon from '../../vectors/InstrumentGreenIcon';
import SmileyGreenIcon from '../../vectors/SmileyGreenIcon';
import SoundWavesGreenIcon from '../../vectors/SoundWavesGreenIcon';
import BoltGreenIcon from '../../vectors/BoltGreenIcon';
import TempoGreenIcon from '../../vectors/TempoGreenIcon';
import MobileModalSectionTitle from '../MobileModalSectionTitle';

const AudioFeatures = ({ audioFeatures }) => {
  const {
    danceability,
    speechiness,
    instrumentalness,
    valence,
    loudness,
    energy,
    tempo
  } = audioFeatures || {};

  const roundNumber = number => (number ? (number * 100).toFixed(1) : 0);
  const moreSpeechiness = speechiness > instrumentalness;

  const features = [
    {
      value: `${roundNumber(danceability)}`,
      label: 'Danceability',
      icon: <DanceGreenIcon />,
      progress: roundNumber(danceability)
    },
    {
      value: `${roundNumber(energy)}`,
      label: 'Energy',
      icon: <BoltGreenIcon />,
      progress: roundNumber(energy)
    },
    {
      value: `${roundNumber(Math.max(speechiness, instrumentalness))}`,
      label: moreSpeechiness ? 'Speechiness' : 'Instrumentalness',
      icon: moreSpeechiness ? <SpeechGreenIcon /> : <InstrumentGreenIcon />,
      progress: roundNumber(Math.max(speechiness, instrumentalness))
    },
    {
      value: `${roundNumber(valence)}`,
      label: 'Valence',
      icon: <SmileyGreenIcon />,
      progress: roundNumber(valence)
    },
    {
      value: (
        <>
          {loudness ? loudness.toFixed(1) : 0}
          <sub>db</sub>
        </>
      ),
      label: 'Loudness',
      icon: <SoundWavesGreenIcon />,
      progress: roundNumber(Math.min(Math.max(loudness, -20), 0) / 20 + 1)
    },
    {
      value: (
        <>
          {Math.round(tempo || 0)}
          <sub>bpm</sub>
        </>
      ),
      label: 'Tempo',
      icon: <TempoGreenIcon />,
      progress: roundNumber((Math.min(Math.max(tempo, 60), 170) - 60) / 110)
    }
  ];

  return (
    <div className={audioFeaturesContainer}>
      <MobileModalSectionTitle title="Audio Features" />
      <div className={featuresCards}>
        {features.map((data, index) => (
          <MobileAudioFeatureCard key={index} {...data} />
        ))}
      </div>
      <div />
    </div>
  );
};

AudioFeatures.propTypes = {
  audioFeatures: PropTypes.object.isRequired
};

export default AudioFeatures;
