import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import MobileModal from './MobileModal';

const ModalWrapper = ({ activeArtist, activeTrack, activeTopList }) => {
  const openModal = activeArtist || activeTrack || activeTopList;
  return <>{openModal && <MobileModal />}</>;
};

export default UserConsumer(ModalWrapper);
