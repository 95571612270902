import React from 'react';
import PropTypes from 'prop-types';

const HeartIcon = ({ blaze }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height={blaze ? 44 : 34}
    width={blaze ? 44 : 34}
  >
    <g transform="translate(-4.547473508864641e-13, 0)" opacity="1">
      <path
        fill={blaze ? '#FF3660' : '#FFFFFF'}
        id="path-1"
        opacity="1"
        fillRule="evenodd"
        d="M5.534764405092787,6.431923996476657 C3.408867840351375,8.863816534541336 3.41009471841857,12.77771486095382 5.534764405092787,15.211966779916951 L14.945233764599521,26 C18.08616745026707,22.408078520196298 21.2259057162794,18.8161570403926 24.366713568299033,15.224235560588903 C26.49261013304045,12.792343022524223 26.49261013304045,8.875928023153392 24.366713568299033,6.44419277714861 C22.240817003557623,4.012300239083931 18.815876774540385,4.012300239083931 16.690231877094803,6.44419277714861 L14.956338584028238,8.427551277211528 L13.211371929944937,6.4318925380646785 C11.085475365203523,4 7.660535136186284,4 5.534764405092787,6.431923996476657Z"
      />
    </g>
  </svg>
);

HeartIcon.propTypes = {
  blaze: PropTypes.bool
};

export default HeartIcon;
