import React, { Component } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  artistNav,
  hideNav,
  closeNav,
  navActions,
  artistCover,
  coverImage,
  artistSummary,
  title,
  artistGenres,
  playlistButton,
  quickStats
} from './MobileArtistDetails.module.sass';
import {
  getImageUrl,
  preloadImages,
  getPopularityGroup,
  formatReleaseDate
} from '../../utils';
import RadialLoader from '../RadialLoader';
import { TracksCarousel, ArtistsCarousel } from '../SwipeCarousel';
import MultiLineEllipsis from '../MultiLineEllipsis';
import { PlaylistButton, RadialIconButton } from '../Button';
import SpotifyLogo from '../../vectors/SpotifyLogo';
import HeartIcon from '../../vectors/HeartIcon';
import PublishIcon from '../../vectors/PublishIcon';
import DiscIcon from '../../vectors/DiscIcon';
import MobileTitleCard from '../MobileTitleCard';
import { MOBILE_MODAL_ENTANCE_DELAY as entranceDelay } from '../../config';

class MobileArtistDetails extends Component {
  state = {
    activeNavIndex: 0,
    isLoading: true
  };

  getArtistData = async () => {
    const {
      resetModalScroll,
      activeArtist,
      getArtist,
      getArtistTopTracks,
      getAlbums,
      getRelatedArtist
    } = this.props;

    try {
      await Promise.all([
        getArtist(activeArtist, artist => this.setState({ artist })),
        getArtistTopTracks(activeArtist, this.setTopTracks),
        getRelatedArtist(activeArtist, this.setRelatedArtists),
        getAlbums(
          activeArtist,
          { include_groups: 'album', limit: 1 },
          ({ items, total }) =>
            this.setState({
              totalAlbums: total,
              latestAlbum: items[0] ? items[0].release_date : ''
            })
        ),
        getAlbums(
          activeArtist,
          { include_groups: 'single', limit: 1 },
          ({ items, total }) =>
            this.setState({
              totalSingles: total,
              latestSingle: items[0] ? items[0].release_date : ''
            })
        )
      ]);

      const { latestAlbum, latestSingle } = this.state;

      const { artist, relatedArtists, topTracks } = this.state;
      const artistImage = getImageUrl(artist.images);
      const artistsWithCovers = relatedArtists.filter(
        ({ artistCover }) => artistCover
      );
      const albumsWithCovers = topTracks.filter(({ albumCover }) => albumCover);
      const artistsCovers = artistsWithCovers.map(
        ({ artistCover }) => artistCover
      );
      const albumCovers = albumsWithCovers.map(({ albumCover }) => albumCover);

      artistImage && artistsCovers.push(artistImage);

      await preloadImages([...artistsCovers, ...albumCovers]);

      this.setState(
        {
          latestRelease:
            latestAlbum > latestSingle ? latestAlbum : latestSingle,
          isLoading: false,
          navReady: true
        },
        () => {
          resetModalScroll(false, true);
        }
      );
    } catch (error) {}
  };

  setTopTracks = data => {
    const topTracks = data
      .slice(0, 6)
      .map(({ id, name, artists, uri, album, explicit }) => ({
        id,
        name,
        uri,
        explicit,
        artists: artists.map(({ name }) => name),
        albumCover: getImageUrl(album.images)
      }));
    this.setState({ topTracks });
  };

  setRelatedArtists = data => {
    const relatedArtists = data
      .slice(0, 6)
      .map(({ id, uri, name, images, genres }) => ({
        id,
        genres,
        uri,
        artistName: name,
        artistCover: getImageUrl(images)
      }));
    this.setState({ relatedArtists });
  };

  setActiveStats = activeNavIndex => {
    this.setState({ activeNavIndex });
  };

  componentDidUpdate(prevProps) {
    const { activeArtist, resetModalScroll, scrollTo } = this.props;

    if (activeArtist !== prevProps.activeArtist) {
      this.setState(
        {
          isLoading: true,
          navReady: false,
          activeNavIndex: 0
        },
        () => {
          resetModalScroll();
          scrollTo(0, false);
        }
      );
      this.getArtistData();
    }
  }

  componentDidMount() {
    setTimeout(this.getArtistData, entranceDelay);
  }

  render() {
    const { disableScroll, willCloseModal } = this.props;

    const {
      activeNavIndex,
      isLoading,
      navReady,
      totalAlbums,
      totalSingles,
      latestRelease,
      topTracks,
      relatedArtists,
      artist = {}
    } = this.state;

    const { name, images, genres, uri, popularity, followers } = artist;
    const {
      label: popularityLabel,
      mobileNavIcon: MobileNavIcon,
      icon: popularityIcon
    } = getPopularityGroup(popularity);
    const navMenus = [MobileNavIcon, HeartIcon, DiscIcon, PublishIcon];

    const titleCardData = [
      { icon: popularityIcon, label: 'Popularity', value: popularityLabel },
      {
        icon: (
          <span>
            <HeartIcon blaze />
          </span>
        ),
        label: 'Followers',
        value: followers && followers.total.toLocaleString()
      },
      {
        icon: (
          <span>
            <DiscIcon blaze />
          </span>
        ),
        label: 'Discography',
        value: `${totalAlbums} Album${
          totalAlbums > 1 ? 's' : ''
        }, ${totalSingles} Single${totalSingles > 1 ? 's' : ''}`
      },
      {
        icon: (
          <span>
            <PublishIcon blaze />
          </span>
        ),
        label: 'Latest Release',
        value: formatReleaseDate(latestRelease)
      }
    ];

    return (
      <>
        <nav
          className={cx(artistNav, {
            [hideNav]: !navReady,
            [closeNav]: willCloseModal
          })}
        >
          <span className={navActions}>
            <a href={uri}>
              <SpotifyLogo /> Open in Spotify
            </a>
          </span>
        </nav>
        <div>
          <div>
            <RadialLoader isLoading={isLoading}>
              {!isLoading && (
                <>
                  <div data-cover className={artistCover}>
                    <div data-cover-image>
                      <div
                        className={coverImage}
                        style={{
                          backgroundImage: `url(${getImageUrl(images)})`
                        }}
                      />
                    </div>
                  </div>

                  <div className={artistSummary}>
                    <h1 className={title}>
                      <span>
                        <MultiLineEllipsis text={name} lines={3} />
                      </span>
                    </h1>
                    <div className={artistGenres}>
                      {genres.length > 0 && (
                        <>
                          <span>{genres[0]}</span>
                          {genres.length > 1 && (
                            <span>+{genres.length - 1}</span>
                          )}
                        </>
                      )}
                    </div>
                    <PlaylistButton
                      text="Generate Playlist"
                      className={playlistButton}
                    />
                  </div>

                  <div className={quickStats}>
                    <nav>
                      {navMenus.map((icon, index) => (
                        <RadialIconButton
                          key={index}
                          icon={icon}
                          active={activeNavIndex === index}
                          onClick={() => this.setActiveStats(index)}
                          renderDelay={600 + index * 50}
                          columnDelay={index * 50}
                        />
                      ))}
                    </nav>
                    <MobileTitleCard
                      data={titleCardData}
                      activeIndex={activeNavIndex}
                      renderDelay={700}
                    />
                  </div>

                  <TracksCarousel
                    title="Top Tracks"
                    data={topTracks}
                    disableParentScroll={disableScroll}
                  />

                  <ArtistsCarousel
                    title="Related Artists"
                    data={relatedArtists}
                    disableParentScroll={disableScroll}
                  />
                </>
              )}
            </RadialLoader>
          </div>
        </div>
      </>
    );
  }
}

export default UserConsumer(MobileArtistDetails);
