import React from 'react';

import SectionIconBox from './SectionIconBox';
import HeadPhoneIcon from '../../vectors/HeadPhoneIcon';

const HeadPhoneIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(135.57deg, #119AF0 0%, #800FBD 100%)"
    icon={<HeadPhoneIcon />}
    {...props}
  />
);

export default HeadPhoneIconBox;
