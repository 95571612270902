import React from 'react';

const MadeWithStudioIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124 19"
    width="124"
    height="19"
  >
    <text fontSize="0">
      <tspan
        x="0"
        y="13"
        fill="#7A8185"
        fontFamily="Maison Neue"
        fontSize="14"
        fontWeight="400"
        style={{
          whiteSpace: 'pre',
          WebkitTextTransform: 'none',
          textTransform: 'none'
        }}
      >
        #MadeWith
      </tspan>
      <tspan
        fill="#FFF"
        fontFamily="Maison Neue"
        fontSize="14"
        fontWeight="700"
        style={{
          whiteSpace: 'pre',
          WebkitTextTransform: 'none',
          textTransform: 'none'
        }}
      >
        Studio
      </tspan>
    </text>
    <path fill="#FF3660" d="M116 11H119V14H116z" />
  </svg>
);

export default MadeWithStudioIcon;
