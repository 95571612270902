import React from 'react';

const NavigationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53.16 45.372"
    width="53.16"
    height="45.372"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M26.523 8.77c-5.194 0-9.444 4.25-9.444 9.444v8.973c0 5.194 4.25 9.444 9.444 9.444 5.195 0 9.445-4.25 9.445-9.444v-8.973c0-5.194-4.25-9.445-9.445-9.445zm6.999 18.58a6.997 6.997 0 01-6.999 6.998 6.997 6.997 0 01-6.998-6.999v-9.298a6.997 6.997 0 016.998-6.998 6.997 6.997 0 016.999 6.998v9.298zm-5.775-5.695v-4.893a1.224 1.224 0 00-2.447 0v4.893a1.224 1.224 0 002.447 0zM28.532.97L26.523-1.04 24.515.969a1.162 1.162 0 01-1.64 0 1.162 1.162 0 010-1.64l2.828-2.828a1.162 1.162 0 011.64 0L30.172-.67a1.162 1.162 0 010 1.64 1.22 1.22 0 01-.82.329 1.11 1.11 0 01-.82-.329zm-4.01 43.46l2.001 2 2.002-2c.45-.45 1.185-.45 1.634 0 .45.45.45 1.184 0 1.634l-2.819 2.818a1.216 1.216 0 01-.817.327c-.285 0-.57-.081-.817-.327l-2.818-2.818a1.158 1.158 0 010-1.634c.45-.45 1.184-.45 1.634 0z"
      transform="rotate(90 26.523 22.686)"
    />
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M44.29 24.709l2.008-2.009-2.009-2.008a1.162 1.162 0 010-1.64 1.162 1.162 0 011.64 0l2.829 2.828a1.162 1.162 0 010 1.64l-2.829 2.829a1.162 1.162 0 01-1.64 0 1.22 1.22 0 01-.328-.82c0-.287.082-.574.328-.82zM8.757 20.692L6.749 22.7l2.009 2.009c.451.451.451 1.19 0 1.64a1.162 1.162 0 01-1.64 0L4.289 23.52a1.162 1.162 0 010-1.64l2.829-2.828a1.162 1.162 0 011.64 0c.205.205.328.533.328.82 0 .287-.082.573-.328.82z"
      opacity="0.3"
      transform="rotate(90 26.523 22.686)"
    />
  </svg>
);

export default NavigationIcon;
