import React from 'react';
import PropTypes from 'prop-types';

const OvalDash = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 610 610"
    width="610"
    height="610"
  >
    <circle
      cx="305"
      cy="305"
      r={isActive ? '301' : '161'}
      stroke="#33FF7A"
      strokeWidth="4"
      fill="none"
      strokeDasharray="0 36"
      strokeOpacity="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

OvalDash.propTypes = {
  isActive: PropTypes.bool
};

export default OvalDash;
