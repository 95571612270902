import React from 'react';
import cx from 'classnames';

import { discIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import DiscIcon from '../../vectors/DiscIcon';
import DiscBgIcon from '../../vectors/DiscBgIcon';

const DiscIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(136.15deg, #CCAE55 0%, #95690B 100%)"
    icon={<DiscIcon />}
    bgIcon={<DiscBgIcon />}
    className={cx(className, discIconBox)}
    {...props}
  />
);

export default DiscIconBox;
