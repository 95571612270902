import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MOBILE_WIDTH_BREAKPOINT as mobileWidthBreakpoint } from '../../config';

const ResponsiveRender = ({
  mobileComponent: MobileComponent,
  desktopComponent: DesktopComponent,
  ...props
}) => {
  const getIsMobile = () => {
    return window.innerWidth < mobileWidthBreakpoint;
  };
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const onResize = () => {
    setIsMobile(getIsMobile());
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isMobile ? (
    <MobileComponent {...props} />
  ) : (
    <DesktopComponent {...props} />
  );
};

ResponsiveRender.propTypes = {
  mobileComponent: PropTypes.func.isRequired,
  desktopComponent: PropTypes.func.isRequired
};

export default ResponsiveRender;
