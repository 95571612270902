import React from 'react';

import DiscIcon from '../../vectors/DiscIcon';
import DanceIcon from '../../vectors/DanceIcon';
import BoltIcon from '../../vectors/BoltIcon';
import SmileyIcon from '../../vectors/SmileyIcon';
import TempoIcon from '../../vectors/TempoIcon';

export const MAX_TRACKS_OPTIONS = [15, 25, 50, 100];

export const FILTERS_ICONS = {
  maxTracks: <DiscIcon />,
  danceability: <DanceIcon />,
  energy: <BoltIcon />,
  mood: <SmileyIcon />,
  tempo: <TempoIcon />
};
