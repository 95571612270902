import React from 'react';

import SectionIconBox from './SectionIconBox';
import DiscFlameIcon from '../../vectors/DiscFlameIcon';

const DiscFlameIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(139.13deg, #E43F3F 0%, #E46C3F 100%)"
    icon={<DiscFlameIcon />}
    {...props}
  />
);

export default DiscFlameIconBox;
