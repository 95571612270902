import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  analysis,
  isPassive,
  features,
  featureRing,
  activeRing,
  firstCycle,
  passiveRing,
  pauseRing,
  featureDetails,
  activeFeatureDetails,
  activeValueAnimatable,
  isActive,
  activeValueStartPosition,
  previousFeatureDetails,
  featureInfo,
  featureBackground,
  activeFeatureBg,
  previousFeatureBg
} from './MobileStreamingAnalysis.module.sass';
import DanceIcon from '../../vectors/DanceIcon';
import DanceLgIcon from '../../vectors/DanceLgIcon';
import SpeechIcon from '../../vectors/SpeechIcon';
import SpeechLgIcon from '../../vectors/SpeechLgIcon';
import InstrumentIcon from '../../vectors/InstrumentIcon';
import SmileyIcon from '../../vectors/SmileyIcon';
import SmileyLgIcon from '../../vectors/SmileyLgIcon';
import SoundWavesIcon from '../../vectors/SoundWavesIcon';
import SoundWavesLgIcon from '../../vectors/SoundWavesLgIcon';
import BoltIcon from '../../vectors/BoltIcon';
import BoltLgIcon from '../../vectors/BoltLgIcon';
import TempoIcon from '../../vectors/TempoIcon';
import TempoLgIcon from '../../vectors/TempoLgIcon';
import RadialProgress from '../../vectors/RadialProgress';
import InfoIcon from '../../vectors/InfoIcon';
import { mobileScrollViewListener } from '../../utils';

const Analysis = ({ audioFeatures }) => {
  const [containerInView, setContainerInview] = useState(false);
  const [canAnimateRing, setCanAnimateRing] = useState(false);
  const [activeRingIndex, setActiveRingIndex] = useState(0);
  const [previousRingIndex, setPreviousRingIndex] = useState(null);
  const [hasCompletedFirstCycle, setHasCompletedFirstCycle] = useState(false);
  const [detailsFocused, setDetailsFocused] = useState(false);
  const [animateValue, setAnimateValue] = useState(false);
  const [animateValueDelay, setAnimateValueDelay] = useState(800);
  const [canSwitchRing, setCanSwitchRing] = useState(false);

  const containerRef = useRef();
  const featureDetailsRef = useRef();
  const valueEndRef = useRef();
  const valueStartRef = useRef();
  const activeValueRef = useRef();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = '900 70px Circular Std';

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, 0.5, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnterView = () => {
    setContainerInview(true);
    setTimeout(() => setCanAnimateRing(true), 1000);
    updateValue();
  };

  const onRingIterationComplete = () => {
    setPreviousRing();
    setActiveRingIndex(activeRingIndex < 5 ? activeRingIndex + 1 : 0);
    setAnimateValue(false);
    setTimeout(updateValue, 100);
  };

  const onRingSelect = index => {
    if (index === activeRingIndex || !canSwitchRing) {
      return;
    }

    setPreviousRing();
    setActiveRingIndex(index);
    setAnimateValue(false);
    setTimeout(updateValue, 100);
  };

  const updateValue = () => {
    const featureDetails = featureDetailsRef.current;

    if (!featureDetails) {
      return;
    }

    const {
      top: detailsTop,
      left: detailsLeft
    } = featureDetails.getBoundingClientRect();
    const valueEndChars = valueEndRef.current.children;
    const valueStartChars = valueStartRef.current.children;
    const valueChars = activeValueRef.current.children;
    const endPos = [];
    const startPos = [];

    setCanSwitchRing(false);

    [...valueEndChars].forEach(char => {
      const { left } = char.getBoundingClientRect();
      endPos.push({
        top: 25,
        left: left - detailsLeft
      });
    });

    [...valueStartChars].forEach(char => {
      const { top, left } = char.getBoundingClientRect();
      startPos.push({
        top: top - detailsTop,
        left: left - detailsLeft
      });
    });

    [...valueChars].forEach((char, index) => {
      char.style.transform = `translate3d(${startPos[index].left}px, ${
        startPos[index].top
      }px, 0) scale3d(0, 0, 0)`;
    });

    setTimeout(() => {
      setAnimateValue(true);
      [...valueChars].forEach((char, index) => {
        char.style.transform = `translate3d(${endPos[index].left}px, ${
          endPos[index].top
        }px, 0) scale3d(1, 1, 1)`;
      });
    }, animateValueDelay);

    setTimeout(() => setCanSwitchRing(true), animateValueDelay + 1000);
    setAnimateValueDelay(300);
  };

  const setPreviousRing = () => {
    setPreviousRingIndex(activeRingIndex);
    !hasCompletedFirstCycle && setHasCompletedFirstCycle(true);
  };

  const getSum = (key, valueFunc) => {
    return audioFeatures.reduce(
      (acc, curr) =>
        acc + (curr ? (valueFunc ? valueFunc(curr[key]) : curr[key]) : 0),
      0
    );
  };

  const getKerningValue = (charA, charB) => {
    const charAWidth = ctx.measureText(charA).width;
    const charBWidth = ctx.measureText(charB).width;
    const kernedWidth = ctx.measureText(`${charA}${charB}`).width;
    return kernedWidth - (charAWidth + charBWidth);
  };

  const getTextWidth = text => {
    const chars = text.split('');
    return chars.reduce((acc, curr, index) => {
      const nextChar = chars[index + 1] || '';
      const charWidth = ctx.measureText(curr).width;
      const kerningValue = getKerningValue(curr, nextChar);
      return acc + charWidth + kerningValue - 2.33;
    }, 0);
  };

  const getCharsTranslateX = chars => {
    const pos = [0];
    chars.forEach((char, index) => {
      ctx.fillText(char, pos[index], 0);
      const nextChar = chars[index + 1] || '';
      const charWidth = ctx.measureText(char).width;
      const kerningValue = getKerningValue(char, nextChar);
      pos.push(pos[index] + charWidth + kerningValue - 2.33);
    });
    return pos;
  };

  const total = audioFeatures.length;
  const loudnessValue = val => Math.min(Math.max(val, -20), 0) / 20 + 1;
  const tempoValue = val => (Math.min(Math.max(val, 60), 170) - 60) / 110;

  const danceabilityTotal = getSum('danceability') / total;
  const speechinessTotal = getSum('speechiness') / total;
  const instrumentalnessTotal = getSum('instrumentalness') / total;
  const valenceTotal = getSum('valence') / total;
  const loudnessTotal = getSum('loudness', loudnessValue) / total;
  const energyTotal = getSum('energy') / total;
  const tempoTotal = getSum('tempo', tempoValue) / total;

  const moreSpeechiness = speechinessTotal > instrumentalnessTotal;

  const data = [
    {
      label: 'Danceability',
      value: (danceabilityTotal * 100).toFixed(1),
      icon: <DanceIcon />,
      bgIcon: <DanceLgIcon />,
      description:
        'Danceability describes how danceable a track is, based on its beat and rhythm.'
    },
    {
      label: 'Valence',
      value: (valenceTotal * 100).toFixed(1),
      icon: <SmileyIcon />,
      bgIcon: <SmileyLgIcon />,
      description:
        'Danceability describes how danceable a track is, based on its beat and rhythm.'
    },
    {
      label: 'Tempo',
      value: Math.round(tempoTotal * 110) + 60,
      icon: <TempoIcon />,
      bgIcon: <TempoLgIcon />,
      description: 'Tempo is the speed, pace or beat of any track.'
    },
    {
      label: 'Loudness',
      value: `${Math.round((loudnessTotal - 1) * 20)}db`,
      icon: <SoundWavesIcon />,
      bgIcon: <SoundWavesLgIcon />,
      description:
        'Danceability describes how danceable a track is, based on its beat and rhythm.'
    },
    {
      label: moreSpeechiness ? 'Speechiness' : 'Instrumentalness',
      value: (Math.max(speechinessTotal, instrumentalnessTotal) * 100).toFixed(
        1
      ),
      icon: moreSpeechiness ? <SpeechIcon /> : <InstrumentIcon />,
      bgIcon: <SpeechLgIcon />,
      description: moreSpeechiness
        ? 'Speechiness defines how many spoken words exist on a track.'
        : 'Instrumentalness defines whether a track contains no vocals.'
    },
    {
      label: 'Energy',
      value: (energyTotal * 100).toFixed(1),
      icon: <BoltIcon />,
      bgIcon: <BoltLgIcon />,
      description: 'Energy describes how intense, upbeat, or chill a track is.'
    }
  ];

  const { label: activeLabel, value: activeValue, bgIcon: activeBgIcon } = data[
    activeRingIndex
  ];
  const { label: previousLabel, value: previousValue, bgIcon: previousBgIcon } =
    data[previousRingIndex] || {};

  const activeValueChars = activeValue.toString().split('');

  return (
    <div
      ref={containerRef}
      className={cx(analysis, { [isPassive]: !containerInView })}
    >
      <div className={features}>
        {data.map(({ icon }, index) => (
          <div
            onClick={() => onRingSelect(index)}
            className={cx(featureRing, {
              [activeRing]: activeRingIndex === index,
              [firstCycle]: !hasCompletedFirstCycle,
              [passiveRing]: !canAnimateRing,
              [pauseRing]: detailsFocused
            })}
            key={index}
          >
            <RadialProgress onAnimationEnd={onRingIterationComplete} />
            <span>{icon}</span>
          </div>
        ))}
        <div
          ref={featureDetailsRef}
          className={cx(featureDetails, {
            [firstCycle]: !hasCompletedFirstCycle
          })}
          onTouchStart={() => setDetailsFocused(true)}
          onTouchEnd={() => setDetailsFocused(false)}
        >
          <div key={activeRingIndex} className={activeFeatureDetails}>
            <h4>
              <span>{activeLabel}</span>
            </h4>
            <h2
              ref={valueEndRef}
              style={{
                transform: `translateX(-${getTextWidth(
                  activeValueChars.join('')
                ) / 2}px)`
              }}
            >
              {activeValueChars.map((char, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      transform: `translateX(${
                        getCharsTranslateX(activeValueChars)[index]
                      }px)`
                    }}
                  >
                    {char}
                  </span>
                );
              })}
            </h2>
          </div>
          <div
            ref={activeValueRef}
            className={cx(activeValueAnimatable, { [isActive]: animateValue })}
          >
            {activeValueChars.map((char, index) => (
              <span key={index}>{char}</span>
            ))}
          </div>
          <div ref={valueStartRef} className={activeValueStartPosition}>
            {activeValueChars.map((char, index) => (
              <span key={index}>{char}</span>
            ))}
          </div>
          {previousLabel && (
            <div key={previousRingIndex} className={previousFeatureDetails}>
              <h4>
                <span>{previousLabel}</span>
              </h4>
              <h2>
                <span>{previousValue}</span>
              </h2>
            </div>
          )}
        </div>
        <span className={featureInfo}>
          <InfoIcon />
        </span>
        <div className={featureBackground}>
          <span
            key={activeRingIndex}
            className={cx(activeFeatureBg, {
              [firstCycle]: !hasCompletedFirstCycle
            })}
          >
            {activeBgIcon}
          </span>
        </div>
        <div className={featureBackground}>
          <span key={previousRingIndex} className={previousFeatureBg}>
            {previousBgIcon}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserConsumer(Analysis);
