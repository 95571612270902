import React from 'react';

import SectionIconBox from './SectionIconBox';
import TieIcon from '../../vectors/TieIcon';

const TieIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(317.59deg, #FFE433 0%, #EE6E11 46%, #FA9917 100%)"
    icon={<TieIcon />}
    {...props}
  />
);

export default TieIconBox;
