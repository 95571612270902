import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { shareCard } from './ShareCard.module.sass';
import { TYPES as types } from './ShareCard.constants';

const ShareCard = ({ onClick, type, className }) => {
  const { label, icon: Icon } = types[type];
  return (
    <div className={cx(shareCard, className)} onClick={onClick}>
      <Icon />
      <span>{label}</span>
    </div>
  );
};

ShareCard.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['facebook', 'twitter', 'instagram', 'copyLink']),
  className: PropTypes.string
};

export default ShareCard;
