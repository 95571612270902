import React from 'react';
import cx from 'classnames';

import { boltIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import BoltIcon from '../../vectors/BoltIcon';
import BoltBgIcon from '../../vectors/BoltBgIcon';

const BoltIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(131.92deg, #CAB000 0%, #FA9917 100%)"
    icon={<BoltIcon />}
    bgIcon={<BoltBgIcon />}
    className={cx(className, boltIconBox)}
    {...props}
  />
);

export default BoltIconBox;
