import React from 'react';

import { footer, navigationTip, navigationTipIcon } from './Footer.module.sass';
import NavigationIcon from '../../vectors/NavigationIcon';

const Footer = () => (
  <footer className={footer}>
    <div className={navigationTip}>
      <span className={navigationTipIcon}>
        <NavigationIcon />
      </span>
      <span>Swipe or scroll to navigate</span>
    </div>
  </footer>
);

export default Footer;
