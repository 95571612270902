import React, { Component, createRef } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  playlistCreator,
  settingsToggle,
  isActive,
  regenerateLoader,
  playlistData,
  playlistTitle,
  activeFilters,
  playlistSourceTag,
  playlistTag
} from './PlaylistCreator.module.sass';
import PlaylistSummary from './PlaylistSummary';
import PlaylistFilterForm from './PlaylistFilterForm';
import TrackCard from './TrackCard';
import RadialLoader from '../RadialLoader';
import {
  getImageUrl,
  preloadImages,
  getPlaylistName,
  getPlaylistCoverOptions,
  createPlaylistCover
} from '../../utils';
import SettingsIcon from '../../vectors/SettingsIcon';
import {
  MAX_TRACKS_OPTIONS as maxTracksOptions,
  FILTERS_ICONS as filtersIcons
} from './PlaylistCreator.constant';

class PlaylistCreator extends Component {
  state = {
    isLoading: true,
    stickyColumnTab: 0,
    coverUrl: '',
    filterFormValues: {
      maxTracks: maxTracksOptions[1],
      danceability: null,
      energy: null,
      mood: null,
      tempo: null
    },
    audioFeaturesFilters: {
      danceability: null,
      energy: null,
      mood: null,
      tempo: null
    },
    tracks: []
  };

  stickyColumnRef = createRef();

  setListData = async () => {
    const { scrollTo, resetModalScroll, getPlaylists } = this.props;

    this.setState(
      {
        isRegenerating: true,
        tracks: []
      },
      () => {
        scrollTo(0, false);
      }
    );

    try {
      await this.setTracks();
      await this.loadImages();
      await this.createPlaylistCover();

      const playlists = await getPlaylists();
      const previouslyCreated = playlists.find(
        ({ name }) => name === this.getPlaylistName()
      );

      this.setState(
        {
          previouslyCreated,
          isLoading: false,
          isRegenerating: false
        },
        () => resetModalScroll(false, true)
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        isRegenerating: false
      });
    }
  };

  setTracks = () => {
    const { activePlaylistType } = this.props;
    const source = {
      shortTermTopTracks: () =>
        this.getTermTopRecommendations('shortTermTopTracks'),
      mediumTermTopTracks: () =>
        this.getTermTopRecommendations('mediumTermTopTracks'),
      longTermTopTracks: () =>
        this.getTermTopRecommendations('longTermTopTracks'),
      track: this.getTrackRecommendations,
      artist: this.getArtistRecommendations
    };

    return source[activePlaylistType]();
  };

  getTermTopRecommendations = term => {
    const termTracks = this.props[term];
    const { maxTracks } = this.state.filterFormValues;
    return this.setState({ tracks: termTracks.slice(0, maxTracks) });
  };

  getTrackRecommendations = () => {
    const { activePlaylistData, getRecommendations } = this.props;
    const recommendationData = {
      seed_tracks: activePlaylistData.id,
      ...this.getRecommendationsFilters()
    };
    return getRecommendations(recommendationData, tracks =>
      this.setState({ tracks })
    );
  };

  getArtistRecommendations = () => {
    const { activePlaylistData, getRecommendations } = this.props;
    const recommendationData = {
      seed_artists: activePlaylistData.id,
      ...this.getRecommendationsFilters()
    };
    return getRecommendations(recommendationData, tracks =>
      this.setState({ tracks })
    );
  };

  getRecommendationsFilters = () => {
    const {
      maxTracks,
      danceability,
      energy,
      mood,
      tempo
    } = this.state.filterFormValues;
    return {
      limit: maxTracks,
      min_danceability: danceability === '+' ? 0.51 : 0,
      max_danceability: danceability === '-' ? 0.5 : 1,
      min_energy: energy === '+' ? 0.51 : 0,
      max_energy: energy === '-' ? 0.5 : 1,
      min_valence: mood === '+' ? 0.51 : 0,
      max_valence: mood === '-' ? 0.5 : 1,
      min_tempo: tempo === '+' ? 120.1 : 0,
      max_tempo: tempo === '-' ? 120 : 500
    };
  };

  updateFilters = (filterFormValues, audioFeaturesFilters) => {
    this.setState({ filterFormValues, audioFeaturesFilters }, this.setListData);
  };

  getListTitle = () => {
    const { activePlaylistType, activePlaylistData = {} } = this.props;
    const { name } = activePlaylistData;

    const listsData = {
      shortTermTopTracks: {
        title: 'Top Tracks Playlist',
        type: 'Favourites'
      },
      mediumTermTopTracks: {
        title: 'Top Tracks Playlist',
        type: 'Favourites'
      },
      longTermTopTracks: {
        title: 'Top Tracks Playlist',
        type: 'Favourites'
      },
      track: {
        title: `${name} Playlist`,
        type: 'Favourites'
      },
      artist: {
        title: `${name} Playlist`,
        type: 'Favourites'
      }
    };

    return listsData[activePlaylistType];
  };

  loadImages = () => {
    const { tracks } = this.state;
    const tracksWithCovers = tracks.filter(({ album }) =>
      getImageUrl(album.images)
    );
    const albumCovers = tracksWithCovers.map(({ album }) =>
      getImageUrl(album.images)
    );

    return preloadImages(albumCovers);
  };

  createPlaylistCover = async () => {
    const {
      getArtists,
      activePlaylistType,
      activePlaylistData = {}
    } = this.props;
    const { tracks } = this.state;
    const { name, cover } = activePlaylistData;

    if (activePlaylistType === 'artist') {
      let artists = [];
      const artistIds = tracks.map(({ artists }) => artists[0].id);
      const uniqueIds = [...new Set(artistIds)].slice(0, 50);
      await getArtists(uniqueIds.join(), data => (artists = data));
      const artistsWithCovers = artists.filter(({ images }) =>
        getImageUrl(images)
      );
      const covers = artistsWithCovers.map(({ images }) => getImageUrl(images));
      cover && covers.unshift(cover);
      const coverImages = [...new Set(covers)].slice(0, 4);

      const coverOptions = {
        coverImages,
        ...getPlaylistCoverOptions(activePlaylistType, name)
      };
      const coverUrl = await createPlaylistCover(coverOptions);
      return this.setState({ coverUrl });
    }

    const tracksWithCovers = tracks.filter(({ album }) =>
      getImageUrl(album.images)
    );
    const covers = tracksWithCovers.map(({ album }) =>
      getImageUrl(album.images)
    );
    cover && covers.unshift(cover);
    const coverImages = [...new Set(covers)].slice(0, 4);

    const coverOptions = {
      coverImages,
      ...getPlaylistCoverOptions(activePlaylistType, name)
    };

    const coverUrl = await createPlaylistCover(coverOptions);
    this.setState({ coverUrl });
  };

  removeFromTracksList = removeId => {
    const { tracks } = this.state;
    const removedIndex = tracks.findIndex(({ id }) => id === removeId);
    const newTracks = tracks.filter(({ id }) => id !== removeId);
    this.setState(
      {
        removedIndex,
        tracks: newTracks
      },
      () => {
        this.props.resetModalScroll(false, true);
        this.createPlaylistCover();
      }
    );

    setTimeout(() => this.setState({ removedIndex: null }), 400);
  };

  getPlaylistName = () => {
    const { activePlaylistType, activePlaylistData = {} } = this.props;

    return getPlaylistName(activePlaylistType, activePlaylistData.name);
  };

  createPlaylist = () => {
    const { tracks, coverUrl } = this.state;
    const { createPlaylist } = this.props;

    this.setState({ isSavingPlaylist: true });

    const data = {
      name: this.getPlaylistName(),
      description: 'Created with Cruuunchify'
    };
    const tracksUris = tracks.map(({ uri }) => uri);
    const cover = coverUrl.split('base64,')[1];

    createPlaylist(data, tracksUris, cover, this.onSave, this.onSaveError);
  };

  updatePlaylist = () => {
    const { previouslyCreated, tracks, coverUrl } = this.state;
    const { updatePlaylist } = this.props;

    this.setState({ isSavingPlaylist: true });

    const tracksUris = tracks.map(({ uri }) => uri);
    const cover = coverUrl.split('base64,')[1];

    updatePlaylist(
      previouslyCreated.id,
      tracksUris,
      cover,
      this.onSave,
      this.onSaveError
    );
  };

  onSave = previouslyCreated => {
    previouslyCreated && this.setState({ previouslyCreated });
    setTimeout(() => this.setState({ isSavingPlaylist: false }), 1300);
  };

  onSaveError = () => {
    setTimeout(() => {
      this.setState({
        isSavingPlaylist: false,
        playlistSaveError: true
      });
    }, 1300);
  };

  resetSaveError = () => {
    this.setState({ playlistSaveError: false });
  };

  setStickyColumnTab = tabIndex => {
    const { stickyColumnTab } = this.state;
    this.setState({ willSwitchTab: true });

    setTimeout(() => {
      this.setState({
        stickyColumnTab: tabIndex === stickyColumnTab ? 0 : tabIndex,
        willSwitchTab: false
      });
    }, 100);
  };

  componentDidMount() {
    const { addToSticky } = this.props;

    this.setListData();
    this.stickyColumn = this.stickyColumnRef.current;
    addToSticky(this.stickyColumn);
  }

  componentWillUnmount() {
    const { removeFromSticky } = this.props;

    removeFromSticky(this.stickyColumn);
  }

  render() {
    const {
      isLoading,
      isRegenerating,
      isSavingPlaylist,
      playlistSaveError,
      stickyColumnTab,
      willSwitchTab,
      coverUrl,
      previouslyCreated,
      filterFormValues,
      audioFeaturesFilters,
      tracks,
      removedIndex
    } = this.state;
    const { freezeScroll } = this.props;
    const { title, type } = this.getListTitle();
    const { maxTracks } = filterFormValues;

    const audioFeaturesTags = Object.entries(audioFeaturesFilters).filter(
      ([label, value]) => value
    );
    const filtersOpen = stickyColumnTab === 1;

    return (
      <div className={playlistCreator}>
        <RadialLoader isLoading={isLoading}>
          <div ref={this.stickyColumnRef}>
            {!isLoading && (
              <>
                <div className={settingsToggle}>
                  <span
                    className={cx({ [isActive]: filtersOpen })}
                    onClick={() => this.setStickyColumnTab(1)}
                  >
                    <SettingsIcon /> Playlist Settings
                  </span>
                </div>

                {!filtersOpen && (
                  <PlaylistSummary
                    coverUrl={coverUrl}
                    playlistUri={previouslyCreated && previouslyCreated.uri}
                    onPlaylistButtonClick={
                      previouslyCreated
                        ? this.updatePlaylist
                        : this.createPlaylist
                    }
                    isLoading={isSavingPlaylist}
                    saveError={playlistSaveError}
                    resetSaveError={this.resetSaveError}
                    willExit={willSwitchTab}
                  />
                )}

                {filtersOpen && (
                  <PlaylistFilterForm
                    formValues={filterFormValues}
                    audioFeaturesFilters={audioFeaturesFilters}
                    onSubmit={this.updateFilters}
                    close={() => this.setStickyColumnTab(0)}
                    freezeScroll={freezeScroll}
                    willExit={willSwitchTab}
                  />
                )}
              </>
            )}
          </div>
          <div className={playlistData}>
            {!isLoading && (
              <RadialLoader
                className={regenerateLoader}
                isLoading={isRegenerating}
                renderChildrenOnLoad={true}
              >
                <div>
                  <h1 className={playlistTitle}>
                    <span>{title}</span>
                  </h1>
                  <div className={activeFilters}>
                    <span className={playlistSourceTag}>{type}</span>
                    <span className={playlistTag}>
                      {filtersIcons.maxTracks} {maxTracks} Tracks
                    </span>
                    {audioFeaturesTags.map(([label, value], index) => (
                      <span key={index} className={playlistTag}>
                        {filtersIcons[label]} {value}
                      </span>
                    ))}
                  </div>
                  <div>
                    {tracks.map(
                      (
                        { album, preview_url, uri, name, artists, id },
                        index
                      ) => (
                        <TrackCard
                          key={id}
                          albumCover={getImageUrl(album.images)}
                          index={index}
                          name={name}
                          artists={artists}
                          previewUrl={preview_url}
                          uri={uri}
                          id={id}
                          removedIndex={removedIndex}
                          remove={this.removeFromTracksList}
                          renderDelay={500 + index * 100}
                        />
                      )
                    )}
                  </div>
                </div>
              </RadialLoader>
            )}
          </div>
        </RadialLoader>
      </div>
    );
  }
}

export default UserConsumer(PlaylistCreator);
