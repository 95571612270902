export const RANGE_OPTIONS = {
  shortTerm: {
    label: 'RECENT',
    description: 'in the past 30 days'
  },
  mediumTerm: {
    label: 'PAST 6 MONTHS',
    description: 'in the past 6 months'
  },
  longTerm: {
    label: 'ALL TIME',
    description: 'of all time'
  }
};

export const PLAYLIST_TYPES = {
  tracks: {
    shortTerm: 'shortTermTopTracks',
    mediumTerm: 'mediumTermTopTracks',
    longTerm: 'longTermTopTracks'
  },
  artists: {}
};

export const SHARE_TIME_RANGES = {
  shortTerm: 'Recent',
  mediumTerm: 'Past 6 Months',
  longTerm: 'All Time'
};

export const ORDER_KEYS = {
  0: 'rank',
  1: 'popularity',
  2: 'title'
};
