import React from 'react';

const MobileExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="70" width="63.99999999999977">
    <g transform="translate(1.1368683772161603e-13, 3.552713678800501e-14)">
      <g>
        <path
          fill="rgb(51,255,122)"
          fillRule="evenodd"
          d="M58.499999999999986,4 L52.749999999999986,4 L52.749999999999986,7 L56.999999999999986,7 L56.999999999999986,11.25 L59.999999999999986,11.25 L59.999999999999986,5.5 C59.999999999999986,4.674799999999999 59.32519999999998,4 58.499999999999986,4Z"
        />
      </g>
      <g>
        <path
          fill="rgb(51,255,122)"
          fillRule="evenodd"
          d="M4,5.5 L4,11.25 L7,11.25 L7,7 L11.25,7 L11.25,4 L5.5,4 C4.674799999999999,4 4,4.674799999999999 4,5.5Z"
        />
      </g>
      <g>
        <path
          fill="rgb(51,255,122)"
          fillRule="evenodd"
          d="M11.25,63.000000000000014 L7,63.000000000000014 L7,58.750000000000014 L4,58.750000000000014 L4,64.50000000000001 C4,65.32520000000001 4.674799999999999,66.00000000000001 5.5,66.00000000000001 L11.25,66.00000000000001 L11.25,63.000000000000014Z"
        />
      </g>
      <g>
        <path
          fill="rgb(51,255,122)"
          fillRule="evenodd"
          d="M56.999999999999986,63.000000000000014 L52.749999999999986,63.000000000000014 L52.749999999999986,66.00000000000001 L58.499999999999986,66.00000000000001 C59.32519999999998,66.00000000000001 59.999999999999986,65.32520000000001 59.999999999999986,64.50000000000001 L59.999999999999986,58.750000000000014 L56.999999999999986,58.750000000000014 L56.999999999999986,63.000000000000014Z"
        />
      </g>
    </g>
  </svg>
);

export default MobileExpandIcon;
