import React from 'react';

const MicBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    height="130"
    width="130"
  >
    <defs>
      <linearGradient id="Lnfjs6gTcx" x1="0.07" x2="0.51" y1="0.05" y2="0.51">
        <stop offset="0" stopColor="rgb(255,255,255)" stopOpacity="0" />
        <stop offset="1" stopColor="rgb(255,255,255)" stopOpacity="1" />
      </linearGradient>
    </defs>
    <g opacity="1">
      <g opacity="1">
        <path
          opacity="1"
          fillRule="evenodd"
          d="M35.82576370239258,54.51974964141846 L56.08027648925781,23.738285064697266 C54.443912506103516,19.196434020996094 51.416053771972656,15.111920356750488 47.0674934387207,12.251639366149902 C36.424766540527344,5.2487897872924805 22.122699737548828,8.20588207244873 15.120016098022461,18.848609924316406 C8.117167472839355,29.48490619659424 11.061394691467285,43.7802095413208 21.710556030273438,50.7960901260376 C26.059112548828125,53.65010166168213 31.000408172607422,54.81611728668213 35.82576370239258,54.51974964141846 L65.37830352783203,129.99999713897705 L76.37001419067383,129.74172687530518 L76.37001419067383,93.5592622756958 C79.39142227172852,93.5592622756958 81.86528396606445,91.08539295196533 81.86528396606445,88.06397724151611 L81.86528396606445,77.6081132888794 L105.27078628540039,89.03602313995361 C109.46792984008789,91.08530902862549 114.53361892700195,89.72128009796143 117.1342658996582,85.84154224395752 C119.72012710571289,81.98387622833252 119.00001907348633,76.80000019073486 115.46062088012695,73.79319477081299 L67.42105865478516,32.98257923126221 L48.7120475769043,61.41882038116455 L59.87659454345703,66.87550449371338 L59.87659454345703,88.0715684890747 C59.87659454345703,91.09298419952393 62.350460052490234,93.56684589385986 65.37830352783203,93.56684589385986 L65.37830352783203,129.99999713897705 L35.82576370239258,54.51974964141846Z"
          fill="url(#Lnfjs6gTcx)"
          fillOpacity="1"
        />
      </g>
    </g>
  </svg>
);

export default MicBgIcon;
