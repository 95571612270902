import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  userSummary,
  topGenreCaption,
  greenText,
  topGenreSubcaption
} from './MobileTopGenre.module.sass';
import GenreBar from './GenreBar';
import { GENRE_LEVEL_COLORS as levelColors } from '../../config';

const MobileTopGenre = ({ topGenres }) => {
  return (
    <section className={container}>
      <div className={userSummary}>
        <h1 className={topGenreCaption}>
          <span>
            <span>Once you pop, the</span>{' '}
            <span className={greenText}>fun don't stop!</span>
          </span>
        </h1>
        <p className={topGenreSubcaption}>
          <span>Pop is your top genre.</span>
        </p>
      </div>
      {topGenres.slice(0, 5).map(({ name, percentage }, index) => (
        <GenreBar
          key={index}
          name={name}
          level={percentage}
          levelColor={levelColors[index]}
        />
      ))}
    </section>
  );
};

export default UserConsumer(MobileTopGenre);
