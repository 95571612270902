import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import Modal from './Modal';

const ModalWrapper = ({
  activeArtist,
  activeTrack,
  activeTopList,
  activePlaylistType,
  activeSuggestionPlaylist
}) => {
  const modalItems = [
    activeArtist,
    activeTrack,
    activeTopList,
    activePlaylistType,
    activeSuggestionPlaylist
  ];
  const openModal = modalItems.find(item => item);
  return <>{openModal && <Modal />}</>;
};

export default UserConsumer(ModalWrapper);
