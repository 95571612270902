import React, { useState } from 'react';

import {
  shareMenu,
  sharePlatform,
  shareCard,
  copyToast
} from './Header.module.sass';
import Dropdown from '../Dropdown';
import ShareCard from '../ShareCard';
import {
  APP_URL as appUrl,
  FACEBOOK_SHARE_URL as facebookUrl,
  TWITTER_SHARE_URL as twitterUrl
} from '../../config';
import { copyStringToClipboard } from '../../utils';

const ShareMenu = ({ closeModal }) => {
  const [hasCopied, setHasCopied] = useState(false);

  const shareToFacebook = () => {
    const facebookLink = `${facebookUrl}${appUrl}`;
    window.open(facebookLink, '_blank');
  };

  const shareToTwitter = () => {
    const twitterLink = `${twitterUrl}${appUrl}`;
    window.open(twitterLink, '_blank');
  };

  const copyLinkToClipboard = () => {
    !hasCopied && copyStringToClipboard(appUrl);
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 5000);
  };

  return (
    <Dropdown className={shareMenu} close={closeModal}>
      <div>
        <h3>Share cruuunchify</h3>
        <p>Choose your prefered social platform</p>
        <div className={sharePlatform}>
          <ShareCard
            className={shareCard}
            type="facebook"
            onClick={shareToFacebook}
          />
          <ShareCard
            className={shareCard}
            type="twitter"
            onClick={shareToTwitter}
          />
          <ShareCard
            className={shareCard}
            type="copyLink"
            onClick={copyLinkToClipboard}
          />
        </div>
        {hasCopied && <span className={copyToast}>Copied!</span>}
      </div>
    </Dropdown>
  );
};

export default ShareMenu;
