import React from 'react';
import PropTypes from 'prop-types';

import {
  featureCard,
  featureSummary,
  featureLabel,
  featureValue,
  progressBar
} from './MobileAudioFeatureCard.module.sass';

const MobileAudioFeatureCard = ({ label, value, icon, progress }) => (
  <div className={featureCard}>
    <span>{icon}</span>
    <div className={featureSummary}>
      <span className={featureLabel}>{label}</span>
      <h3 className={featureValue}>{value}</h3>
    </div>
    <div className={progressBar}>
      <div style={{ width: `${progress}%` }} />
    </div>
  </div>
);

MobileAudioFeatureCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  icon: PropTypes.node,
  progress: PropTypes.number
};

export default MobileAudioFeatureCard;
