import React from 'react';

import SectionIconBox from './SectionIconBox';
import ButterflyIcon from '../../vectors/ButterflyIcon';

const ButterflyIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(139.13deg, #E43F3F 0%, #E46C3F 100%)"
    icon={<ButterflyIcon />}
    {...props}
  />
);

export default ButterflyIconBox;
