import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import { TRACK_CARDS_CLASS_NAMES as cardsClassNames } from './TopTracks.constants';

import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  sectionIcon,
  userSummary,
  isPassive,
  userSummaryContent,
  topTrackCaption,
  topTrackArtistName,
  topTrackSubcaption,
  topTrackName,
  whipPlaylist,
  topModalButton
} from './TopTracks.module.sass';
import TrackCard from './TrackCard';
import DrumPads from '../DrumPads';
import PlaylistCard from '../PlaylistCard';
import { TopModalButton } from '../Button';
import { MusicIconBox } from '../SectionIconBox';
import { getImageUrl, scrollViewListener } from '../../utils';
import { SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction } from '../../config';

const TopTracks = ({
  shortTermTopTracks,
  setActiveTopList,
  setActivePlaylist,
  setActiveSuggestionPlaylist
}) => {
  const [summaryInView, setSummaryInview] = useState(false);
  const summaryRef = useRef();

  const tracks = shortTermTopTracks
    .slice(0, 5)
    .map(({ id, uri, name, album, artists, explicit, preview_url }) => ({
      id,
      uri,
      artists,
      explicit,
      title: name,
      albumCover: getImageUrl(album.images),
      previewUrl: preview_url
    }));

  const albumCovers = shortTermTopTracks.map(({ album }) =>
    getImageUrl(album.images)
  );
  const playlistCovers = [...new Set(albumCovers)].slice(0, 4);
  const topTrackArtist = tracks[0].artists[0].name;

  const openTopTracksPlaylistCreator = () => {
    setActivePlaylist('shortTermTopTracks');
  };

  const suggestionPlaylistData = {
    coverImages: playlistCovers,
    titleOne: 'Top Tracks',
    titleTwo: 'Playlist',
    summary: `A fresh playlist we think you'll like based on your top tracks`,
    createNewAction: openTopTracksPlaylistCreator
  };

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  return (
    <section className={container}>
      <MusicIconBox className={sectionIcon} isPassive={!summaryInView} />
      <div
        ref={summaryRef}
        className={cx(userSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={42} />
        <div className={userSummaryContent}>
          <h1 className={topTrackCaption}>
            <span>
              <span className={topTrackArtistName}>{topTrackArtist}</span>{' '}
              <span>has been on repeat</span>
            </span>
          </h1>
          <p className={topTrackSubcaption}>
            <span>
              Yeah, you have other tracks you love to listen to, but{' '}
              <span className={topTrackName}>{tracks[0].title}</span> by{' '}
              {topTrackArtist} just hits different!
            </span>
          </p>
        </div>
      </div>
      {tracks.map((track, index) => (
        <TrackCard
          key={index}
          className={cardsClassNames[index]}
          number={index + 1}
          {...track}
        />
      ))}
      <PlaylistCard
        className={whipPlaylist}
        summary="A fresh playlist just for you."
        covers={playlistCovers}
        action={() => setActiveSuggestionPlaylist(suggestionPlaylistData)}
      />
      <TopModalButton
        className={topModalButton}
        onClick={() => setActiveTopList('tracks')}
      />
      <DrumPads total={200} />
    </section>
  );
};

export default UserConsumer(TopTracks);
