import Request from '../../utils/request';

export const userProfile = new Request('/me');
export const userTopTracks = new Request('/me/top/tracks');
export const userTopArtists = new Request('/me/top/artists');
export const userPlaylists = new Request('/me/playlists');
export const audioFeatures = new Request('/audio-features');
export const artists = new Request('/artists');
export const tracks = new Request('/tracks');
export const recommendations = new Request('/recommendations');
export const users = new Request('/users');
export const playlists = new Request('/playlists');
