import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  titleCard,
  isPassive,
  cardIcon,
  cardTitle,
  cardContent
} from './TitleCard.module.sass';
import { modalScrollViewListener } from '../../utils';

const TitleCard = ({
  icon,
  title,
  content,
  className,
  renderDelay,
  columnDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const cardRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : columnDelay);
  };

  useEffect(() => {
    modalScrollViewListener(cardRef.current, 0.3, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx(titleCard, className, {
        [isPassive]: !isReady
      })}
      ref={cardRef}
    >
      <span className={cardIcon}>{icon}</span>
      <div>
        <h4 className={cardTitle}>{title}</h4>
        <p className={cardContent}>{content}</p>
      </div>
    </div>
  );
};

TitleCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

TitleCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default TitleCard;
