import React, { Component } from 'react';
import cx from 'classnames';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { cursor, cursorRing, hover, focus } from './Mouse.module.sass';
import {
  ON_GRID_CLASS_STRINGS as onGridClassStrings,
  ON_GRID_CLASS_BASE_STRINGS as onGridClassBaseStrings
} from './Mouse.constants';

class Mouse extends Component {
  state = {};

  setCursorPosition = e => {
    this.setState({
      cursorPosition: {
        transform: `translate3d(${e.clientX}px, ${e.clientY}px, 0)`
      }
    });
  };

  setCursorStyle = e => {
    const { onGrid } = this.state;
    const className = e.target.className;
    const nodeName = e.target.nodeName;
    const isGridElement =
      typeof className === 'string'
        ? nodeName === 'SECTION' ||
          onGridClassStrings.find(classString =>
            className.includes(classString)
          )
        : onGridClassBaseStrings.find(classBaseString =>
            className.baseVal.includes(classBaseString)
          );

    if (isGridElement && !onGrid) {
      this.setState({ onGrid: true });
    } else if (!isGridElement && onGrid) {
      this.setState({ onGrid: false });
    }
  };

  onMouseDown = () => {
    this.setState({ mouseFocus: true });
  };

  onMouseUp = () => {
    this.setState({ mouseFocus: false });
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.setCursorPosition);
    window.addEventListener('mouseover', this.setCursorStyle);
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  render() {
    const { cursorPosition, onGrid, mouseFocus } = this.state;

    return (
      <>
        <div
          className={cx(cursor, { [hover]: !onGrid, [focus]: mouseFocus })}
          style={cursorPosition}
        />
        <div
          className={cx(cursorRing, { [hover]: !onGrid })}
          style={cursorPosition}
        />
      </>
    );
  }
}

export default ExperienceConsumer(Mouse);
