import React from 'react';

const SpeechLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient id="Lkdk7uaSDaxN" x1="0.81" x2="0.51" y1="0" y2="0.57">
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lkdk7uaSDaxN)"
      fillRule="evenodd"
      d="M79.895 568.4l1.675-.42 99.138-51.842c34.547 20.277 74.077 30.987 114.398 30.987 38.91 0 81.662-12.202 104.894-24.408 23.232-12.206 27.263-17.744 37.015-25.603a231.853 231.853 0 0018.05-16.17c42.772-42.802 66.342-99.693 66.342-160.087 0-34.374-7.638-67.894-22.438-98.46-5.392-11.13-11.802-21.927-19.182-32.324-7.502-10.526-15.715-20.269-24.617-29.199-42.738-42.737-99.655-66.316-160.06-66.316-60.419 0-117.305 23.561-160.021 66.278-75.57 75.564-87.883 194.38-29.549 283.63l-51.109 96.317L54 542.5c-3.94 15.649 10.247 29.838 25.896 25.9zm450.418 90.771c31.012 9.482 63.717 9.82 94.654.982l47.227 41.563 1.12.676c13.595 8.207 31.03-1.063 31.826-16.924l.089-1.758-11.914-62.068c55.517-51.69 69.367-133.888 33.7-201.01-20.939-39.426-56.1-68.418-98.811-81.476a167.85 167.85 0 00-24.637-5.562c-13.753-2.024-25.642 9.634-23.89 23.425 3.664 28.817 1.15 58.02-7.42 86.05-16.076 52.584-51.61 95.678-100.173 121.48-4.602 2.431-9.592 4.807-15.096 7.186-12.725 5.5-16.724 21.604-8.048 32.417 20.952 26.116 49.117 45.158 81.373 55.02z"
      opacity="0.1"
    />
  </svg>
);

export default SpeechLgIcon;
