import React from 'react';

const SmileyBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    width="130"
    height="130"
  >
    <defs>
      <linearGradient id="Lf8347BDEft" x1="0.5" x2="0.5" y1="0" y2="1">
        <stop offset="0" stopColor="#FFF" stopOpacity="0" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lf8347BDEft)"
      fillRule="evenodd"
      d="M21.096 65c0-24.277 19.627-43.903 43.904-43.903 24.277 0 43.904 19.626 43.904 43.903 0 24.277-19.627 43.903-43.904 43.903-24.277 0-43.904-19.626-43.904-43.903zm16.273-9.039c0 1.034.903 1.937 1.937 1.937h16.01c1.16 0 1.937-.777 1.937-1.937 0-5.034-4.52-9.296-9.942-9.296-5.422 0-9.942 4.13-9.942 9.296zm37.315 1.937h16.01c1.034 0 1.937-.777 1.937-1.937 0-5.165-4.52-9.296-9.942-9.296-5.422 0-9.942 4.13-9.942 9.296 0 1.16.903 1.937 1.937 1.937zM65 93.54a22.541 22.541 0 01-22.597-22.598h45.194A22.541 22.541 0 0165 93.54z"
    />
  </svg>
);

export default SmileyBgIcon;
