import React from 'react';
import PropTypes from 'prop-types';

const ExpandIcon = ({ cornerClassName }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65 65"
    height="65"
    width="65"
  >
    <g opacity="1">
      <g className={cornerClassName} opacity="1">
        <path
          id="pG7erTBY"
          opacity="1"
          fillRule="evenodd"
          d="M59.500000000000156,4 L53.750000000000156,4 L53.750000000000156,7 L58.000000000000156,7 L58.000000000000156,11.25 L61.000000000000156,11.25 L61.000000000000156,5.5 C61.000000000000156,4.674799999999999 60.32520000000015,4 59.500000000000156,4Z"
          fill="rgb(122,129,133)"
          fillOpacity="1"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          id="phYVgv23H"
          opacity="1"
          fillRule="evenodd"
          d="M4,5.5 L4,11.25 L7,11.25 L7,7 L11.25,7 L11.25,4 L5.5,4 C4.674799999999999,4 4,4.674799999999999 4,5.5Z"
          fill="rgb(122,129,133)"
          fillOpacity="1"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          id="pNY234Ybv"
          opacity="1"
          fillRule="evenodd"
          d="M11.25,57.99999999999996 L7,57.99999999999996 L7,53.74999999999996 L4,53.74999999999996 L4,59.49999999999996 C4,60.32519999999995 4.674799999999999,60.99999999999996 5.5,60.99999999999996 L11.25,60.99999999999996 L11.25,57.99999999999996Z"
          fill="rgb(122,129,133)"
          fillOpacity="1"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          id="pnYGvr23"
          opacity="1"
          fillRule="evenodd"
          d="M58.000000000000156,57.99999999999996 L53.750000000000156,57.99999999999996 L53.750000000000156,60.99999999999996 L59.500000000000156,60.99999999999996 C60.32520000000015,60.99999999999996 61.000000000000156,60.32519999999995 61.000000000000156,59.49999999999996 L61.000000000000156,53.74999999999996 L58.000000000000156,53.74999999999996 L58.000000000000156,57.99999999999996Z"
          fill="rgb(122,129,133)"
          fillOpacity="1"
        />
      </g>
    </g>
  </svg>
);

ExpandIcon.defaultProps = {
  cornerClassName: ''
};

ExpandIcon.propTypes = {
  cornerClassName: PropTypes.string
};

export default ExpandIcon;
