import React from 'react';

import SectionIconBox from './SectionIconBox';
import CrownIcon from '../../vectors/CrownIcon';

const CrownIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(136.5deg, #21DD63 0%, #1A79C6 100%)"
    icon={<CrownIcon />}
    {...props}
  />
);

export default CrownIconBox;
