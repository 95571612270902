import React from 'react';

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <defs />
    <path
      fill="#33FF7A"
      fillRule="evenodd"
      d="M16.413 12.747l-6.04 6.04a1.058 1.058 0 01-1.496-1.494L14.17 12 8.877 6.707a1.058 1.058 0 011.495-1.495l6.04 6.04a1.056 1.056 0 010 1.495z"
      transform="rotate(90 12 12)"
    />
  </svg>
);

export default ChevronDownIcon;
