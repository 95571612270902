import React, { Component } from 'react';

import { explore } from './resources';
import { getImageUrl, preloadImages } from '../../utils';

const ExploreContext = React.createContext();

class ExploreProvider extends Component {
  state = {};

  getExploreData = () => {
    explore
      .get()
      .then(async ({ data }) => {
        const { artists } = data;
        const artistsWithCovers = artists.filter(({ images }) => images.length);
        const artistsWithAlbumCovers = artists.filter(
          ({ featuredTrack }) => featuredTrack.album.images.length
        );
        const artistsCovers = artistsWithCovers.map(({ images }) =>
          getImageUrl(images)
        );
        const albumCovers = artistsWithAlbumCovers.map(({ featuredTrack }) =>
          getImageUrl(featuredTrack.album.images)
        );

        await preloadImages([...artistsCovers, ...albumCovers]);
        this.setState({ exploreData: data });
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          this.getExploreData();
        }
      });
  };

  render() {
    return (
      <ExploreContext.Provider
        value={{
          ...this.state,
          getExploreData: this.getExploreData
        }}
      >
        {this.props.children}
      </ExploreContext.Provider>
    );
  }
}

const ExploreConsumer = Component => {
  return class Consumer extends React.Component {
    render() {
      return (
        <ExploreContext.Consumer>
          {data => <Component {...this.props} {...data} />}
        </ExploreContext.Consumer>
      );
    }
  };
};

export default ExploreContext;
export { ExploreProvider, ExploreConsumer };
