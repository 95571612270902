import React from 'react';

const MusicBgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170 170"
    width="170"
    height="170"
  >
    <defs>
      <linearGradient id="LkjsvDsre" x1="0.25" x2="0.44" y1="0.09" y2="0.61">
        <stop offset="0" stopColor="#FFF" stopOpacity="0" />
        <stop offset="1" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <path
      fill="url(#LkjsvDsre)"
      fillRule="evenodd"
      d="M58.6 34.493a6.157 6.157 0 016.158-6.16h66.162a6.158 6.158 0 016.157 6.161l-.005 64.273c-1.39 9.98-9.886 17.71-20.193 17.71-11.797 0-21.239-10.127-20.097-22.171.888-9.35 8.31-16.945 17.628-18.081a20.202 20.202 0 017.89.59V51.55a6.157 6.157 0 00-6.158-6.16H79.473a6.157 6.157 0 00-6.157 6.16l-.006 69.916c-.005 11.155-9.047 20.2-20.197 20.2-11.798 0-21.233-10.126-20.098-22.165.85-8.995 7.766-16.438 16.667-17.945a20.18 20.18 0 018.918.467v-67.53z"
      opacity="1"
    />
  </svg>
);

export default MusicBgIcon;
