import React, { useState, useEffect } from 'react';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { UserConsumer } from '../../contexts/UserContext';
import { userMenu } from './Header.module.sass';
import Dropdown from '../Dropdown';
import Switch from '../Switch';
import MusicIcon from '../../vectors/MusicIcon';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import SmileyIcon from '../../vectors/SmileyIcon';
import LogoutIcon from '../../vectors/LogoutIcon';
import {
  openFullscreen,
  closeFullscreen,
  isFullScreenActive
} from '../../utils';
import { TWITTER_ID as twitterID } from '../../config';

const UserMenu = ({
  logout,
  toggleAudioAutoPreview,
  audioAutoPreview,
  closeModal
}) => {
  const [inFullScreen, setInFullScreen] = useState(isFullScreenActive());
  const twitterMessageLink = `https://twitter.com/messages/compose?recipient_id=${twitterID}`;

  const onFullScreenChange = () => {
    setInFullScreen(isFullScreenActive());
  };

  const toggleFullScreen = () => {
    inFullScreen ? closeFullscreen() : openFullscreen();
    closeModal();
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange);
    document.addEventListener('webkitfullscreenchange', onFullScreenChange);
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        onFullScreenChange
      );
    };
  }, []);

  return (
    <Dropdown className={userMenu} close={closeModal} reverseAlign>
      <ul>
        <li onClick={toggleAudioAutoPreview}>
          <span>
            <MusicIcon /> Autoplay on hover
          </span>
          <Switch on={audioAutoPreview} />
        </li>
        <li>
          <span onClick={toggleFullScreen}>
            <ExpandAltIcon /> {inFullScreen ? 'Exit' : 'Enter'} Fullscreen
          </span>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={twitterMessageLink}
          >
            <SmileyIcon /> Send Feedback
          </a>
        </li>
        <li>
          <span onClick={logout}>
            <LogoutIcon /> Logout
          </span>
        </li>
      </ul>
    </Dropdown>
  );
};

export default ExperienceConsumer(UserConsumer(UserMenu));
