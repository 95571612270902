import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  playlistCover,
  playlistActionProgress,
  playlistButton,
  playlistOtherActions,
  spotifyButton,
  moreSelect,
  fadeOut,
  hasSubmitted,
  fadeOutActions
} from './PlaylistCreator.module.sass';
import { PlaylistButton, SpotifyButton } from '../Button';
import PlaylistActionProgress from '../PlaylistActionProgress';
import MenuIcon from '../../vectors/MenuIcon';
import {
  UPDATE_PLAYLIST_ID as updateId,
  SAVE_PLAYLIST_ID as saveId
} from '../../config';

const PlaylistSummary = ({
  coverUrl,
  onPlaylistButtonClick,
  playlistUri,
  isLoading,
  saveError,
  resetSaveError,
  willExit
}) => {
  const [mounted, setMounted] = useState(false);
  const [canUpdate, setCanUpdate] = useState(Boolean(playlistUri));
  const [hasClickedPlaylistButton, setHasClickedPlaylistButton] = useState(
    false
  );
  const [showProgress, setShowProgress] = useState(false);
  const [willExitSaving, setWillExitSaving] = useState(false);
  const [willExitProgress, setWillExitProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showActions, setShowActions] = useState(true);

  const onPlaylistPrimaryActionClick = () => {
    setCanUpdate(Boolean(playlistUri));
    setHasClickedPlaylistButton(true);
    onPlaylistButtonClick();
  };

  const dismissError = () => {
    setWillExitProgress(true);
    setTimeout(() => setShowActions(true), 300);
    setTimeout(() => {
      setShowProgress(false);
      setIsError(false);
      setWillExitSaving(false);
      setWillExitProgress(false);
    }, 500);
    resetSaveError();
  };

  useEffect(() => {
    if (!mounted) {
      return setMounted(true);
    }

    if (isLoading) {
      setTimeout(() => setShowProgress(true), 300);
      setTimeout(() => setShowActions(false), 500);
    } else {
      setWillExitSaving(true);

      if (saveError) {
        setTimeout(() => setIsError(true), 500);
        return;
      }

      setTimeout(() => setIsSuccess(true), 200);
      setTimeout(() => setWillExitProgress(true), 1200);
      setTimeout(() => setShowActions(true), 1500);
      setTimeout(() => {
        setShowProgress(false);
        setIsSuccess(false);
        setWillExitSaving(false);
        setWillExitProgress(false);
      }, 1700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    return () => saveError && resetSaveError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={cx(playlistCover, { [fadeOut]: willExit })}>
        <div style={{ backgroundImage: `url(${coverUrl})` }} />
      </div>

      {showProgress && (
        <PlaylistActionProgress
          className={playlistActionProgress}
          isSuccess={isSuccess}
          isError={isError}
          canUpdate={canUpdate}
          willExitProgress={willExitProgress}
          willExitSaving={willExitSaving}
          dismissError={dismissError}
        />
      )}

      {showActions && (
        <div
          className={cx({
            [hasSubmitted]: hasClickedPlaylistButton,
            [fadeOut]: willExit,
            [fadeOutActions]: isLoading
          })}
        >
          <PlaylistButton
            id={playlistUri ? updateId : saveId}
            text={playlistUri ? 'Update Playlist' : 'Save Playlist To Spotify'}
            className={playlistButton}
            onClick={onPlaylistPrimaryActionClick}
            updateButton={Boolean(playlistUri)}
          />
          {playlistUri && (
            <div className={playlistOtherActions}>
              <SpotifyButton className={spotifyButton} uri={playlistUri} />
              <div className={moreSelect}>
                <MenuIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

PlaylistSummary.propTypes = {
  onPlaylistButtonClick: PropTypes.func.isRequired,
  playlistUri: PropTypes.string,
  isLoading: PropTypes.bool,
  saveError: PropTypes.bool,
  resetSaveError: PropTypes.func,
  willExit: PropTypes.bool
};

export default PlaylistSummary;
