import React from 'react';
import cx from 'classnames';

import { analyticsIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import AnalyticsIcon from '../../vectors/AnalyticsIcon';
import AnalyticsBgIcon from '../../vectors/AnalyticsBgIcon';

const AnalyticsIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(268.81deg, #3FE4D0 0%, #1B52DF 100%)"
    icon={<AnalyticsIcon />}
    bgIcon={<AnalyticsBgIcon />}
    className={cx(className, analyticsIconBox)}
    {...props}
  />
);

export default AnalyticsIconBox;
