import React from 'react';

const TempoLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient
        id="Lnfs7843hBDasX"
        x1="0.39"
        x2="0.54"
        y1="-0.03"
        y2="0.51"
      >
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lnfs7843hBDasX)"
      fillRule="evenodd"
      d="M333.484 69.804A167.14 167.14 0 00248.93 178.09l-78.255 320.58c-3.416 13.12 4.451 26.527 17.573 29.943 2.02.526 4.098.792 6.185.792h411.483c13.56 0 24.55-10.992 24.55-24.55 0-2.087-.266-4.166-.792-6.186l-78.26-320.58a167.143 167.143 0 00-84.556-108.284 143.233 143.233 0 00-133.373 0zm-75.256 385.95a1 1 0 01-.971-1.237l63.22-258.963a93.491 93.491 0 0147.297-60.569 69.583 69.583 0 0164.793 0 93.494 93.494 0 0147.298 60.57l63.217 258.962a1 1 0 01-.971 1.237H258.228zm186.465-236.912c0 20.338-16.488 36.825-36.825 36.825h-14.73c-20.338 0-36.825-16.487-36.825-36.825 0-20.338 16.487-36.825 36.825-36.825h14.73c20.337 0 36.825 16.487 36.825 36.825zm-76.105 159.575c-20.338 0-36.825-16.487-36.825-36.825 0-20.338 16.487-36.825 36.825-36.825h63.83c20.338 0 36.825 16.487 36.825 36.825 0 20.338-16.487 36.825-36.825 36.825h-63.83zM189.416 697.754c-26.063 0-47.191-21.128-47.191-47.191 0-28.275 22.921-51.196 51.196-51.196h415.16c27.17 0 49.194 22.024 49.194 49.194 0 27.137-21.974 49.143-49.1 49.193-.102 27.013-22.063 48.913-49.1 48.913-27.036 0-48.998-21.9-49.1-48.913h-220.95c-.102 27.013-22.064 48.913-49.1 48.913s-48.998-21.9-49.1-48.913h-1.909z"
      opacity="0.1"
    />
  </svg>
);

export default TempoLgIcon;
