import React from 'react';

const TempoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M12.506 2.618a6.268 6.268 0 00-3.171 4.06L6.4 18.7a.92.92 0 00.891 1.153h15.43a.92.92 0 00.892-1.153L20.678 6.678a6.268 6.268 0 00-3.17-4.06 5.371 5.371 0 00-5.002 0zM10.909 17.09a1 1 0 01-.971-1.237l2.08-8.52a3.506 3.506 0 011.774-2.272c.76-.4 1.669-.4 2.43 0a3.506 3.506 0 011.773 2.271l2.08 8.52a1 1 0 01-.972 1.238H10.91zm5.767-8.884a1.38 1.38 0 01-1.381 1.38h-.552a1.38 1.38 0 010-2.761h.552c.763 0 1.381.618 1.381 1.38zm-2.854 5.984a1.38 1.38 0 110-2.762h2.394a1.38 1.38 0 010 2.762h-2.394zM7.103 26.166a1.77 1.77 0 01-1.77-1.77c0-1.06.86-1.92 1.92-1.92h15.569a1.845 1.845 0 01.003 3.69 1.842 1.842 0 01-3.682 0h-8.286a1.842 1.842 0 01-3.682 0h-.072z"
    />
  </svg>
);

export default TempoIcon;
