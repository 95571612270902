import React from 'react';

const SpinnerAlt = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.754 34.754"
    width="34.754"
    height="34.754"
  >
    <g transform="translate(17.377, 17.377) rotate(-1000) translate(-17.377, -17.377) translate(2.377, 2.377)">
      <path
        fillOpacity="0"
        stroke="rgb(51,255,122)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fillRule="evenodd"
        d="M15 5C9.48084975506 5.000000000000002 5.000000000000001 9.480849755060001 5.000000000000001 15.000000000000002C5.000000000000003 20.51915024494 9.480849755060003 25 15 25C19.932372890796184 25 24.035486331087817 21.421279733669433 24.852192863307533 16.720986658575658"
      />
    </g>
  </svg>
);

export default SpinnerAlt;
