import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { sectionTitle, isPassive } from './MobileModalSectionTitle.module.sass';
import { mobileScrollViewListener } from '../../utils';

const MobileModalSectionTitle = ({ renderDelay, title }) => {
  const [titleInView, setTitleInview] = useState(false);
  const titleRef = useRef();

  const onEnterView = onStart => {
    setTimeout(() => setTitleInview(true), onStart ? renderDelay : 0);
  };

  useEffect(() => {
    mobileScrollViewListener(titleRef.current, -4.4, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <h2
      ref={titleRef}
      className={cx(sectionTitle, { [isPassive]: !titleInView })}
    >
      {title}
    </h2>
  );
};

MobileModalSectionTitle.defaultProps = {
  renderDelay: 0
};

MobileModalSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  renderDelay: PropTypes.bool
};

export default MobileModalSectionTitle;
