import React from 'react';

const SmileyGreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <path
      fill="#33FF7A"
      fillRule="evenodd"
      d="M6.491 20C6.491 12.53 12.53 6.491 20 6.491c7.47 0 13.509 6.04 13.509 13.509 0 7.47-6.039 13.509-13.509 13.509-7.47 0-13.509-6.04-13.509-13.509zm5.007-2.781a.61.61 0 00.596.596h4.926c.357 0 .596-.24.596-.596 0-1.55-1.39-2.86-3.059-2.86-1.668 0-3.059 1.27-3.059 2.86zm10.886 0c0 .357.278.596.596.596h4.926a.585.585 0 00.596-.596c0-1.59-1.39-2.86-3.06-2.86-1.668 0-3.058 1.27-3.058 2.86zM20 28.78a6.936 6.936 0 01-6.953-6.953h13.906c0 3.854-3.1 6.953-6.953 6.953z"
    />
  </svg>
);

export default SmileyGreenIcon;
