export const RANGE_OPTIONS = {
  shortTerm: {
    label: 'Recent',
    description: 'From Recent'
  },
  mediumTerm: {
    label: 'Past 6 months',
    description: 'From 6 Months'
  },
  longTerm: {
    label: 'All Time',
    description: 'From All Time'
  }
};

export const SORT_KEYS = {
  0: 'rank',
  1: 'popularity',
  2: 'title'
};
