import React from 'react';

const PhonographIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M42.37 52H18.763c-.51-.006-.547-.426-.564-.796 0-1.548 1.284-2.812 2.855-2.812h.526V39.34c0-.3.248-.545.553-.545h2.905v-1.369c0-.964.808-1.754 1.781-1.754h7.637c.979 0 1.776.79 1.776 1.754v1.369H39a.55.55 0 01.553.545v1.481c6.822-1.652 3.884-11.018-3.075-12.414-4.615-.922-11.287 1.132-19.406 9.684v2.103a.55.55 0 01-.553.545h-4.466a.547.547 0 01-.553-.545V8.545c0-.3.244-.545.553-.545h4.466a.55.55 0 01.553.545v2.735c5.572 5.652 12.195 7.92 17.867 9.859 20.977 7.171 13.585 23.303 4.614 24.092v3.162h.526a2.83 2.83 0 012.844 3.073.554.554 0 01-.554.534z"
    />
  </svg>
);

export default PhonographIcon;
