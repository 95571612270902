import React from 'react';

const StudioIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M26 4H4v22h22V4zm-3.171 13.874h-4.757v4.757h4.757v-4.757z"
    />
  </svg>
);

export default StudioIcon;
