import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  actionProgress,
  exitProgress,
  hasError,
  saving,
  exitSaving,
  saved,
  actionError
} from './PlaylistActionProgress.module.sass';
import SpinnerAlt from '../../vectors/SpinnerAlt';
import CheckIcon from '../../vectors/CheckIcon';
import ExclamationIcon from '../../vectors/ExclamationIcon';

const PlaylistActionProgress = ({
  className,
  isSuccess,
  isError,
  canUpdate,
  willExitProgress,
  willExitSaving,
  dismissError
}) => (
  <div
    className={cx(actionProgress, className, {
      [exitProgress]: willExitProgress,
      [hasError]: isError
    })}
  >
    {!(isSuccess || isError) && (
      <div className={cx(saving, { [exitSaving]: willExitSaving })}>
        <SpinnerAlt /> {canUpdate ? 'Updating' : 'Saving'} Playlist
      </div>
    )}
    {isSuccess && (
      <div className={saved}>
        <CheckIcon /> Playlist {canUpdate ? 'Updated' : 'Added to Spotify'}
      </div>
    )}
    {isError && (
      <div className={actionError}>
        <ExclamationIcon />
        <p>
          We couldn't complete this action.
          <br />
          <span onClick={dismissError}>Dismiss</span>
        </p>
      </div>
    )}
  </div>
);

PlaylistActionProgress.propTypes = {
  className: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  canUpdate: PropTypes.bool,
  willExitProgress: PropTypes.bool,
  willExitSaving: PropTypes.bool,
  dismissError: PropTypes.func
};

export default PlaylistActionProgress;
