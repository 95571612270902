import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  mobileTitleCard,
  isPassive,
  statsIcon,
  currentIcon,
  previousIcon,
  statsContent,
  currentStatsContent,
  prevStatsContent
} from './MobileTitleCard.module.sass';
import { mobileScrollViewListener } from '../../utils';

const MobileTitleCard = ({ data, activeIndex, renderDelay }) => {
  const [containerInView, setContainerInview] = useState(false);
  const [hasSwitchedStats, setHasSwitchedStats] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const [previousIndex, setPreviousIndex] = useState(null);
  const { icon, label, value } = data[currentIndex] || {};
  const { icon: prevIcon, label: previousLabel, value: previousValue } =
    data[previousIndex] || {};
  const containerRef = useRef();

  const onEnterView = onStart => {
    setTimeout(() => setContainerInview(true), onStart ? renderDelay : 0);
  };

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, -1.2, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPreviousIndex(currentIndex);
    setCurrentIndex(activeIndex);
    !hasSwitchedStats && setHasSwitchedStats(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <div
      ref={containerRef}
      className={cx(mobileTitleCard, { [isPassive]: !containerInView })}
    >
      <div className={statsIcon}>
        <span className={currentIcon} key={`curr-${currentIndex}`}>
          {icon}
        </span>
        <span className={previousIcon} key={`prev-${previousIndex}`}>
          {prevIcon}
        </span>
      </div>
      <div
        key={`curr-${currentIndex}`}
        className={cx(statsContent, {
          [currentStatsContent]: hasSwitchedStats
        })}
      >
        <h4>{label}</h4>
        <p>{value}</p>
      </div>
      <div
        key={`prev-${previousIndex}`}
        className={cx(statsContent, prevStatsContent)}
      >
        <h4>{previousLabel}</h4>
        <p>{previousValue}</p>
      </div>
    </div>
  );
};

MobileTitleCard.defaultProps = {
  renderDelay: 0,
  activeIndex: 0
};

MobileTitleCard.propTypes = {
  data: PropTypes.array,
  activeIndex: PropTypes.number,
  renderDelay: PropTypes.number
};

export default MobileTitleCard;
