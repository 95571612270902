import React from 'react';
import PropTypes from 'prop-types';

import DrumPad from './DrumPad';

const DrumPads = ({ total }) => {
  const drumPads = Array(total).fill();

  return (
    <>
      {drumPads.map((item, index) => (
        <DrumPad key={index} />
      ))}
    </>
  );
};

DrumPads.propTypes = {
  total: PropTypes.number.isRequired
};

export default DrumPads;
