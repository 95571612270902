import React from 'react';

const FacebookColoredIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50"
    height="50"
  >
    <path
      fill="#1977F3"
      fillRule="evenodd"
      d="M45 25c0-11.045-8.955-20-20-20S5 13.955 5 25c0 9.982 7.313 18.256 16.875 19.758V30.783h-5.08V25h5.08v-4.407c0-5.012 2.987-7.783 7.555-7.783 2.188 0 4.477.391 4.477.391v4.922h-2.523c-2.483 0-3.26 1.542-3.26 3.125V25h5.547l-.886 5.783h-4.66v13.975C37.687 43.26 45 34.985 45 25z"
    />
    <path
      fill="#FEFEFE"
      fillRule="evenodd"
      d="M32.785 30.783L33.671 25h-5.546v-3.752c0-1.58.773-3.125 3.26-3.125h2.522v-4.922s-2.29-.39-4.477-.39c-4.568 0-7.555 2.767-7.555 7.782V25h-5.08v5.783h5.08v13.975c1.018.16 2.062.242 3.125.242 1.063 0 2.107-.084 3.125-.242V30.783h4.66z"
    />
  </svg>
);

export default FacebookColoredIcon;
