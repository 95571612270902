import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  genreBarContainer,
  genreBarContent,
  isBarPassive,
  genreBar,
  genreBarLevelText,
  genreBarLevel,
  genreName
} from './TopGenre.module.sass';
import DrumPads from '../DrumPads';
import { scrollViewListener } from '../../utils';

const GenreBar = ({ className, name, level, levelColor }) => {
  const [inView, setInview] = useState(false);
  const barContainer = useRef(null);

  useEffect(() => {
    scrollViewListener(barContainer.current, 0.7, () => setInview(true));
  }, []);

  return (
    <div ref={barContainer} className={cx(genreBarContainer, className)}>
      <DrumPads total={7} />
      <div className={cx(genreBarContent, { [isBarPassive]: !inView })}>
        <div className={genreBar}>
          <span className={genreBarLevelText}>{level}%</span>
          <div
            className={genreBarLevel}
            style={{ height: `${level}%`, background: levelColor }}
          />
        </div>
        <h3 className={genreName}>
          <span>{name}</span>
        </h3>
      </div>
    </div>
  );
};

GenreBar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  level: PropTypes.number,
  levelColor: PropTypes.string
};

export default GenreBar;
