import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { dropdown, reverseMask } from './Dropdown.module.sass';

const Dropdown = ({ className, reverseAlign, children, close }) => {
  const isWithinDropdown = useRef(false);

  const onDropdownClick = () => {
    isWithinDropdown.current = true;
  };
  const closeDropdown = () => {
    if (!isWithinDropdown.current) {
      return close();
    }

    isWithinDropdown.current = false;
  };

  useEffect(() => {
    window.addEventListener('click', closeDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onClick={onDropdownClick}
      className={cx(dropdown, className, { [reverseMask]: reverseAlign })}
    >
      {children}
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  reverseAlign: PropTypes.bool
};

export default Dropdown;
