import React from 'react';

const UpdatePlaylistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M9.367 4.729h12.011c.72 0 1.304.584 1.304 1.304v4.025h-3.104l-.025-.413c0-.72-.584-1.304-1.304-1.304h-5.766c-.72 0-1.303.584-1.303 1.304l-.002 10.806a4.28 4.28 0 01-4.277 4.278 4.278 4.278 0 111.162-8.395v-10.3c0-.721.584-1.305 1.304-1.305zm15.339 11.863c.482-.84 1.666-.891 2.198-.091.143.217.272.441.388.673 1.424 2.8.561 6.27-2.005 8.068-3.246 2.296-7.76 1.087-9.457-2.523-1.326-2.874-.293-6.354 2.39-8.043l-.488-.845a.776.776 0 01.701-1.16l4.252.147a.77.77 0 01.655 1.134l-1.998 3.757a.776.776 0 01-1.356.027l-.48-.831-.013.008c-1.602 1.064-2.134 3.218-1.227 4.91a3.794 3.794 0 005.465 1.364 3.764 3.764 0 001.405-4.54 3.694 3.694 0 00-.385-.727 1.278 1.278 0 01-.045-1.328z"
    />
  </svg>
);

export default UpdatePlaylistIcon;
