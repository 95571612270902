import React from 'react';
import PropTypes from 'prop-types';

import Carousel from './Carousel';
import { TrackCard } from '../ListCards';

const TracksCarousel = ({ title, data, renderDelay }) => (
  <Carousel title={title} noSlide={data.length < 4} renderDelay={renderDelay}>
    {data.map((track, index) => (
      <TrackCard
        key={index}
        number={index + 1}
        {...track}
        renderDelay={renderDelay + 100 + index * 100}
        columnDelay={(index % 3) * 100}
      />
    ))}
  </Carousel>
);

TracksCarousel.defaultProps = {
  renderDelay: 0
};

TracksCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  renderDelay: PropTypes.number
};

export default TracksCarousel;
