import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import {
  container,
  sectionIcon,
  tasteSummary,
  isPassive,
  tasteSummaryContent,
  tasteCaption,
  greenText,
  tasteSubcaption,
  tasteSubcaptionText,
  artistsLink
} from './TasteProfileLink.module.sass';
import DrumPads from '../DrumPads';
import TasteArtistLink from '../TasteArtistLink';
import { AnalyticsIconBox } from '../SectionIconBox';
import { scrollViewListener } from '../../utils';
import { SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction } from '../../config';

const TasteProfileLink = () => {
  const [summaryInView, setSummaryInview] = useState(false);

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  const summaryRef = useRef();

  return (
    <section className={container}>
      <AnalyticsIconBox className={sectionIcon} isPassive={!summaryInView} />
      <div
        ref={summaryRef}
        className={cx(tasteSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={36} />
        <div className={tasteSummaryContent}>
          <h1 className={tasteCaption}>
            <span>
              What's your <br />{' '}
              <span className={greenText}>taste profile?</span>
            </span>
          </h1>
          <p className={tasteSubcaption}>
            <span className={tasteSubcaptionText}>
              Audio analysis on how you listen using{' '}
              <a
                href="https://developer.spotify.com/documentation/web-api/reference/tracks/get-several-audio-features/"
                target="blank"
                className={greenText}
              >
                Spotify's AI
              </a>
              , based on your top 50 tracks.
            </span>
          </p>
        </div>
      </div>
      <TasteArtistLink className={artistsLink} />
      <DrumPads total={69} />
    </section>
  );
};

export default TasteProfileLink;
