import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { UserConsumer } from '../../contexts/UserContext';
import styles from './TopTracks.module.sass';
import DrumPads from '../DrumPads';
import ExplicitIcon from '../../vectors/ExplicitIcon';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import { SpotifyLogo } from '../AnimatedVectors';
import { AudioControl } from '../AnimatedVectors';
import {
  scrollViewListener,
  playAudio,
  pauseAudio,
  fadeOutAudio
} from '../../utils';
import {
  AUDIO_LOADING_DELAY as audioLoadingDelay,
  AUDIO_FADE_DURATION as audioFadeDuration
} from '../../config';

class TrackCard extends Component {
  state = {};
  containerRef = createRef();
  trackPreviewRef = createRef();
  mousePlayTimeout = null;

  onMouseEnter = () => {
    const { audioAutoPreview } = this.props;
    const {
      hasEnteredCard,
      canPlayAudio,
      mouseLeavePause,
      hasInitializedPlay
    } = this.state;

    this.setState({ cardActive: true });

    if (!hasEnteredCard) {
      this.setState({
        audioState: 'loading',
        hasEnteredCard: true
      });

      setTimeout(() => this.trackPreview.load(), audioLoadingDelay);
    }

    if (
      canPlayAudio &&
      audioAutoPreview &&
      (!hasInitializedPlay || mouseLeavePause)
    ) {
      this.mousePlayTimeout = setTimeout(() => {
        const { isPlaying, hasInitializedPlay, mouseLeavePause } = this.state;

        if (!isPlaying && (!hasInitializedPlay || mouseLeavePause)) {
          playAudio(this.trackPreview);
          this.setState({
            isPlaying: true,
            mouseLeavePause: false
          });
        }

        !hasInitializedPlay && this.setState({ hasInitializedPlay: true });
      }, audioFadeDuration);
    }
  };

  onMouseLeave = () => {
    clearTimeout(this.mousePlayTimeout);
    const { canPlayAudio, isPlaying } = this.state;

    if (canPlayAudio && isPlaying) {
      pauseAudio(this.trackPreview);

      this.setState({
        isPlaying: false,
        audioState: 'paused',
        mouseLeavePause: true
      });
    }

    this.setState({ cardActive: false });
  };

  onCanPlayAudio = () => {
    const { audioAutoPreview } = this.props;
    const { cardActive, hasPlayedThrough } = this.state;

    if (hasPlayedThrough) {
      return;
    }

    const autoPreview = cardActive && audioAutoPreview;

    this.setState(
      {
        canPlayAudio: true,
        audioState: autoPreview ? 'playing' : 'paused'
      },
      () => {
        autoPreview && this.togglePlayState();
      }
    );
  };

  onAudioTimeUpdate = () => {
    if (this.trackPreview.currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.trackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      hasPlayedThrough: true,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { canPlayAudio, isPlaying, hasInitializedPlay } = this.state;

    if (!canPlayAudio) {
      return;
    }

    isPlaying ? pauseAudio(this.trackPreview) : playAudio(this.trackPreview);
    !hasInitializedPlay && this.setState({ hasInitializedPlay: true });

    this.setState({
      isPlaying: !isPlaying,
      mouseLeavePause: false
    });
  };

  onEnterView = () => {
    this.setState({ containerInView: true });
  };

  componentDidMount() {
    this.container = this.containerRef.current;
    this.trackPreview = this.trackPreviewRef.current;
    scrollViewListener(this.container, 0.5, this.onEnterView);
  }

  render() {
    const {
      id,
      number,
      albumCover,
      className,
      title,
      uri,
      artists,
      previewUrl,
      explicit,
      setActiveTrack
    } = this.props;
    const { containerInView, audioState } = this.state;
    const isFeatured = number === 1;

    return (
      <div
        ref={this.containerRef}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={cx(styles.trackCardContainer, className, {
          [styles.featured]: isFeatured
        })}
      >
        <DrumPads total={isFeatured ? 30 : 12} />
        <div
          className={cx(styles.trackCard, {
            [styles.isCardPassive]: !containerInView
          })}
        >
          <div className={styles.topQuickInfo}>
            <div>
              <span className={styles.topNumber}>#{number}</span>
              {explicit && (
                <span className={styles.explicitIcon}>
                  <ExplicitIcon />
                </span>
              )}
            </div>
          </div>
          <div className={styles.trackActions}>
            <div>
              <span onClick={() => setActiveTrack(id)}>
                <ExpandAltIcon cornerClassName={styles.expandCorner} />
              </span>
              <a href={uri}>
                <SpotifyLogo />
              </a>
            </div>
          </div>
          <div className={styles.albumCover} onClick={() => setActiveTrack(id)}>
            <div style={{ backgroundImage: `url(${albumCover})` }} />
          </div>
          <div className={styles.trackInfo}>
            <div onClick={this.togglePlayState} className={styles.audioControl}>
              <AudioControl audioState={audioState} />
            </div>
            <div className={styles.titleColumn}>
              <h2 className={styles.trackName}>
                <a href={uri}>{title}</a>
              </h2>
              <h3 className={styles.artistName}>
                {artists.map(({ id, name, uri }, index) => (
                  <Fragment key={id}>
                    {index !== 0 && <span>, </span>}
                    <a href={uri}>{name}</a>
                  </Fragment>
                ))}
              </h3>
            </div>
            <audio
              ref={this.trackPreviewRef}
              preload="none"
              src={previewUrl}
              onCanPlay={this.onCanPlayAudio}
              onPlay={this.onAudioPlay}
              onPause={this.onAudioPause}
              onEnded={this.onAudioEnd}
              onTimeUpdate={this.onAudioTimeUpdate}
            />
          </div>
        </div>
      </div>
    );
  }
}

TrackCard.propTypes = {
  number: PropTypes.number,
  uri: PropTypes.string,
  albumCover: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  artists: PropTypes.array,
  previewUrl: PropTypes.string
};

export default ExperienceConsumer(UserConsumer(TrackCard));
