import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  card,
  isPassive,
  cover,
  cardInfo,
  cardFooter,
  cardActions
} from './ListCards.module.sass';
import { modalScrollViewListener } from '../../utils';
import MultiLineEllipsis from '../MultiLineEllipsis';
import { SpotifyLogo } from '../AnimatedVectors';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';

const ArtistCard = ({
  id,
  artistCover,
  artistName,
  number,
  genres,
  uri,
  setActiveArtist,
  renderDelay,
  columnDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const topGenreCount = genres.slice(0, 3).map(genre => genre.length);
  const topGenreTotal = topGenreCount.reduce((acc, curr) => acc + curr, 0);
  const visibleGenreCount =
    Math.max(...topGenreCount) > 20 || topGenreTotal > 36 ? 2 : 3;
  const genreList = genres.slice(0, visibleGenreCount);
  const genresExtra = genres.length - visibleGenreCount;
  const cardRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : columnDelay);
  };

  useEffect(() => {
    setTimeout(
      () => modalScrollViewListener(cardRef.current, -0.05, onEnterView),
      20
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(card, { [isPassive]: !isReady })} ref={cardRef}>
      <div
        className={cover}
        style={{ backgroundImage: `url(${artistCover})` }}
      />
      <div className={cardInfo}>
        <h3>
          <span>
            <MultiLineEllipsis
              text={`${number ? `${number}. ` : ''}${artistName}`}
              lines={2}
            />
          </span>
        </h3>
        <p>
          {genreList.join(', ')}
          {genresExtra > 0 && `, +${genresExtra}`}
        </p>
        <div className={cardFooter}>
          <div className={cardActions}>
            <span onClick={() => setActiveArtist(id)}>
              <ExpandAltIcon />
            </span>
            <a href={uri}>
              <SpotifyLogo />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ArtistCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

ArtistCard.propTypes = {
  artistCover: PropTypes.string,
  artistName: PropTypes.string,
  genres: PropTypes.array,
  number: PropTypes.number,
  uri: PropTypes.string,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default UserConsumer(ArtistCard);
