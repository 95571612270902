import { memberOne, memberTwo, memberThree } from './Team.module.sass';
import richardCoverImage from '../../shared/images/richard-ekwonye.png';
import joshuaCoverImage from '../../shared/images/joshua-oluwagbemiga.png';
import nemiCoverImage from '../../shared/images/nemi-banigo.png';

export const TEAM_MEMBERS = [
  {
    firstName: 'Richard',
    lastName: 'Ekwonye',
    coverImage: richardCoverImage,
    twitterUrl: 'https://twitter.com/ekwonye',
    githubUrl: 'https://github.com/ekwonye-richard',
    instagramUrl: 'https://www.instagram.com/ekwonyenoob',
    className: memberOne
  },
  {
    firstName: 'Joshua',
    lastName: 'Oluwagbemiga',
    coverImage: joshuaCoverImage,
    twitterUrl: 'https://twitter.com/JoAccord',
    dribbbleUrl: 'https://dribbble.com/JoAccord',
    instagramUrl: 'https://www.instagram.com/joaccord',
    className: memberTwo
  },
  {
    firstName: 'Nemi',
    lastName: 'Banigo',
    coverImage: nemiCoverImage,
    twitterUrl: 'https://twitter.com/nemibanigo',
    dribbbleUrl: 'https://dribbble.com/nemibanigo',
    instagramUrl: 'https://www.instagram.com/evebanigo',
    className: memberThree
  }
];
