import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { pillText, isPassive } from './PillText.module.sass';
import { modalScrollViewListener } from '../../utils';

const PillText = ({ className, onClick, children, renderDelay }) => {
  const [isReady, setIsReady] = useState(false);
  const pillRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : 0);
  };

  useEffect(() => {
    modalScrollViewListener(pillRef.current, 0.6, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      className={cx(pillText, className, { [isPassive]: !isReady })}
      onClick={onClick}
      ref={pillRef}
    >
      {children}
    </span>
  );
};

PillText.defaultProps = {
  renderDelay: 0
};

PillText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  renderDelay: PropTypes.number
};

export default PillText;
