import {
  insightBlockOne,
  insightBlockTwo,
  insightBlockThree,
  iconBoxOne,
  iconBoxTwo,
  iconBoxThree
} from './StreamingInsights.module.sass';

export const INSIGHT_BLOCKS_CLASS_NAMES = [
  insightBlockOne,
  insightBlockTwo,
  insightBlockThree
];

export const INSIGHT_BLOCKS_ICON_BOX_CLASS_NAMES = [
  iconBoxOne,
  iconBoxTwo,
  iconBoxThree
];
