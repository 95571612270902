import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from './Button';
import { playlistButton } from './Button.module.sass';
import UpdatePlaylistIcon from '../../vectors/UpdatePlaylistIcon';
import PlaylistIcon from '../../vectors/PlaylistIcon';

const PlaylistButton = ({ text, onClick, id, className, updateButton }) => (
  <Button className={cx(className, playlistButton)} onClick={onClick} id={id}>
    <span>
      {updateButton ? <UpdatePlaylistIcon /> : <PlaylistIcon />} {text}
    </span>
  </Button>
);

PlaylistButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  updateButton: PropTypes.bool
};

export default PlaylistButton;
