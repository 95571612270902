import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  trackCard,
  featured,
  isPassive,
  withPerf,
  albumCoverImage,
  trackInfo,
  cardTitleRow,
  spotifyLink
} from './MobileTrackCard.module.sass';
import { UserConsumer } from '../../contexts/UserContext';
import ExplicitIcon from '../../vectors/ExplicitIcon';
import SpotifyLogo from '../../vectors/SpotifyLogo';
import { mobileScrollViewListener } from '../../utils';

const MobileTrackCard = ({
  id,
  albumCover,
  isFeatured,
  title,
  name,
  explicit,
  uri,
  setActiveTrack,
  forceSmooth,
  renderDelay,
  columnDelay
}) => {
  const [containerInView, setContainerInview] = useState(false);
  const [hasAnimatedIn, setHasAnimatedIn] = useState(false);
  const containerRef = useRef();

  const onEnterView = onStart => {
    const delay = onStart ? renderDelay : columnDelay;
    setTimeout(() => setContainerInview(true), delay);
    setTimeout(() => setHasAnimatedIn(true), 3000 + delay);
  };

  useEffect(() => {
    mobileScrollViewListener(containerRef.current, -0.55, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={cx(trackCard, {
        [featured]: isFeatured,
        [isPassive]: !containerInView,
        [withPerf]: !hasAnimatedIn || forceSmooth
      })}
      onClick={() => setActiveTrack(id)}
    >
      <div
        className={albumCoverImage}
        style={{ backgroundImage: `url(${albumCover})` }}
      />
      <div className={trackInfo}>
        <div className={cardTitleRow}>
          <h3>{title}</h3>
          {explicit && <ExplicitIcon />}
        </div>
        <h2>
          <span>{name}</span>
        </h2>
        <a
          onClick={e => e.stopPropagation()}
          className={spotifyLink}
          href={uri}
        >
          <SpotifyLogo />
        </a>
      </div>
    </div>
  );
};

MobileTrackCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

MobileTrackCard.propTypes = {
  id: PropTypes.string,
  albumCover: PropTypes.string,
  isFeatured: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  explicit: PropTypes.bool,
  uri: PropTypes.string,
  forceSmooth: PropTypes.bool,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default UserConsumer(MobileTrackCard);
