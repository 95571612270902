import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  listShare,
  shareSource,
  sharePlatform,
  disableShare,
  shareCard,
  actionButton,
  closeListShareButton,
  exitTab
} from './TopList.module.sass';
import ShareCard from '../ShareCard';
import Button from '../Button';
import {
  createTopArtistsShareCover,
  createTopTracksShareCover,
  uploadToShareImagesBucket
} from '../../utils';
import {
  SHARE_URL as shareUrl,
  FACEBOOK_SHARE_URL as facebookUrl,
  TWITTER_SHARE_URL as twitterUrl
} from '../../config';

const ListShare = ({ sourceType, timeRange, data, close, willExit }) => {
  const [shareCover, setShareCover] = useState('');
  const [uploadingCover, setUploadingCover] = useState(false);

  const generateFileName = () => {
    return `${Date.now()}${Math.floor(100000 + Math.random() * 90000)}`;
  };

  const shareToTwitter = async () => {
    try {
      const fileName = generateFileName();
      setUploadingCover(true);
      await uploadToShareImagesBucket(fileName, shareCover);
      const tweet = `My ${timeRange} Top ${sourceType} %23cruuunchify%0a${shareUrl}/${fileName}`;
      const twitterLink = `${twitterUrl}${tweet}`;
      window.open(twitterLink, '_blank');
      setUploadingCover(false);
    } catch (error) {}
  };

  const shareToFacebook = async () => {
    try {
      const fileName = generateFileName();
      setUploadingCover(true);
      await uploadToShareImagesBucket(fileName, shareCover);
      const shareLink = `${shareUrl}/${fileName}`;
      const facebookLink = `${facebookUrl}${shareLink}`;
      window.open(facebookLink, '_blank');
      setUploadingCover(false);
    } catch (error) {}
  };

  useEffect(() => {
    const coverReq =
      sourceType === 'Artists'
        ? createTopArtistsShareCover
        : createTopTracksShareCover;
    coverReq(timeRange, data).then(url => setShareCover(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={cx(listShare, { [exitTab]: willExit })}>
      <h3>
        <span>
          Share your top <span className={shareSource}>{sourceType}</span>
        </span>
      </h3>
      <p>
        <span>Choose your preffered social platform</span>
      </p>
      <div className={cx(sharePlatform, { [disableShare]: uploadingCover })}>
        <ShareCard
          className={shareCard}
          type="facebook"
          onClick={shareToFacebook}
        />
        <ShareCard
          className={shareCard}
          type="twitter"
          onClick={shareToTwitter}
        />
        <ShareCard className={shareCard} type="instagram" />
      </div>
      <Button
        onClick={close}
        className={cx(actionButton, closeListShareButton)}
      >
        Close
      </Button>
    </div>
  );
};

ListShare.propTypes = {
  sourceType: PropTypes.string.isRequired,
  timeRange: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  willExit: PropTypes.bool
};

export default ListShare;
