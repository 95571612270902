import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import DrumPads from '../DrumPads';

import {
  sectionIconBox,
  sectionIconBoxContent,
  isPassiveBox,
  glow,
  isPassiveGlow,
  boxIcon,
  boxBgIcon
} from './SectionIconBox.module.sass';

const SectionIconBox = ({ className, bgColor, icon, bgIcon, isPassive }) => (
  <div className={cx(className, sectionIconBox)}>
    <div className={cx(glow, { [isPassiveGlow]: isPassive })} />
    <DrumPads total={1} />
    <div
      className={cx(sectionIconBoxContent, {
        [isPassiveBox]: isPassive
      })}
    >
      <span style={{ background: bgColor }}>
        <span className={boxIcon}>{icon}</span>
        <span className={boxBgIcon}>{bgIcon}</span>
      </span>
    </div>
  </div>
);

SectionIconBox.propTypes = {
  bgColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  bgIcon: PropTypes.node,
  className: PropTypes.string,
  isPassive: PropTypes.bool
};

export default SectionIconBox;
