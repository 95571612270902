import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  carousel,
  isPassive,
  carouselControls,
  activeChevron,
  carouselContents
} from './Carousel.module.sass';
import ChevronLeftIcon from '../../vectors/ChevronLeftIcon';
import ChevronRightIcon from '../../vectors/ChevronRightIcon';
import { modalScrollViewListener } from '../../utils';

const Carousel = ({
  title,
  children,
  className,
  noSlide,
  disableSlide,
  renderDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const firstActive = activeIndex === 0;
  const secondActive = activeIndex === 1;

  const containerRef = useRef(null);
  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : 0);
  };

  useEffect(() => {
    modalScrollViewListener(containerRef.current, 0.1, onEnterView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={carousel} ref={containerRef}>
      <nav className={cx({ [isPassive]: !isReady })}>
        <h4>
          <span>{title}</span>
        </h4>
        {!disableSlide && (
          <div className={carouselControls}>
            <span
              onClick={() => !noSlide && !firstActive && setActiveIndex(0)}
              className={cx({ [activeChevron]: !noSlide && !firstActive })}
            >
              <ChevronLeftIcon />
            </span>
            <span
              onClick={() => !noSlide && !secondActive && setActiveIndex(1)}
              className={cx({
                [activeChevron]: !noSlide && !secondActive
              })}
            >
              <ChevronRightIcon />
            </span>
          </div>
        )}
      </nav>
      <div
        className={cx(className, carouselContents)}
        style={{
          transform: `translate3d(calc(${activeIndex * -100}% - ${activeIndex *
            15}px), 0, 0)`
        }}
      >
        {children}
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  renderDelay: 0
};

Carousel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  noSlide: PropTypes.bool,
  disableSlide: PropTypes.bool,
  className: PropTypes.string,
  renderDelay: PropTypes.number
};

export default Carousel;
