import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import {
  loader,
  loaderContent,
  radialProgress,
  playIcon,
  playIconReady,
  glow,
  isPassiveGlow
} from './Loader.module.sass';
import RadialLoader from './RadialLoader';
import PlayIcon from '../../vectors/PlayIcon';
import DrumPads from '../DrumPads';

const Loader = ({ progress, onUserReady }) => {
  const minColumns = 16;
  const [loaderActive, setLoaderActive] = useState(true);
  const [progressComplete, setProgressComplete] = useState(false);
  const [totalColumns, setTotalColumns] = useState(minColumns);
  const [containerStyle, setContainerStyle] = useState(null);

  const onPlayClick = () => {
    if (!progressComplete) {
      return;
    }

    onUserReady();
    setLoaderActive(false);
  };

  const setContainerColumns = () => {
    const rows = 10;

    const viewportColumns = Math.ceil(
      window.innerWidth / (window.innerHeight / rows)
    );

    const totalColumns = Math.max(viewportColumns, minColumns);
    const sectionStyle = {
      width: `calc((100vh / ${rows}) * ${totalColumns})`,
      gridTemplateColumns: `repeat(${totalColumns}, calc(100vh / ${rows}))`
    };

    setTotalColumns(totalColumns);
    setContainerStyle(sectionStyle);
  };

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgressComplete(true);
      }, 800);
    }
  }, [progress]);

  useEffect(() => {
    setContainerColumns();
    window.addEventListener('resize', setContainerColumns);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', setContainerColumns);
    };
  }, []);

  return (
    <>
      {loaderActive && (
        <div className={loader} style={containerStyle}>
          <div className={cx(glow, { [isPassiveGlow]: !progressComplete })} />
          <div className={loaderContent}>
            <span className={radialProgress}>
              <RadialLoader
                progress={progress}
                progressComplete={progressComplete}
              />
            </span>
            <span
              onClick={onPlayClick}
              className={cx(playIcon, { [playIconReady]: progressComplete })}
            >
              <PlayIcon />
            </span>
          </div>
          <DrumPads total={totalColumns * 10} />
        </div>
      )}
    </>
  );
};

export default Loader;
