import React from 'react';

const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M17.32 9.312h-4.642c-.91 0-1.647-.742-1.647-1.656 0-.914.738-1.656 1.647-1.656h4.644c.91 0 1.647.742 1.647 1.656a1.653 1.653 0 01-1.648 1.656zM21.824 15c0 .914-.737 1.656-1.647 1.656H9.824c-.91 0-1.647-.742-1.647-1.656 0-.914.737-1.656 1.647-1.656h10.352c.91 0 1.647.742 1.647 1.656zm2.381 7.344c0 .914-.738 1.656-1.647 1.656H7.443c-.91 0-1.647-.742-1.647-1.656 0-.914.738-1.656 1.647-1.656h15.114c.91 0 1.647.742 1.647 1.656z"
      transform="matrix(1 0 0 -1 0 30)"
    />
  </svg>
);

export default FilterIcon;
