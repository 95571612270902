import React from 'react';
import PropTypes from 'prop-types';

const ExpandAltIcon = ({ cornerClassName }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height="30"
    width="30"
  >
    <g transform="translate(-1.7053025658242404e-13, 0)" opacity="1">
      <g className={cornerClassName} opacity="1">
        <path
          fill="rgb(255,255,255)"
          opacity="1"
          fillRule="evenodd"
          d="M24.500000000000156,4 L18.750000000000156,4 L18.750000000000156,7 L23.000000000000156,7 L23.000000000000156,11.25 L26.000000000000156,11.25 L26.000000000000156,5.5 C26.000000000000156,4.674799999999999 25.325200000000155,4 24.500000000000156,4Z"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          fill="rgb(255,255,255)"
          opacity="1"
          fillRule="evenodd"
          d="M4,5.5 L4,11.25 L7,11.25 L7,7 L11.25,7 L11.25,4 L5.5,4 C4.674799999999999,4 4,4.674799999999999 4,5.5Z"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          fill="rgb(255,255,255)"
          opacity="1"
          fillRule="evenodd"
          d="M11.25,22.999999999999957 L7,22.999999999999957 L7,18.749999999999957 L4,18.749999999999957 L4,24.499999999999957 C4,25.325199999999956 4.674799999999999,25.999999999999957 5.5,25.999999999999957 L11.25,25.999999999999957 L11.25,22.999999999999957Z"
        />
      </g>
      <g className={cornerClassName} opacity="1">
        <path
          fill="rgb(255,255,255)"
          opacity="1"
          fillRule="evenodd"
          d="M23.000000000000156,22.999999999999957 L18.750000000000156,22.999999999999957 L18.750000000000156,25.999999999999957 L24.500000000000156,25.999999999999957 C25.325200000000155,25.999999999999957 26.000000000000156,25.325199999999956 26.000000000000156,24.499999999999957 L26.000000000000156,18.749999999999957 L23.000000000000156,18.749999999999957 L23.000000000000156,22.999999999999957Z"
        />
      </g>
    </g>
  </svg>
);

ExpandAltIcon.defaultProps = {
  cornerClassName: ''
};

ExpandAltIcon.propTypes = {
  cornerClassName: PropTypes.string
};

export default ExpandAltIcon;
