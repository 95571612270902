import React from 'react';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 92"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M57.044 53.456V11.333H46.468v42.123c0 9.121 7.998 16.527 17.85 16.527 9.851 0 17.85-7.406 17.85-16.527V37.589H71.589v15.867c0 3.283-3.259 5.95-7.272 5.95-4.014 0-7.273-2.667-7.273-5.95zm-29.088-28.9v28.9c0 3.283-3.259 5.95-7.273 5.95-4.013 0-7.272-2.667-7.272-5.95V40.8H2.833v12.656c0 9.121 7.999 16.527 17.85 16.527 9.852 0 17.85-7.406 17.85-16.527v-28.9H27.956z"
      transform="translate(7 7)"
    />
  </svg>
);

export default Logo;
