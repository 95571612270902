import React from 'react';

const ExclamationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FF3660"
      fillRule="evenodd"
      d="M12.828 15.547c.138 1.197.99 2.186 2.186 2.186 1.23 0 2.05-1.025 2.153-2.186l1.092-9.802c.204-1.675-1.57-3.245-3.245-3.245-1.672 0-3.449 1.57-3.278 3.245l1.092 9.802zm5.43 8.709c0-1.81-1.434-3.278-3.244-3.278a3.278 3.278 0 00-3.278 3.278c0 1.81 1.468 3.244 3.278 3.244a3.221 3.221 0 003.245-3.244z"
    />
  </svg>
);

export default ExclamationIcon;
