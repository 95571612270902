import React from 'react';

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width="20"
    height="20"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M4.871 14.553c.027 1.117 1.236 1.794 2.295 1.262l8.516-4.576c.464-.25.779-.707.779-1.242 0-.536-.315-.993-.779-1.243L7.166 4.185c-1.06-.532-2.268.138-2.295 1.256v9.112z"
    />
  </svg>
);

export default PlayIcon;
