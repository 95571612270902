import React from 'react';

const TieIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M49.393 17.587a1.739 1.739 0 011.993.962 26.463 26.463 0 013.077 11.874 22.216 22.216 0 01-3.05 11.115 1.726 1.726 0 01-1.942.87v-.002c-8.248-2.305-13.923-9.184-13.923-9.184.17-.792.275-1.595.317-2.403 2.187.981 5.83 2.132 5.83-.822s-3.616-1.797-5.824-.822a13.281 13.281 0 00-.317-2.424s5.626-6.831 13.839-9.164zm-24.947 9.164s-5.626-6.83-13.839-9.164a1.739 1.739 0 00-1.993.962 26.463 26.463 0 00-3.077 11.875 22.21 22.21 0 003.045 11.12c.365.7 1.17 1.058 1.941.868 8.25-2.313 13.923-9.184 13.923-9.184a15.394 15.394 0 01-.317-2.42c-2.202.977-5.865 2.164-5.865-.81 0-2.975 3.658-1.789 5.865-.808.029-.82.136-1.637.317-2.439zM30 25.127a6.15 6.15 0 012.519.515c.77.37 1.305 1.09 1.43 1.926.307 1.59.347 3.22.121 4.822a2.448 2.448 0 01-1.416 1.845c-1.27.53-3.466 1.109-5.372-.072a2.499 2.499 0 01-1.04-1.296v.002c-.323-.908-.651-2.604-.193-5.301a2.533 2.533 0 011.42-1.926 6.24 6.24 0 012.53-.515z"
    />
  </svg>
);

export default TieIcon;
