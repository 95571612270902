import React from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import TasteProfileLink from '../TasteProfileLink';
import TasteSection from '../TasteSection';

const TasteProfile = ({
  setActiveSuggestionPlaylist,
  trackTaste,
  artistTaste
}) => (
  <>
    <TasteProfileLink />
    <TasteSection
      setActiveSuggestionPlaylist={setActiveSuggestionPlaylist}
      {...trackTaste}
    />
    <TasteSection
      setActiveSuggestionPlaylist={setActiveSuggestionPlaylist}
      {...artistTaste}
    />
  </>
);

export default UserConsumer(TasteProfile);
