import React, { useState, useEffect } from 'react';

import { ARTIST_CARDS_CLASS_NAMES as cardsClassNames } from './TopArtists.constants';
import { UserConsumer } from '../../contexts/UserContext';
import {
  container,
  sectionIcon,
  userSummary,
  userSummaryContent,
  topArtistCaption,
  topArtistName,
  topArtistSubcaption,
  whipPlaylist,
  topModalButton
} from './TopArtists.module.sass';
import ArtistCard from './ArtistCard';
import DrumPads from '../DrumPads';
import PlaylistCard from '../PlaylistCard';
import { TopModalButton } from '../Button';
import { MicIconBox } from '../SectionIconBox';
import { getImageUrl } from '../../utils';
import { LOAD_COMPLETE_ENTANCE_DELAY as entranceDelay } from '../../config';

const TopArtists = ({
  shortTermTopArtists,
  setActiveTopList,
  setActiveSuggestionPlaylist
}) => {
  const [animateIn, setAnimateIn] = useState(false);
  const artists = shortTermTopArtists
    .slice(0, 5)
    .map(({ id, uri, popularity, name, images, followers }) => ({
      id,
      uri,
      popularity,
      artistName: name,
      artistCover: getImageUrl(images),
      followers: followers.total
    }));

  const playlistCovers = artists
    .slice(0, 4)
    .map(({ artistCover }) => artistCover);

  const suggestionPlaylistData = {
    coverImages: playlistCovers,
    titleOne: 'Top Artists',
    titleTwo: 'Playlist',
    summary: `A fresh playlist we think you'll like based on your top artists`
  };

  useEffect(() => {
    setTimeout(() => setAnimateIn(true), entranceDelay);
  }, []);

  return (
    <section className={container}>
      <MicIconBox className={sectionIcon} isPassive={!animateIn} />
      <div className={userSummary}>
        <DrumPads total={42} />
        <div className={userSummaryContent}>
          <h1 className={topArtistCaption}>
            <span>
              <span>Grooving to</span> <br />
              <span className={topArtistName}>{artists[0].artistName}</span>
            </span>
          </h1>
          <p className={topArtistSubcaption}>
            <span>
              When it comes to your favourite artist to listen to, nobody does
              it like {artists[0].artistName} for you!
            </span>
          </p>
        </div>
      </div>
      {artists.map((artist, index) => (
        <ArtistCard
          key={index}
          inViewDelay={animateIn ? 0 : entranceDelay + index * 150}
          className={cardsClassNames[index]}
          isFeatured={index === 0}
          title={index === 0 ? 'Your Top Artist' : `#${index + 1}`}
          {...artist}
        />
      ))}
      <PlaylistCard
        className={whipPlaylist}
        summary="A fresh playlist just for you."
        covers={playlistCovers}
        action={() => setActiveSuggestionPlaylist(suggestionPlaylistData)}
      />
      <TopModalButton
        className={topModalButton}
        onClick={() => setActiveTopList('artists')}
      />
      <DrumPads total={162} />
    </section>
  );
};

export default UserConsumer(TopArtists);
