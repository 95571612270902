import React, { Component, createRef } from 'react';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase,
  MOBILE_WIDTH_BREAKPOINT as mobileWidthBreakpoint
} from '../../config';

import { UserConsumer } from '../../contexts/UserContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import {
  modal,
  willClose,
  modalNav,
  hideNav,
  expandedNav,
  collapsedNav,
  navCollapse,
  navControl,
  disabled,
  closeModal,
  contentArea,
  animateIn,
  canPushUp
} from './MobileModal.module.sass';
import CircularArrowTopIcon from '../../vectors/CircularArrowTopIcon';
import ArrowLeftIcon from '../../vectors/ArrowLeftIcon';
import ArrowRightIcon from '../../vectors/ArrowRightIcon';
import CloseIcon from '../../vectors/CloseIcon';
import MobileArtistDetails from '../MobileArtistDetails';
import MobileTrackDetails from '../MobileTrackDetails';
import MobileTopList from '../MobileTopList';

class MobileModal extends Component {
  state = {
    navHistory: [],
    hashHistory: [],
    navIndex: 0
  };

  contentPositionY = 0;
  targetY = 0;
  contentAreaRef = createRef();
  ease = true;

  initializeVirtualScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaY }) => {
      if (this.preventScroll) {
        return;
      }

      const { containerHeight } = this.state;
      const maxOverflow = containerHeight - window.innerHeight + 80;

      this.targetY += deltaY;
      this.targetY = Math.min(Math.max(-maxOverflow, this.targetY), 0);
    });
  };

  setContainerHeight = (initializeScroll, childContainerLoaded) => {
    const { navReady } = this.state;
    childContainerLoaded && !navReady && this.setState({ navReady: true });

    if (window.innerWidth >= mobileWidthBreakpoint) {
      return this.closeModal();
    }

    const areaHeight = this.contentArea.getBoundingClientRect().height;

    this.setState(
      {
        containerHeight: areaHeight
      },
      () => {
        initializeScroll && this.initializeVirtualScroll();
      }
    );

    const maxOverflow = areaHeight - window.innerHeight + 80;

    if (this.targetY < 0 && this.targetY < -maxOverflow) {
      this.targetY = -maxOverflow;
    }
  };

  scrollTo = (position, ease = true) => {
    this.targetY = position;
    this.ease = ease;
  };

  animatePosition = () => {
    const { navExpansion } = this.state;
    const ease = this.ease ? scrollEase : 1;
    this.animateScroll = requestAnimationFrame(this.animatePosition);

    this.contentPositionY += (this.targetY - this.contentPositionY) * ease;
    const translateY = parseFloat(this.contentPositionY.toFixed(2));
    const translate = `translate3d(0, ${translateY}px, 0)`;
    const modalContentWrapper = [...this.contentArea.children].find(
      el => el.nodeName === 'DIV'
    );
    modalContentWrapper.querySelector('div').style.transform = translate;

    const modalContentWrapperStyle = navExpansion
      ? `transform: translate3d(0, ${navExpansion}px, 0); transition-duration: .5s`
      : '';
    modalContentWrapper.style.cssText = modalContentWrapperStyle;

    if (!this.ease) {
      this.ease = true;
    }

    this.animateParallax(Math.abs(translateY));
  };

  animateParallax = pos => {
    const cover = document.querySelector('[data-cover]');
    if (cover) {
      const coverTranslateY = pos * 0.285;
      cover.style.transform = `translate3d(0, ${coverTranslateY}px, 0)`;
      const coverImage = document.querySelector('[data-cover-image]');
      const scale = 1 + pos * 0.0035;
      coverImage.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    }
  };

  disableScroll = preventScroll => {
    this.preventScroll = preventScroll;
  };

  resetModal = () => {
    this.setContainerHeight();
    this.scrollTo(0, false);
  };

  closeModal = () => {
    const {
      setActiveArtist,
      setActiveTrack,
      setActiveTopList,
      setMobileModalOpen
    } = this.props;

    this.setState({ willCloseModal: true });
    setMobileModalOpen(false);

    setTimeout(() => {
      setActiveArtist(null);
      setActiveTrack(null);
      setActiveTopList(null);
    }, 800);
  };

  getActiveModal = () => {
    const {
      activeArtist,
      activeTrack,
      activeTopList,
      setActiveArtist,
      setActiveTrack,
      setActiveTopList
    } = this.props;
    if (activeArtist) {
      return {
        typeAction: setActiveArtist,
        id: activeArtist
      };
    } else if (activeTrack) {
      return {
        typeAction: setActiveTrack,
        id: activeTrack
      };
    } else {
      return {
        typeAction: setActiveTopList,
        id: activeTopList
      };
    }
  };

  addToNavHistory = () => {
    const { navHistory, navIndex, hashHistory } = this.state;
    this.setState(
      {
        hashHistory: hashHistory.slice(0, navIndex + 1)
      },
      this.pushHash
    );
    const currentNavHistory = navHistory.slice(0, navIndex + 1);
    currentNavHistory.push(this.getActiveModal());

    this.setState({
      navHistory: currentNavHistory,
      navIndex: navIndex + 1
    });
  };

  gotoPrev = () => {
    const { navHistory, navIndex } = this.state;
    const { typeAction, id } = navHistory[navIndex - 1];
    typeAction(id, false);

    this.setState({
      navIndex: navIndex - 1
    });
  };

  gotoNext = () => {
    const { navHistory, navIndex } = this.state;
    const { typeAction, id } = navHistory[navIndex + 1];
    typeAction(id, false);

    this.setState({
      navIndex: navIndex + 1
    });
  };

  backward = () => {
    window.history.go(-1);
  };

  forward = () => {
    window.history.go(1);
  };

  close = () => {
    const { navIndex } = this.state;
    window.history.go(-(navIndex + 1));
  };

  expandNav = navExpansion => {
    this.setState({
      navExpansion,
      hasCollapsedNav: false
    });
    this.disableScroll(true);
  };

  collapseNav = () => {
    this.setState({
      navExpansion: false,
      hasCollapsedNav: true
    });
    this.disableScroll(false);
  };

  pushHash = () => {
    const { hashHistory } = this.state;
    const hash = `#${Math.random()
      .toString(36)
      .replace('0.', '')}`;

    window.history.pushState(null, null, hash);
    this.setState({ hashHistory: [...hashHistory, hash] });
  };

  onPopState = () => {
    const { navIndex, hashHistory } = this.state;
    const currHash = window.location.hash;

    if (currHash === hashHistory[navIndex - 1]) {
      this.gotoPrev();
    } else if (currHash === hashHistory[navIndex + 1]) {
      this.gotoNext();
    } else {
      return this.closeModal();
    }
  };

  componentDidUpdate(prevProps) {
    const { activeArtist, activeTrack, activeTopList, modalKey } = this.props;

    if (prevProps.modalKey !== modalKey) {
      this.addToNavHistory();
    }

    if (prevProps.activeArtist && !activeArtist) {
      this.resetModal();
    }

    if (prevProps.activeTrack && !activeTrack) {
      this.resetModal();
    }

    if (prevProps.activeTopList && !activeTopList) {
      this.resetModal();
    }
  }

  componentDidMount() {
    this.pushHash();
    this.setState({ navHistory: [this.getActiveModal()] });
    this.contentArea = this.contentAreaRef.current;
    this.setContainerHeight(true);
    this.props.setVScrollable(false);
    this.props.setMobileModalOpen(true);
    this.animateScroll = requestAnimationFrame(this.animatePosition);
    window.addEventListener('resize', () => this.setContainerHeight(false));
    window.addEventListener('popstate', this.onPopState);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animateScroll);
    this.props.setVScrollable(true);
    this.vScroll.destroy();
    window.removeEventListener('resize', () => this.setContainerHeight(false));
    window.removeEventListener('popstate', this.onPopState);
  }

  render() {
    const { activeArtist, activeTrack, activeTopList } = this.props;
    const {
      navReady,
      navHistory,
      navIndex,
      navExpansion,
      hasCollapsedNav,
      willCloseModal
    } = this.state;

    const isNavExpanded = Boolean(navExpansion);

    return (
      <div className={cx(modal, { [willClose]: willCloseModal })}>
        <nav
          className={cx(modalNav, {
            [hideNav]: !navReady,
            [expandedNav]: isNavExpanded,
            [collapsedNav]: hasCollapsedNav
          })}
        >
          <div>
            <span onClick={this.collapseNav} className={navCollapse}>
              <CircularArrowTopIcon />
            </span>
            <span
              onClick={this.backward}
              className={cx(navControl, {
                [disabled]: navIndex < 1
              })}
            >
              <ArrowLeftIcon />
            </span>
            <span
              onClick={this.forward}
              className={cx(navControl, {
                [disabled]: navIndex === navHistory.length - 1
              })}
            >
              <ArrowRightIcon />
            </span>
          </div>
          <span className={closeModal} onClick={this.close}>
            <CloseIcon />
          </span>
        </nav>
        <div
          ref={this.contentAreaRef}
          className={cx(contentArea, {
            [animateIn]: !navReady,
            [canPushUp]: hasCollapsedNav
          })}
        >
          {activeArtist && (
            <MobileArtistDetails
              resetModalScroll={this.setContainerHeight}
              disableScroll={this.disableScroll}
              scrollTo={this.scrollTo}
              willCloseModal={willCloseModal}
            />
          )}
          {activeTrack && (
            <MobileTrackDetails
              resetModalScroll={this.setContainerHeight}
              disableScroll={this.disableScroll}
              scrollTo={this.scrollTo}
              willCloseModal={willCloseModal}
            />
          )}
          {activeTopList && (
            <MobileTopList
              resetModalScroll={this.setContainerHeight}
              scrollTo={this.scrollTo}
              expandNav={this.expandNav}
              collapseNav={this.collapseNav}
              isNavExpanded={isNavExpanded}
              hasCollapsedNav={hasCollapsedNav}
              willCloseModal={willCloseModal}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ExperienceConsumer(UserConsumer(MobileModal));
