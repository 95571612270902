import React from 'react';
import VirtualScroll from 'virtual-scroll';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/storage';

import {
  BlazingHot,
  Trending,
  WellKnown,
  Lowkey,
  Underground
} from '../components/AnimatedVectors';
import {
  CrownIconBox,
  ButterflyIconBox,
  DanceAltIconBox,
  DiscFlameIconBox,
  HarpIconBox,
  HeadPhoneIconBox,
  PhonographIconBox,
  TieIconBox
} from '../components/SectionIconBox';
import StarIcon from '../vectors/StarIcon';
import FireIcon from '../vectors/FireIcon';
import GhostIcon from '../vectors/GhostIcon';
import logo from '../shared/images/logo.svg';
import spotifyLogo from '../shared/images/spotify-logo.svg';
import shareBgGradientOne from '../shared/images/share-bg-gradient-one.svg';
import shareBgGradientTwo from '../shared/images/share-bg-gradient-two.svg';
import wavesBg from '../shared/images/waves-bg.png';

import playlistCoverArtifact from '../shared/images/playlistCoverArtifact.svg';
import { AUDIO_FADE_DURATION as audioFadeDuration } from '../config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_DATABASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
firebase.initializeApp(firebaseConfig);

export const getHashParams = () => {
  let hashParams = {};

  const query = window.location.hash.substring(1);
  const entries = query.split('&');

  entries.forEach(entry => {
    const [key, value] = entry.split('=');
    hashParams[key] = value;
  });

  window.history.replaceState({}, '', window.location.href.split('#')[0]);

  return hashParams;
};

export const getImageUrl = images => {
  if (!images) {
    return;
  }

  const image = images.find(({ height }) => height === 640) || images[0];
  return image && image.url;
};

export const preloadImages = images => {
  return new Promise(resolve => {
    if (!images.length) {
      return resolve('loaded');
    }

    let loadedImages = 0;
    const onImageRequestComplete = () => {
      loadedImages++;

      if (loadedImages === images.length) {
        resolve('loaded');
      }
    };

    images.forEach(image => {
      const img = new Image();
      img.onload = onImageRequestComplete;
      img.onerror = onImageRequestComplete;
      img.src = image;
    });
  });
};

export const uploadToShareImagesBucket = (fileName, file) => {
  return new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`${fileName}.png`);
    imageRef
      .put(file)
      .then(() => {
        resolve('uploaded');
      })
      .catch(error => reject(error));
  });
};

export const getPopularityGroup = value => {
  switch (true) {
    case value < 30:
      return {
        label: 'Underground',
        icon: <Underground />,
        mobileNavIcon: GhostIcon
      };
    case value < 55:
      return {
        label: 'Lowkey',
        icon: <Lowkey />,
        mobileNavIcon: StarIcon
      };
    case value < 80:
      return {
        label: 'Well Known',
        icon: <WellKnown />,
        mobileNavIcon: StarIcon
      };
    case value < 88:
      return {
        label: 'Trending',
        icon: <Trending />,
        mobileNavIcon: StarIcon
      };
    default:
      return {
        label: 'Blazing Hot',
        icon: <BlazingHot />,
        mobileNavIcon: FireIcon
      };
  }
};

export const getTrackTaste = async (tracks, getArtists, getRecommendations) => {
  const recentTracks = [];
  const recentDecadeTracks = [];
  const doubleZerosTracks = [];
  const classicsTracks = [];
  const recentTracksArtists = [];
  const recentDecadeTracksArtists = [];
  const doubleZerosTracksArtists = [];
  const classicsTracksArtists = [];

  tracks.forEach(({ artists, album, id }) => {
    const { images, release_date: releaseDate } = album;
    const [year, month] = releaseDate.split('-');
    const validDate = month ? releaseDate : `${year}-01-01`;

    const monthDifference = moment().diff(moment(validDate), 'months');

    if (monthDifference < 8) {
      recentTracksArtists.push(artists[0].id);
      return recentTracks.push({ id, cover: getImageUrl(images) });
    }
    if (year > 2009) {
      recentDecadeTracksArtists.push(artists[0].id);
      return recentDecadeTracks.push({ id, cover: getImageUrl(images) });
    }
    if (year > 1999) {
      doubleZerosTracksArtists.push(artists[0].id);
      return doubleZerosTracks.push({ id, cover: getImageUrl(images) });
    }
    classicsTracksArtists.push(artists[0].id);
    return classicsTracks.push({ id, cover: getImageUrl(images) });
  });

  const recentFraction = recentTracksArtists.length / tracks.length;
  const recentDecadeFraction = recentDecadeTracksArtists.length / tracks.length;
  const doubleZerosFraction = doubleZerosTracksArtists.length / tracks.length;
  const classicsFraction = classicsTracksArtists.length / tracks.length;

  const getArtistsCovers = async artistsIds => {
    let artists = [];
    await getArtists(artistsIds.slice(0, 50).join(), data => (artists = data));
    const artistsWithCovers = artists.filter(({ images }) =>
      getImageUrl(images)
    );
    return artistsWithCovers
      .slice(0, 4)
      .map(({ images }) => getImageUrl(images));
  };

  const minFraction = Math.min(
    recentFraction,
    recentDecadeFraction,
    doubleZerosFraction,
    classicsFraction
  );

  const maxFraction = Math.max(
    recentFraction,
    recentDecadeFraction,
    doubleZerosFraction,
    classicsFraction
  );

  if (minFraction >= 0.14) {
    const ids = [
      classicsTracksArtists[0],
      doubleZerosTracksArtists[0],
      recentDecadeTracksArtists[0],
      recentTracksArtists[0],
      ...recentTracksArtists,
      ...recentDecadeTracksArtists,
      ...doubleZerosTracksArtists,
      ...classicsTracksArtists
    ];
    const tracks = [
      classicsTracks[0],
      doubleZerosTracks[0],
      recentDecadeTracks[0],
      recentTracks[0],
      ...recentTracks,
      ...recentDecadeTracks,
      ...doubleZerosTracks,
      ...classicsTracks
    ];
    const artistsIds = [...new Set(ids)];
    const artistsCovers = await getArtistsCovers(artistsIds);
    const seedTracks = tracks
      .slice(0, 4)
      .map(({ id }) => id)
      .join();
    const playlistParams = {
      seed_tracks: seedTracks,
      limit: 25
    };

    const albumCovers = tracks.map(({ cover }) => cover);
    const playlistCovers = [...new Set(albumCovers)].slice(0, 4);

    let playlistTracks = [];
    await getRecommendations(
      playlistParams,
      tracks => (playlistTracks = tracks)
    );

    return {
      artistsCovers,
      playlistCovers,
      playlistTracks,
      shareKey: '01',
      labelOne: 'The Time',
      labelTwo: 'Traveler',
      description: `Traveling though sound from different times is your favourite thing to do.`,
      titleOne: 'The Time',
      titleTwo: 'Traveler',
      summary: `A fresh playlist we think you'll like based on your time travels`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: HarpIconBox
    };
  }

  if (maxFraction === recentFraction) {
    const artistsIds = [...new Set(recentTracksArtists)];
    const artistsCovers = await getArtistsCovers(artistsIds);
    const seedTracks = recentTracks
      .slice(0, 5)
      .map(({ id }) => id)
      .join();
    const playlistParams = {
      seed_tracks: seedTracks,
      limit: 100
    };
    const albumCovers = recentTracks.map(({ cover }) => cover);
    const playlistCovers = [...new Set(albumCovers)].slice(0, 4);

    let recommendedTracks = [];
    await getRecommendations(
      playlistParams,
      tracks => (recommendedTracks = tracks)
    );
    const periodTracks = recommendedTracks.filter(({ album }) => {
      const { release_date: releaseDate } = album;
      const [year, month] = releaseDate.split('-');
      const validDate = month ? releaseDate : `${year}-01-01`;
      const monthDifference = moment().diff(moment(validDate), 'months');
      return monthDifference < 8;
    });

    return {
      artistsCovers,
      playlistCovers,
      playlistTracks: periodTracks.slice(0, 25),
      shareKey: '02',
      labelOne: 'New Sound',
      labelTwo: 'Hunter',
      description: 'You like your music served fresh and piping hot.',
      titleOne: 'New Sound',
      titleTwo: 'Hunter',
      summary: `A fresh playlist we think you'll like based on new sounds`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: DiscFlameIconBox
    };
  }

  if (maxFraction === recentDecadeFraction) {
    const artistsIds = [...new Set(recentDecadeTracksArtists)];
    const artistsCovers = await getArtistsCovers(artistsIds);
    const seedTracks = recentDecadeTracks
      .slice(0, 5)
      .map(({ id }) => id)
      .join();
    const playlistParams = {
      seed_tracks: seedTracks,
      limit: 100
    };
    const albumCovers = recentDecadeTracks.map(({ cover }) => cover);
    const playlistCovers = [...new Set(albumCovers)].slice(0, 4);

    let recommendedTracks = [];
    await getRecommendations(
      playlistParams,
      tracks => (recommendedTracks = tracks)
    );
    const periodTracks = recommendedTracks.filter(({ album }) => {
      const { release_date: releaseDate } = album;
      const [year, month] = releaseDate.split('-');
      const validDate = month ? releaseDate : `${year}-01-01`;
      const monthDifference = moment().diff(moment(validDate), 'months');
      return year > 2009 && monthDifference > 7;
    });

    return {
      artistsCovers,
      playlistCovers,
      playlistTracks: periodTracks.slice(0, 25),
      shareKey: '03',
      labelOne: 'The',
      labelTwo: 'Audiohead',
      description: 'You like a mix of the not-so-old and the new.',
      titleOne: 'The',
      titleTwo: 'Audiohead',
      summary: `A fresh playlist we think you'll like as an audiohead`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: HeadPhoneIconBox
    };
  }

  if (maxFraction === doubleZerosFraction) {
    const artistsIds = [...new Set(doubleZerosTracksArtists)];
    const artistsCovers = await getArtistsCovers(artistsIds);
    const seedTracks = doubleZerosTracks
      .slice(0, 5)
      .map(({ id }) => id)
      .join();
    const playlistParams = {
      seed_tracks: seedTracks,
      limit: 100
    };
    const albumCovers = doubleZerosTracks.map(({ cover }) => cover);
    const playlistCovers = [...new Set(albumCovers)].slice(0, 4);

    let recommendedTracks = [];
    await getRecommendations(
      playlistParams,
      tracks => (recommendedTracks = tracks)
    );
    const periodTracks = recommendedTracks.filter(({ album }) => {
      const { release_date: releaseDate } = album;
      const [year] = releaseDate.split('-');
      return year > 1999 && year < 2010;
    });

    return {
      artistsCovers,
      playlistCovers,
      playlistTracks: periodTracks,
      shareKey: '04',
      labelOne: `The '00`,
      labelTwo: 'Agent',
      description: 'As a 00 agent, serving jams from the 00 era is your thing.',
      titleOne: 'The 00',
      titleTwo: 'Agent',
      summary: `A fresh playlist we think you'll like as a 00 agent`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: TieIconBox
    };
  }

  const artistsIds = [...new Set(classicsTracksArtists)];
  const artistsCovers = await getArtistsCovers(artistsIds);
  const seedTracks = classicsTracks
    .slice(0, 5)
    .map(({ id }) => id)
    .join();
  const playlistParams = {
    seed_tracks: seedTracks,
    limit: 100
  };
  const albumCovers = classicsTracks.map(({ cover }) => cover);
  const playlistCovers = [...new Set(albumCovers)].slice(0, 4);

  let recommendedTracks = [];
  await getRecommendations(
    playlistParams,
    tracks => (recommendedTracks = tracks)
  );
  const periodTracks = recommendedTracks.filter(({ album }) => {
    const { release_date: releaseDate } = album;
    const [year] = releaseDate.split('-');
    return year < 2000;
  });

  return {
    artistsCovers,
    playlistCovers,
    playlistTracks: periodTracks,
    shareKey: '05',
    labelOne: 'The Classics',
    labelTwo: 'Lover',
    description: 'You’re an old soul...',
    titleOne: 'The Classics',
    titleTwo: 'Lover',
    summary: `A fresh playlist we think you'll like as a classics lover`,
    playlistDescription: 'A fresh playlist just for you.',
    sectionIcon: PhonographIconBox
  };
};

export const getArtistsTaste = (
  shortTermTopArtists,
  mediumTermTopArtists,
  longTermTopArtists
) => {
  const allOccurence = [];
  const doubleOccurence = [];

  longTermTopArtists.forEach(({ id, images }) => {
    const inMediumTerm = Boolean(
      mediumTermTopArtists.find(artist => artist.id === id)
    );
    const inShortTerm = Boolean(
      shortTermTopArtists.find(artist => artist.id === id)
    );

    if (inMediumTerm && inShortTerm) {
      allOccurence.push({ id, images });
    } else if (inMediumTerm || inShortTerm) {
      doubleOccurence.push({ id, images });
    }
  });

  mediumTermTopArtists.forEach(({ id, images }) => {
    if (!doubleOccurence.find(artist => artist.id === id)) {
      const inShortTerm = Boolean(
        shortTermTopArtists.find(artist => artist.id === id)
      );
      inShortTerm && doubleOccurence.push({ id, images });
    }
  });

  if (allOccurence.length > 4) {
    const artistsWithCovers = allOccurence.filter(
      ({ images }) => images.length
    );
    const artistsCovers = artistsWithCovers
      .slice(0, 4)
      .map(({ images }) => getImageUrl(images));
    return {
      artistsCovers,
      playlistCovers: artistsCovers,
      labelOne: 'Ultimate',
      labelTwo: 'Stan!',
      shareKey: '06',
      description:
        'You stan for favorite artists in a good way, if you get what we mean.',
      titleOne: 'Ultimate',
      titleTwo: 'Stan',
      summary: `A fresh playlist we think you'll like based on your stans`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: CrownIconBox
    };
  } else if (allOccurence.length > 0 || doubleOccurence > 4) {
    const artists = [...new Set([...allOccurence, ...doubleOccurence])];
    const artistsWithCovers = artists.filter(({ images }) => images.length);
    const artistsCovers = artistsWithCovers
      .slice(0, 4)
      .map(({ images }) => getImageUrl(images));
    return {
      artistsCovers,
      playlistCovers: artistsCovers,
      shareKey: '07',
      labelOne: 'The',
      labelTwo: 'Mixologist',
      description: 'Mixing and matching your artists is your style.',
      titleOne: 'The',
      titleTwo: 'Mixologist',
      summary: `A fresh playlist we think you'll like based on your artists style`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: DanceAltIconBox
    };
  } else {
    const artists = [
      ...new Set([
        ...shortTermTopArtists,
        ...mediumTermTopArtists,
        ...longTermTopArtists
      ])
    ];
    const artistsWithCovers = artists.filter(({ images }) => images.length);
    const artistsCovers = artistsWithCovers
      .slice(0, 4)
      .map(({ images }) => getImageUrl(images));
    return {
      artistsCovers,
      playlistCovers: artistsCovers,
      shareKey: '08',
      labelOne: 'The Shape',
      labelTwo: 'Shifter',
      description: 'You have a wide range of artists you support.',
      titleOne: 'The Shape',
      titleTwo: 'Shifter',
      summary: `A fresh playlist we think you'll like as a shapeshifter`,
      playlistDescription: 'A fresh playlist just for you.',
      sectionIcon: ButterflyIconBox
    };
  }
};

export const formatReleaseDate = date => {
  if (!date) {
    return '';
  }
  const [year, month] = date.split('-');
  return month ? moment(date).format('MMMM YYYY') : year;
};

export const playAudio = audio => {
  return new Promise(async (resolve, reject) => {
    let volCounter = 0;
    audio.volume = 0;
    audio.play();

    const promise = audio.play();

    try {
      await promise;
      resolve('playing');

      let volumeFade = setInterval(() => {
        volCounter++;
        audio.volume = volCounter / 10;
      }, 100);

      setTimeout(() => {
        clearInterval(volumeFade);
      }, audioFadeDuration);
    } catch (error) {
      reject(error);
    }
  });
};

export const pauseAudio = audio => {
  let volCounter = audio.volume * 10;

  let volumeFade = setInterval(() => {
    volCounter--;
    audio.volume = Math.max(volCounter / 10, 0);
  }, 100);

  setTimeout(() => {
    clearInterval(volumeFade);
    audio.pause();
  }, audioFadeDuration);
};

export const fadeOutAudio = audio => {
  let volCounter = audio.volume * 10;

  let volumeFade = setInterval(() => {
    volCounter--;
    audio.volume = Math.max(volCounter / 10, 0);
  }, 100);

  setTimeout(() => {
    clearInterval(volumeFade);
  }, 1000);
};

export const scrollViewListener = (container, fraction, onEnterView) => {
  const getInViewState = () => {
    if (!container) {
      return;
    }

    const left = container.getBoundingClientRect().left;
    const inView = window.innerWidth >= left + fraction * container.clientWidth;

    if (inView) {
      return onEnterView();
    }

    requestAnimationFrame(getInViewState);
  };

  requestAnimationFrame(getInViewState);
};

export const mobileScrollViewListener = (container, fraction, onEnterView) => {
  const getInViewState = onStart => {
    if (!container) {
      return;
    }

    const top = container.getBoundingClientRect().top;
    const inView =
      window.innerHeight >= top + fraction * container.clientHeight;

    if (inView) {
      return onEnterView(onStart);
    }

    requestAnimationFrame(() => getInViewState());
  };

  requestAnimationFrame(() => getInViewState(true));
};

export const onScroll = (onStart, onEnd) => {
  let isScrolling = false;
  let hasScrollEnded;
  const vScroll = new VirtualScroll();

  const onScrollEnd = () => {
    onEnd && onEnd();
    isScrolling = false;
  };

  vScroll.on(() => {
    if (!isScrolling) {
      onStart && onStart();
      isScrolling = true;
    }

    clearTimeout(hasScrollEnded);
    hasScrollEnded = setTimeout(onScrollEnd, 66);
  });
};

export const isModalContainerInView = (container, fraction, offset) => {
  const top = container.getBoundingClientRect().top;
  return (
    (offset || window.innerHeight * 0.9) >=
    top + fraction * container.clientHeight
  );
};

export const modalScrollViewListener = (
  container,
  fraction,
  onEnterView,
  offset
) => {
  const getInViewState = onStart => {
    if (!container) {
      return;
    }

    const inView = isModalContainerInView(container, fraction, offset);

    if (inView) {
      return onEnterView(onStart);
    }

    requestAnimationFrame(() => getInViewState());
  };

  requestAnimationFrame(() => getInViewState(true));
};

export const getPlaylistName = (playlistType, name) => {
  const playlistNames = {
    shortTermTopTracks: 'Uu - Recent Top Songs',
    mediumTermTopTracks: 'Uu - Past 6 Months Top Songs',
    longTermTopTracks: 'Uu - All TIme Top Songs',
    track: `Uu - ${name}`,
    artist: `Uu - ${name}`
  };

  return playlistNames[playlistType];
};

export const getPlaylistCoverOptions = (playlistType, name) => {
  const playlistsCoverOptions = {
    shortTermTopTracks: {
      titleOne: 'Recent',
      titleTwo: 'Top Songs'
    },
    mediumTermTopTracks: {
      titleOne: 'Past 6 Months',
      titleTwo: 'Top Songs'
    },
    longTermTopTracks: {
      titleOne: 'All Time',
      titleTwo: 'Top Songs'
    },
    track: {
      titleOne: name,
      titleTwo: 'Cruuunched',
      titleTwoColor: '#33FF7A'
    },
    artist: {
      titleOne: name,
      titleTwo: 'Cruuunched',
      titleTwoColor: '#33FF7A'
    }
  };

  return playlistsCoverOptions[playlistType];
};

const loadCanvasImage = url => {
  return new Promise(resolve => {
    if (!url) {
      return resolve('');
    }

    const img = new Image();
    const onImageRequestComplete = () => {
      resolve(img);
    };
    img.crossOrigin = 'anonymous';
    img.onload = onImageRequestComplete;
    img.onerror = onImageRequestComplete;
    img.src = url;
  });
};

const getKerningValue = (ctx, charA, charB) => {
  const charAWidth = ctx.measureText(charA).width;
  const charBWidth = ctx.measureText(charB).width;
  const kernedWidth = ctx.measureText(`${charA}${charB}`).width;
  return kernedWidth - (charAWidth + charBWidth);
};

const getTextWidth = (ctx, text, letterSpacing = 0) => {
  const chars = text.split('');
  return chars.reduce((acc, curr, index) => {
    const nextChar = chars[index + 1] || '';
    const charWidth = ctx.measureText(curr).width;
    const kerningValue = getKerningValue(ctx, curr, nextChar);
    return acc + charWidth + kerningValue + letterSpacing;
  }, 0);
};

const toCanvasEllipsis = (ctx, text, maxWidth, letterSpacing = 0) => {
  const chars = text.split('');
  const ellipsisWidth = getTextWidth(ctx, '...', letterSpacing);
  const maxTextWidth = maxWidth - ellipsisWidth;
  let newText = '';
  let newTextWidth = 0;
  let hasSetEllipsis = false;

  chars.forEach((char, index) => {
    if (hasSetEllipsis) {
      return;
    }

    const nextChar = chars[index + 1] || '';
    const charWidth = ctx.measureText(char).width;
    const kerningValue = getKerningValue(ctx, char, nextChar);
    newTextWidth += charWidth + kerningValue + letterSpacing;
    if (newTextWidth < maxTextWidth || chars.length - index < 3) {
      newText += char;
    } else {
      newText += '...';
      hasSetEllipsis = true;
    }
  });

  return newText;
};

export const createTopArtistsShareCover = (timeRange, data) => {
  return new Promise(async resolve => {
    const [
      firstArtist,
      secondArtist,
      thirdArtist,
      fourthArtist,
      fifthArtist
    ] = data;
    const canvas = document.createElement('canvas');
    const canvasWidth = 1200;
    const canvasHeight = 628;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    const otherArtistsLetterSpacing = -0.83;

    const fillText = (text, x, y, letterSpacing = 0) => {
      const chars = text.split('');
      let currentX = x;
      chars.forEach((char, index) => {
        ctx.fillText(char, currentX, y);
        const nextChar = chars[index + 1] || '';
        const charWidth = ctx.measureText(char).width;
        const kerningValue = getKerningValue(ctx, char, nextChar);
        currentX += charWidth + kerningValue + letterSpacing;
      });
    };

    const getOtherArtistTitle = (number, artist) => {
      const otherArtistsWidth = 250;

      return toCanvasEllipsis(
        ctx,
        `${number}. ${artist.artistName}`,
        otherArtistsWidth,
        otherArtistsLetterSpacing
      );
    };

    const fillImage = (img, dim, x, y) => {
      const scale = Math.max(dim / img.width, dim / img.height);
      const posX = x + (dim / 2 - (img.width / 2) * scale);
      const posY = y + (dim / 2 - (img.height / 2) * scale);
      ctx.drawImage(img, posX, posY, img.width * scale, img.height * scale);
    };

    const bgGradient = await loadCanvasImage(shareBgGradientOne);
    ctx.drawImage(bgGradient, 0, 0);

    ctx.globalCompositeOperation = 'overlay';

    const wavesBgImage = await loadCanvasImage(wavesBg);
    ctx.drawImage(wavesBgImage, 0, -45);

    const spotifyLogoImage = await loadCanvasImage(spotifyLogo);
    ctx.drawImage(spotifyLogoImage, 110, 42.5, 40, 40);

    const logoImage = await loadCanvasImage(logo);
    ctx.drawImage(logoImage, 170, 40, 45, 45);

    ctx.globalCompositeOperation = 'source-over';

    ctx.fillStyle = '#FFFFFF';
    ctx.font = '700 30px Circular Std';
    ctx.textBaseline = 'top';

    fillText(`${timeRange} Top Artists`, 110, 125, -0.83);

    ctx.font = '900 91px Circular Std';

    const firstArtistsLetterSpacing = -4.55;

    const firstArtistName = toCanvasEllipsis(
      ctx,
      firstArtist.artistName,
      633,
      firstArtistsLetterSpacing
    );
    fillText('My top artist is', 110, 171, firstArtistsLetterSpacing);
    fillText(firstArtistName, 110, 280, firstArtistsLetterSpacing);

    ctx.globalAlpha = 0.8;
    ctx.font = '300 30px Circular Std';

    fillText(
      getOtherArtistTitle('2', secondArtist),
      110,
      406,
      otherArtistsLetterSpacing
    );
    fillText(
      getOtherArtistTitle('3', thirdArtist),
      110,
      462,
      otherArtistsLetterSpacing
    );
    fillText(
      getOtherArtistTitle('4', fourthArtist),
      376,
      406,
      otherArtistsLetterSpacing
    );
    fillText(
      getOtherArtistTitle('5', fifthArtist),
      376,
      462,
      otherArtistsLetterSpacing
    );

    ctx.globalAlpha = 1;

    ctx.save();
    ctx.beginPath();
    ctx.arc(940, 300, 175, 0, Math.PI * 2);
    ctx.clip();

    const firstArtistImage = await loadCanvasImage(firstArtist.artistCover);
    fillImage(firstArtistImage, 350, 765, 125);
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(768, 618, 138, 0, Math.PI * 2);
    ctx.clip();

    const secondArtistImage = await loadCanvasImage(secondArtist.artistCover);
    fillImage(secondArtistImage, 276, 630, 480);
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(1088, 646, 109, 0, Math.PI * 2);
    ctx.clip();

    const thirdArtistImage = await loadCanvasImage(thirdArtist.artistCover);
    fillImage(thirdArtistImage, 218, 979, 537);
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(1136, 63, 94, 0, Math.PI * 2);
    ctx.clip();

    const fourthArtistImage = await loadCanvasImage(fourthArtist.artistCover);
    fillImage(fourthArtistImage, 188, 1042, -31);
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(1192, 439, 72, 0, Math.PI * 2);
    ctx.clip();

    const fifthArtistImage = await loadCanvasImage(fifthArtist.artistCover);
    fillImage(fifthArtistImage, 144, 1120, 367);
    ctx.restore();

    ctx.globalCompositeOperation = 'overlay';
    ctx.font = '700 30px Circular Std';
    fillText('cruuunchify.com', 867, 40, -0.85);

    ctx.globalCompositeOperation = 'source-over';

    canvas.toBlob(blob => resolve(blob), 'image/png', 1);
  });
};

export const createTopTracksShareCover = (timeRange, data) => {
  return new Promise(async resolve => {
    const [firstTrack, secondTrack, thirdTrack, fourthTrack, fifthTrack] = data;
    const canvas = document.createElement('canvas');
    const canvasWidth = 1200;
    const canvasHeight = 628;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    const fillText = (text, x, y, letterSpacing = 0) => {
      const chars = text.split('');
      let currentX = x;
      chars.forEach((char, index) => {
        ctx.fillText(char, currentX, y);
        const nextChar = chars[index + 1] || '';
        const charWidth = ctx.measureText(char).width;
        const kerningValue = getKerningValue(ctx, char, nextChar);
        currentX += charWidth + kerningValue + letterSpacing;
      });
    };

    const addTrackBlock = (number, track, x, y) => {
      const trackTitleWidth = 415;
      const trackTitleLetterSpacing = -1.36;
      const trackArtistsWidth = 415;
      const trackArtistsLetterSpacing = -0.72;

      ctx.font = '700 49px Circular Std';
      fillText(`${number}.`, x, y, trackTitleLetterSpacing);

      const firstTrackTitle = toCanvasEllipsis(
        ctx,
        track.name,
        trackTitleWidth,
        trackTitleLetterSpacing
      );
      fillText(firstTrackTitle, x + 45, y, trackTitleLetterSpacing);

      ctx.globalAlpha = 0.8;
      ctx.font = '300 26px Circular Std';

      const firstTrackArtists = toCanvasEllipsis(
        ctx,
        track.artists.join(', '),
        trackArtistsWidth,
        trackArtistsLetterSpacing
      );
      fillText(firstTrackArtists, x + 45, y + 62, trackArtistsLetterSpacing);

      ctx.globalAlpha = 1;
    };

    const bgGradient = await loadCanvasImage(shareBgGradientTwo);
    ctx.drawImage(bgGradient, 0, 0);

    ctx.globalCompositeOperation = 'overlay';

    const wavesBgImage = await loadCanvasImage(wavesBg);
    ctx.drawImage(wavesBgImage, 0, -45);

    const spotifyLogoImage = await loadCanvasImage(spotifyLogo);
    ctx.drawImage(spotifyLogoImage, 110, 42.5, 40, 40);

    const logoImage = await loadCanvasImage(logo);
    ctx.drawImage(logoImage, 170, 40, 45, 45);

    ctx.globalCompositeOperation = 'source-over';

    ctx.fillStyle = '#FFFFFF';
    ctx.font = '700 30px Circular Std';
    ctx.textBaseline = 'top';

    fillText(`${timeRange} Top Tracks`, 110, 107, -0.83);

    addTrackBlock('1', firstTrack, 110, 165);
    addTrackBlock('2', secondTrack, 110, 280);
    addTrackBlock('3', thirdTrack, 110, 395);
    addTrackBlock('4', fourthTrack, 600, 112);
    addTrackBlock('5', fifthTrack, 600, 227);

    const firstTrackImage = await loadCanvasImage(firstTrack.albumCover);
    ctx.drawImage(firstTrackImage, 629, 383, 360, 360);

    const secondTrackImage = await loadCanvasImage(secondTrack.albumCover);
    ctx.drawImage(secondTrackImage, 1029, 339, 120, 120);

    const thirdTrackImage = await loadCanvasImage(thirdTrack.albumCover);
    ctx.drawImage(thirdTrackImage, 149, 561, 240, 240);

    const fourthTrackImage = await loadCanvasImage(fourthTrack.albumCover);
    ctx.drawImage(fourthTrackImage, 429, 511, 160, 160);

    const fifthTrackImage = await loadCanvasImage(fifthTrack.albumCover);
    ctx.drawImage(fifthTrackImage, 1029, 501, 200, 200);

    ctx.globalCompositeOperation = 'overlay';
    ctx.font = '700 30px Circular Std';
    fillText('cruuunchify.com', 867, 40, -0.85);

    ctx.globalCompositeOperation = 'source-over';

    canvas.toBlob(blob => resolve(blob), 'image/png', 1);
  });
};

export const createPlaylistCover = ({
  coverImages,
  titleOne,
  titleTwo,
  titleTwoColor = '#FFFFFF'
}) => {
  return new Promise(async resolve => {
    const canvas = document.createElement('canvas');
    const canvasDim = 640;
    canvas.width = canvasDim;
    canvas.height = canvasDim;

    const ctx = canvas.getContext('2d');

    const fillImage = img => {
      const scale = Math.max(canvasDim / img.width, canvasDim / img.height);
      const x = canvasDim / 2 - (img.width / 2) * scale;
      const y = canvasDim / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
    };

    const fillText = (text, y, letterSpacing = 0) => {
      const chars = text.split('');
      const textWidth = getTextWidth(ctx, text, letterSpacing);
      let currentX = canvasDim / 2 - textWidth / 2;
      chars.forEach((char, index) => {
        ctx.fillText(char, currentX, y);
        const nextChar = chars[index + 1] || '';
        const charWidth = ctx.measureText(char).width;
        const kerningValue = getKerningValue(ctx, char, nextChar);
        currentX += charWidth + kerningValue + letterSpacing;
      });
    };

    ctx.fillRect(0, 0, canvasDim, canvasDim);

    if (coverImages.length) {
      const [imgOne, ...otherImgs] = coverImages;

      ctx.globalAlpha = 0.5;

      const firstImage = await loadCanvasImage(imgOne);
      fillImage(firstImage);

      ctx.globalCompositeOperation = 'overlay';

      for (const img of otherImgs) {
        const image = await loadCanvasImage(img);
        fillImage(image);
      }

      ctx.globalAlpha = 1;
      ctx.globalCompositeOperation = 'source-over';
    }

    const logoImage = await loadCanvasImage(logo);
    ctx.drawImage(logoImage, 274.75, 121.64);

    const artifactImage = await loadCanvasImage(playlistCoverArtifact);
    ctx.drawImage(artifactImage, 514.29, -23.47);

    ctx.fillStyle = '#FFFFFF';
    ctx.font = '900 85px Circular Std';
    ctx.textBaseline = 'top';

    const titleLetterSpacing = -4;

    const titleMaxWidth = 542.03;
    const titleOneWords = titleOne.split(' ');
    let titleOneLineOne = '';
    let titleOneLineTwo = '';

    titleOneWords.forEach(word => {
      if (!titleOneLineTwo) {
        const newLineOneText = `${titleOneLineOne} ${word}`;
        const newLineOneWidth = getTextWidth(
          ctx,
          newLineOneText,
          titleLetterSpacing
        );
        if (newLineOneWidth < titleMaxWidth) {
          titleOneLineOne = newLineOneText;
        } else {
          titleOneLineTwo = word;
        }
      } else {
        titleOneLineTwo = `${titleOneLineTwo} ${word}`;
      }
    });

    titleOneLineTwo = toCanvasEllipsis(
      ctx,
      titleOneLineTwo,
      titleMaxWidth,
      titleLetterSpacing
    );

    fillText(titleOneLineOne, 245.41, titleLetterSpacing);
    titleOneLineTwo && fillText(titleOneLineTwo, 330.15, titleLetterSpacing);

    ctx.fillStyle = titleTwoColor;
    fillText(titleTwo, titleOneLineTwo ? 416.13 : 330.15, titleLetterSpacing);

    ctx.fillStyle = '#FFFFFF';
    ctx.globalAlpha = 0.5;
    ctx.font = '700 40px Circular Std';
    fillText('cruuunchify.com', 558.98, -0.85);

    resolve(canvas.toDataURL('image/jpeg', 0.9));
  });
};

export const shuffleArray = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * arr.length);
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr.reverse();
};

export const compareObjectValues = (key, desc) => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const valA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const valB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    if (valA > valB) {
      return desc ? -1 : 1;
    } else if (valA < valB) {
      return desc ? 1 : -1;
    }

    return 0;
  };
};

export const filterByUniqueKey = (arr, key) => {
  return arr.filter((obj, index) => {
    return arr.findIndex(currObj => currObj[key] === obj[key]) === index;
  });
};

export const openFullscreen = () => {
  const docElem = document.documentElement;

  if (docElem.requestFullscreen) {
    docElem.requestFullscreen();
  } else if (docElem.mozRequestFullScreen) {
    docElem.mozRequestFullScreen();
  } else if (docElem.webkitRequestFullscreen) {
    docElem.webkitRequestFullscreen();
  } else if (docElem.msRequestFullscreen) {
    docElem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const isFullScreenActive = () => {
  return Boolean(
    document.fullscreenElement || document.webkitCurrentFullScreenElement
  );
};

export const copyStringToClipboard = string => {
  let el = document.createElement('textarea');
  el.value = string;
  el.setAttribute('readonly', '');
  el.style = {
    position: 'absolute',
    left: '-9999px',
    opacity: 0,
    'z-index': -9999
  };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
