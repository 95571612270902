import React from 'react';

const TempoGreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <path
      fill="#33FF7A"
      fillRule="evenodd"
      d="M26.673 5.003a1.11 1.11 0 00-.292-.524 8.964 8.964 0 00-6.382-2.644 8.96 8.96 0 00-6.381 2.644 1.088 1.088 0 00-.292.524L7.101 31.252h25.798l-6.226-26.25zm-11.286.822l-4.7 19.819h18.71l-4.789-19.82a6.772 6.772 0 00-4.61-1.79c-1.723 0-3.35.632-4.611 1.79zm2.23 3.32h4.762V7.388h-4.762v1.757zm0 4.276h4.762v-1.757h-4.762v1.757zm4.762 4.278h-4.762v-1.757h4.762v1.757zm-4.762 4.274h4.762v-1.757h-4.762v1.757zM7.895 36.017a1.907 1.907 0 01-1.905-1.905v-2.106h28.02v2.106c0 1.05-.855 1.905-1.905 1.905h-2.021v.231c0 1.057-.86 1.917-1.917 1.917a1.919 1.919 0 01-1.917-1.917v-.231H13.748l.002.231c0 1.057-.86 1.917-1.917 1.917a1.919 1.919 0 01-1.917-1.917v-.231H7.895z"
    />
  </svg>
);

export default TempoGreenIcon;
