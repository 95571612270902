import React from 'react';

const TwitterColoredIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50"
    height="50"
  >
    <path
      fill="#1DA1F2"
      fillRule="evenodd"
      d="M40.694 16.952c.016.348.023.696.023 1.047 0 10.7-8.144 23.038-23.038 23.038A22.92 22.92 0 015.267 37.4c.634.075 1.278.112 1.931.112 3.795 0 7.286-1.293 10.057-3.466a8.106 8.106 0 01-7.564-5.624 8.053 8.053 0 003.657-.139 8.1 8.1 0 01-6.496-7.937v-.104a8.038 8.038 0 003.668 1.013 8.09 8.09 0 01-3.603-6.74c0-1.483.4-2.873 1.096-4.07 3.993 4.9 9.96 8.121 16.69 8.46a8.083 8.083 0 01-.21-1.846 8.098 8.098 0 0114.01-5.538 16.238 16.238 0 005.141-1.966 8.126 8.126 0 01-3.56 4.48c1.637-.197 3.199-.63 4.65-1.276a16.465 16.465 0 01-4.04 4.193z"
    />
  </svg>
);

export default TwitterColoredIcon;
