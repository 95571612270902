import React from 'react';

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="rgb(51,255,122)"
      fillOpacity="1"
      opacity="1"
      fillRule="evenodd"
      d="M11.44662857055664 18.83203887939453C15.166428565979004 15.034830093383789 18.226329803466797 11.681026458740234 22.23404884338379 7.665107727050781C22.61159324645996 7.272854804992676 23.128097534179688 7.0440287590026855 23.67238426208496 7.024415016174316L23.672510147094727 7.024519920349121C24.53714370727539 7 25.32822608947754 7.506712913513184 25.663293838500977 8.304314613342285C26 9.100326538085938 25.81041145324707 10.020527839660645 25.189300537109375 10.620382308959961C20.769895553588867 15.049830436706543 17.561410903930664 18.657073974609375 13.054971694946289 23.173137664794922C12.286785125732422 23.939712524414062 11.060924530029297 23.99038314819336 10.230602264404297 23.29081916809082L4.791074752807617 18.688140869140625C4.364494323730469 18.331851959228516 4.098078727722168 17.82024383544922 4.049039363861084 17.26616668701172C4 16.712087631225586 4.174872398376465 16.162904739379883 4.532822608947754 15.736319541931152C4.890743255615234 15.31136703491211 5.4023518562316895 15.044947624206543 5.956430435180664 14.997549057006836C6.510509014129639 14.951814651489258 7.061324119567871 15.126687049865723 7.484644889831543 15.484637260437012L11.44662857055664 18.83203887939453Z"
    />
  </svg>
);

export default CheckIcon;
