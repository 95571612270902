import React from 'react';

const CrownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M44.022 9.29L38.46 19.724s-2.958 1.82-8.182 1.82-8.725-1.82-8.725-1.82L15.991 9.289l9.761 4.778L30.388 5.5l3.873 8.567 9.76-4.778zM30.006 27.194l4.2-1.978c8.016-3.68 16.47 4.45 12.982 12.632-2.783 6.591-9.873 8.404-16.798 16.259 0 .043-.219.296-.219.296a.277.277 0 01-.426-.009l-.229-.287c-6.87-7.855-14.015-9.724-16.742-16.259-3.384-8.182 5.017-16.256 13.033-12.576l4.2 1.922z"
    />
  </svg>
);

export default CrownIcon;
