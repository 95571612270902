import React from 'react';

const ChevronLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    height="28"
    width="28"
  >
    <g
      transform="translate(14, 14) rotate(180.00006436155007) translate(-14, -14)"
      opacity="1"
    >
      <g opacity="1">
        <path
          fill="rgb(255,255,255)"
          fillOpacity="1"
          opacity="1"
          fillRule="evenodd"
          d="M19.147966141507695,14.871978658076213 L12.101104511669268,21.918840287914634 C11.619944799583902,22.4 10.83926528078388,22.4 10.357006258284258,21.918840287914634 C9.87584654619889,21.43768057582927 9.87584654619889,20.65590174661499 10.357006258284258,20.174742034529622 L16.53174829281388,14.000000000000004 L10.357006258284258,7.82525796547038 C9.87584654619889,7.344098253385011 9.87584654619889,6.562319424170737 10.357006258284258,6.08115971208537 C10.83926528078388,5.600000000000001 11.619944799583902,5.600000000000001 12.101104511669268,6.08115971208537 L19.147966141507695,13.128021341923795 C19.630225164007314,13.609181054009163 19.630225164007314,14.390959883223438 19.147966141507695,14.871978658076213Z"
        />
      </g>
    </g>
  </svg>
);

export default ChevronLeftIcon;
