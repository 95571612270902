import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  card,
  track,
  isPassive,
  cover,
  cardInfo,
  explicitTag,
  cardFooter,
  cardActions
} from './ListCards.module.sass';
import { modalScrollViewListener } from '../../utils';
import MultiLineEllipsis from '../MultiLineEllipsis';
import { SpotifyLogo } from '../AnimatedVectors';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import ExplicitIcon from '../../vectors/ExplicitIcon';

const ArtistCard = ({
  id,
  name,
  albumCover,
  artists,
  number,
  uri,
  explicit,
  setActiveTrack,
  renderDelay,
  columnDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const cardRef = useRef(null);

  const onEnterView = onStart => {
    setTimeout(() => setIsReady(true), onStart ? renderDelay : columnDelay);
  };

  useEffect(() => {
    setTimeout(
      () => modalScrollViewListener(cardRef.current, -0.05, onEnterView),
      20
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(card, track, { [isPassive]: !isReady })} ref={cardRef}>
      <div
        className={cover}
        style={{ backgroundImage: `url(${albumCover})` }}
      />
      <div className={cardInfo}>
        <h3>
          <span>
            <MultiLineEllipsis text={`${number}. ${name}`} lines={2} />
          </span>
        </h3>
        <p>
          <MultiLineEllipsis text={artists.join(', ')} lines={2} />
        </p>
        <div className={cardFooter}>
          <div className={cardActions}>
            <span onClick={() => setActiveTrack(id)}>
              <ExpandAltIcon />
            </span>
            <a href={uri}>
              <SpotifyLogo />
            </a>
          </div>
          {explicit && (
            <span className={explicitTag}>
              <ExplicitIcon />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

ArtistCard.defaultProps = {
  renderDelay: 0,
  columnDelay: 0
};

ArtistCard.propTypes = {
  name: PropTypes.string,
  albumCover: PropTypes.string,
  artists: PropTypes.array,
  number: PropTypes.number,
  uri: PropTypes.string,
  renderDelay: PropTypes.number,
  columnDelay: PropTypes.number
};

export default UserConsumer(ArtistCard);
