import React from 'react';

const BoltLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient id="Ljhf35ihGCX" x1="0.81" x2="0.51" y1="0" y2="0.57">
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Ljhf35ihGCX)"
      fillRule="evenodd"
      d="M561.417 332.373h-78.222c-6.39 0-11.39-4.475-10.667-10.25l12.083-179.192c2.834-40.948-56.889-57.598-80.362-22.4L201.61 418.772c-17.777 26.25 2.834 59.523 36.972 59.523h78.223c6.389 0 11.389 4.475 10.666 10.25l-12.083 179.192c-2.833 40.948 56.889 57.597 80.363 22.399l202.666-298.86c17.75-25.624-2.86-58.898-37-58.902z"
      opacity="0.1"
    />
  </svg>
);

export default BoltLgIcon;
