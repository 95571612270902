import React from 'react';
import PropTypes from 'prop-types';

import { UserConsumer } from '../../contexts/UserContext';
import { artistPill, artistCoverImage } from './MobileArtistPill.module.sass';
import SpotifyLogo from '../../vectors/SpotifyLogo';

const MobileArtistPill = ({
  id,
  artistCover,
  artistName,
  uri,
  setActiveArtist
}) => (
  <div className={artistPill} onClick={() => setActiveArtist(id)}>
    <div
      className={artistCoverImage}
      style={{ backgroundImage: `url(${artistCover})` }}
    />
    <h3>{artistName}</h3>
    <a href={uri} onClick={e => e.stopPropagation()}>
      <SpotifyLogo />
    </a>
  </div>
);

MobileArtistPill.propTypes = {
  id: PropTypes.string,
  artistName: PropTypes.string,
  artistCover: PropTypes.string,
  uri: PropTypes.string
};

export default UserConsumer(MobileArtistPill);
