import React from 'react';
import cx from 'classnames';

import { danceIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import DanceIcon from '../../vectors/DanceIcon';
import DanceBgIcon from '../../vectors/DanceBgIcon';

const DanceIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(135.57deg, #1DB954 0%, #7B9D19 100%)"
    icon={<DanceIcon />}
    bgIcon={<DanceBgIcon />}
    className={cx(className, danceIconBox)}
    {...props}
  />
);

export default DanceIconBox;
