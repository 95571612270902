import React from 'react';

const OutlinePlayIcon = ({ isMedium }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={isMedium ? 32 : 46}
    width={isMedium ? 32 : 46}
    viewBox={isMedium ? '0 0 34 34' : '0 0 50 50'}
  >
    <defs>
      <path
        id={isMedium ? 'pathASdc' : 'pathVgBt'}
        opacity="1"
        fillRule="evenodd"
        d={
          isMedium
            ? 'M15,0 C23.27872536741,0 30,6.721274632590001 30,15 C30,23.278725367409997 23.27872536741,30 15,30 C6.721274632590003,30 1.7763568394002505e-15,23.27872536741 0,15 C0,6.721274632590003 6.7212746325899975,1.7763568394002505e-15 14.999999999999996,0 L15,0Z'
            : 'M23,0 C35.694045563362,0 46,10.305954436638 46,23 C46,35.694045563361996 35.694045563362,46 23,46 C10.305954436638004,46 3.552713678800501e-15,35.694045563362 0,23 C0,10.305954436638004 10.305954436637995,3.552713678800501e-15 22.999999999999996,0 L23,0Z'
        }
      />
      <mask
        id={isMedium ? 'maskHbfD' : 'maskTHegr'}
        maskUnits="userSpaceOnUse"
        maskContentUnits="userSpaceOnUse"
        x="-2"
        y="-2"
      >
        <rect
          opacity="1"
          x="-2"
          y="-2"
          width={isMedium ? 34 : 50}
          height={isMedium ? 34 : 50}
          fill="#ffffff"
        />
        <use
          xlinkHref={`#${isMedium ? 'pathASdc' : 'pathVgBt'}`}
          fill="#000000"
        />
      </mask>
      <path
        id={isMedium ? 'pathGbfGT' : 'pathBTORx'}
        opacity="1"
        fillRule="evenodd"
        d={
          isMedium
            ? 'M10.9577678545712,18.96109566774842 C10.981041392895674,19.9332317953914 12.032561059378502,20.52173913043478 12.953908406938185,20.05887897202026 L20.36343058586048,16.078264679876376 C20.766844694430837,15.861126155792071 21.0407678545712,15.463234024367885 21.0407678545712,14.99755223611665 C21.0407678545712,14.531320230047259 20.766844694430837,14.133428098623073 20.36343058586048,13.916289574538768 L12.953908406938185,9.941177460576473 C12.032499949917232,9.478260869565219 10.980989449853595,10.06131681576407 10.9577678545712,11.033458586666722 L10.9577678545712,18.96109566774842Z'
            : 'M16.801910710342504,29.07368002388091 C16.837596802440032,30.564288752933482 18.4499269577137,31.466666666666665 19.862659557305214,30.756947757097734 L31.223926898319405,24.653339175810444 C31.84249519812728,24.320393438881176 32.262510710342504,23.71029217069742 32.262510710342504,22.99624676204553 C32.262510710342504,22.281357686072464 31.84249519812728,21.67125641788871 31.223926898319405,21.338310680959445 L19.862659557305214,15.243138772883924 C18.449833256539755,14.533333333333335 16.83751715644218,15.427352450838239 16.801910710342504,16.917969832888975 L16.801910710342504,29.07368002388091Z'
        }
      />
    </defs>
    <g transform="translate(2, 2)" opacity="1">
      <g opacity="1">
        <use
          xlinkHref={`#${isMedium ? 'pathASdc' : 'pathVgBt'}`}
          fill="rgb(26,27,28)"
          fillOpacity="1"
        />
        <use
          xlinkHref={`#${isMedium ? 'pathASdc' : 'pathVgBt'}`}
          fillOpacity="0"
          stroke="rgb(51,255,122)"
          strokeOpacity="1"
          strokeWidth="4"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          mask={`url(#${isMedium ? 'maskHbfD' : 'maskTHegr'})`}
        />
      </g>
      <g opacity="1">
        <use
          xlinkHref={`#${isMedium ? 'pathGbfGT' : 'pathBTORx'}`}
          fill="rgb(51,255,122)"
          fillOpacity="1"
        />
      </g>
    </g>
  </svg>
);

export default OutlinePlayIcon;
