import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  audioFeatureFieldSet,
  isPassive,
  audioFeatures
} from './PlaylistCreator.module.sass';
import Fieldset from '../Fieldset';
import CardRadio from '../CardRadio';
import { isModalContainerInView, modalScrollViewListener } from '../../utils';

const AudioFeatureFieldSet = ({
  icon,
  title,
  name,
  value,
  onChange,
  plusTitle,
  minusTitle,
  renderDelay
}) => {
  const [isReady, setIsReady] = useState(false);
  const featuresRef = useRef(null);

  const onEnterView = () => {
    setIsReady(true);
  };

  useEffect(() => {
    const offset = window.innerHeight * 0.9 - 150;
    const isReady = isModalContainerInView(
      featuresRef.current,
      -0.6,
      offset + 80
    );
    if (isReady) {
      setTimeout(() => setIsReady(true), renderDelay);
    }

    modalScrollViewListener(featuresRef.current, -0.6, onEnterView, offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fieldset
      className={cx(audioFeatureFieldSet, { [isPassive]: !isReady })}
      legend={
        <span>
          {icon} {title}
        </span>
      }
    >
      <div ref={featuresRef} className={audioFeatures}>
        <CardRadio
          value={value}
          name={null}
          onChange={value => onChange(value, name)}
          title="Mixed"
        />
        <CardRadio
          value={value}
          name="+"
          onChange={value => onChange(value, name, plusTitle)}
          title={plusTitle}
        />
        <CardRadio
          value={value}
          name="-"
          onChange={value => onChange(value, name, minusTitle)}
          title={minusTitle}
        />
      </div>
    </Fieldset>
  );
};

AudioFeatureFieldSet.defaultProps = {
  renderDelay: 0
};

AudioFeatureFieldSet.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  plusTitle: PropTypes.string.isRequired,
  minusTitle: PropTypes.string.isRequired,
  renderDelay: PropTypes.number
};

export default AudioFeatureFieldSet;
