import React from 'react';
import PropTypes from 'prop-types';

const RadialLoader = ({ progress, progressComplete }) => (
  <svg viewBox="0 0 290 290" width="290" height="290">
    <circle
      stroke="#0E5828"
      strokeWidth="2"
      fill="none"
      cx="145"
      cy="145"
      r="140"
      opacity={progressComplete ? 0 : 1}
    />
    <circle
      stroke="#33FF7A"
      strokeWidth="3"
      strokeDasharray={`${progress * 9} 900`}
      fill="#111112"
      cx={progressComplete ? 50 : 145}
      cy={progressComplete ? 50 : 145}
      r={progressComplete ? 45 : 140}
      transform={
        progressComplete
          ? 'rotate(-90) translate(-195, 95)'
          : 'rotate(-90) translate(-290)'
      }
    />
  </svg>
);

RadialLoader.propTypes = {
  progress: PropTypes.number.isRequired,
  progressComplete: PropTypes.bool
};

export default RadialLoader;
