import React from 'react';
import PropTypes from 'prop-types';

import Carousel from './Carousel';
import { ArtistCard } from '../ListCards';

const ArtistsCarousel = ({ title, data, renderDelay }) => (
  <Carousel title={title} noSlide={data.length < 4} renderDelay={renderDelay}>
    {data.map((artist, index) => (
      <ArtistCard
        key={index}
        {...artist}
        renderDelay={renderDelay + 100 + index * 100}
        columnDelay={(index % 3) * 100}
      />
    ))}
  </Carousel>
);

ArtistsCarousel.defaultProps = {
  renderDelay: 0
};

ArtistsCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  renderDelay: PropTypes.number
};

export default ArtistsCarousel;
