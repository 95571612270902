import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  container,
  sectionIcon,
  tasteSummary,
  isPassive,
  tasteSummaryContent,
  tasteCaption,
  greenText,
  tasteShare,
  artistCovers,
  artistCover,
  shareAction,
  shareThis,
  sharePlatforms,
  tasteIconBg,
  whipPlaylist,
  tasteInfo,
  tasteInfoContent
} from './TasteSection.module.sass';
import DrumPads from '../DrumPads';
import BinocularsBgIcon from '../../vectors/BinocularsSectionBg';
import ShareIcon from '../../vectors/ShareIcon';
import FacebookColoredIcon from '../../vectors/FacebookColoredIcon';
import TwitterColoredIcon from '../../vectors/TwitterColoredIcon';
import InstagramColoredIcon from '../../vectors/InstagramColoredIcon';
import PlaylistCard from '../PlaylistCard';
import { scrollViewListener } from '../../utils';
import {
  SECTION_SUMMARY_ENTRANCE_FRACTION as summaryEntranceFraction,
  TASTE_SHARE_URL as shareUrl,
  FACEBOOK_SHARE_URL as facebookUrl,
  TWITTER_SHARE_URL as twitterUrl
} from '../../config';

const TasteSection = ({
  setActiveSuggestionPlaylist,
  artistsCovers,
  labelOne,
  labelTwo,
  description,
  playlistDescription,
  playlistTracks,
  playlistCovers,
  titleOne,
  titleTwo,
  summary,
  shareKey,
  createNewAction,
  sectionIcon: SectionIconBox
}) => {
  const [summaryInView, setSummaryInview] = useState(false);

  const suggestionPlaylistData = {
    titleOne,
    titleTwo,
    summary,
    playlistTracks,
    createNewAction,
    coverImages: playlistCovers
  };

  const generateFileName = () => {
    return `${shareKey}${Date.now()}`;
  };

  const shareToTwitter = () => {
    try {
      const fileName = generateFileName();
      const tweet = `%23cruuunchify%0a${shareUrl}/${fileName}`;
      const twitterLink = `${twitterUrl}${tweet}`;
      window.open(twitterLink, '_blank');
    } catch (error) {}
  };

  const shareToFacebook = () => {
    try {
      const fileName = generateFileName();
      const shareLink = `${shareUrl}/${fileName}`;
      const facebookLink = `${facebookUrl}${shareLink}`;
      window.open(facebookLink, '_blank');
    } catch (error) {}
  };

  useEffect(() => {
    scrollViewListener(summaryRef.current, summaryEntranceFraction, () =>
      setSummaryInview(true)
    );
  }, []);

  const summaryRef = useRef();

  return (
    <section className={container}>
      {SectionIconBox && (
        <SectionIconBox className={sectionIcon} isPassive={!summaryInView} />
      )}
      <div
        ref={summaryRef}
        className={cx(tasteSummary, {
          [isPassive]: !summaryInView
        })}
      >
        <DrumPads total={30} />
        <div className={tasteSummaryContent}>
          <h1 className={tasteCaption}>
            <span>
              {' '}
              {labelOne} <br /> <span className={greenText}>{labelTwo}</span>{' '}
            </span>
          </h1>
          {artistsCovers && (
            <div className={tasteShare}>
              <div className={artistCovers}>
                {artistsCovers.map((url, index) => (
                  <div
                    key={index}
                    className={artistCover}
                    style={{ backgroundImage: `url(${url})` }}
                  />
                ))}
              </div>
              <div className={shareAction}>
                <div>
                  <span className={shareThis}>
                    <ShareIcon /> Share this
                  </span>
                  <span className={sharePlatforms}>
                    <span onClick={shareToFacebook}>
                      <FacebookColoredIcon />
                    </span>
                    <span onClick={shareToTwitter}>
                      <TwitterColoredIcon />
                    </span>
                    <span>
                      <InstagramColoredIcon />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={tasteIconBg}>
        <BinocularsBgIcon />
      </div>
      <div className={tasteInfo}>
        <DrumPads total={12} />
        <div className={tasteInfoContent}>
          <p>
            <span>{description}</span>
          </p>
        </div>
      </div>
      <PlaylistCard
        className={whipPlaylist}
        summary={playlistDescription}
        covers={playlistCovers}
        action={() => setActiveSuggestionPlaylist(suggestionPlaylistData)}
      />
      <DrumPads total={69} />
    </section>
  );
};

TasteSection.propTypes = {
  labelOne: PropTypes.string,
  labelTwo: PropTypes.string,
  description: PropTypes.string,
  playlistDescription: PropTypes.string,
  sectionIcon: PropTypes.func,
  artistsCovers: PropTypes.array
};

export default TasteSection;
