import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import {
  tasteArtistLink,
  isPassive,
  hasLoaded,
  refreshing,
  tasteArtistLinkContent,
  userCover,
  passiveCover,
  artistCover,
  cover,
  nextCover,
  artistsLink,
  connector,
  connectorPassive,
  refresh
} from './TasteArtistLink.module.sass';
import DrumPads from '../DrumPads';
import { getImageUrl, scrollViewListener } from '../../utils';

const TasteArtistLink = ({ className, user, tasteCovers }) => {
  const [inView, setInview] = useState(false);
  const [loadedInitialArtists, setLoadedInitialArtistssetInview] = useState(
    false
  );
  const [refreshingArtists, setRefreshingArtists] = useState(false);
  const [userCoverPassive, setUserCoverPassive] = useState(false);
  const [artistCovers, setArtistCovers] = useState(tasteCovers);
  const [nextArtistCovers, setNextArtistCovers] = useState(null);
  const { images } = user;
  const avatarUrl = getImageUrl(images);
  const linkLines = Array(8).fill();

  const refreshArtists = () => {
    if (refreshingArtists) {
      return;
    }
    setRefreshingArtists(true);
    setUserCoverPassive(true);
    const artistsCoversCopy = [...artistCovers];
    const currentSet = artistsCoversCopy.slice(0, 8);
    const others = artistsCoversCopy.slice(8, artistsCoversCopy.length);
    setNextArtistCovers(others.slice(0, 8));
    setTimeout(() => {
      setRefreshingArtists(false);
      setArtistCovers([...others, ...currentSet]);
      setNextArtistCovers(null);
    }, 1600);
    setTimeout(() => setUserCoverPassive(false), 1650);
  };

  const onInview = () => {
    setInview(true);
    setTimeout(() => setLoadedInitialArtistssetInview(true), 1900);
  };

  useEffect(() => {
    scrollViewListener(containerRef.current, 1, onInview);
  });

  const containerRef = useRef();

  return (
    <div
      ref={containerRef}
      className={cx(tasteArtistLink, className, {
        [isPassive]: !inView,
        [hasLoaded]: loadedInitialArtists,
        [refreshing]: refreshingArtists
      })}
    >
      <DrumPads total={64} />
      <div className={tasteArtistLinkContent}>
        <div
          className={cx(userCover, { [passiveCover]: userCoverPassive })}
          onClick={refreshArtists}
        >
          <div
            className={cover}
            style={{ backgroundImage: `url(${avatarUrl})` }}
          />
        </div>
        {artistCovers.slice(0, 8).map((url, index) => (
          <div key={index} className={artistCover}>
            <div className={cover} style={{ backgroundImage: `url(${url})` }} />
            {nextArtistCovers && (
              <div className={nextCover}>
                <div
                  className={cover}
                  style={{ backgroundImage: `url(${nextArtistCovers[index]})` }}
                />
              </div>
            )}
          </div>
        ))}
        <div className={artistsLink}>
          {linkLines.map((item, index) => (
            <div
              key={index}
              className={cx(connector, {
                [connectorPassive]: userCoverPassive
              })}
            >
              <div className={refresh} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserConsumer(TasteArtistLink);
