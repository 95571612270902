import React from 'react';

const HarpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fillRule="evenodd"
      d="M46.875 7.5a5.626 5.626 0 00-5.625 5.625v5.625h-2.812v-1.875a1.875 1.875 0 10-3.751 0v1.875h-2.813v-1.876a1.874 1.874 0 10-3.748 0v1.876h-2.813v-1.875a1.875 1.875 0 10-3.75 0v1.875H18.75v-5.625a5.625 5.625 0 10-5.625 5.625c.66 0 1.285-.136 1.874-.345V30c0 7.646 5.729 13.946 13.127 14.871v3.88H22.5A3.747 3.747 0 0018.75 52.5h22.5a3.752 3.752 0 00-3.75-3.753h-5.626V44.87c7.394-.923 13.127-7.223 13.127-14.87V18.406a5.583 5.583 0 001.874.345 5.625 5.625 0 100-11.25zm-1.874 5.625a1.873 1.873 0 103.748 0 1.873 1.873 0 10-3.748 0zm-31.876 1.874a1.873 1.873 0 111.874-1.874 1.873 1.873 0 01-1.874 1.874zm15 26.082a11.21 11.21 0 01-2.812-.864V22.501h2.813v18.58zM41.25 30c0 2.847-1.068 5.44-2.812 7.422V22.5h2.812V30zm-9.376 11.08a11.24 11.24 0 002.813-.863V22.501h-2.813v18.58zM18.75 30c0 2.845 1.068 5.442 2.813 7.422V22.501H18.75V30z"
    />
  </svg>
);

export default HarpIcon;
