import React from 'react';

const DanceLgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800">
    <defs>
      <linearGradient
        id="Lkmf7HGb47BH"
        x1="0.39"
        x2="0.54"
        y1="-0.03"
        y2="0.51"
      >
        <stop offset="0" stopColor="#FFF" />
        <stop offset="1" stopColor="#FFF" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill="url(#Lkmf7HGb47BH)"
      fillRule="evenodd"
      d="M654.562 226.46v84.872a89.008 89.008 0 00-21.124.07c-41.151 5.02-73.796 38.497-77.712 79.724-4.979 52.532 36.07 97.685 88.583 97.685 44.772 0 82.7-33.367 88.9-77.896l.124-250.548c0-19.127-15.489-34.623-34.612-34.628H442.333c-19.123 0-34.613 15.497-34.613 34.623V408.97c-8.468-1.01-17.052-.794-25.615.668-39.289 6.654-69.735 39.436-73.483 79.13-4.955 52.52 36.085 97.657 88.584 97.657 49.143 0 88.997-39.86 89.022-89.029l.022-270.936c0-7.252 5.864-13.118 13.107-13.118h142.097c7.244 0 13.108 5.866 13.108 13.117zm-453.779-12.885c-19.122 0-34.613 15.497-34.613 34.623v248.608c-8.467-1.01-17.052-.793-25.615.668-39.288 6.654-69.735 39.436-73.482 79.13-4.956 52.52 36.085 97.657 88.584 97.657 49.143 0 88.997-39.86 89.022-89.028l.021-270.937c0-7.252 5.864-13.118 13.108-13.118h78.601v-87.603H200.783z"
      opacity="0.1"
    />
  </svg>
);

export default DanceLgIcon;
