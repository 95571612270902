import React, { Component, createRef } from 'react';
import VirtualScroll from 'virtual-scroll';
import cx from 'classnames';

import {
  V_SCROLL_OPTIONS as scrollOptions,
  DEFAULT_SCROLL_EASE as scrollEase
} from '../../config';

import { UserConsumer } from '../../contexts/UserContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { main, isLoadingStats } from './MobileStats.module.sass';
import MobileLanding from '../MobileLanding';
import MobileFavourites from '../MobileFavourites';
import MobileStreamingAnalysis from '../MobileStreamingAnalysis';

class MobileStats extends Component {
  state = {};

  mainPositionY = 0;
  targetY = 0;
  mainContainerRef = createRef();

  initializeVirtualScroll = () => {
    this.vScroll = new VirtualScroll(scrollOptions);

    this.vScroll.on(({ deltaY }) => {
      if (this.disableVScroll || this.props.disableVScroll) {
        return;
      }

      this.targetY += deltaY;
      this.setTarget();
    });
  };

  animatePosition = () => {
    const ease =
      this.noTranslateEase || this.props.disableGlobalScrollEase
        ? 1
        : scrollEase;
    this.animateScroll = requestAnimationFrame(this.animatePosition);

    this.mainPositionY += (this.targetY - this.mainPositionY) * ease;
    const translateY = parseFloat(this.mainPositionY.toFixed(2));
    const translate = `translate3d(0, ${translateY}px, 0)`;

    if (
      this.disableVScroll &&
      this.noTranslateEase &&
      Math.abs(translateY) === 0
    ) {
      this.disableVScroll = false;
      this.noTranslateEase = false;
    }

    this.mainContainer.style.transform = translate;
    this.setMenuProgress(translateY);
  };

  setMenuProgress = translateY => {
    const { containerHeight } = this.state;
    const statsProgress = document.querySelector('[data-stats-progress]');

    if (!statsProgress || !containerHeight) {
      return;
    }

    const maxOverflow = containerHeight - window.innerHeight;
    const progress = parseFloat(
      (Math.abs(translateY) / maxOverflow).toFixed(5)
    );
    statsProgress.style.transform = `scale3d(${progress}, 1, 1)`;

    const sections = this.mainContainer.querySelectorAll('section');
    const sectionsDistance = [...sections].map(
      section => section.getBoundingClientRect().y - window.innerHeight * 0.4
    );
    const visibleDistance = sectionsDistance.filter(distance => distance < 0);
    const activeMenuIndex = visibleDistance.length - 1;
    const menuTitlesFraction = 1 / sectionsDistance.length;

    const menuTitles = document.querySelector('[data-menu-titles]');
    menuTitles.style.transform = `translate3d(0, -${activeMenuIndex *
      menuTitlesFraction *
      100}%, 0)`;
  };

  setTarget = () => {
    const { containerHeight, maxTranslate, minTranslate } = this.state;
    const maxOverflow = containerHeight - window.innerHeight;
    const targetYMax =
      typeof maxTranslate === 'number' ? maxTranslate : -maxOverflow;
    const targetYMin = typeof minTranslate === 'number' ? minTranslate : 0;

    this.targetY = Math.min(Math.max(targetYMax, this.targetY), targetYMin);
  };

  setContainerHeight = initializeScroll => {
    const mainHeight = this.mainContainer.getBoundingClientRect().height;

    this.setState(
      {
        containerHeight: mainHeight
      },
      () => {
        initializeScroll && this.initializeVirtualScroll();
      }
    );

    const maxOverflow = mainHeight - window.innerHeight;

    if (this.targetY < 0 && this.targetY < -maxOverflow) {
      this.targetY = -maxOverflow;
    }
  };

  setScrollPosition = pos => {
    this.noTranslateEase = true;
    this.targetY = pos;
    this.setContainerHeight();
    setTimeout(() => {
      this.noTranslateEase = false;
    }, 100);
  };

  setMaxTranslate = maxTranslate => {
    this.setState({ maxTranslate });

    if (this.targetY < maxTranslate) {
      this.targetY = maxTranslate;
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      setTimeout(this.setContainerHeight, 100);
    }
  }

  componentDidMount() {
    this.mainContainer = this.mainContainerRef.current;
    document.readyState === 'complete'
      ? this.setContainerHeight(true)
      : window.addEventListener('load', () => this.setContainerHeight(true));
    window.addEventListener('resize', () => this.setContainerHeight(false));
    window.addEventListener('wheel', e => e.preventDefault(), {
      passive: false
    });
    this.animateScroll = requestAnimationFrame(this.animatePosition);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animateScroll);
    this.vScroll.destroy();
    window.removeEventListener('load', () => this.setContainerHeight(true));
    window.removeEventListener('resize', () => this.setContainerHeight(false));
    window.removeEventListener('wheel', e => e.preventDefault(), {
      passive: false
    });
  }

  render() {
    const { isLoading, hasToken } = this.props;

    return (
      <main
        ref={this.mainContainerRef}
        className={cx(main, { [isLoadingStats]: isLoading })}
      >
        {!isLoading && (
          <>
            {hasToken ? (
              <>
                <MobileFavourites />
                <MobileStreamingAnalysis />
              </>
            ) : (
              <MobileLanding setScrollPosition={this.setScrollPosition} />
            )}
          </>
        )}
      </main>
    );
  }
}

export default UserConsumer(ExperienceConsumer(MobileStats));
