import React from 'react';

const MusicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 45"
    height="45"
    width="45"
  >
    <defs>
      <linearGradient id="Lnsfg24b" x1="0.25" y1="0.09" x2="0.97" y2="1">
        <stop offset="0" stopColor="rgb(255,255,255)" stopOpacity="1" />
        <stop offset="1" stopColor="rgb(255,255,255)" stopOpacity="1" />
      </linearGradient>
    </defs>
    <g opacity="1">
      <g opacity="1">
        <path
          opacity="1"
          fillRule="evenodd"
          d="M34.6552251099524,7.5 L17.14176362502515,7.5 C16.241227006428325,7.5 15.511896372148158,8.229685616852162 15.511896372148158,9.130575356022025 L15.511896372148158,27.0059687948014 C14.776583245204355,26.798979923069403 13.980263935110994,26.740905471958563 13.151174283038483,26.88237093121235 C10.794910484639923,27.281466000583084 8.96419151946979,29.2515523593576 8.73937173147329,31.63270397107171 C8.4387000333097,34.81944131264946 10.936356425833651,37.5 14.059217596251461,37.5 C17.010872782352614,37.5 19.404212793246188,35.10550582698302 19.405701176452823,32.15268264584647 L19.407189559659457,13.645614233385437 C19.407189559659457,12.744686372611856 20.136558298988433,12.015038877363413 21.03705681253645,12.015038877363413 L30.743555896663246,12.015038877363413 C31.64409251526007,12.015038877363413 32.37342314954024,12.744724494215577 32.37342314954024,13.645614233385437 L32.37342314954024,20.33346062772595 C31.718473570542976,20.15178831302606 31.015930785578327,20.087755455253394 30.285075949345632,20.177101057897794 C27.81865045482879,20.477903384230466 25.85383982288584,22.488223283573692 25.61873167170984,24.963268405256297 C25.316570066137665,28.15149248937922 27.8157163660702,30.832089298333482 30.938577536488005,30.832089298333482 C33.66697524159399,30.832089298333482 35.915859012437615,28.786026583340046 36.28371753266822,26.144275688579004 L36.285205915874855,9.130811709965101 C36.285205915874855,8.229883849191518 35.55583717654587,7.500236353943078 34.6552251099524,7.5Z"
          fill="url(#Lnsfg24b)"
        />
      </g>
    </g>
  </svg>
);

export default MusicIcon;
