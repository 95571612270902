import React from 'react';

import SectionIconBox from './SectionIconBox';
import DanceIcon from '../../vectors/DanceIcon';

const DanceAltIconBox = props => (
  <SectionIconBox
    bgColor="linear-gradient(93.55deg, #1C6DB0 0%, #BD1FB2 100%)"
    icon={<DanceIcon />}
    {...props}
  />
);

export default DanceAltIconBox;
