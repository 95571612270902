import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { fieldset } from './Fieldset.module.sass';

const Fieldset = ({ legend, className, children }) => (
  <fieldset className={cx(fieldset, className)}>
    <legend>{legend}</legend>
    {children}
  </fieldset>
);

Fieldset.propTypes = {
  legend: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Fieldset;
