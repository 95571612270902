import React from 'react';

const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50"
    height="50"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M21.52 32.106h19.855A3.642 3.642 0 0045 28.478V8.626A3.642 3.642 0 0041.373 5H21.52a3.642 3.642 0 00-3.626 3.626V28.48a3.642 3.642 0 003.626 3.626zm6.958 9.269H8.625V21.52h5.687v-3.626H8.625A3.642 3.642 0 005 21.52v19.854A3.642 3.642 0 008.625 45l19.855-.002a3.642 3.642 0 003.626-3.625v-5.686H28.48l-.002 5.688z"
    />
  </svg>
);

export default CopyIcon;
