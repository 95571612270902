import React from 'react';
import cx from 'classnames';

import { UserConsumer } from '../../contexts/UserContext';
import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import {
  header,
  landingHeader,
  loading,
  modalOpen,
  modalClose,
  nav,
  logo,
  menu,
  menuTitles,
  statsProgress,
  userAvatar,
  landingMenu,
  share
} from './MobileHeader.module.sass';
import { SECTION_TITLES as sectionTitles } from './MobileHeader.constants';
import Logo from '../../vectors/Logo';
import ShareIcon from '../../vectors/ShareIcon';
import LinkIcon from '../../vectors/LinkIcon';
import { getImageUrl } from '../../utils';

const MobileHeader = ({
  user = {},
  isLoading,
  logout,
  hasToken,
  mobileModalOpen
}) => {
  const { images } = user;
  const avatarUrl = getImageUrl(images);
  const avatarBg = avatarUrl && `url(${avatarUrl})`;
  const mobileModalUpdated = typeof mobileModalOpen === 'boolean';

  return (
    <header
      className={cx(header, {
        [landingHeader]: !hasToken,
        [loading]: isLoading,
        [modalOpen]: mobileModalOpen,
        [modalClose]: mobileModalUpdated && !mobileModalOpen
      })}
    >
      <nav className={nav}>
        <span className={logo}>
          <Logo />
        </span>
        {hasToken ? (
          <>
            <div className={menu}>
              <div className={statsProgress} data-stats-progress />
              <div className={menuTitles}>
                <div data-menu-titles>
                  {sectionTitles.map((title, index) => (
                    <span key={index}>{title}</span>
                  ))}
                </div>
              </div>
            </div>
            <div className={userAvatar} style={{ backgroundImage: avatarBg }} />
          </>
        ) : (
          <ul className={landingMenu}>
            <li>
              <LinkIcon /> About
            </li>
            <li className={share}>
              <ShareIcon /> Share
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};

export default ExperienceConsumer(UserConsumer(MobileHeader));
