import React from 'react';

import {
  INSIGHT_BLOCKS_CLASS_NAMES as blocksClassNames,
  INSIGHT_BLOCKS_ICON_BOX_CLASS_NAMES as iconBoxClassNames
} from './StreamingInsights.constant';

import { UserConsumer } from '../../contexts/UserContext';
import { container } from './StreamingInsights.module.sass';
import InsightBlock from './InsightBlock';
import DrumPads from '../DrumPads';
import { DanceIconBox, BoltIconBox, SmileyIconBox } from '../SectionIconBox';

const StreamingInsights = ({
  dancePercentage,
  danceableTrack,
  energyPercentage,
  energeticTrack,
  happyPercentage,
  happyTrack
}) => {
  const insights = [
    {
      labelOne: 'Got the moves',
      labelTwo: 'like JAGGER',
      icon: DanceIconBox,
      percentage: dancePercentage,
      percentageSuffix: 'of your tracks are danceable',
      track: danceableTrack
    },
    {
      labelOne: 'Your music has',
      labelTwo: `a life of it's own`,
      icon: BoltIconBox,
      percentage: energyPercentage,
      percentageSuffix: 'of your tracks are energetic',
      track: energeticTrack
    },
    {
      labelOne: 'Great vibes',
      labelTwo: 'and tunes',
      icon: SmileyIconBox,
      percentage: happyPercentage,
      percentageSuffix: 'of your tracks are happy',
      track: happyTrack
    }
  ];

  return (
    <section className={container}>
      {insights.map((insight, index) => (
        <InsightBlock
          key={index}
          className={blocksClassNames[index]}
          iconBoxClassName={iconBoxClassNames[index]}
          {...insight}
        />
      ))}
      <DrumPads total={117} />
    </section>
  );
};

export default UserConsumer(StreamingInsights);
