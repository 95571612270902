import React from 'react';
import cx from 'classnames';

import { smileyIconBox } from './SectionIconBox.module.sass';
import SectionIconBox from './SectionIconBox';
import SmileyIcon from '../../vectors/SmileyIcon';
import SmileyBgIcon from '../../vectors/SmileyBgIcon';

const SmileyIconBox = ({ className, ...props }) => (
  <SectionIconBox
    bgColor="linear-gradient(135.57deg, #1DA1F2 0%, #A727EE 100%)"
    icon={<SmileyIcon />}
    bgIcon={<SmileyBgIcon />}
    className={cx(className, smileyIconBox)}
    {...props}
  />
);

export default SmileyIconBox;
