import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ExperienceConsumer } from '../../contexts/ExperienceContext';
import { UserConsumer } from '../../contexts/UserContext';
import {
  trackPill,
  isPassivePill,
  audioPreview,
  audioToggle,
  albumCoverImage,
  trackDetails,
  actions
} from './TrackPill.module.sass';
import ExpandAltIcon from '../../vectors/ExpandAltIcon';
import { AudioControl, SpotifyLogo } from '../AnimatedVectors';
import { playAudio, pauseAudio, fadeOutAudio } from '../../utils';
import {
  AUDIO_LOADING_DELAY as audioLoadingDelay,
  AUDIO_FADE_DURATION as audioFadeDuration
} from '../../config';

class TrackPill extends Component {
  state = {};

  trackPreviewRef = createRef();
  mousePlayTimeout = null;

  onMouseEnter = () => {
    const { audioAutoPreview } = this.props;
    const {
      hasEnteredCard,
      canPlayAudio,
      mouseLeavePause,
      hasInitializedPlay
    } = this.state;

    this.setState({ cardActive: true });

    if (!hasEnteredCard) {
      this.setState({
        audioState: 'loading',
        hasEnteredCard: true
      });

      setTimeout(() => this.trackPreview.load(), audioLoadingDelay);
    }

    if (
      canPlayAudio &&
      audioAutoPreview &&
      (!hasInitializedPlay || mouseLeavePause)
    ) {
      this.mousePlayTimeout = setTimeout(() => {
        const { isPlaying, hasInitializedPlay, mouseLeavePause } = this.state;

        if (!isPlaying && (!hasInitializedPlay || mouseLeavePause)) {
          playAudio(this.trackPreview);
          this.setState({
            isPlaying: true,
            mouseLeavePause: false
          });
        }

        !hasInitializedPlay && this.setState({ hasInitializedPlay: true });
      }, audioFadeDuration);
    }
  };

  onMouseLeave = () => {
    clearTimeout(this.mousePlayTimeout);
    const { canPlayAudio, isPlaying } = this.state;

    if (canPlayAudio && isPlaying) {
      pauseAudio(this.trackPreview);

      this.setState({
        isPlaying: false,
        audioState: 'paused',
        mouseLeavePause: true
      });
    }

    this.setState({ cardActive: false });
  };

  onCanPlayAudio = () => {
    const { audioAutoPreview } = this.props;
    const { cardActive, hasPlayedThrough } = this.state;

    if (hasPlayedThrough) {
      return;
    }

    const autoPreview = cardActive && audioAutoPreview;

    this.setState(
      {
        canPlayAudio: true,
        audioState: autoPreview ? 'playing' : 'paused'
      },
      () => {
        autoPreview && this.togglePlayState();
      }
    );
  };

  onAudioTimeUpdate = () => {
    if (this.trackPreview.currentTime > 28 && !this.state.closeFading) {
      this.setState({ closeFading: true });
      fadeOutAudio(this.trackPreview);
    }
  };

  onAudioPlay = () => {
    this.setState({ audioState: 'playing' });
  };

  onAudioPause = () => {
    this.setState({ audioState: 'paused' });
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      audioState: 'paused',
      hasPlayedThrough: true,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { canPlayAudio, isPlaying, hasInitializedPlay } = this.state;

    if (!canPlayAudio) {
      return;
    }

    isPlaying ? pauseAudio(this.trackPreview) : playAudio(this.trackPreview);
    !hasInitializedPlay && this.setState({ hasInitializedPlay: true });

    this.setState({
      isPlaying: !isPlaying,
      mouseLeavePause: false
    });
  };

  setActiveTrack = () => {
    const { setActiveTrack, id } = this.props;

    this.state.isPlaying && this.togglePlayState();
    setActiveTrack(id);
  };

  componentDidMount() {
    this.trackPreview = this.trackPreviewRef.current;
  }

  render() {
    const {
      albumCover,
      name,
      artists,
      previewUrl,
      uri,
      isPassive
    } = this.props;
    const { audioState } = this.state;

    return (
      <div
        className={cx(trackPill, {
          [isPassivePill]: isPassive
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={audioPreview}>
          <span onClick={this.togglePlayState} className={audioToggle}>
            <AudioControl audioState={audioState} />
          </span>
        </div>
        <div className={albumCoverImage}>
          <div style={{ backgroundImage: `url(${albumCover})` }} />
        </div>
        <div className={trackDetails}>
          <h3>{name}</h3>
          <span>{artists}</span>
        </div>
        <div className={actions}>
          <span onClick={this.setActiveTrack}>
            <ExpandAltIcon />
          </span>
          <a href={uri}>
            <SpotifyLogo />
          </a>
        </div>
        <audio
          ref={this.trackPreviewRef}
          preload="none"
          src={previewUrl}
          onCanPlay={this.onCanPlayAudio}
          onPlay={this.onAudioPlay}
          onPause={this.onAudioPause}
          onEnded={this.onAudioEnd}
          onTimeUpdate={this.onAudioTimeUpdate}
        />
      </div>
    );
  }
}

TrackPill.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  artists: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  albumCover: PropTypes.string,
  isPassive: PropTypes.bool
};

export default ExperienceConsumer(UserConsumer(TrackPill));
