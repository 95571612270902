import React from 'react';

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M2.28 14.986c0-4.479 3.667-8.138 8.162-8.138l.294.005.283.015a8.23 8.23 0 015.457 2.626c.439.483.411 1.25-.065 1.684-.484.44-1.252.413-1.685-.063a5.764 5.764 0 00-4.254-1.881c-3.19 0-5.78 2.59-5.78 5.779 0 3.185 2.586 5.754 5.78 5.754 1.652 0 3.175-.668 4.256-1.883a1.181 1.181 0 011.68-.065c.488.445.523 1.21.07 1.685a8.224 8.224 0 01-6.032 2.648c-4.502 0-8.166-3.663-8.166-8.166zm18.797-5.622a1.205 1.205 0 011.59.085l4.709 4.71.085.096a1.205 1.205 0 01-.085 1.589l-4.71 4.71-.103.092c-.215.17-.475.258-.752.258-.324 0-.625-.12-.855-.35a1.203 1.203 0 010-1.686l2.708-2.648h-6.903l-.123-.006a1.201 1.201 0 01-1.083-1.2c0-.683.523-1.206 1.206-1.206h6.894l-2.674-2.674-.085-.095a1.208 1.208 0 010-1.494l.085-.096.096-.085z"
    />
  </svg>
);

export default LogoutIcon;
