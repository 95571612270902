import React from 'react';
import PropTyes from 'prop-types';
import cx from 'classnames';

import { switchToggle, isActive } from './Switch.module.sass';

const Switch = ({ on }) => (
  <div className={cx(switchToggle, { [isActive]: on })} />
);

Switch.propTypes = {
  on: PropTyes.bool
};

export default Switch;
