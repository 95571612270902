import React, { Component, createRef } from 'react';

import { UserConsumer } from '../../contexts/UserContext';
import {
  trackNav,
  navActions,
  previewControl,
  albumCoverBg,
  trackSummary,
  albumCover,
  title,
  playlistButton,
  quickStats,
  artists
} from './MobileTrackDetails.module.sass';
import {
  getImageUrl,
  preloadImages,
  getPopularityGroup,
  formatReleaseDate,
  playAudio,
  pauseAudio,
  fadeOutAudio
} from '../../utils';
import AudioFeatures from './AudioFeatures';
import RadialLoader from '../RadialLoader';
import { TracksCarousel } from '../SwipeCarousel';
import MobileArtistPill from '../MobileArtistPill';
import { PlaylistButton, RadialIconButton } from '../Button';
import SpotifyLogo from '../../vectors/SpotifyLogo';
import OutlinePlayIcon from '../../vectors/OutlinePlayIcon';
import PeopleIcon from '../../vectors/PeopleIcon';
import PublishIcon from '../../vectors/PublishIcon';
import MobileTitleCard from '../MobileTitleCard';
import { MOBILE_MODAL_ENTANCE_DELAY as entranceDelay } from '../../config';

class MobileTrackDetails extends Component {
  state = {
    activeNavIndex: 0,
    isLoading: true
  };

  trackPreviewRef = createRef();

  getTrackData = async () => {
    const {
      resetModalScroll,
      activeTrack,
      getTrack,
      getRecommendations,
      getTracksAudioFeatures,
      getArtists
    } = this.props;

    try {
      await Promise.all([
        getTrack(activeTrack, track => this.setState({ track })),
        getRecommendations(
          { seed_tracks: activeTrack, limit: 6 },
          this.setRelatedTracks
        ),
        getTracksAudioFeatures({ ids: activeTrack }, data =>
          this.setState({ audioFeatures: data[0] })
        )
      ]);

      const { track, relatedTracks } = this.state;

      await getArtists(
        track.artists.map(({ id }) => id).join(),
        this.setTrackArtists
      );

      const trackCover = getImageUrl(track.album.images);

      const artistsWithCovers = this.state.trackArtists.filter(
        ({ artistCover }) => artistCover
      );
      const artistsCovers = artistsWithCovers.map(
        ({ artistCover }) => artistCover
      );
      const albumsWithCovers = relatedTracks.filter(
        ({ albumCover }) => albumCover
      );
      const albumCovers = albumsWithCovers.map(({ albumCover }) => albumCover);
      trackCover && albumCovers.push(trackCover);

      await preloadImages([...artistsCovers, ...albumCovers]);

      this.setState({ isLoading: false }, () => {
        resetModalScroll(false, true);
      });
    } catch (error) {}
  };

  setTrackArtists = data => {
    const trackArtists = data.map(({ id, uri, name, images }) => ({
      id,
      uri,
      artistName: name,
      artistCover: getImageUrl(images)
    }));
    this.setState({ trackArtists });
  };

  setRelatedTracks = data => {
    const relatedTracks = data.map(
      ({ id, name, artists, uri, album, explicit }) => ({
        id,
        name,
        uri,
        explicit,
        artists: artists.map(({ name }) => name),
        albumCover: getImageUrl(album.images)
      })
    );
    this.setState({ relatedTracks });
  };

  onAudioTimeUpdate = () => {
    if (this.trackPreview.currentTime > 28 && !this.state.closeFading) {
      console.log('hey');
      this.setState({ closeFading: true });
      fadeOutAudio(this.trackPreview);
    }
  };

  onAudioEnd = () => {
    this.setState({
      isPlaying: false,
      closeFading: false
    });
  };

  togglePlayState = () => {
    const { isPlaying } = this.state;
    isPlaying ? pauseAudio(this.trackPreview) : playAudio(this.trackPreview);
    this.setState({ isPlaying: !isPlaying });
  };

  setActiveStats = activeNavIndex => {
    this.setState({ activeNavIndex }, this.props.resetModalScroll);
  };

  componentDidUpdate(prevProps) {
    const { activeTrack, resetModalScroll, scrollTo } = this.props;

    if (activeTrack !== prevProps.activeTrack) {
      this.setState(
        {
          isLoading: true,
          isPlaying: false,
          activeNavIndex: 0
        },
        () => {
          resetModalScroll();
          scrollTo(0, false);
        }
      );
      this.getTrackData();
    }
  }

  componentDidMount() {
    this.trackPreview = this.trackPreviewRef.current;
    setTimeout(this.getTrackData, entranceDelay);
  }

  render() {
    const { disableScroll } = this.props;
    const {
      isLoading,
      relatedTracks,
      trackArtists,
      audioFeatures,
      activeNavIndex,
      track = {}
    } = this.state;

    const { name, uri, popularity, preview_url, album = {} } = track;
    const { images, release_date } = album;
    const {
      label: popularityLabel,
      mobileNavIcon: MobileNavIcon,
      icon: popularityIcon
    } = getPopularityGroup(popularity);

    const navMenus = [PeopleIcon, MobileNavIcon, PublishIcon];
    const albumImage = getImageUrl(images);

    const titleCardData = [
      { icon: popularityIcon, label: 'Popularity', value: popularityLabel },
      {
        icon: (
          <span>
            <PublishIcon blaze />
          </span>
        ),
        label: 'Release Date',
        value: formatReleaseDate(release_date)
      }
    ];

    return (
      <>
        <nav className={trackNav}>
          <span className={navActions}>
            <a href={uri}>
              <SpotifyLogo />
            </a>
            {preview_url && !isLoading && (
              <span className={previewControl} onClick={this.togglePlayState}>
                <OutlinePlayIcon /> Play Preview
              </span>
            )}
          </span>
        </nav>
        <div>
          <div>
            <RadialLoader isLoading={isLoading}>
              {!isLoading && (
                <>
                  <div className={albumCoverBg}>
                    <div
                      style={{
                        backgroundImage: `url(${albumImage})`
                      }}
                    />
                  </div>

                  <div className={trackSummary}>
                    <div
                      className={albumCover}
                      style={{
                        backgroundImage: `url(${albumImage})`
                      }}
                    />
                    <h1 className={title}>
                      <span>{name}</span>
                    </h1>
                    <PlaylistButton
                      text="Generate Playlist"
                      className={playlistButton}
                    />
                  </div>

                  <div className={quickStats}>
                    <nav>
                      {navMenus.map((icon, index) => (
                        <RadialIconButton
                          key={index}
                          icon={icon}
                          active={activeNavIndex === index}
                          onClick={() => this.setActiveStats(index)}
                        />
                      ))}
                    </nav>
                    {activeNavIndex === 0 ? (
                      <div className={artists}>
                        {trackArtists.map((artist, index) => (
                          <MobileArtistPill key={index} {...artist} />
                        ))}
                      </div>
                    ) : (
                      <MobileTitleCard
                        data={titleCardData}
                        activeIndex={activeNavIndex - 1}
                      />
                    )}
                  </div>

                  <AudioFeatures audioFeatures={audioFeatures} />
                  <TracksCarousel
                    title="Related Tracks"
                    data={relatedTracks}
                    disableParentScroll={disableScroll}
                  />
                </>
              )}
              <audio
                ref={this.trackPreviewRef}
                preload="auto"
                src={preview_url}
                onEnded={this.onAudioEnd}
                onTimeUpdate={this.onAudioTimeUpdate}
              />
            </RadialLoader>
          </div>
        </div>
      </>
    );
  }
}

export default UserConsumer(MobileTrackDetails);
