import React from 'react';

const DiscFlameIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="60"
    height="60"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M30 7.5c10.988 0 19.979 8.986 19.979 19.97 0 6.961-3.613 13.121-9.058 16.698-.336-1.945-1.193-3.673-2.513-4.863l-1.978-1.782a.535.535 0 00-.862.217l-.45 1.255-.015-.07a12.416 12.416 0 00-1.097-2.941c3.191-1.512 5.413-4.767 5.413-8.515 0-5.178-4.239-9.414-9.419-9.414-5.18 0-9.418 4.236-9.418 9.414 0 3.532 1.972 6.626 4.87 8.236-.472.988-1.07 1.803-1.854 2.585l-.068.067-.099.096-.104.1c-.107.1-.223.209-.358.331l-.766.69-.177.163c-1.563 1.448-2.363 2.606-2.893 4.282l-.048.153c-5.448-3.576-9.064-9.738-9.064-16.703C10.021 16.486 19.011 7.5 30 7.5zm3.996 19.97A4.007 4.007 0 0030 23.474a4.007 4.007 0 00-3.996 3.994c0 .994.368 1.905.974 2.606l.198-.944a.357.357 0 01.591-.188l2.62 2.423.075.07a4.009 4.009 0 003.534-3.967zm-4.914 7.096a.238.238 0 01.093.044l.023.02c2.795 2.715 3.906 5.622 3.333 8.721 1.664.419 2.81-.25 3.439-2.006 2.922 2.634 1.987 9.891-2.768 11.155-3.201-.842-4.775-3.595-3.218-9.149-3.265 3.78-3.323 5.281-2.832 7.538l.046.21a.23.23 0 01-.234.28l-.12-.005a4.357 4.357 0 01-.1-.008l-.03-.002c-3.179-.297-5.172-3.163-4.298-6.725 1.144-3.742 4.78-3.324 6.335-9.7l.046-.195a.238.238 0 01.285-.178z"
    />
  </svg>
);

export default DiscFlameIcon;
