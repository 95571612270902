import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  spiderChart,
  isPassive,
  spiderChartContent,
  chartVector,
  chartTips,
  chartTipsEllipse
} from './SpiderChart.module.sass';
import DrumPads from '../DrumPads';
import ChartTip from './ChartTip';
import { scrollViewListener } from '../../utils';

const CHART_WIDTH = 542;
const CHART_HEIGHT = 542.0005014125236;

const SpiderChart = ({ className, data }) => {
  const [inView, setInview] = useState(false);

  useEffect(() => {
    scrollViewListener(containerRef.current, 1, () => setInview(true));
  });

  const containerRef = useRef();
  const midPoint = (CHART_WIDTH / 2).toFixed(4);
  const pointKeys = Object.keys(data);
  const columns = pointKeys.map((key, i) => {
    return {
      key,
      angle: (Math.PI * 2 * i) / pointKeys.length
    };
  });

  const polarToX = (angle, distance) =>
    Math.cos(angle - Math.PI / 2) * distance * CHART_WIDTH;

  const polarToY = (angle, distance) =>
    Math.sin(angle - Math.PI / 2) * distance * CHART_WIDTH;

  const getPathData = points => {
    const d = points.reduce((acc, point, i, a) => {
      return i === 0
        ? `M ${point[0]},${point[1]}`
        : `${acc} L ${point[0]} ${point[1]}`;
    }, '');
    return `${d}z`;
  };

  const columnsData = columns.map(({ key, angle }) => {
    const { value, ...tipData } = data[key];
    return [polarToX(angle, value / 2), polarToY(angle, value / 2), tipData];
  });

  const percentageFraction = 100 / CHART_WIDTH;

  return (
    <div
      ref={containerRef}
      className={cx(spiderChart, className, {
        [isPassive]: !inView
      })}
    >
      <DrumPads total={36} />
      <div className={spiderChartContent}>
        <svg
          className={chartVector}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
        >
          <defs>
            <path id="MainPath99L" d={getPathData(columnsData)} />
            <ellipse
              id="ELhsjf327"
              opacity="1"
              rx="270"
              ry="270.00025070626174"
              cx="270"
              cy="270.00025070626174"
            />
            <ellipse
              id="Ege65f1D"
              opacity="1"
              rx="179.99975022205308"
              ry="179.99999999954116"
              cx="270"
              cy="270.0002507062618"
            />
            <ellipse
              id="ejH76uGf"
              opacity="1"
              rx="89.99975022205308"
              ry="89.99999999954116"
              cx="270"
              cy="270.0002507062618"
            />
            <rect
              id="Recvb567VC"
              opacity="1"
              x="269"
              y="0"
              width="2"
              height="540"
            />
            <rect
              id="RFht5ytX"
              opacity="1"
              x="269"
              y="0"
              width="2"
              height="540"
            />
            <rect
              id="R656XCvsfu"
              opacity="1"
              x="269"
              y="0"
              width="2"
              height="540"
            />
            <ellipse
              id="Ekdj88i7uyd"
              opacity="1"
              rx="24.99975022205308"
              ry="24.99999999954116"
              cx="270"
              cy="270.0002507062618"
            />
            <linearGradient
              id="lLhkjf734DS"
              x1="0.5"
              y1="0"
              x2="0.48"
              y2="1.11"
            >
              <stop offset="0" stopColor="rgb(51,255,122)" stopOpacity="1" />
              <stop offset="1" stopColor="rgb(51,255,122)" stopOpacity="1" />
            </linearGradient>
          </defs>
          <g transform="translate(1, 0.9999999999999432)" opacity="1">
            <g opacity="1">
              <use
                xlinkHref="#ELhsjf327"
                fill="rgba(17,17,18,0.5)"
                fillOpacity="1"
              />
              <use
                xlinkHref="#ELhsjf327"
                fillOpacity="0"
                stroke="rgb(39,41,43)"
                strokeOpacity="1"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
              />
            </g>
            <g opacity="1">
              <use
                xlinkHref="#Ege65f1D"
                fill="rgba(17,17,18,0.5)"
                fillOpacity="1"
              />
              <use
                xlinkHref="#Ege65f1D"
                fillOpacity="0"
                stroke="rgb(39,41,43)"
                strokeOpacity="1"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
              />
            </g>
            <g opacity="1">
              <use
                xlinkHref="#ejH76uGf"
                fill="rgba(17,17,18,0.5)"
                fillOpacity="1"
              />
              <use
                xlinkHref="#ejH76uGf"
                fillOpacity="0"
                stroke="rgb(39,41,43)"
                strokeOpacity="1"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
              />
            </g>
            <g opacity="1">
              <use
                xlinkHref="#Recvb567VC"
                fill="rgb(39,41,43)"
                fillOpacity="1"
              />
            </g>
            <g
              transform="translate(270, 270) rotate(60.00002145385002) translate(-270, -270)"
              opacity="0.5"
            >
              <use xlinkHref="#RFht5ytX" fill="rgb(39,41,43)" fillOpacity="1" />
            </g>
            <g
              transform="translate(270, 270) rotate(120.00004290770003) translate(-270, -270)"
              opacity="1"
            >
              <use
                xlinkHref="#R656XCvsfu"
                fill="rgb(39,41,43)"
                fillOpacity="1"
              />
            </g>
            <g opacity="1">
              <use xlinkHref="#Ekdj88i7uyd" fill="#111112" fillOpacity="1" />
              <use
                xlinkHref="#Ekdj88i7uyd"
                fillOpacity="0"
                stroke="rgb(39,41,43)"
                strokeOpacity="1"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
              />
            </g>
          </g>
          <g transform={`translate(${midPoint}, ${midPoint})`}>
            <use
              xlinkHref="#MainPath99L"
              fill="rgb(30,215,96)"
              fillOpacity="0.1"
            />
            <use
              xlinkHref="#MainPath99L"
              fillOpacity="0"
              stroke="url(#lLhkjf734DS)"
              strokeOpacity="1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        <div className={chartTips}>
          <div className={chartTipsEllipse} />
          {columnsData.map(([x, y, data], index) => (
            <ChartTip
              key={index}
              x={x}
              y={y}
              percentageFraction={percentageFraction}
              data={data}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SpiderChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired
};

export default SpiderChart;
